import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Card, CardContent, Grid, CssVarsProvider, Stack, Table, Chip } from '@mui/joy';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadUsers } from '../redux/actions';
import AdminHomeInfoCard from './AdminHomeInfoCard';
import GroupIcon from '@mui/icons-material/Group';
import SyncIcon from '@mui/icons-material/Sync';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArticleIcon from '@mui/icons-material/Article';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { timeAgo } from '../utils'

const AdminHomeComponent = (props) => {
  const { totalUsers, dashboardMetrics } = props;
  const [stats, setStats] = useState({
    totalUsers: dashboardMetrics.userCount,
    totalCases: dashboardMetrics.caseCount,
    totalFiles: dashboardMetrics.fileCount,
    recentActivitiesCount: dashboardMetrics.recentActivitiesCount,
    recentActivities: []
  });

  useEffect(() => {
    setStats({
      totalUsers: dashboardMetrics.userCount,
      totalCases: dashboardMetrics.caseCount,
      totalFiles: dashboardMetrics.fileCount,
      recentActivitiesCount: dashboardMetrics.recentActivitiesCount,
      recentActivities: dashboardMetrics?.recentActivities?.reverse()
    });
  }, [dashboardMetrics]);

  return (
    <CssVarsProvider>
    <Box sx={{ padding: 2, overflowY: 'scroll' }}>
      <Grid container spacing={2} columns={{ xs: 12, md: 6, lg: 9, xl: 12 }}>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <AdminHomeInfoCard title={'Total Users'} color={'primary'} value={stats.totalUsers} icon={<GroupIcon />} />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <AdminHomeInfoCard title={'Total Cases'} color={'warning'} value={stats.totalCases} icon={<PictureAsPdfIcon />} />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <AdminHomeInfoCard title={'Total Files'} color={'success'} value={stats.totalFiles} icon={<ArticleIcon />} />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <AdminHomeInfoCard title={'Recent Activities'} color={'danger'} value={stats.recentActivitiesCount} icon={<TrendingUpIcon />} />
        </Grid>
      </Grid>
      <Stack sx={{flexDirection: { sm: 'column', md: 'row'}, py: 2}} spacing={2}>
        <Box sx={{ flex: 1 }}>
          <Typography level="h2" gutterBottom>
            Recent Activities
          </Typography>
          <Table 
            color={'neutral'}
            borderAxis={'none'}
            sx={{borderRadius: '10px'}}
          >
            <thead>
              <tr>
                <th >User</th>
                <th >Action</th>
                <th style={{width: '40%'}} >Activity</th>
                <th >Time</th>
              </tr>
            </thead>
            <tbody>
              {stats?.recentActivities?.map((activity, index) => (
                <tr key={index}>
                  <td>{activity.user.username}</td>
                  <td><Chip color={'success'} startDecorator={<SyncIcon />}>{activity.action}</Chip></td>
                  <td>{activity.details}</td>
                  <td>{timeAgo(activity.timestamp)}</td>
                </tr>
              ))}
            </tbody>
            
          </Table>
        </Box>
        <Box sx={{ flex: 1 }}>
          <Typography level="h2" gutterBottom>
            Announcements
          </Typography>
          <Box>
            {/* Display announcements or notifications here */}
            <Typography>No announcements at this time.</Typography>
          </Box>
        </Box>
      </Stack>
    </Box>
    </CssVarsProvider>
  );
};

const mapStateToProps = (store) => ({
  dashboardMetrics: store.admin.dashboardMetrics
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
  loadUsers
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AdminHomeComponent);
