import React from "react";

const DeveloperComponent = ({ content }) => {
    return (
        <section>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 col-md-12">
                        <div className="team-single box-shadow white-bg rounded">
                            <div className="team-images">
                                <img className="img-fluid w-100 rounded" src={content.image} alt={content.name} />
                            </div>
                            <div className="team-contact p-5">
                                <ul className="list-unstyled team-meta">
                                    <li>
                                        <i className="flaticon flaticon-phone-call"></i>
                                        <div>
                                            <span>Phone:</span>
                                            <a href={`tel:${content.phone}`}>{content.phone}</a>
                                        </div>
                                    </li>
                                    <li>
                                        <i className="flaticon flaticon-open"></i>
                                        <div>
                                            <span>Email:</span>
                                            <a href={`mailto:${content.email}`}>{content.email}</a>
                                        </div>
                                    </li>
                                    <li>
                                        <i className="flaticon flaticon-expert"></i>
                                        <div>
                                            <span>Specialist:</span>{content.specialist}
                                        </div>
                                    </li>
                                    <li>
                                        <i className="flaticon flaticon-calendar"></i>
                                        <div>
                                            <span>Experience:</span>{content.experience}
                                        </div>
                                    </li>
                                </ul>
                                <div className="social-icons team-social-icon">
                                    <ul>
                                        {content.socialLinks.map((link, index) => (
                                            <li key={index}>
                                                <a href={link.url}>
                                                    <i className={`bi ${link.icon}`}></i>
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-12 mt-6 mt-lg-0 ps-lg-8">
                        <div className="team-description">
                            <h2 className="mb-2">{content.name}</h2>
                            <p className="lead mb-3 text-black">{content.description}</p>
                            <p className="mb-4">{content.additionalInfo}</p>
                            {content.skills.map((skill, index) => (
                                <div key={index} className="ht-progress-bar">
                                    <h4>{skill.name}</h4>
                                    <div className="progress" data-value={skill.percentage}>
                                        <div className="progress-bar" style={{ width: `${skill.percentage}%` }}>
                                            <div className="progress-parcent">
                                                <span>{skill.percentage}</span>%
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default DeveloperComponent;
