import React from "react";

export default function LandingHero({ content }){
    return(
        <section className="banner">
        <div className="container">
            <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 order-lg-1 ps-lg-8">
                <div className="banner-img wow animate__fadeInRight animate__slow">
                <img className="img-fluid banner-img2 rounded topBottom" src={content?.secondaryTopBanner?.src} alt={content?.secondaryTopBanner?.alt} />
                <div className="macbook-box box-shadow">
                    <img className="img-fluid" src={content?.mainBanner?.src} alt={content?.mainBanner?.alt} />
                </div>
                <img className="img-fluid banner-img3 rounded leftRight" src={content?.secondaryRightBanner?.src} alt={content?.secondaryRightBanner?.alt} />
                </div>
            </div>
            <div className="col-lg-6 col-md-12 mt-8 mt-lg-0">
                <h1 className="mb-4 wow animate__shakeX animate__slow">{ content?.title[0] || '' }<span>{content?.title[1] || '' }</span> {content?.title[2] || ''} </h1>
                <p className="lead mb-5 wow animate__zoomIn animate__slow">{content?.description}</p>
                <div className="wow animate__fadeInUp animate__slow">
                <a className="themeht-btn" href={content?.mainButton?.href || '#'}>{content?.mainButton?.text || ''}</a>
                <a className="themeht-btn dark-btn ms-3" href={content?.secondaryButton?.href || '#'}>{ content?.secondaryButton?.text || '' }</a>
                </div>
            </div>
            </div>
        </div>
        <div className="round-shape-animation"></div>
        </section>
    )
}