

import React, { useEffect, useState } from 'react';

import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import Stack from '@mui/joy/Stack';


import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createNewCase } from '../redux/actions';
import { CssVarsProvider } from '@mui/joy';
import { useNavigate } from 'react-router-dom';

const CreateNewBundleModal = React.memo((props) => {
    const { open, setOpen, user, setBackDropOpen, createNewCase } = props;
    const navigate = useNavigate();

    const handleClose = () => {
        setBackDropOpen(false);
        setOpen(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setBackDropOpen(true);
        const { name: { value: name }, number: { value: number } } = event.target;
        if ( name?.trim().length > 0 && number > 0 ) {
            createNewCase(user.apiKey, name, number, navigate, handleClose);
        }
        handleClose();
    };

    return (
        <React.Fragment>
        <CssVarsProvider>
        <Modal open={open} onClose={handleClose}>
            <ModalDialog>
            <DialogTitle>Create a New Bundle</DialogTitle>
            <DialogContent>To give your bundle an unique identity, please enter your name and number below.</DialogContent>
            <form
                onSubmit={handleSubmit}
            >
                <Stack spacing={2}>
                    <FormControl>
                        <FormLabel>Bundle Name</FormLabel>
                        <Input autoFocus required name='name' id='name' type='text' />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Case Number</FormLabel>
                        <Input autoFocus required name='number' id='number' type='number' />
                    </FormControl>
                    <Button type="submit">Create Bundle</Button>
                </Stack>
            </form>
            </ModalDialog>
        </Modal>
        </CssVarsProvider>
        </React.Fragment>
    );
});


const mapStateToProps = (store) => ({
    user: store.user,
    bundleBusy: store.app.bundleUpdating,
    deskBusy: store.app.creatingFolder,
    toolBarBusy: store.app.toolBarBusy,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    createNewCase
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewBundleModal);
