import * as constants from '../constants';
const initialState = {
    loading: false,
    cases: [],
    trash: [],
    shared: [],
    connectedUsers: [],
    connectedUsersData: {
        totalPages: 1,
        totalUsers: 0,
        users: {}
    },
    searchedConnectedUsersData: {
        totalPages: 1,
        totalUsers: 0,
        users: {}
    },
}

export const user = (state = initialState, action) => {
    switch(action.type){
        case constants.ON_USER_LOADED:
            return{
                ...state,
                ...action.data,
            }
        case constants.SIGNIN_SUCCESS:
            return {
                ...state,
                ...action.data,
                loading: false,
            }
        case constants.SIGNUP_SUCCESS:
            return {
                ...state,
                ...action.data,
                loading: false,
            }
        case constants.ON_ALL_CASES_LOADED:
            return {
                ...state,
                cases: action.data
            }
        case constants.ON_SHARED_CASES_LOADED:
            return {
                ...state,
                shared: action.data
            }
        case constants.ON_TRASH_CASES_LOADED:
            return {
                ...state,
                trash: action.data
            }
        case constants.ON_BUTTON_PRESSED:
            return{
                ...state,
                loading: true
            }
        case constants.ON_ACTION_FAILED:
            return{
                ...state,
                loading: false
            }
        case constants.ON_CASE_REMOVED:
            return {
                ...state,
                trash: [
                    ...state.trash,
                    {...state.cases.filter(value => value._id === action.data)[0], flagged: true }
                ],
                cases: state.cases.filter(value => value._id !== action.data)
            }
        case constants.ON_CASE_DELETED:
            return {
                ...state,
                trash: state.trash.filter(value => value._id !== action.data)
            }
        case constants.ON_CASE_RECOVERED:
            return {
                ...state,
                cases: [
                    ...state.cases,
                    {...state.trash.filter(value => value._id === action.data)[0], flagged: false}
                ],
                trash: state.trash.filter(value => value._id !== action.data)
            }
        case constants.ON_NEW_CASE_CREATED:
            return {
                ...state,
                cases: [
                    ...state.cases,
                    action.data
                ]
            }
        case constants.ON_USER_RENAME_BUNDLE:
            return {
                ...state,
                cases: state.cases.map(caseItem => caseItem._id === action.data._id ? { ...caseItem, name: action.data.newName }: caseItem )
            }
        case constants.ON_USER_DETAILS_UPDATED:
            return {
                ...state,
                ...action.data,
            }
        case constants.ON_CONNECTED_USERS_LOADED: {
            return {
                ...state,
                connectedUsersData: {
                    ...state.connectedUsersData,
                    totalPages: action.data.totalPages,
                    totalUsers: action.data.totalUsers,
                    users: {
                        ...state.connectedUsersData.users,
                        [action.page]: action.data.users
                    }
                }
            }
        }
        case constants.ON_CONNECTED_USER_CREATED:
            return {
                ...state,
                connectedUsersData: {
                    ...state.connectedUsersData,
                    totalPlans: action.data.totalUsers + 1,
                    users: {
                        ...state.connectedUsersData.users,
                        1: [action.data, ...(state.connectedUsersData.users[1] || [])]
                    }
                }
            }
        case constants.SIGNOUT_SUCCESS:
            return initialState;
        default:
            return state;
    }
}