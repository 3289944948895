import React from "react";

const TeamComponent = ({ content }) => {
    return (
        <section>
            <div className="container">
                <div className="row justify-content-center text-center">
                    <div className="col-lg-8 col-md-12">
                        <div className="theme-title">
                            <h6>{content.teamExpert}</h6>
                            <h2>{content.title}</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {content.teamMembers.map((member, index) => (
                        <div key={index} className="col-lg-3 col-md-6">
                            <div className="team-member">
                                <div className="team-images">
                                    <img className="img-fluid rounded" src={member.image} alt={member.name} />
                                    <div className="social-icons team-social-icon">
                                        <ul>
                                            {member.socialLinks.map((link, i) => (
                                                <li key={i}>
                                                    <a href={link.url}>
                                                        <i className={link.iconClass}></i>
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className="team-desc">
                                    <h5><a href={member.profileUrl}>{member.name}</a></h5>
                                    <span>{member.role}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default TeamComponent;
