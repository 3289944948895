import { createWebSocket } from '../../utils';
import { arrayBufferToBase64 } from '../../utils';
import { calculatePageNumbers, findAndAddItem, findAndRemoveItem } from '../../utils';
import * as constants from '../constants'

export function clearData() {
    return ((dispatch) => {
        dispatch({type: constants.CLEAR_DATA})
    })
}

export function closeCase(){
    return (dispatch => {
        dispatch({type: constants.ON_CASE_CLOSED})
        dispatch({type: constants.ON_SYSTEM_CLEAN})
    })
}

export function loadTheme(){
    return ((dispatch) => {
        const theme = localStorage.getItem('systemTheme') || 'dark';
        dispatch({type: constants.ON_THEME_LOADED, data: theme});
    })
}

export function toggleTheme(){
    return ((dispatch, getState) => {
        const { theme } = getState().app;
        localStorage.setItem('systemTheme', theme === 'dark' ? 'light' : 'dark' )
        dispatch({type: constants.ON_TOGGLE_THEME})
        window.location.reload();
    })
}

export function addLog(level, message) {
  return async (dispatch) => {
    try {
      const apiEndpoint = `/api/log`;
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ level, message }),
      });

      if (!response.ok) {
        const json = await response.json();
        throw new Error(`Error: ${json.message}`);
      }

      const json = await response.json();
    } catch (error) {
      console.error(error.message);
    }
  };
}

export function addAuditTrail(apiKey, action, details) {
  return async (dispatch) => {
    try {
      const apiEndpoint = `/api/audits`;
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          'x-api-key': apiKey,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({action, details}),
      });

      if (!response.ok) {
        const json = await response.json();
        throw new Error(`Error: ${json.message}`);
      }

      const json = await response.json();
    } catch (error) {
      console.error(error.message);
    }
  };
}

export function logout(navigate) {
    return async dispatch => {
        try {
            const response = await fetch('/api/auth/logout', {
                method: 'POST',
                credentials: 'include' // Send cookies with the request
            });
            if (response.ok) {
                dispatch({type: constants.SIGNOUT_SUCCESS})
                navigate('/')
            } else {
                console.error('Failed to logout:', response.statusText);
            }
        } catch (error) {
            console.error('Failed to logout:', error);
        }
    };
}

export function loadUser() {
    return async dispatch => {
        try {
            const response = await fetch('/api/user/me', { credentials: 'include' });
            if (response.ok) {
                const data = await response.json();
                if (data.user && !data.isAdmin) {
                    dispatch({ type: constants.ON_USER_LOADED, data: data.user });
                }
            } else {
                console.error('Failed to load user:', response.statusText);
            }
        } catch (error) {
            console.error('Failed to load user:', error);
        }
    };
}

export function loadAdmin() {
    return async dispatch => {
        try {
            const response = await fetch('/api/user/me', { credentials: 'include' });
            if (response.ok) {
                const data = await response.json();
                if (data.user && data.isAdmin) {
                    dispatch({ type: constants.ON_ADMIN_LOADED, data: data.user });
                }
            } else {
                console.error('Failed to load admin:', response.statusText);
            }
        } catch (error) {
            console.error('Failed to load admin:', error);
        }
    };
}

export function updateUser(user){
    return (async dispatch => {
        const data = localStorage.getItem('userInfo');
        if(data){
            const json = JSON.parse(data);
            localStorage.setItem('userInfo', JSON.stringify({ ...json, user }));
            dispatch({ type: constants.ON_USER_LOADED, data: user })
        }        
    })
}

export function signin(email, password, persistent, location, navigate){
    return (async dispatch => {
        try {
            console.log(JSON.stringify({ email, password, location }))
            dispatch({type: constants.ON_SYSTEM_USER_LOGGING_IN})
            const response = await fetch('/api/auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, password, location }),
                credentials: 'include'
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to sign in');
            }

            const data = await response.json();

            await dispatch({ type: constants.SIGNIN_SUCCESS, data: data.user });
            // localStorage.setItem('userInfo', JSON.stringify(data));
            navigate('/')
        } catch (error) {
            console.error(error);
            dispatch({ type: constants.SIGNIN_FAIL });
            alert(error, null, 2);
        } finally {
            dispatch({type: constants.ON_SYSTEM_USER_LOGGED_IN})
        }
    })
}

export function adminsignin(email, password, persistent, navigate){
    return (async dispatch => {
        try {
            dispatch({type: constants.ON_SYSTEM_USER_LOGGING_IN})
            const response = await fetch('/api/admin/signin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, password }),
                credentials: 'include'
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to sign in');
            }

            const data = await response.json();

            await dispatch({ type: constants.ON_ADMIN_SIGNIN_SUCCESS, data: data.user });
            navigate('/')
        } catch (error) {
            console.error(error);
            dispatch({ type: constants.ON_ADMIN_SIGNIN_FAIL });
            alert(error, null, 2);
        } finally {
            dispatch({type: constants.ON_SYSTEM_USER_LOGGED_IN})
        }
    })
}


export function signup( firstname, lastname, username, email, password, businessName, location, navigate){
    return (async dispatch => {
        try{
            dispatch({type: constants.ON_SYSTEM_USER_SIGNING_UP})
            const response = await fetch('/api/auth/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ firstname, lastname, username, email, password, businessName, location })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to sign in');
            }

            const data = await response.json();

            await dispatch({ type: constants.SIGNUP_SUCCESS, data: data.user });
            navigate('/')
        } catch(error){
            console.error(error);
            dispatch({ type: constants.SIGNUP_FAIL });
            alert(error, null, 2);
        } finally {
            dispatch({type: constants.ON_SYSTEM_USER_SIGNED_UP})
        }
    })
}

export function loadConnectedUsers(apiKey, page, limit) {
  return (async dispatch => {
    try{
      const response = await fetch(`/api/user/connected-users?page=${page}&limit=${limit}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': apiKey
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to load the connected users');
      }

      const json = await response.json();
      await dispatch({ type: constants.ON_CONNECTED_USERS_LOADED, data: json, page });

    } catch (error){
      console.log(error)
    }
  })
}

export function addConnectedUser( apiKey, user, enqueueSnackbar, handleOnFinished ){
  return (async dispatch => {
    try{
      dispatch({ type: constants.ON_SYSTEM_CREATING_CONNECTED_USER })
      const response = await fetch('/api/user/connected-user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': apiKey
        },
        body: JSON.stringify({ ...user, role: 'Additional' })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to create the user');
      }

      const json = await response.json();
      await dispatch({ type: constants.ON_CONNECTED_USER_CREATED, data: json });
      enqueueSnackbar('Connected User Created Successfully', { variant: 'success' })
    } catch(error){
      enqueueSnackbar(error.message, { variant: 'error' })
    } finally {
      dispatch({type: constants.ON_SYSTEM_CONNECTED_USER_CREATED })
      handleOnFinished();
    }
  })
}


export function loadPDF(caseid, apiKey) {
    return (async (dispatch, getState) => {
        const { app: { loadingMergedPDF }, caseData: { bundle: { items: xitems} }} = getState();
        if(loadingMergedPDF){
            return;
        }
        try {
            await dispatch({ type: constants.ON_SYSTEM_LOADING_MERGED_PDF })
            const apiEndpoint = `/api/case/${caseid}/bundle/pdf`;
            const response = await fetch(apiEndpoint, {
                headers: { 'x-api-key': apiKey }
            });
            const arrayBuffer = await response.arrayBuffer();
            const base64String = arrayBufferToBase64(arrayBuffer);
            await dispatch({type: constants.ON_PDF_LOADED, data: base64String})
            const pageIndexes = await calculatePageNumbers(xitems);
            await dispatch({type: constants.ON_SYSTEM_DOCUMENTS_INDEXES_CALCULATED, data: pageIndexes})
        } catch (error) {
            console.error('Error fetching PDF:', error);
        } finally {
            dispatch({ type: constants.ON_SYSTEM_LOADED_MERGED_PDF })
        }
    })
}

export function openCase(caseid, apiKey, navigate = null){
    return (async (dispatch) => {
        try {
            const response = await fetch(`/api/case/${caseid}/open`, {
                headers: {
                    'x-api-key': apiKey,
                },
            });
            if (!response.ok) {
                const json = await response.json();
                throw new Error(`Error: ${json.message}`);
            }
            const json = await response.json();
            dispatch({type: constants.ON_CASE_LOADED, data: json.data})

            if(json.data.pagination)
                await dispatch({type: constants.ON_PAGINATION_ON, data: json.data.pagination})
            else
                await dispatch({type: constants.ON_PAGINATION_OFF})

            if(navigate){
                navigate(`/case/${caseid}`)
            }
            
        } catch (error) {
            console.error(error);
        }
    });
}

export function updatePaginationSettings(caseid, apiKey, paginationSettings, enqueueSnackbar){
    return (async (dispatch) => {
        try {
            dispatch({type: constants.ON_SYSTEM_PAGINATION_SETTINGS_UPDATING});
            const response = await fetch(`/api/case/${caseid}/updatepaginationsettings`, {
                method: 'POST',
                headers: {
                    'x-api-key': apiKey, 'Content-Type': 'application/json'
                },
                body: JSON.stringify({paginationSettings})
            });
            if (!response.ok) {
                const json = await response.json();
                throw new Error(`Error: ${json.message}`);
            }
            const json = await response.json();
            dispatch({type: constants.ON_PAGINATION_SETTINGS_UPDATED, data: json.data});
            enqueueSnackbar(json.message, { variant: 'success' })
        } catch (error) {
            console.error(error);
            enqueueSnackbar(error, { variant: 'error' })
        } finally {
            dispatch({type: constants.ON_SYSTEM_PAGINATION_SETTINGS_UPDATED});
        }
    });
}

export function createNewCase(apiKey, name, number, navigate, handleClose){
    return (async (dispatch) => {
        try {
            const response = await fetch(`/api/case/new`, {
                method: 'post',
                headers: {
                  'x-api-key': apiKey,
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({ name, number })
            });
            if (!response.ok) {
                const json = await response.json();
                throw new Error(`Error: ${json.message}`);
            }
            const json = await response.json();
            await dispatch({type: constants.ON_CASE_LOADED, data: json.data})
            await dispatch({type: constants.ON_NEW_CASE_CREATED, data: json.data})
            navigate(`/case/${json.data._id}`)         
        } catch (error) {
            console.error(error);
        } finally {
            if(handleClose)
                handleClose();
        }
    });
}

export function setToBeRenamedCaseId(caseid){
    return (dispatch => {
        dispatch({type: constants.SET_TO_BE_RENAMED_CASE_ID, data: caseid})
    })
}

export function clearToBeRenamedCaseId(){
    return (dispatch => {
        dispatch({type: constants.CLEAR_TO_BE_RENAMED_CASE_ID})
    })
}

export function setToBeSharedCaseId(caseid){
    return (dispatch => {
        dispatch({type: constants.SET_TO_BE_SHARED_CASE_ID, data: caseid})
    })
}

export function clearToBeSharedCaseId(){
    return (dispatch => {
        dispatch({type: constants.CLEAR_TO_BE_SHARED_CASE_ID})
    })
}

export function setToBeRemovedCaseId(caseid){
    return (dispatch => {
        dispatch({type: constants.SET_TO_BE_REMOVED_CASE_ID, data: caseid})
    })
}

export function clearToBeRemovedCaseId(){
    return (dispatch => {
        dispatch({type: constants.CLEAR_TO_BE_REMOVED_CASE_ID})
    })
}

export function setToBeDeletedCaseId(caseid){
    return (dispatch => {
        dispatch({type: constants.SET_TO_BE_DELETED_CASE_ID, data: caseid})
    })
}

export function clearToBeDeletedCaseId(){
    return (dispatch => {
        dispatch({type: constants.CLEAR_TO_BE_DELETED_CASE_ID})
    })
}

export function setToBeRecoveredCaseId(caseid){
    return (dispatch => {
        dispatch({type: constants.SET_TO_BE_RECOVERED_CASE_ID, data: caseid})
    })
}

export function clearToBeRecoveredCaseId(){
    return (dispatch => {
        dispatch({type: constants.CLEAR_TO_BE_RECOVERED_CASE_ID})
    })
}

export function removeCase(caseid, apiKey, enqueueSnackbar, handleClose){
    return (async (dispatch, getState) => {
        const { removingCase } = getState().app;
        if(removingCase){
            return;
        }
        try {
            dispatch({type: constants.ON_SYSTEM_REMOVING_CASE})
            const response = await fetch(`/api/case/${caseid}/remove`, {
                method: 'POST',
                headers: {
                    'x-api-key': apiKey,
                },
            });
            if (!response.ok) {
                const json = await response.json();
                throw new Error(`Error: ${json.message}`);
            }
            const json = await response.json();
            dispatch({type: constants.ON_CASE_REMOVED, data: caseid })
            enqueueSnackbar(json.message, { variant: 'success' })
        } catch (error) {
            console.error(error);
            enqueueSnackbar(error, { variant: 'error' })
        } finally {
            dispatch({type: constants.ON_SYSTEM_REMOVED_CASE})
            if(handleClose){
                handleClose();
            }
        }
    })
}

export function shareCase(caseid, apiKey, email, permission, enqueueSnackbar, handleClose){
    return (async (dispatch, getState) => {
        const { sharingCase } = getState().app;
        if(sharingCase){
            return;
        }
        try {
            dispatch({type: constants.ON_SYSTEM_SHARING_CASE})
            const response = await fetch(`/api/case/${caseid}/share`, {
                method: 'POST',
                headers: {
                    'x-api-key': apiKey, 'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, permission })
            });
            if (!response.ok) {
                const json = await response.json();
                throw new Error(`Error: ${json.message}`);
            }
            const json = await response.json();
            dispatch({type: constants.ON_CASE_SHARED, data: caseid })
            enqueueSnackbar(json.message, { variant: 'success' })
        } catch (error) {
            console.error(error);
            enqueueSnackbar(error, { variant: 'error' })
        } finally {
            dispatch({type: constants.ON_SYSTEM_SHARED_CASE})
            if(handleClose){
                handleClose();
            }
        }
    })
}

export function recoverCase(caseid, apiKey, enqueueSnackbar, handleClose){
    return (async (dispatch, getState) => {
        const { recoveringCase } = getState().app;
        if(recoveringCase){
            return;
        }
        try {
            dispatch({type: constants.ON_SYSTEM_RECOVERING_CASE})
            const response = await fetch(`/api/case/${caseid}/recover`, {
                method: 'POST',
                headers: {
                    'x-api-key': apiKey,
                },
            });
            if (!response.ok) {
                const json = await response.json();
                throw new Error(`Error: ${json.message}`);
            }
            const json = await response.json();
            dispatch({type: constants.ON_CASE_RECOVERED, data: caseid })
            enqueueSnackbar(json.message, { variant: 'success' })
        } catch (error) {
            console.error(error);
            enqueueSnackbar(error, { variant: 'error' })
        } finally {
            dispatch({type: constants.ON_SYSTEM_RECOVERED_CASE})
            if(handleClose){
                handleClose();
            }
        }
    })
}

export function deleteCase(caseid, apiKey, enqueueSnackbar, handleClose){
    return (async (dispatch, getState) => {
        const { deletingCase } = getState().app;
        if(deletingCase){
            return;
        }
        try {
            dispatch({type: constants.ON_SYSTEM_DELETING_CASE})
            const response = await fetch(`/api/case/${caseid}/delete`, {
                method: 'POST',
                headers: {
                    'x-api-key': apiKey,
                },
            });
            if (!response.ok) {
                const json = await response.json();
                throw new Error(`Error: ${json.message}`);
            }
            const json = await response.json();
            dispatch({type: constants.ON_CASE_DELETED, data: caseid })
            enqueueSnackbar(json.message, { variant: 'success' })
        } catch (error) {
            console.error(error);
            enqueueSnackbar(error, { variant: 'error' })
        } finally {
            dispatch({type: constants.ON_SYSTEM_DELETED_CASE})
            if(handleClose){
                handleClose();
            }
        }
    })
}

export function deleteItem(caseid, apiKey, itemid, enqueueSnackbar){
    return (async (dispatch, getState) => {
        const { deletingItem } = getState().app;
        if(deletingItem){
            return;
        }
        try {
            dispatch({type: constants.ON_SYSTEM_DELETING_ITEM})
            const response = await fetch(`/api/case/${caseid}/item/${itemid}/delete`, {
                method: 'POST',
                headers: {
                    'x-api-key': apiKey,
                },
            });
            if (!response.ok) {
                const json = await response.json();
                throw new Error(json.message);
            }
            const json = await response.json();
            const { data: {deskSpace: { items }} } = json;
            dispatch({type: constants.ON_ITEM_DELETED, data: items })
            enqueueSnackbar(json.message, { variant: 'success' })
        } catch (error) {
            enqueueSnackbar(error, { variant: 'error' })
        } finally {
            dispatch({type: constants.ON_SYSTEM_DELETED_ITEM})
        }
    })
}

export function getUserCases(apiKey){
    return (async (dispatch, getState) => {
        const { loadingCases } = getState().app;
        if(loadingCases){
            return;
        }
        try {
            dispatch({type: constants.ON_SYSTEM_LOADING_CASES})
            const response = await fetch(`/api/case/all`, {
                headers: {
                    'x-api-key': apiKey,
                },
            });
            if (!response.ok) {
                const json = await response.json();
                throw new Error(`Error: ${json.message}`);
            }
            const json = await response.json();
            dispatch({type: constants.ON_ALL_CASES_LOADED, data: json.data})
        } catch (error) {
            console.error(error);
        } finally {
            dispatch({type: constants.ON_SYSTEM_LOADED_CASES})
        }
    });
}

export function getFlaggedCases(apiKey){
    return (async (dispatch, getState) => {
        const { loadingTrashCases } = getState().app;
        if(loadingTrashCases){
            return;
        }
        try {
            dispatch({type: constants.ON_SYSTEM_LOADING_TRASH_CASES})
            const response = await fetch(`/api/case/trash`, {
                headers: {
                    'x-api-key': apiKey,
                },
            });
            if (!response.ok) {
                const json = await response.json();
                throw new Error(`Error: ${json.message}`);
            }
            const json = await response.json();
            dispatch({type: constants.ON_TRASH_CASES_LOADED, data: json.data})
        } catch (error) {
            console.error(error);
        } finally {
            dispatch({type: constants.ON_SYSTEM_LOADED_TRASH_CASES})
        }
    });
}

export function getSharedCases(apiKey){
    return (async (dispatch, getState) => {
        const { loadingSharedCases } = getState().app;
        if(loadingSharedCases){
            return;
        }
        try {
            dispatch({type: constants.ON_SYSTEM_LOADING_SHARED_CASES})
            const response = await fetch(`/api/case/shared`, {
                headers: {
                    'x-api-key': apiKey,
                },
            });
            if (!response.ok) {
                const json = await response.json();
                throw new Error(`Error: ${json.message}`);
            }
            const json = await response.json();
            dispatch({type: constants.ON_SHARED_CASES_LOADED, data: json.data})
        } catch (error) {
            console.error(error);
        } finally {
            dispatch({type: constants.ON_SYSTEM_LOADED_SHARED_CASES})
        }
    });
}

export function editUserDetails(apiKey, formData, enqueueSnackbar){
    return (async (dispatch, getState) => {
        const { updatingUserDetails } = getState().app;
        if(updatingUserDetails){
            return;
        }
        try {
            dispatch({type: constants.ON_SYSTEM_UPDATING_USER_DETAILS})
            const response = await fetch(`/api/user/editdetails`, {
                method: 'POST',
                headers: {
                    'x-api-key': apiKey,
                },
                body: formData
            });
            if (!response.ok) {
                const json = await response.json();
                throw new Error(`Error: ${json.message}`);
            }
            const json = await response.json();
            dispatch({type: constants.ON_USER_DETAILS_UPDATED, data: json.data})
            localStorage.setItem('userInfo', JSON.stringify(json.data));
            enqueueSnackbar("User Details Updated Successfully", { variant: 'success' })
        } catch (error) {
            console.error(error);
            enqueueSnackbar(error, { variant: 'error' })
        } finally {
            dispatch({type: constants.ON_SYSTEM_UPDATED_USER_DETAILS})
        }
    });
}

export function mergePDF(caseid, apiKey, togglePagination = false, reGenerateToc = true){
    return (async (dispatch, getState) => {
        try {
            const { caseData: { bundle: { items: xitems}, isTocCreated } } = getState();
            let { app: { pagination } } = getState();

            dispatch({type: constants.ON_SYSTEM_UPDATING_BUNDLE})
            dispatch({type: constants.ON_SYSTEM_PAGINATION_START})

            if(reGenerateToc){
                await dispatch(generateToc(caseid, apiKey, pagination));
            }

            const apiEndpoint = `/api/case/${caseid}/bundle/mergefiles`;
            const response = await fetch(apiEndpoint, {
                method: 'post',
                headers: { 'x-api-key': apiKey }
            });
            if (!response.ok) {
                const json = await response.json();
                throw new Error(`Error: ${json.message}`);
            }
            const arrayBuffer = await response.arrayBuffer();
            const base64String = arrayBufferToBase64(arrayBuffer);
            dispatch({type: constants.ON_PDF_LOADED, data: base64String})

            const pageIndexes = await calculatePageNumbers(xitems);
            await dispatch({type: constants.ON_SYSTEM_DOCUMENTS_INDEXES_CALCULATED, data: pageIndexes})

            await dispatch(loadNotes(caseid, apiKey));
        } catch (error) {
            console.error('Error fetching PDF:', error);
        } finally{
            dispatch({type: constants.ON_SYSTEM_UPDATED_BUNDLE})
            dispatch({type: constants.ON_SYSTEM_PAGINATION_DONE})
        }
    })
}

export function setPagination(caseid, apiKey, pagination){
    return (async (dispatch) => {
        try {
            dispatch({type: constants.ON_SYSTEM_PAGINATION_START})
            const apiEndpoint = `/api/case/${caseid}/bundle/mergefiles?pagination=${pagination ? 1 : 0}`;
            const response = await fetch(apiEndpoint, {
                method: 'post',
                headers: { 'x-api-key': apiKey }
            });
            if (!response.ok) {
                const json = await response.json();
                
                throw new Error(`Error: ${json.message}`);
            }
            const arrayBuffer = await response.arrayBuffer();
            const base64String = arrayBufferToBase64(arrayBuffer);
            dispatch({type: constants.ON_PDF_LOADED, data: base64String})

            if(pagination)
                dispatch({type: constants.ON_PAGINATION_ON})
            else
                dispatch({type: constants.ON_PAGINATION_OFF})
        } catch (error) {
            console.error('Error fetching PDF:', error);
        } finally{
            dispatch({type: constants.ON_SYSTEM_PAGINATION_DONE})
        }
    })
}

export function generateToc(caseid, apiKey, explicitPagination = null){
    return (async (dispatch, getState) => {
        try{
            const apiEndpoint = `/api/case/${caseid}/deskspace/createtableofcontents`;
            const response = await fetch(apiEndpoint, {
                method: 'post',
                headers: { 'x-api-key': apiKey, 'Content-Type': 'application/json'},
            })
            if (!response.ok) {
                const json = await response.json();
                
                throw new Error(`Error: ${json.message}`);
            }
            // const arrayBuffer = await response.arrayBuffer();
            await dispatch(refreshDeskSpace(caseid, apiKey));
        } catch(error){
            console.log(error.message);
        }
    })
}

export function renameCase(caseid, apiKey, newCaseName, enqueueSnackbar, handleClose = null){
    return (async dispatch => {
        try{
            const response = await fetch(`/api/case/${caseid}/rename`, {
                method: 'post',
                headers: { 'x-api-key': apiKey, 'Content-Type': 'application/json'},
                body: JSON.stringify({ name: newCaseName })
            })
            if (!response.ok) {
                const json = await response.json();
                
                throw new Error(`Error: ${json.message}`);
            }
            const json = await response.json();
            dispatch({type: constants.ON_RENAME_BUNDLE, data: json.data});
            dispatch({type: constants.ON_USER_RENAME_BUNDLE, data: {_id: caseid, newName: json.data}});
            enqueueSnackbar('Case Name Updated Successfully!', { variant: 'success' })
        } catch(error){
            console.log(error.message);
            enqueueSnackbar(error, { variant: 'error' })
        } finally {
            if(handleClose){
                handleClose();
            }
        }
    })
}

export function toggleCaseFavorite(caseid, apiKey, enqueueSnackbar){
    return (async (dispatch, getState) => {
        const { caseData: { favorite } } = getState();
        try{
            dispatch({type: constants.ON_ADDED_OR_REMOVED_CASE_TO_OR_FROM_FAVORITE_CASES, data: !favorite});
            const response = await fetch(`/api/case/${caseid}/togglefavorite`, {
                method: 'post',
                headers: { 'x-api-key': apiKey, 'Content-Type': 'application/json'},
            })
            if (!response.ok) {
                const json = await response.json();
                
                throw new Error(`Error: ${json.message}`);
            }
            const json = await response.json();
            dispatch({type: constants.ON_ADDED_OR_REMOVED_CASE_TO_OR_FROM_FAVORITE_CASES, data: json.data});
            if(json.data === true){
                enqueueSnackbar('Case Added to Favorites Successfully!', { variant: 'success' })
            }
            else{
                enqueueSnackbar('Case Removed from Favorites Successfully!', { variant: 'success' })
            }
        } catch(error){
            console.log(error.message);
            dispatch({type: constants.ON_ADDED_OR_REMOVED_CASE_TO_OR_FROM_FAVORITE_CASES, data: favorite});
            enqueueSnackbar(error, { variant: 'error' })
        }
    })
}

export function updateBundleLocally(itemId, newParentId, index) {
    return async (dispatch, getState) => {
        try {
            const state = await getState().caseData;
            const newState = JSON.parse(JSON.stringify(state));
            const itemToMove = await findAndRemoveItem(newState.bundle.items, itemId);

            if (!itemToMove) {
                throw new Error('Item not found');
            }

            // Update the parent of the item to move
            itemToMove.parent = newParentId;
            const parent = await findAndAddItem(newState.bundle.items, itemToMove, newParentId, index);

            if (!parent) {
                throw new Error('New parent not found');
            }

            await dispatch({ type: constants.ON_UPDATE_BUNDLE, data: newState.bundle.items });
        } catch (error) {
            console.log(error.message);
        }
    };
}

export function updateDeskLocally(itemId, newParentId, index) {
    return async (dispatch, getState) => {
        try {
            const state = await getState().caseData;
            const newState = JSON.parse(JSON.stringify(state));
            const itemToMove = await findAndRemoveItem(newState.deskSpace.items, itemId);

            if (!itemToMove) {
                throw new Error('Item not found');
            }

            // Update the parent of the item to move
            itemToMove.parent = newParentId;

            const parent = await findAndAddItem(newState.deskSpace.items, itemToMove, newParentId, index);

            if (!parent) {
                throw new Error('New parent not found');
            }

            console.log(newState.deskSpace.items);

            await dispatch({ type: constants.ON_UPDATE_DESKSPACE, data: newState.deskSpace.items });
        } catch (error) {
            console.log(error.message);
        }
    };
}

export function moveItemsFromDeskToBundleLocally(itemId, newParentId, index) {
    return async (dispatch, getState) => {
        try {
            const state = getState().caseData;
            const newState = JSON.parse(JSON.stringify(state));

            const itemToMove = findAndRemoveItem(newState.deskSpace.items, itemId);

            if (!itemToMove) {
                throw new Error('Item not found');
            }

            // Update the parent of the item to move
            itemToMove.parent = newParentId;

            const parentFound = findAndAddItem(newState.bundle.items, itemToMove, newParentId, index);

            if (!parentFound) {
                throw new Error('New parent not found');
            }

            await dispatch({ type: constants.ON_UPDATE_DESKSPACE, data: newState.deskSpace.items });
            await dispatch({ type: constants.ON_UPDATE_BUNDLE, data: newState.bundle.items });
        } catch (error) {
            console.log(error.message);
        }
    };
}

export function moveItemsFromBundleToDeskLocally(itemId, newParentId, index) {
    return async (dispatch, getState) => {
        try {
            const state = getState().caseData;
            const newState = JSON.parse(JSON.stringify(state));

            const itemToMove = findAndRemoveItem(newState.bundle.items, itemId);

            if (!itemToMove) {
                throw new Error('Item not found');
            }

            // Update the parent of the item to move
            itemToMove.parent = newParentId;
            const parentFound = findAndAddItem(newState.deskSpace.items, itemToMove, newParentId, index);

            if (!parentFound) {
                throw new Error('New parent not found');
            }

            await dispatch({ type: constants.ON_UPDATE_DESKSPACE, data: newState.deskSpace.items });
            await dispatch({ type: constants.ON_UPDATE_BUNDLE, data: newState.bundle.items });
        } catch (error) {
            console.log(error.message);
        }
    };
}

export function updateBundle(caseid, apiKey, droppedItemId, parentid, index){
    return (async (dispatch, getState) => {
        try{
            dispatch({type: constants.ON_SYSTEM_UPDATING_BUNDLE})
            const apiEndpoint = parentid ? `/api/case/${caseid}/bundle/dragitems?parentid=${parentid}` : `/api/case/${caseid}/bundle/dragitems`         
            const response = await fetch(apiEndpoint, {
                method: 'post',
                headers: { 'x-api-key': apiKey, 'Content-Type': 'application/json' },
                body: JSON.stringify({ itemIds: [droppedItemId], index: index })
            })
            if (!response.ok) {
                const data = await response.json();
                throw new Error(`Error: ${data.message}`);
            }
            const xcase = await response.json();
            // await dispatch({type: constants.ON_UPDATE_BUNDLE, data: xcase.data.bundle.items});
        } catch(error){
            console.log(error.message);
        } finally {
            dispatch({type: constants.ON_SYSTEM_UPDATED_BUNDLE})
        }
    })
}

export function updateDesk(caseid, apiKey, droppedItemId, parentid, index){
    return (async (dispatch, getState) => {
        try{
            dispatch({type: constants.ON_SYSTEM_UPDATING_DESKSPACE})
            const apiEndpoint = parentid ? `/api/case/${caseid}/deskspace/dragitems?parentid=${parentid}` : `/api/case/${caseid}/deskspace/dragitems`         
            const response = await fetch(apiEndpoint, {
                method: 'post',
                headers: { 'x-api-key': apiKey, 'Content-Type': 'application/json' },
                body: JSON.stringify({ itemIds: [droppedItemId], index: index })
            })
            if (!response.ok) {
                const json = await response.json();
                
                throw new Error(`Error: ${json.message}`);
            }
            const xcase = await response.json();
            // await dispatch({type: constants.ON_UPDATE_DESKSPACE, data: xcase.data.deskSpace.items});
        } catch(error){
            console.log(error.message);
        } finally {
            dispatch({type: constants.ON_SYSTEM_UPDATED_DESKSPACE})
        }
    })
}

export function moveItemsFromDeskToBundle(caseid, apiKey, droppedItemId, parentid, index){
    return (async (dispatch, getState) => {
        try{
            dispatch({type: constants.ON_SYSTEM_UPDATING_DESKSPACE})
            dispatch({type: constants.ON_SYSTEM_UPDATING_BUNDLE})
            const apiEndpoint = parentid ? `/api/case/${caseid}/deskspace/movefilestobundle?parentid=${parentid}` : `/api/case/${caseid}/deskspace/movefilestobundle`         
            const response = await fetch(apiEndpoint, {
                method: 'post',
                headers: { 'x-api-key': apiKey, 'Content-Type': 'application/json' },
                body: JSON.stringify({ itemIds: [droppedItemId], index: index })
            })
            if (!response.ok) {
                const json = await response.json();
                
                throw new Error(`Error: ${json.message}`);
            }
            const xcase = await response.json();
            // await dispatch({type: constants.ON_UPDATE_BUNDLE, data: xcase.data.bundle.items});
            // await dispatch({type: constants.ON_UPDATE_DESKSPACE, data: xcase.data.deskSpace.items});
        } catch(error){
            console.log(error.message);
        } finally {
            dispatch({type: constants.ON_SYSTEM_UPDATED_DESKSPACE})
            dispatch({type: constants.ON_SYSTEM_UPDATED_BUNDLE})
        }
    })
}

export function moveItemsFromBundleToDesk(caseid, apiKey, droppedItemId, parentid, index){
    return (async (dispatch, getState) => {
        try{
            dispatch({type: constants.ON_SYSTEM_UPDATING_DESKSPACE})
            dispatch({type: constants.ON_SYSTEM_UPDATING_BUNDLE})
            const apiEndpoint = parentid ? `/api/case/${caseid}/bundle/movefilestodeskspace?parentid=${parentid}` : `/api/case/${caseid}/bundle/movefilestodeskspace`         
            const response = await fetch(apiEndpoint, {
                method: 'post',
                headers: { 'x-api-key': apiKey, 'Content-Type': 'application/json' },
                body: JSON.stringify({ itemIds: [droppedItemId], index: index })
            })
            if (!response.ok) {
                const json = await response.json();
                
                throw new Error(`Error: ${json.message}`);
            }
            const xcase = await response.json();
            // await dispatch({type: constants.ON_UPDATE_BUNDLE, data: xcase.data.bundle.items});
            // await dispatch({type: constants.ON_UPDATE_DESKSPACE, data: xcase.data.deskSpace.items});
        } catch(error){
            console.log(error.message);
        } finally {
            dispatch({type: constants.ON_SYSTEM_UPDATED_DESKSPACE})
            dispatch({type: constants.ON_SYSTEM_UPDATED_BUNDLE})
        }
    })
}

export function createNewFolder(caseid, apiKey, folderName){
    return (async dispatch => {
        try{
            dispatch({type: constants.ON_SYSTEM_UPDATING_DESKSPACE})
            const response = await fetch(`/api/case/${caseid}/deskspace/createnewfolder?index=0`, {
                method: 'post',
                headers: { 'x-api-key': apiKey, 'Content-Type': 'application/json' },
                body: JSON.stringify({ name: folderName })
            })
            if (!response.ok) {
                const json = await response.json();
                
                throw new Error(`Error: ${json.message}`);
            }
            const json = await response.json();
            dispatch({ type: constants.ON_NEW_FOLDER_CREATED, data: json.data })
        }  catch(error){
            console.log(error.message);
        } finally {
            dispatch({type: constants.ON_SYSTEM_UPDATED_DESKSPACE})
        }
    })
}

export function refreshDeskSpace(caseid, apiKey){
    return (async (dispatch) => {
        try {
            const response = await fetch(`/api/case/${caseid}/open`, {
                headers: {
                    'x-api-key': apiKey,
                },
            });
            if (!response.ok) {
                const json = await response.json();
                
                throw new Error(`Error: ${json.message}`);
            }
            const json = await response.json();
            dispatch({type: constants.ON_CASE_LOADED, data: json.data})
        } catch (error) {
            console.error(error);
        }
    });
}

export function importFiles(caseid, apiKey, files){
    return (async (dispatch) => {
        try{
            dispatch({type: constants.ON_SYSTEM_UPDATING_DESKSPACE})
            const formData = new FormData();
            files.forEach((file, index) => {
                formData.append(`files`, file, file.name);
            });
            const response = await fetch(`/api/case/${caseid}/deskspace/importfiles/`, {
                method: 'post',
                headers: {'x-api-key': apiKey},
                body: formData,
            })
            if (!response.ok) {
                const errorMessage = await response.text();
                throw new Error(`Failed to upload files: ${errorMessage}`);
            }

            // const responseData = await response.json();
            await dispatch(refreshDeskSpace(caseid, apiKey));
        } catch(error){
            console.log(error.message);
        } finally {
            dispatch({type: constants.ON_SYSTEM_UPDATED_DESKSPACE})
        }
    })
}

export function renameItem(caseid, apiKey, item, newName, enqueueSnackbar){
    return (async (dispatch) => {
        try{
            dispatch({type: constants.ON_ITEM_RENAMED, data: { itemId: item._id, newName }})
            const response = await fetch(`/api/case/${caseid}/item/${item._id}/rename`, {
                method: 'post',
                headers: { 'x-api-key': apiKey, 'Content-Type': 'application/json' },
                body: JSON.stringify({ name: newName })
            })
            if (!response.ok) {
                const json = await response.json();
                
                throw new Error(`Error: ${json.message}`);
            }
            const json = await response.json();
            dispatch({ type: constants.ON_ITEM_RENAMED, data: json.data })
            enqueueSnackbar('Item Name Updated Successfully!', { variant: 'success' })
        } catch(error){
            dispatch({type: constants.ON_ITEM_RENAMED, data: { itemId: item._id, newName: item.name }})
            console.log(error.message);
            enqueueSnackbar(error, { variant: 'error' })
        }
        
    })
}

export function initializeOperation(space){
    return (async (dispatch) => {
        dispatch({type: constants.ON_SYSTEM_OPERATION_NULL, data: space})
    })
}

export function getOperation(currentSpace){
    return (async (dispatch, getState) => {
        const { space: initialSpace } = await getState().app;
        if(initialSpace === currentSpace )
            dispatch({type: constants.ON_SYSTEM_OPERATION_DRAG})
        else
            dispatch({type: constants.ON_SYSTEM_OPERATION_MOVE})
    })
    
}

export function changeTheme(theme){
    return (async (dispatch) => {
        if(theme === 'dark' || theme === 'light')
            dispatch({type: constants.ON_SYSTEM_THEME_CHANGED, data: theme})
    })
}

export function addNote(caseid, apiKey, note) {
  return async (dispatch) => {    
    // dispatch({ type: constants.NOTE_ADD_REQUEST });
    try {
        const response = await fetch(`/api/case/${caseid}/addnote`, {
            method: 'POST',
            headers: { 'x-api-key': apiKey, 'Content-Type': 'application/json' },
            body: JSON.stringify({ note }),
        });
        if (!response.ok) {
            const json = await response.json();
            throw new Error(`Error: ${json.message}`);
        }
        const json = await response.json();
        dispatch({ type: constants.ON_NOTE_ADDED, data: json.data });
    } catch (error) {
        console.log(error.message);
    }
  };
}

export function removeNote(caseid, apiKey, noteId){
    return (async (dispatch) => {
        dispatch({type: constants.ON_NOTE_REMOVED, data: noteId})
    })
}

export function updateNote(caseid, apiKey, note){
    return (async (dispatch) => {
        dispatch({type: constants.ON_NOTE_UPDATED, data: note})
    })
}

export function loadNotes(caseid, apiKey) {
  return async (dispatch) => {
    try {
      const apiEndpoint = `/api/case/${caseid}/notesandfilesmetadata`;
      const response = await fetch(apiEndpoint, {
        method: 'GET',
        headers: {
          'x-api-key': apiKey,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        const json = await response.json();
        throw new Error(`Error: ${json.message}`);
      }
      const json = await response.json();
      dispatch({ type: constants.ON_NOTES_N_FILES_METADATA_LOADED, data: json.data });
    } catch (error) {
      console.error('Error loading notes and files metadata:', error);
    //   dispatch({ type: constants.ON_NOTES_N_FILES_METADATA_ERROR, error: error.message });
    }
  };
}

export function updateUserStatus(apiKey, status){
    return async (dispatch) => {
        try{
            const apiEndpoint = `/api/user/status`;
            const response = await fetch(apiEndpoint, {
                method: 'POST',
                headers: {
                    'x-api-key': apiKey,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ status })
            });
            if (!response.ok) {
                const json = await response.json();
                throw new Error(`Error: ${json.message}`);
            }
            const json = await response.json();
        } catch(error){
            console.error('Error updating user status: ', error);
        }
    }
}

export function initializeWebSocket(token){
    return async (dispatch) => {
        try{
            const ws = createWebSocket(token);
            ws.onmessage = (event) => {
                const data = JSON.parse(event.data);
                console.log(data);
                
                // Dispatch Redux actions based on the type of change event
                // switch (data.operationType) {
                //     case 'insert':
                //         dispatch({ type: 'ADD_CASE', payload: data.fullDocument });
                //         break;
                //     case 'update':
                //         dispatch({ type: 'UPDATE_CASE', payload: data.documentKey._id });
                //         break;
                //     case 'delete':
                //         dispatch({ type: 'DELETE_CASE', payload: data.documentKey._id });
                //         break;
                //     default:
                //         break;
                // }
            };
        } catch(error){
            console.error('Error initializing web socket: ', error);
        }
    }
}

export function updateUserLocation(apiKey, location){
    return async (dispatch) => {
        try{

        } catch(error){
            console.error('Error updating :', error);
        }
    }
}

export function loadMessages () {
  return async dispatch => {
    try {
        dispatch({ type: constants.ON_SITE_CONTENT_LOADING })
        const apiEndpoint = `/api/message/active`;
        const response = await fetch(apiEndpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (!response.ok) {
            const json = await response.json();
            throw new Error(`Error: ${json.message}`);
        }
        const json = await response.json();
        dispatch({ type: constants.ON_MESSAGES_LOADED, data: json.data });
    } catch (error) {
      console.error ('Error updating :', error);
    }
  };
}


// ------------------------------------- site ---------------------------------------------------

export function loadSite(name) {
  return async (dispatch) => {
    try {
        dispatch({ type: constants.ON_SITE_CONTENT_LOADING })
        const apiEndpoint = `/api/site?name=${name}`;
        const response = await fetch(apiEndpoint, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/json'
            }
        });
        if (!response.ok) {
            const json = await response.json();
            throw new Error(`Error: ${json.message}`);
        }
        const json = await response.json();
        dispatch({ type: constants.ON_SITE_CONTENT_LOADED, data: json.data });
    } catch (error) {
      console.error('Error loading site content', error);
    }
  };
}

export function loadActiveSubscriptionPlans(){
    return async dispatch => {
        try{
            const apiEndpoint = `/api/subscription/active`;
            const response = await fetch(apiEndpoint, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                const json = await response.json();
                throw new Error(`Error: ${json.message}`);
            }
            const json = await response.json();
            dispatch({ type: constants.ON_ACTIVE_SUBSCRIPTION_PLANS_LOADED, data: json.data });
        } catch(error){
            console.error (error.message);
        }
    }
}

// ------------------------------------- admin ---------------------------------------------------

export function logoutAdmin(navigate){
    return async dispatch => {
        try {
            const response = await fetch('/api/auth/logout', {
                method: 'POST',
                credentials: 'include' // Send cookies with the request
            });
            if (response.ok) {
                dispatch({type: constants.ON_ADMIN_SIGNOUT_SUCCESS})
                navigate('/')
            } else {
                console.error('Failed to logout:', response.statusText);
            }
        } catch (error) {
            console.error('Failed to logout:', error);
        }
    };
}

export function loadAdminSite(name) {
  return async (dispatch) => {
    try {
        dispatch({ type: constants.ON_SITE_CONTENT_LOADING })
        const apiEndpoint = `/api/site?name=${name}`;
        const response = await fetch(apiEndpoint, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/json'
            }
        });
        if (!response.ok) {
            const json = await response.json();
            throw new Error(`Error: ${json.message}`);
        }
        const json = await response.json();
        dispatch({ type: constants.ADMIN_ON_SITE_LOADED, data: json.data });
    } catch (error) {
      console.error('Error loading site content', error);
    }
  };
}

export function loadWebsiteSettings(apiKey) {
  return async (dispatch) => {
    try {
      const apiEndpoint = `/api/admin/website-settings`;
      const response = await fetch(apiEndpoint, {
        method: 'GET',
        headers: {
          'x-api-key': apiKey,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        const json = await response.json();
        throw new Error(`Error: ${json.message}`);
      }
      const json = await response.json();
      dispatch({ type: constants.ADMIN_ON_WEBSITE_SETTINGS_LOADED, data: json });
    } catch (error) {
      console.error('Error loading website settings', error);
    }
  };
}

export function updateSiteHeader(apiKey, siteId, header) {
  return async (dispatch) => {
    try {
      const apiEndpoint = `/api/admin/site/${siteId}/header`;
      const response = await fetch(apiEndpoint, {
        method: 'PUT',
         headers: {
            'x-api-key': apiKey,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ header }),
      });

      if (!response.ok) {
        const json = await response.json();
        throw new Error(`Error: ${json.message}`);
      }

      const json = await response.json();
      dispatch({ type: constants.ADMIN_ON_SITE_HEADER_UPDATED, data: json.data });
    } catch (error) {
      console.error('Error updating site header', error);
    }
  };
}

export function updateSiteLandingHero(apiKey, siteId, formData) {
  return async (dispatch) => {
    try {
      const apiEndpoint = `/api/admin/site/${siteId}/hero`;
      const response = await fetch(apiEndpoint, {
        method: 'PUT',
         headers: {
            'x-api-key': apiKey,
        },
        body: formData,
      });

      if (!response.ok) {
        const json = await response.json();
        throw new Error(`Error: ${json.message}`);
      }

      const json = await response.json();
      dispatch({ type: constants.ADMIN_ON_SITE_LANDING_HERO_UPDATED, data: json.data });
    } catch (error) {
      console.error('Error updating site hero', error);
    }
  };
}

export function updateSiteLandingServices(apiKey, siteId, formData) {
  return async (dispatch) => {
    try {
      const apiEndpoint = `/api/admin/site/${siteId}/services`;
      const response = await fetch(apiEndpoint, {
        method: 'PUT',
         headers: {
            'x-api-key': apiKey,
        },
        body: formData,
      });

      if (!response.ok) {
        const json = await response.json();
        throw new Error(`Error: ${json.message}`);
      }

      const json = await response.json();
      dispatch({ type: constants.ADMIN_ON_SITE_LANDING_SERVICES_UPDATED, data: json.data });
    } catch (error) {
      console.error('Error updating site hero', error);
    }
  };
}

export function updateSiteLandingAboutUs(apiKey, siteId, formData) {
  return async (dispatch) => {
    try {
      const apiEndpoint = `/api/admin/site/${siteId}/aboutus`;
      const response = await fetch(apiEndpoint, {
        method: 'PUT',
         headers: {
            'x-api-key': apiKey,
        },
        body: formData
      });

      if (!response.ok) {
        const json = await response.json();
        throw new Error(`Error: ${json.message}`);
      }

      const json = await response.json();
      dispatch({ type: constants.ADMIN_ON_SITE_LANDING_ABOUT_US_UPDATED, data: json.data });
    } catch (error) {
      console.error('Error updating site aboutus', error);
    }
  };
}

export function updateSiteLandingMission(apiKey, siteId, formData) {
  return async (dispatch) => {
    try {
      const apiEndpoint = `/api/admin/site/${siteId}/mission`;
      const response = await fetch(apiEndpoint, {
        method: 'PUT',
         headers: {
            'x-api-key': apiKey,
        },
        body: formData,
      });

      if (!response.ok) {
        const json = await response.json();
        throw new Error(`Error: ${json.message}`);
      }

      const json = await response.json();
      dispatch({ type: constants.ADMIN_ON_SITE_LANDING_MISSION_UPDATED, data: json.data });
    } catch (error) {
      console.error('Error updating site mission', error);
    }
  };
}

export function updateSiteLandingFeatures(apiKey, siteId, formData) {
  return async (dispatch) => {
    try {
      const apiEndpoint = `/api/admin/site/${siteId}/features`;
      const response = await fetch(apiEndpoint, {
        method: 'PUT',
         headers: {
            'x-api-key': apiKey,
        },
        body: formData,
      });

      if (!response.ok) {
        const json = await response.json();
        throw new Error(`Error: ${json.message}`);
      }

      const json = await response.json();
      dispatch({ type: constants.ADMIN_ON_SITE_LANDING_FEATURES_UPDATED, data: json.data });
    } catch (error) {
      console.error('Error updating site features', error);
    }
  };
}

export function updateSiteLandingMarqueeItems(apiKey, siteId, marqueeItems) {
  return async (dispatch) => {
    try {
      const apiEndpoint = `/api/admin/site/${siteId}/marqueeitems`;
      const response = await fetch(apiEndpoint, {
        method: 'PUT',
         headers: {
            'x-api-key': apiKey,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ marqueeItems }),
      });

      if (!response.ok) {
        const json = await response.json();
        throw new Error(`Error: ${json.message}`);
      }

      const json = await response.json();
      dispatch({ type: constants.ADMIN_ON_SITE_LANDING_MARQEE_ITEMS_UPDATED, data: json.data });
    } catch (error) {
      console.error('Error updating site marquee items', error);
    }
  };
}

export function updateSiteLandingOveriew(apiKey, siteId, formData) {
  return async (dispatch) => {
    try {
      const apiEndpoint = `/api/admin/site/${siteId}/overview`;
      const response = await fetch(apiEndpoint, {
        method: 'PUT',
         headers: {
            'x-api-key': apiKey,
        },
        body: formData,
      });

      if (!response.ok) {
        const json = await response.json();
        throw new Error(`Error: ${json.message}`);
      }

      const json = await response.json();
      dispatch({ type: constants.ADMIN_ON_SITE_LANDING_OVERVIEW_UPDATED, data: json.data });
    } catch (error) {
      console.error('Error updating site overview', error);
    }
  };
}

export function updateSiteLandingTestimonials(apiKey, siteId, formData) {
  return async (dispatch) => {
    try {
      const apiEndpoint = `/api/admin/site/${siteId}/testimonials`;
      const response = await fetch(apiEndpoint, {
        method: 'PUT',
         headers: {
            'x-api-key': apiKey,
        },
        body: formData,
      });

      if (!response.ok) {
        const json = await response.json();
        throw new Error(`Error: ${json.message}`);
      }

      const json = await response.json();
      dispatch({ type: constants.ADMIN_ON_SITE_LANDING_TESTIMONIALS_UPDATED, data: json.data });
    } catch (error) {
      console.error('Error updating site testimonials', error);
    }
  };
}

export function updateSiteLandingStats(apiKey, siteId, stats) {
  return async (dispatch) => {
    try {
      const apiEndpoint = `/api/admin/site/${siteId}/stats`;
      const response = await fetch(apiEndpoint, {
        method: 'PUT',
         headers: {
            'x-api-key': apiKey,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ stats }),
      });

      if (!response.ok) {
        const json = await response.json();
        throw new Error(`Error: ${json.message}`);
      }

      const json = await response.json();
      dispatch({ type: constants.ADMIN_ON_SITE_LANDING_STATS_UPDATED, data: json.data });
    } catch (error) {
      console.error('Error updating site stats', error);
    }
  };
}

export function updateSiteFooter(apiKey, siteId, footerContent) {
  return async (dispatch) => {
    try {
      const apiEndpoint = `/api/admin/site/${siteId}/footer`;
      const response = await fetch(apiEndpoint, {
        method: 'PUT',
         headers: {
            'x-api-key': apiKey,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ footerContent }),
      });

      if (!response.ok) {
        const json = await response.json();
        throw new Error(`Error: ${json.message}`);
      }

      const json = await response.json();
      dispatch({ type: constants.ADMIN_ON_SITE_LANDING_FOOTER_UPDATED, data: json.data });
    } catch (error) {
      console.error('Error updating site footer', error);
    }
  };
}

export function updateSiteServicesContent(apiKey, siteId, formData) {
  return async (dispatch) => {
    try {
      const apiEndpoint = `/api/admin/site/${siteId}/services/content`;
      const response = await fetch(apiEndpoint, {
        method: 'PUT',
         headers: {
            'x-api-key': apiKey,
        },
        body: formData,
      });

      if (!response.ok) {
        const json = await response.json();
        throw new Error(`Error: ${json.message}`);
      }

      const json = await response.json();
      dispatch({ type: constants.ADMIN_ON_SERVICES_CONTENT_UPDATED, data: json.data });
    } catch (error) {
      console.error('Error updating site services content', error);
    }
  };
}

export function updateSiteServicesStats(apiKey, siteId, stats) {
  return async (dispatch) => {
    try {
      const apiEndpoint = `/api/admin/site/${siteId}/services/stats`;
      const response = await fetch(apiEndpoint, {
        method: 'PUT',
         headers: {
            'x-api-key': apiKey,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ stats }),
      });

      if (!response.ok) {
        const json = await response.json();
        throw new Error(`Error: ${json.message}`);
      }

      const json = await response.json();
      dispatch({ type: constants.ADMIN_ON_SERVICES_STATS_UPDATED, data: json.data });
    } catch (error) {
      console.error('Error updating site services stats', error);
    }
  };
}

export function updateSiteContactForm(apiKey, siteId, form) {
  return async (dispatch) => {
    try {
      const apiEndpoint = `/api/admin/site/${siteId}/contactus/form`;
      const response = await fetch(apiEndpoint, {
        method: 'PUT',
         headers: {
            'x-api-key': apiKey,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ form }),
      });

      if (!response.ok) {
        const json = await response.json();
        throw new Error(`Error: ${json.message}`);
      }

      const json = await response.json();
      dispatch({ type: constants.ADMIN_ON_CONTACT_US_FORM_UPDATED, data: json.data });
    } catch (error) {
      console.error('Error updating site contactus form', error);
    }
  };
}

export function updateSiteContactInfo(apiKey, siteId, info) {
  return async (dispatch) => {
    try {
      const apiEndpoint = `/api/admin/site/${siteId}/contactus/info`;
      const response = await fetch(apiEndpoint, {
        method: 'PUT',
         headers: {
            'x-api-key': apiKey,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ info }),
      });

      if (!response.ok) {
        const json = await response.json();
        throw new Error(`Error: ${json.message}`);
      }

      const json = await response.json();
      dispatch({ type: constants.ADMIN_ON_CONTACT_US_INFO_UPDATED, data: json.data });
    } catch (error) {
      console.error('Error updating site contactus form', error);
    }
  };
}

export function updateSiteFAQBody(apiKey, siteId, formData) {
  return async (dispatch) => {
    try {
      const apiEndpoint = `/api/admin/site/${siteId}/faq/body`;
      const response = await fetch(apiEndpoint, {
        method: 'PUT',
         headers: {
            'x-api-key': apiKey,
        },
        body: formData,
      });

      if (!response.ok) {
        const json = await response.json();
        throw new Error(`Error: ${json.message}`);
      }

      const json = await response.json();
      dispatch({ type: constants.ADMIN_ON_FAQ_BODY_UPDATED, data: json.data });
    } catch (error) {
      console.error('Error updating site faq body', error);
    }
  };
}

export function updateSiteTeamBody(apiKey, siteId, formData) {
  return async (dispatch) => {
    try {
      const apiEndpoint = `/api/admin/site/${siteId}/team/content`;
      const response = await fetch(apiEndpoint, {
        method: 'PUT',
         headers: {
            'x-api-key': apiKey,
        },
        body: formData,
      });

      if (!response.ok) {
        const json = await response.json();
        throw new Error(`Error: ${json.message}`);
      }

      const json = await response.json();
      dispatch({ type: constants.ADMIN_ON_TEAM_BODY_UPDATED, data: json.data });
    } catch (error) {
      console.error('Error updating site team content', error);
    }
  };
}

export function updateSiteAboutUsContent(apiKey, siteId, formData) {
  return async (dispatch) => {
    try {
      const apiEndpoint = `/api/admin/site/${siteId}/aboutus/content`;
      const response = await fetch(apiEndpoint, {
        method: 'PUT',
         headers: {
            'x-api-key': apiKey,
        },
        body: formData
      });

      if (!response.ok) {
        const json = await response.json();
        throw new Error(`Error: ${json.message}`);
      }

      const json = await response.json();
      dispatch({ type: constants.ADMIN_ON_ABOUT_US_CONTENT_UPDATED, data: json.data });
    } catch (error) {
      console.error('Error updating site aboutus content', error);
    }
  };
}

export function updateSiteAboutUsMarqueeItems(apiKey, siteId, marqueeItems) {
  return async (dispatch) => {
    try {
      const apiEndpoint = `/api/admin/site/${siteId}/aboutus/marqueeitems`;
      const response = await fetch(apiEndpoint, {
        method: 'PUT',
         headers: {
            'x-api-key': apiKey,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ marqueeItems }),
      });

      if (!response.ok) {
        const json = await response.json();
        throw new Error(`Error: ${json.message}`);
      }

      const json = await response.json();
      dispatch({ type: constants.ADMIN_ON_ABOUT_US_MARQUEE_ITEMS_UPDATED, data: json.data });
    } catch (error) {
      console.error('Error updating site aboutus marquee items', error);
    }
  };
}

export function updateSiteAboutUsFeatures(apiKey, siteId, formData) {
  return async (dispatch) => {
    try {
      const apiEndpoint = `/api/admin/site/${siteId}/aboutus/features`;
      const response = await fetch(apiEndpoint, {
        method: 'PUT',
         headers: {
            'x-api-key': apiKey,
        },
        body: formData,
      });

      if (!response.ok) {
        const json = await response.json();
        throw new Error(`Error: ${json.message}`);
      }

      const json = await response.json();
      dispatch({ type: constants.ADMIN_ON_ABOUT_US_FEATURES_UPDATED, data: json.data });
    } catch (error) {
      console.error('Error updating site aboutus features', error);
    }
  };
}

export function updateSiteAboutUsTimeline(apiKey, siteId, timeline) {
  return async (dispatch) => {
    try {
      const apiEndpoint = `/api/admin/site/${siteId}/aboutus/timeline`;
      const response = await fetch(apiEndpoint, {
        method: 'PUT',
         headers: {
            'x-api-key': apiKey,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ timeline }),
      });

      if (!response.ok) {
        const json = await response.json();
        throw new Error(`Error: ${json.message}`);
      }

      const json = await response.json();
      dispatch({ type: constants.ADMIN_ON_ABOUT_US_TIMELINE_UPDATED, data: json.data });
    } catch (error) {
      console.error('Error updating site aboutus timeline', error);
    }
  };
}

export function updateSiteTermsAndConditionsBody(apiKey, siteId, body) {
  return async (dispatch) => {
    try {
      const apiEndpoint = `/api/admin/site/${siteId}/termsandconditions/body`;
      const response = await fetch(apiEndpoint, {
        method: 'PUT',
         headers: {
            'x-api-key': apiKey,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ body }),
      });

      if (!response.ok) {
        const json = await response.json();
        throw new Error(`Error: ${json.message}`);
      }

      const json = await response.json();
      dispatch({ type: constants.ADMIN_ON_TERMS_AND_CONDITION_BODY_UPDATED, data: json.data });
    } catch (error) {
      console.error('Error updating site terms & conditions body', error);
    }
  };
}

export function updateSitePrivacyPolicyBody(apiKey, siteId, body) {
  return async (dispatch) => {
    try {
      const apiEndpoint = `/api/admin/site/${siteId}/privacypolicy/body`;
      const response = await fetch(apiEndpoint, {
        method: 'PUT',
         headers: {
            'x-api-key': apiKey,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ body }),
      });

      if (!response.ok) {
        const json = await response.json();
        throw new Error(`Error: ${json.message}`);
      }

      const json = await response.json();
      dispatch({ type: constants.ADMIN_ON_PRIVACY_POLICY_BODY_UPDATED, data: json.data });
    } catch (error) {
      console.error('Error updating site privacy policy body', error);
    }
  };
}

export function loadUserDetails(apiKey, id){
    return async dispatch => {
        try{
            const apiEndpoint = `/api/admin/users/${id}`;
            const response = await fetch(apiEndpoint, {
                method: 'GET',
                headers: {
                    'x-api-key': apiKey,
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                const json = await response.json();
                throw new Error(`Error: ${json.message}`);
            }
            const json = await response.json();
            dispatch({ type: constants.ADMIN_ON_USER_DETAILS_LOADED, data: json.user });
        } catch(error){
            console.error (error.message);
        }
    }
}

export function loadUsers(apiKey, page, limit) {
  return async (dispatch) => {
    try {
      const apiEndpoint = `/api/admin/users?page=${page}&limit=${limit}`;
      const response = await fetch(apiEndpoint, {
        method: 'GET',
        headers: {
          'x-api-key': apiKey,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        const json = await response.json();
        throw new Error(`Error: ${json.message}`);
      }
      const json = await response.json();
      dispatch({ type: constants.ADMIN_ON_USERS_LOADED, data: json, page });
    } catch (error) {
        console.error (error.message);
    }
  };
}

export function loadSearchedUsers(apiKey, query, filters, page, limit) {
  return async (dispatch) => {
    try {
        const { status, role, subscriptionStatus } = filters;
        const apiEndpoint = `/api/admin/users/search?q=${query}&status=${status}&role=${role}&sub=${subscriptionStatus}&page=${page}&limit=${limit}`;
        const response = await fetch(apiEndpoint, {
            method: 'GET',
            headers: {
                'x-api-key': apiKey,
                'Content-Type': 'application/json'
            }
        });
        if (!response.ok) {
            const json = await response.json();
            throw new Error(`Error: ${json.message}`);
        }
        const json = await response.json();
        dispatch({ type: constants.ADMIN_ON_SEARCHED_USERS_LOADED, data: json, page });
    } catch (error) {
        console.error (error.message);
    }
  };
}

export function loadSearchedSubscriptions(apiKey, query, filters, page, limit) {
  return async (dispatch) => {
    try {
        const { status, subscriptionType, renewalInterval } = filters;
        const apiEndpoint = `/api/admin/subscriptions/search?q=${query}&status=${status}&subscriptionType=${subscriptionType}&renewalInterval=${renewalInterval}&page=${page}&limit=${limit}`;
        const response = await fetch(apiEndpoint, {
            method: 'GET',
            headers: {
                'x-api-key': apiKey,
                'Content-Type': 'application/json'
            }
        });
        if (!response.ok) {
            const json = await response.json();
            throw new Error(`Error: ${json.message}`);
        }
        const json = await response.json();
        dispatch({ type: constants.ADMIN_ON_SEARCHED_SUBSCRIPTION_LOADED, data: json, page });
    } catch (error) {
        console.error (error.message);
    }
  };
}

export function loadSearchedMessages(apiKey, query, filters, page, limit) {
  return async (dispatch) => {
    try {
        const { status, type } = filters;
        const apiEndpoint = `/api/admin/messages/search?q=${query}&status=${status}&type=${type}&page=${page}&limit=${limit}`;
        const response = await fetch(apiEndpoint, {
            method: 'GET',
            headers: {
                'x-api-key': apiKey,
                'Content-Type': 'application/json'
            }
        });
        if (!response.ok) {
            const json = await response.json();
            throw new Error(`Error: ${json.message}`);
        }
        const json = await response.json();
        dispatch({ type: constants.ADMIN_ON_SEARCHED_MESSAGES_LOADED, data: json, page });
    } catch (error) {
        console.error (error.message);
    }
  };
}

export function loadAdminMessages(apiKey, page, limit) {
  return async (dispatch) => {
    try {
      const apiEndpoint = `/api/admin/messages?page=${page}&limit=${limit}`;
      const response = await fetch(apiEndpoint, {
        method: 'GET',
        headers: {
          'x-api-key': apiKey,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        const json = await response.json();
        throw new Error(`Error: ${json.message}`);
      }
      const json = await response.json();
      dispatch({ type: constants.ADMIN_ON_MESSAGES_LOADED, data: json, page });
    } catch (error) {
        console.error (error.message);
    }
  };
}

export function loadAdminPayments(apiKey, page, limit) {
  return async (dispatch) => {
    try {
      const apiEndpoint = `/api/admin/payments?page=${page}&limit=${limit}`;
      const response = await fetch(apiEndpoint, {
        method: 'GET',
        headers: {
          'x-api-key': apiKey,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        const json = await response.json();
        throw new Error(`Error: ${json.message}`);
      }
      const json = await response.json();
      dispatch({ type: constants.ADMIN_ON_PAYMENTS_LOADED, data: json, page });
    } catch (error) {
        console.error (error.message);
    }
  };
}

export function loadLogs(apiKey, page, limit) {
  return async (dispatch) => {
    try {
      const apiEndpoint = `/api/admin/logs?page=${page}&limit=${limit}`;
      const response = await fetch(apiEndpoint, {
        method: 'GET',
        headers: {
          'x-api-key': apiKey,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        const json = await response.json();
        throw new Error(`Error: ${json.message}`);
      }
      const json = await response.json();
      dispatch({ type: constants.ADMIN_ON_LOGS_LOADED, data: json, page });
    } catch (error) {
        console.error (error.message);
    }
  };
}

export function loadAudits(apiKey, page, limit) {
  return async (dispatch) => {
    try {
      const apiEndpoint = `/api/admin/audits?page=${page}&limit=${limit}`;
      const response = await fetch(apiEndpoint, {
        method: 'GET',
        headers: {
          'x-api-key': apiKey,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        const json = await response.json();
        throw new Error(`Error: ${json.message}`);
      }
      const json = await response.json();
      dispatch({ type: constants.ADMIN_ON_AUDIT_TRAILS_LOADED, data: json, page });
    } catch (error) {
        console.error (error.message);
    }
  };
}

export function postMessage (apiKey, messsage) {
  return async dispatch => {
    try {
      const apiEndpoint = `/api/admin/message`;
      const response = await fetch (apiEndpoint, {
        method: 'POST',
        headers: {
          'x-api-key': apiKey,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...messsage })
      });
      if (!response.ok) {
        const json = await response.json();
        throw new Error (`Error: ${json.message}`);
      }
      const json = await response.json();
      dispatch ({type: constants.ADMIN_ON_ADD_MESSAGE, data: json.data});
    } catch (error) {
      console.error (error.message);
    }
  };
}

export function loadDashboardMetrics (apiKey) {
  return async dispatch => {
    try {
      const apiEndpoint = `/api/admin/dashboard-metrics`;
      const response = await fetch (apiEndpoint, {
        method: 'GET',
        headers: {
          'x-api-key': apiKey,
          'Content-Type': 'application/json',
        }
      });
      if (!response.ok) {
        const json = await response.json();
        throw new Error (`Error: ${json.message}`);
      }
      const json = await response.json();
      dispatch ({type: constants.ADMIN_ON_DASHBOARD_METRICS_LOADED, data: json});
    } catch (error) {
      console.error (error.message);
    }
  };
}

export function loadPageNumberFormatUsage (apiKey){
    return async dispatch => {
        try{
            const apiEndpoint = `api/admin/page-number-format-usage`;
            const response = await fetch (apiEndpoint, {
                method: 'GET',
                headers: {
                'x-api-key': apiKey,
                'Content-Type': 'application/json',
                }
            });
            if (!response.ok) {
                const json = await response.json();
                throw new Error (`Error: ${json.message}`);
            }
            const json = await response.json();
            dispatch ({type: constants.ADMIN_ON_PAGE_NUMBER_FORMAT_USAGE_STATS_LOADED, data: json});
        } catch(error){
            console.error (error.message);
        }
    }
}

export function loadActiveUserLog (apiKey){
    return async dispatch => {
        try{
            const apiEndpoint = `api/admin/active-user-log`;
            const response = await fetch (apiEndpoint, {
                method: 'GET',
                headers: {
                'x-api-key': apiKey,
                'Content-Type': 'application/json',
                }
            });
            if (!response.ok) {
                const json = await response.json();
                throw new Error (`Error: ${json.message}`);
            }
            const json = await response.json();
            dispatch ({type: constants.ADMIN_ON_ACTIVE_USER_LOG_LOADED, data: json});
        } catch(error){
            console.error (error.message);
        }
    }
}

export function loadUserRegistrationMonthlyTrend (apiKey) {
  return async dispatch => {
    try {
      const apiEndpoint = `/api/admin/registration-trend-monthly-of-last-12-months`;
      const response = await fetch (apiEndpoint, {
        method: 'GET',
        headers: {
          'x-api-key': apiKey,
          'Content-Type': 'application/json',
        }
      });
      if (!response.ok) {
        const json = await response.json();
        throw new Error (`Error: ${json.message}`);
      }
      const json = await response.json();
      dispatch ({type: constants.ADMIN_ON_USER_REGISTRATION_MONTHLY_TREND_DATA_LOADED, data: json});
    } catch (error) {
      console.error (error.message);
    }
  };
}

export function loadRevenueByPlanData(apiKey) {
  return async dispatch => {
    try {
      const apiEndpoint = `/api/admin/revenue-by-plan`;
      const response = await fetch (apiEndpoint, {
        method: 'GET',
        headers: {
          'x-api-key': apiKey,
          'Content-Type': 'application/json',
        }
      });
      if (!response.ok) {
        const json = await response.json();
        throw new Error (`Error: ${json.message}`);
      }
      const json = await response.json();
      dispatch ({type: constants.ADMIN_ON_REVENUE_BY_PLAN_DATA_LOADED, data: json.revenueByPlan});
    } catch (error) {
      console.error (error.message);
    }
  };
}

export function adminLoadSubscriptionPlans(apiKey, page, limit) {
  return async (dispatch) => {
    try {
      const apiEndpoint = `/api/admin/subscription-plans?page=${page}&limit=${limit}`;
      const response = await fetch(apiEndpoint, {
        method: 'GET',
        headers: {
          'x-api-key': apiKey,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        const json = await response.json();
        throw new Error(`Error: ${json.message}`);
      }
      const json = await response.json();
      dispatch({ type: constants.ADMIN_ON_SUBSCRIPTION_PLANS_LOADED, data: json, page });
    } catch (error) {
        console.error (error.message);
    }
  };
}

export function addSubscriptionPlan(apiKey, planData) {
  return async dispatch => {
    try {
      const apiEndpoint = `/api/admin/subscription-plan`;
      const response = await fetch (apiEndpoint, {
        method: 'POST',
        headers: {
          'x-api-key': apiKey,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...planData })
      });
      if (!response.ok) {
        const json = await response.json();
        throw new Error (`Error: ${json.message}`);
      }
      const json = await response.json();
      dispatch ({type: constants.ADMIN_ADD_SUBSCRIPTION_PLAN_SUCCESS, data: json});
    } catch (error) {
      console.error (error.message);
    }
  };
}

export function deleteSubscriptionPlan(apiKey, planId, page) {
  return async dispatch => {
    try {
      const apiEndpoint = `/api/admin/subscription-plan/${planId}/deactivate`;
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          'x-api-key': apiKey,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        const json = await response.json();
        throw new Error(`Error: ${json.message}`);
      }

      const json = await response.json();
      dispatch({ type: constants.ADMIN_DEACTIVATE_SUBSCRIPTION_PLAN_SUCCESS, data: json.data, page });
    } catch (error) {
      console.error(error.message);
    }
  };
}
