import React from "react";

export default function LandingMission({ content }) {
  return (
    <section className="mt-10">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 order-lg-1">
            <div className="about-img-box">
              <img className="img-fluid" src={content.image.src} alt={content.image.alt} />
            </div>
          </div>
          <div className="col-lg-6 col-md-12 mt-6 mt-lg-0 pe-lg-10">
            <div className="theme-title mb-4">
              <h6>{content.title}</h6>
              <h2>{content.subtitle}</h2>
              <p>{content.description}</p>
            </div>
            <ul className="list-unstyled list-icon">
              {content.highlights.map((highlight, index) => (
                <li key={index}>
                  <i className="bi bi-check-lg"></i> {highlight}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
