import React, { useState, useEffect } from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Breadcrumbs from '@mui/joy/Breadcrumbs';
import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';

import { LineChart } from '@mui/x-charts/LineChart';

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';

import Stack from '@mui/joy/Stack';
import Sidebar from '../components/AdminSidebar';
import Header from '../components/AdminHeader';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadActiveUserLog, loadPageNumberFormatUsage, loadRevenueByPlanData, loadUserRegistrationMonthlyTrend, loadUsers } from '../redux/actions';
import { AspectRatio, Grid } from '@mui/joy';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { BarChart } from '@mui/x-charts/BarChart'
import { PieChart } from '@mui/x-charts/PieChart';
import { SparkLineChart } from '@mui/x-charts/SparkLineChart';


const settings = {
  valueFormatter: (v) => `${v}%`,
  height: 100,
  showTooltip: true,
  showHighlight: true,
};
function AdminDashboard(props) {
  const { apiKey, activeUserLog, trend, revenueByPlan, pageNumberFormatUsage, loadUsers, loadUserRegistrationMonthlyTrend, loadRevenueByPlanData, loadPageNumberFormatUsage, loadActiveUserLog } = props;

  useEffect(() => {
    loadUsers(apiKey, 1, 20);
    loadUserRegistrationMonthlyTrend(apiKey);
    loadPageNumberFormatUsage(apiKey);
    loadActiveUserLog(apiKey);
    loadRevenueByPlanData(apiKey);
  }, [])

  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
        <Header />
        <Sidebar selectedTab={'dashboard'} />
        <Box
          component="main"
          className="MainContent"
          sx={{
            px: { xs: 2, md: 6 },
            pt: {
              xs: 'calc(12px + var(--Header-height))',
              sm: 'calc(12px + var(--Header-height))',
              md: 3,
            },
            pb: { xs: 2, sm: 2, md: 3 },
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            minWidth: 0,
            height: '100dvh',
            gap: 1,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Breadcrumbs
              size="sm"
              aria-label="breadcrumbs"
              separator={<ChevronRightRoundedIcon fontSize="sm" />}
              sx={{ pl: 0 }}
            >
              <Link
                underline="none"
                color="neutral"
                href="#some-link"
                aria-label="Home"
              >
                <HomeRoundedIcon />
              </Link>
              <Typography color="primary" fontWeight={500} fontSize={12}>
                Dashboard
              </Typography>
            </Breadcrumbs>
          </Box>
          <Box
            sx={{
              display: 'flex',
              mb: 1,
              gap: 1,
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: { xs: 'start', sm: 'center' },
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            <Typography level="h2" component="h1">
              Dashboard
            </Typography>
          </Box>
          <TeamExample trend={trend} activeUserLog={activeUserLog} revenueByPlan={revenueByPlan} pageNumberFormatUsage={pageNumberFormatUsage} />
        </Box>
      </Box>
    </CssVarsProvider>
  );
}

const mapStateToProps = (store) => ({
  apiKey: store.admin?.apiKey,
  trend: store.admin?.userRegistrationMonthlyTrend,
  pageNumberFormatUsage: store.admin.pageNumberFormatUsage,
  activeUserLog: store.admin.activeUserLog,
  revenueByPlan: store.admin.revenueByPlan,
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
  loadUsers,
  loadUserRegistrationMonthlyTrend,
  loadPageNumberFormatUsage,
  loadActiveUserLog,
  loadRevenueByPlanData
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);

const chartSetting = {
  xAxis: [
    {
      label: 'revenue ($)',
    },
  ],
  height: 400,
};

const valueFormatter = (value) => `${value}$`;

function TeamExample ({trend, activeUserLog, pageNumberFormatUsage, revenueByPlan}) {
  const [formatUsage, setFormatUsage] = useState([])
  const [liveUserLog, setLiveUserLog] = useState([])
  const [revenueByPlanData, setRevenueByPlanData] = useState([])

  useEffect(() => {
    setFormatUsage(pageNumberFormatUsage.map((format, index) => ({id: index, label: format.pageNumberFormat, value: format.count})))

    setLiveUserLog(activeUserLog.map((log) => ({
      x: new Date(log.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
      y: log.activeUserCount,
    })));

    setRevenueByPlanData(Object.keys(revenueByPlan).map((key, index) => ({id: index, label: key, value: revenueByPlan[key]})))
  }, [pageNumberFormatUsage, activeUserLog, revenueByPlan])

  const timestamps = liveUserLog.map((data) => data.x).filter((x) => x !== null && x !== undefined);
  const userCounts = liveUserLog.map((data) => data.y).filter((y) => !isNaN(y));

  console.log(timestamps, userCounts)

  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 12, sm: 12, md: 12 }}
            sx={{ width: '100%' }}
          >
            <Grid
              sx={{
                borderRadius: 'sm',
                p: 2,
                listStyle: 'none',
              }}
              item
              xs={12}
              md={6}
              xl={4}
            >
            <AspectRatio ratio={'5/3'} variant={'plain'}>
              <LineChart
                  xAxis={[{ scaleType: 'point', data: timestamps }]}
                  series={[
                    {
                      data: userCounts,
                    },
                  ]}
                />
            </AspectRatio>
            </Grid>
            <Grid 
              item
              xs={12}
              md={6}
              xl={4}
              sx={{
                borderRadius: 'sm',
                p: 2,
                listStyle: 'none',
              }}>
                <FormatterDemoNoSnap trend={trend} />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              xl={4}
              sx={{
                borderRadius: 'sm',
                p: 2,
                listStyle: 'none',
              }}
            >
              <Stack spacing={2}>
                <AspectRatio ratio={'5/3'} variant={'plain'}>
                  <PieChart
                    series={[
                      {
                        data: formatUsage,
                        innerRadius: 30,
                        outerRadius: 100,
                        paddingAngle: 5,
                        cornerRadius: 5,
                        startAngle: 0,
                        endAngle: 360,
                      }
                    ]}
                  />
                </AspectRatio>
                <Typography textAlign={'center'}>Pagination Usage</Typography>
              </Stack>
            </Grid>
            {/* <Grid
              item
              xs={12}
              md={6}
              xl={4}
              sx={{
                borderRadius: 'sm',
                p: 2,
                listStyle: 'none',
              }}
            >
              <Stack sx={{ width: '100%', mb: 2 }} spacing={2}>
                <Box sx={{ flexGrow: 1 }}>
                  <SparkLineChart data={[0, 2, 3, 4, 6, 8, 7, 9, 15, 6, 8, 7, 12]} colors={['red']} {...settings} />
                </Box>
                <Typography sx={{alignSelf: 'center'}}>Data</Typography>
              </Stack>
            </Grid> */}
            {/* <Grid
              item
              xs={12}
              md={6}
              xl={4}
            >
            <Stack spacing={2}>
              <PieChart
                series={[
                  {
                    data: revenueByPlanData,
                    innerRadius: 30,
                    outerRadius: 100,
                    paddingAngle: 5,
                    cornerRadius: 5,
                    startAngle: 0,
                    endAngle: 360,
                    cx: 150,
                    cy: 150,
                  }
                ]}
                width={400}
                height={300}
              />
              <Typography textAlign={'center'}>Revenue By Plan</Typography>
            </Stack>
            </Grid> */}
            <Grid
              item
              xs={12}
              md={6}
              xl={4}
            >
              <BarChart
                dataset={revenueByPlanData}
                yAxis={[{ scaleType: 'band', dataKey: 'label' }]}
                series={[{ dataKey: 'value', label: 'Revenue', valueFormatter }]}
                layout="horizontal"
                grid={{ vertical: true }}
                {...chartSetting}
              />
            </Grid>
          </Grid>
    </CssVarsProvider>
  );
}

const otherSetting = {
  yAxis: [{ label: 'Registrations' }],
  grid: { horizontal: true },
  sx: {
    [`& .${axisClasses.left} .${axisClasses.label}`]: {
      transform: 'translateX(-10px)',
    },
  },
};

const monthNames = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

function FormatterDemoNoSnap({trend}) {
  const [dataset, setDataset]  = useState([]);
  useEffect(() => {
    setDataset(trend.map(item => ({
      year: item.year,
      month: monthNames[item.month - 1],
      date: `${monthNames[item.month - 1]} ${item.year}`, // Convert month number to name
      registrations: item.count
    })))
  }, [trend])
  return (
    <AspectRatio ratio={'5/3'} variant={'plain'}>
      <BarChart
      dataset={dataset}
      xAxis={[
        {
          scaleType: 'band',
          dataKey: 'date',
          valueFormatter: (date, context) =>
            context.location === 'tick'
              ? `${date.slice(0, 3)} \n${date.split(' ')[1]}` // Display first year
              : `${date}`,
        },
      ]}
      series={[
        {
          dataKey: 'registrations',
          label: 'Registrations',
          valueFormatter: (value) => `${value}`
        }
      ]}
      {...otherSetting}
    />    
    </AspectRatio>
  );
}
