import { findAndAlterItem } from '../../utils';
import * as constants from '../constants';

const initialState = {
    _id: null,
    pdf1: null,
    bundle: {
      items: []
    },
    deskSpace: {
        items: []
    },
    notes: [],
    metadata: {
        filesData: {}
    }
}

export const caseData = (state = initialState, action) => {
    switch(action.type){
        case constants.ON_CASE_LOADED:
            return{
                ...state,
                ...action.data,
            }
        case constants.ON_PDF_LOADED:
            return{
                ...state,
                pdf1: action.data,
            }
        case constants.ON_RENAME_BUNDLE:
            if(state._id){
                return{
                    ...state,
                    name: action.data
                }
            } else{
                return state;
            }
        case constants.ON_UPDATE_BUNDLE:
            return {
              ...state,
              bundle: {
                ...state.bundle,
                items: action.data,
              }
            }
        case constants.ON_REFRESH_DESKSPACE:
            return {
                ...state,
              deskSpace: {
                ...state.deskSpace,
                items: action.data,
              }
            }
        case constants.ON_UPDATE_DESKSPACE:
            return {
                ...state,
              deskSpace: {
                ...state.deskSpace,
                items: action.data,
              }
            }
        case constants.ON_NEW_FOLDER_CREATED:
            return {
                ...state,
                deskSpace: {
                    ...state.deskSpace,
                    items: [
                        action.data,
                        ...state.deskSpace.items
                    ]
                }
            }
        case constants.ON_ADDED_OR_REMOVED_CASE_TO_OR_FROM_FAVORITE_CASES:
            return {
                ...state,
                favorite: action.data
            }
        case constants.ON_ITEM_RENAMED:
            const rename = ({item, newName}) => {
                item.name = newName;
                return item;
            }
            const props = {newName: action.data.newName}
            const deskItems = state.deskSpace.items;
            const foundInDesk = findAndAlterItem(deskItems, action.data.itemId, rename, props);
            if(foundInDesk){
                return {
                    ...state,
                    deskSpace: {
                        ...state.deskSpace,
                        items: deskItems
                    }
                }
            }

            const bundleItems = state.bundle.items;
            const foundInBundle = findAndAlterItem(bundleItems, action.data.itemId, rename, props);
            if(foundInBundle){
                return {
                    ...state,
                    bundle: {
                        ...state.bundle,
                        items: bundleItems
                    }
                }
            }
            return state;
        case constants.ON_CASE_CLOSED:
            return initialState;
        case constants.ON_ITEM_DELETED:
            return {
                ...state,
                deskSpace: {
                    ...state.deskSpace,
                    items: action.data,
                }
            }
        case constants.ON_PAGINATION_SETTINGS_UPDATED:
            return {
                ...state,
                paginationSettings: action.data,
            }
        case constants.ON_NOTE_ADDED:
            return {
                ...state,
                notes: [...state.notes, action.data]
            }
        case constants.ON_NOTE_REMOVED:
            return {
                ...state,
                notes: state.notes.filter(note => note._id !== action.data)
            }
        case constants.ON_NOTE_UPDATED:
            return {
                ...state,
                notes: state.notes.map(note => note.id === action.data ? action.data : note)
            }
        case constants.ON_NOTES_N_FILES_METADATA_LOADED:
            return {
                ...state,
                notes: action.data.notes,
                metadata: {
                    ...state.metadata,
                    filesData: action.data.filesData
                }
            }
        default:
            return state;
    }
}