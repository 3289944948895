import React, { useEffect, useState } from 'react';
import {
  CssVarsProvider,
  Sheet,
  FormControl,
  FormLabel,
  Input,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  Container,
  AspectRatio,
  Grid,
  Stack,
  CssBaseline,
  Breadcrumbs,
  Link
} from '@mui/joy';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateSiteFooter } from '../redux/actions/index.js';

import GradientCover from '../components/GradientCover.js';

const AdminLandingFooterContentEditor = ({content, apiKey, siteId, updateSiteFooter}) => {
    const [footerContent, setFooterContent] = useState({})

    useEffect(() => {
        setFooterContent(content);
    }, [content])

    const handleAddSocial = () => {
        setFooterContent(prev => ({
            ...prev,
            socials: [...prev.socials, { icon: '', link: '' }]
        }));
    };

    const handleRemoveSocial = (index) => {
        setFooterContent(prev => ({
            ...prev,
            socials: prev.socials.filter((_, i) => i !== index)
        }));
    };

    const handleAddInformationLink = () => {
        setFooterContent(prev => ({
            ...prev,
            informationLinks: [...(prev.informationLinks || []), { text: '', link: '' }]
        }));
    };

    const handleRemoveInformationLink = (index) => {
        setFooterContent(prev => ({
            ...prev,
            informationLinks: prev.informationLinks.filter((_, i) => i !== index)
        }));
    };

    const handleAddBlogLink = () => {
        setFooterContent(prev => ({
            ...prev,
            blogLinks: [...(prev.blogLinks || []), { text: '', link: '' }]
        }));
    };

    const handleRemoveBlogLink = (index) => {
        setFooterContent(prev => ({
            ...prev,
            blogLinks: prev.blogLinks.filter((_, i) => i !== index)
        }));
    };

    const handleAddOtherLink = () => {
        setFooterContent(prev => ({
            ...prev,
            otherLinks: [...(prev.otherLinks || []), { text: '', link: '' }]
        }));
    };

    const handleRemoveOtherLink = (index) => {
        setFooterContent(prev => ({
            ...prev,
            otherLinks: prev.otherLinks.filter((_, i) => i !== index)
        }));
    };

    const handleSubmit = (event) => {
        updateSiteFooter(apiKey, siteId, footerContent)
    }

    return (
        <>
            <Typography level="h4" gutterBottom>
            Footer Content
            </Typography>
            <Sheet sx={{ mb: 3, p: 2, borderRadius: '10px' }} variant={'outlined'}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <FormControl fullWidth>
                <FormLabel>Info Title</FormLabel>
                <Input
                    value={footerContent?.infoTitle}
                    onChange={(e) => setFooterContent(prev => ({
                        ...prev,
                        infoTitle: e.target.value
                    }))}
                />
                </FormControl>
                <FormControl fullWidth>
                <FormLabel>Address</FormLabel>
                <Input
                    value={footerContent?.address}
                    onChange={(e) => setFooterContent(prev => ({
                        ...prev,
                        address: e.target.value
                    }))}
                />
                </FormControl>
                <FormControl fullWidth>
                <FormLabel>Email</FormLabel>
                <Input
                    value={footerContent?.email}
                    onChange={(e) => setFooterContent(prev => ({
                        ...prev,
                        email: e.target.value
                    }))}
                />
                </FormControl>
                <FormControl fullWidth>
                <FormLabel>Phone</FormLabel>
                <Input
                    value={footerContent?.phone}
                    onChange={(e) => setFooterContent(prev => ({
                        ...prev,
                        phone: e.target.value
                    }))}
                />
                </FormControl>
                <FormControl fullWidth>
                <FormLabel>Socials Title</FormLabel>
                <Input
                    value={footerContent?.socialsTitle}
                    onChange={(e) => setFooterContent(prev => ({
                        ...prev,
                        socialsTitle: e.target.value
                    }))}
                />
                </FormControl>
                <Grid container spacing={2} columns={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    {footerContent?.socials?.map((social, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <Sheet variant={'outlined'} key={`s${index}`} sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2, p: 2, borderRadius: '10px' }}>
                                <FormControl fullWidth>
                                <FormLabel>Icon</FormLabel>
                                <Input
                                    value={social.icon}
                                    onChange={(e) => setFooterContent(prev => ({
                                        ...prev,
                                        socials: prev.socials.map((v, i) => index === i ? ({...v, icon: e.target.value}) : v )
                                    }))}
                                />
                                </FormControl>
                                <FormControl fullWidth>
                                <FormLabel>Link</FormLabel>
                                <Input
                                    value={social.link}
                                    onChange={(e) => setFooterContent(prev => ({
                                        ...prev,
                                        socials: prev.socials.map((v, i) => index === i ? ({...v, link: e.target.value}) : v )
                                    }))}
                                />
                                </FormControl>
                                <Button variant={'outlined'} color={'danger'} onClick={() => handleRemoveSocial(index)}>
                                    Remove
                                </Button>
                            </Sheet>
                        </Grid>
                    ))}
                </Grid>
                <Button variant="soft" onClick={handleAddSocial}>
                    Add Social
                </Button>
                <FormControl fullWidth>
                <FormLabel>Information Title</FormLabel>
                <Input
                    value={footerContent?.informationTitle}
                    onChange={(e) => setFooterContent(prev => ({
                        ...prev,
                        informationTitle: e.target.value
                    }))}
                />
                </FormControl>
                <Grid container spacing={2} columns={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    {footerContent?.informationLinks?.map((link, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <Sheet variant={'outlined'} key={`a${index}`} sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2, p:2, borderRadius: '10px' }}>
                                <FormControl fullWidth>
                                <FormLabel>Text</FormLabel>
                                <Input
                                    value={link.text}
                                    onChange={(e) => setFooterContent(prev => ({
                                        ...prev,
                                        informationLinks: prev.informationLinks.map((v, i) => index === i ? ({...v, text: e.target.value}) : v )
                                    }))}
                                />
                                </FormControl>
                                <FormControl fullWidth>
                                <FormLabel>Link</FormLabel>
                                <Input
                                    value={link.link}
                                    onChange={(e) => setFooterContent(prev => ({
                                        ...prev,
                                        informationLinks: prev.informationLinks.map((v, i) => index === i ? ({...v, link: e.target.value}) : v )
                                    }))}
                                />
                                </FormControl>
                                <Button variant={'outlined'} color={'danger'} onClick={(e) => handleRemoveInformationLink(index)}>
                                    Remove
                                </Button>
                            </Sheet>
                        </Grid>
                    ))}
                </Grid>
                <Button variant="soft" onClick={handleAddInformationLink}>
                    Add Information Link
                </Button>
                <Grid container spacing={2} columns={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    {footerContent?.blogLinks?.map((link, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <Sheet variant={'outlined'} key={`b${index}`} sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2, p:2, borderRadius: '10px' }}>
                                <FormControl fullWidth>
                                <FormLabel>Text</FormLabel>
                                <Input
                                    value={link.text}
                                    onChange={(e) => setFooterContent(prev => ({
                                        ...prev,
                                        blogLinks: prev.blogLinks.map((v, i) => index === i ? ({...v, text: e.target.value}) : v )
                                    }))}
                                />
                                </FormControl>
                                <FormControl fullWidth>
                                <FormLabel>Link</FormLabel>
                                <Input
                                    value={link.link}
                                    onChange={(e) => setFooterContent(prev => ({
                                        ...prev,
                                        blogLinks: prev.blogLinks.map((v, i) => index === i ? ({...v, link: e.target.value}) : v )
                                    }))}
                                />
                                </FormControl>
                                <Button variant={'outlined'} color={'danger'} onClick={() => handleRemoveBlogLink(index)}>
                                    Remove
                                </Button>
                            </Sheet>
                        </Grid>
                    ))}
                </Grid>
                <Button variant="soft" onClick={handleAddBlogLink}>
                Add Blog Link
                </Button>
                <Grid container spacing={2} columns={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                {footerContent?.otherLinks?.map((link, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <Sheet variant={'outlined'} key={`c${index}`} sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2, p:2, borderRadius: '10px' }}>
                                <FormControl fullWidth>
                                <FormLabel>Text</FormLabel>
                                <Input
                                    value={link.text}
                                    onChange={(e) => setFooterContent(prev => ({
                                        ...prev,
                                        otherLinks: prev.otherLinks.map((v, i) => index === i ? ({...v, text: e.target.value}) : v )
                                    }))}
                                />
                                </FormControl>
                                <FormControl fullWidth>
                                <FormLabel>Link</FormLabel>
                                <Input
                                    value={link.link}
                                    onChange={(e) => setFooterContent(prev => ({
                                        ...prev,
                                        otherLinks: prev.otherLinks.map((v, i) => index === i ? ({...v, link: e.target.value}) : v )
                                    }))}
                                />
                                </FormControl>
                                <Button variant={'outlined'} color={'danger'} onClick={() => handleRemoveOtherLink(index)}>
                                    Remove
                                </Button>
                            </Sheet>
                        </Grid>
                    ))}
                </Grid>
                <Button variant="soft" onClick={handleAddOtherLink}>
                    Add Other Link
                </Button>
                <FormControl fullWidth>
                <FormLabel>Subscribe Title</FormLabel>
                <Input
                    value={footerContent?.subscribeTitle}
                    onChange={(e) => setFooterContent(prev => ({
                        ...prev,
                        subscribeTitle: e.target.value
                    }))}
                />
                </FormControl>
                <FormControl fullWidth>
                <FormLabel>Subscribe Text</FormLabel>
                <Input
                    value={footerContent?.subscribeText}
                    onChange={(e) => setFooterContent(prev => ({
                        ...prev,
                        subscribeText: e.target.value
                    }))}
                />
                </FormControl>
                <FormControl fullWidth>
                <FormLabel>Copyright</FormLabel>
                <Input
                    value={footerContent?.copyright}
                    onChange={(e) => setFooterContent(prev => ({
                        ...prev,
                        copyright: e.target.value
                    }))}
                />
                </FormControl>
            </Box>
            <Button onClick={handleSubmit} color={'success'} fullWidth variant="soft">Save Footer Changes</Button>
            </Sheet>
        </>
    )
}

const mapStateToProps = (store) => ({
    apiKey: store.admin?.apiKey,
    siteId: store.admin.site._id,
    content: store.admin.site.landing.footerContent
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateSiteFooter
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AdminLandingFooterContentEditor);