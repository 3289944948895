import { Card, CardContent, Typography, Box, Sheet, Stack, Dropdown, MenuButton, IconButton, Menu, MenuItem, Divider } from "@mui/joy";
import { useRavenContext } from "./RavenContext.js";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PushPinIcon from '@mui/icons-material/PushPin';
import { timeAgo } from '../utils'

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  removeNote,
  updateNote
} from '../redux/actions/index.js';
import { findAndAlterItem, truncateString } from "../utils/index.js";

const Note = (props) => {
  const { note, user, caseData, removeNote, updateNote } = props;
  const { jumpToHighlightArea } = useRavenContext();

  const getDocumentName = (docId) => {
    const fileName = findAndAlterItem(caseData.bundle.items, docId, ({item}) => {
      return item.name;
    })
    return fileName || '';
  }

  const handleEdit = () => {
    console.log('Edit note:', note._id);
  };

  const handleRemove = () => {
    removeNote(caseData._id, user.apiKey, note._id);
    console.log('Remove note:', note._id);
  };

  return (
    <Card
      key={note._id}
      variant="soft"
      sx={{
        mb: 2,
        position: 'relative'
      }}
    >
       <Dropdown>
       <Stack direction={'row-reverse'} justifyContent={'space-between'} alignItems={'center'}>
        <MenuButton
          variant={'plain'}
          onClick={(event) => event.stopPropagation()} // Prevent click event from propagating to the card
          sx={{
            px: 2,
          }}>
            <MoreHorizIcon />
        </MenuButton>
        <Stack direction={'row'} spacing={1}>
          <PushPinIcon />
          <Typography level="body-sm"> {`${truncateString(getDocumentName(note.highlightAreas[0]?.item))}`}</Typography>
        </Stack>
        
       </Stack>
        
        <Menu>
          <MenuItem onClick={handleEdit}>Edit</MenuItem>
          <MenuItem onClick={handleRemove}>Remove</MenuItem>
        </Menu>
      </Dropdown>
      <CardContent>
        <Sheet
          variant={'soft'}
          color={'primary'}
          sx={{
            padding: 1,
            mb: 1,
            borderRadius: '10px',
            cursor: 'pointer'
          }}
          onClick={() => jumpToHighlightArea(note.highlightAreas[0])}
        >
          <Typography level="body2">{truncateString(note.quote, 100)}</Typography>
        </Sheet>
        <Typography level="title-md">{note.content}</Typography>
        <Stack spacing={1} direction={'row'} alignContent={'center'} justifyContent={'space-between'}>
          <Typography level="body-xs">Added by: {note.createdBy && (note?.createdBy === user._id)? 'You' : note.createdBy} </Typography>
          <Typography level="body-xs" sx={{textAlign: 'end'}}>{timeAgo(note.createdOn)}</Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (store) => ({
  caseData: store.caseData,
  user: store.user
})
const mapDispatchProps = (dispatch) => bindActionCreators({
  updateNote,
  removeNote
}, dispatch);
export default connect(mapStateToProps, mapDispatchProps)(Note);
