import React from "react";

export default function LandingPricing({ content }) {
  
  return (
    <section className="light-bg" data-bg-img="images/bg/02.png">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="theme-title z-index-1">
              <h6>{content.title}</h6>
              <h2>{content.subtitle}</h2>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          {content.plans.map((plan, index) => (
            <div className={plan.classes} key={index}>
              <div className="price-table">
                <div className="price-header">
                  <div>
                    <h3 className="price-title">{plan.name}</h3>
                    <div className="price-value">
                      <h2>
                        <sup>$</sup>
                        {plan.price}
                      </h2>
                      <span>{plan.duration}</span>
                    </div>
                  </div>
                  <div className="price-icon">
                    <img className="img-fluid" src={plan.iconSrc} alt={plan.name} />
                  </div>
                </div>
                <p>{plan.description}</p>
                <div className="price-list">
                  <ul className="list-unstyled">
                    {plan.features.map((feature, i) => (
                      <li key={i}>
                        <i className="bi bi-check-lg"></i>
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
                <a className="themeht-btn mt-5" href={plan.button.link}>
                  {plan.button.text}
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
