import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateUserStatus } from '../redux/actions';

function InactiveUser(props) {
    const { updateUserStatus, user, admin } = props;

    async function inactivateUser() {
        await updateUserStatus(user?.apiKey, 'inactive');
        window.close();
    }

    useEffect(() => {
        inactivateUser();
    }, []);

    return null;
}

const mapStateToProps = (store) => ({
  app: store.app,
  admin: store.admin,
  user: store.user
})

const mapDispatchProps = (dispatch) => bindActionCreators({
  updateUserStatus
}, dispatch)

export default connect(mapStateToProps, mapDispatchProps)(InactiveUser);