import React, { useState, useEffect } from 'react';
import Avatar from '@mui/joy/Avatar';
import AvatarGroup from '@mui/joy/AvatarGroup';
import Typography from '@mui/joy/Typography';
import Table from '@mui/joy/Table';

import FolderRoundedIcon from '@mui/icons-material/FolderRounded';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import { formatDate } from '../utils';
import { convertBytes } from '../utils';
import { Box, CircularProgress, Sheet } from '@mui/joy';
import { Link } from 'react-router-dom';

export default function DashTableFiles(props) {
  const { cases, loadingCases } = props;

  if(loadingCases){
    return (
      <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh'}}>
        <CircularProgress />
      </Box>
    )
  }

  if(!cases || cases.length === 0){
      return (<></>)
  }
  return (
    <Sheet
      variant="outlined"
      sx={{
        borderRadius: 'sm',
        gridColumn: '1/-1',
        display: { xs: 'none', md: 'flex' },
      }}
    >
    <div>
      <Table
        hoverRow
        size="sm"
        borderAxis="none"
        variant="soft"
        sx={{
          '--TableCell-paddingX': '1rem',
          '--TableCell-paddingY': '1rem',
        }}
      >
        <thead>
          <tr>
            <th>
              <Typography level="title-sm">Bundle Name</Typography>
            </th>
            <th>
              <Typography
                level="title-sm"
                endDecorator={<ArrowDropDownRoundedIcon />}
              >
                Last modified
              </Typography>
            </th>
            <th>
              <Typography level="title-sm">Size</Typography>
            </th>
            <th>
              <Typography level="title-sm">Users</Typography>
            </th>
          </tr>
        </thead>
        <tbody>
        {cases.map(bundle => (
          <tr key={`${bundle._id}`} style={{cursor: 'pointer'}}>
            <td>
            <Link to={`/case/${bundle._id}`} style={{textDecoration: 'none'}}>
              <Typography
                level="title-sm"
                startDecorator={<FolderRoundedIcon color="primary" />}
                sx={{ alignItems: 'flex-start' }}
              >
                {bundle.name}
              </Typography>
            </Link>
            </td>
            <td>
              <Typography level="body-sm">{formatDate(bundle.lastUpdatedOn)}</Typography>
            </td>
            <td>
              <Typography level="body-sm">{convertBytes(bundle.size)}</Typography>
            </td>
            <td>
              <AvatarGroup
                size="sm"
                sx={{ '--AvatarGroup-gap': '-8px', '--Avatar-size': '24px' }}
              >
                <Avatar
                  src="https://i.pravatar.cc/24?img=6"
                  srcSet="https://i.pravatar.cc/48?img=6 2x"
                />
                <Avatar
                  src="https://i.pravatar.cc/24?img=7"
                  srcSet="https://i.pravatar.cc/48?img=7 2x"
                />
                <Avatar
                  src="https://i.pravatar.cc/24?img=8"
                  srcSet="https://i.pravatar.cc/48?img=8 2x"
                />
                <Avatar
                  src="https://i.pravatar.cc/24?img=9"
                  srcSet="https://i.pravatar.cc/48?img=9 2x"
                />
              </AvatarGroup>
            </td>
          </tr>
        ))}
        </tbody>
      </Table>
    </div>
    </Sheet>
  );
}