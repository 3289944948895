import React from 'react'

const ContactUsForm = ({ content }) => {

    const handleSubmit = (event) => {
        event.preventDefault();
        fetch('http://localhost:8000/contact.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name: 'Raven', surname: 'Ash', email: 'sajid403044@gmail.com', phone: '+923403100011', message: 'Eww, this website sucks!'})
        }).then(response => response).then(res => console.log(res)).catch(err => console.log(err))
    }
    return (
        <section>
            <div className="container">
                <div className="row align-items-center">
                <div className="col-lg-6 col-md-12 pe-lg-10">
                    <div className="map iframe-h-2">
                    <iframe src={content?.map} allowfullscreen=""></iframe>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 mt-7 mt-lg-0">
                    <div className="theme-title">
                    <h6>{content?.title}</h6>
                    <h2>{content?.subtitle}</h2>
                    </div>
                    <form id="contact-form" onSubmit={handleSubmit}>
                        <div className="messages"></div>
                            {content?.inputs.map((row, index) => (
                                <div className="row">
                                    {row?.map(input => (
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input id={`form_${input.name}`} type={input.type} name={input.name} className="form-control" placeholder={input.placeHolder} required="required" data-error={input.dataError} />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <textarea id={`form_${content?.message?.name}`} name={content?.message?.name} className="form-control" placeholder={content?.message?.placeHolder} rows={`${content?.message?.rows}`} required="required" data-error={content?.message?.dataError}></textarea>
                                    <div className="help-block with-errors"></div>
                                </div>
                            </div>
                            <div className="col-md-12 mt-2">
                                <button type='submit' className="themeht-btn">{content?.button?.text}</button>
                            </div>
                        </div>
                    </form>
                </div>
                </div>
            </div>
        </section>
    )
}

export default ContactUsForm;