import React, { useEffect, useState, useCallback } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadSite } from '../redux/actions/index.js';
import LandingHeader from '../components/LandingHeader.js';
import LandingFooter from '../components/LandingFooter.js';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { CircularProgress, CssVarsProvider, Sheet, Box } from '@mui/joy';
import PageTitleSection from '../components/PageTitleSection.js';
import DeveloperComponent from '../components/DeveloperComponent.js';

function Team(props) {
  const { site, loadSite } = props;
  const name = 'Raven3';

  useEffect(() => {
    loadSite(name);
  }, [])

  return (
    <HelmetProvider>
      <Helmet>
        <link rel="stylesheet" href="css/bootstrap.min.css" type="text/css" />
        <link rel="stylesheet" href="css/bootstrap-icons.css" type="text/css" />
        <link rel="stylesheet" href="css/animate.css" type="text/css" />
        <link rel="stylesheet" href="css/magnific-popup.css" type="text/css" />
        <link rel="stylesheet" href="css/owl.carousel.css" type="text/css" />
        <link rel="stylesheet" href="css/odometer.css" type="text/css" />
        <link rel="stylesheet" href="css/spacing.css" type="text/css" />
        <link rel="stylesheet" href="css/base.css" type="text/css" />
        <link rel="stylesheet" href="css/shortcodes.css" type="text/css" />
        <link rel="stylesheet" href="css/style.css" type="text/css" />
        <link rel="stylesheet" href="css/responsive.css" type="text/css" />
        {/* <link rel="stylesheet" href="css/color-customize/color-customizer.css" type="text/css" /> */}
        <link rel="stylesheet" href="css/theme-color/color-8.css" type="text/css" />
      </Helmet>
        {(site.loaded)? (
          <div className="page-wrapper">
            <LandingHeader content={site?.landing?.header} />
            <PageTitleSection content={site?.developer?.pageTitle} />
            <div className="page-content">
                <DeveloperComponent content={site?.developer?.body} />
            </div>
            <LandingFooter content={site?.landing?.footerContent} />
          </div>
        ): (
          <CssVarsProvider>
            <Sheet sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
              <Box>
                <CircularProgress />
              </Box>
            </Sheet>
          </CssVarsProvider>
        )}

        {/* <div className="color-customizer closed">
          <a className="opener" href="#"> <i className="bi bi-palette"></i></a>
          <div className="clearfix color-chooser text-center">
            <h4 className="mb-4">Customize Colors</h4>
            <ul className="colorChange clearfix">
              <li className="theme-default selected" title="theme-default" data-style="color-1"></li>
              <li className="theme-2" title="theme-2" data-style="color-2"></li>
              <li className="theme-3" title="theme-3" data-style="color-3"></li>
              <li className="theme-4" title="theme-4" data-style="color-4"></li>
              <li className="theme-5" title="theme-5" data-style="color-5"></li>
              <li className="theme-6" title="theme-6" data-style="color-6"></li>
              <li className="theme-7" title="theme-7" data-style="color-7"></li>
              <li className="theme-8" title="theme-8" data-style="color-8"></li>
              <li className="theme-9" title="theme-9" data-style="color-9"></li>
              <li className="theme-10" title="theme-10" data-style="color-10"></li>
              <li className="theme-11" title="theme-11" data-style="color-11"></li>
              <li className="theme-12" title="theme-12" data-style="color-12"></li>
            </ul>
          </div>
        </div> */}

        <div className="scroll-top" style={{display: (site.loaded)? 'initial' : 'none'}}>
          <svg className="scroll-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
          </svg>
        </div>

        <Helmet>
          <script src='js/wscript.js'></script>
        </Helmet>
    </HelmetProvider>
  );
}

const mapStateToProps = (store) => ({
  user: store.user,
  site: store.site
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
  loadSite
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Team);
