import React, { useState } from 'react';
import Box from '@mui/joy/Box';
import List from '@mui/joy/List';
import ListSubheader from '@mui/joy/ListSubheader';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListItemContent from '@mui/joy/ListItemContent';

import FolderRoundedIcon from '@mui/icons-material/FolderRounded';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { AddRounded } from '@mui/icons-material';

import { createNewCase } from '../redux/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function DashNavigation(props) {
  const { user: { apiKey }, createNewCase, setBackDropOpen, selected, setSelected, setOpenCreateNewBundleModal, setDrawerOpen } = props;
  const navigate = useNavigate();

  const handleClose = () => {
    setBackDropOpen(false);
  };

  const handleCreateNewCase = (event) => {
    setDrawerOpen(false);
    // setBackDropOpen(true);
    setSelected(0);
    setOpenCreateNewBundleModal(true);
    //createNewCase(apiKey, navigate, handleClose)
  }

  const handleNavigationToMyBundles = (event) => {
    setDrawerOpen(false);
    setSelected(1);
  }

  const handleNavigationToShared = (event) => {
    setDrawerOpen(false);
    setSelected(2);
  }

  const handleNavigationToTrash = (event) => {
    setDrawerOpen(false);
    setSelected(3);
  }

  return (
    <List size="sm" sx={{ '--ListItem-radius': '8px', '--List-gap': '4px' }}>
      <ListItem nested>
        <ListSubheader sx={{ letterSpacing: '2px', fontWeight: '800' }}>
          Browse
        </ListSubheader>
        <List
          aria-labelledby="nav-list-browse"
          sx={{
            '& .JoyListItemButton-root': { p: '8px' },
          }}
        >
          <ListItem>
            <ListItemButton onClick={handleCreateNewCase} selected={selected === 0}>
              <ListItemDecorator>
                <AddRounded fontSize="small" />
              </ListItemDecorator>
              <ListItemContent className={'ravenride-my-first-step'}>Create New Bundle</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={handleNavigationToMyBundles} selected={selected === 1}>
              <ListItemDecorator>
                <FolderRoundedIcon fontSize="small" />
              </ListItemDecorator>
              <ListItemContent className={'ravenride-my-other-step'}>My Bundles</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={handleNavigationToShared} selected={selected === 2}>
              <ListItemDecorator>
                <ShareRoundedIcon fontSize="small" />
              </ListItemDecorator>
              <ListItemContent>Shared files</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={handleNavigationToTrash} selected={selected === 3}>
              <ListItemDecorator selected={selected === 3}>
                <DeleteRoundedIcon fontSize="small" />
              </ListItemDecorator>
              <ListItemContent>Trash</ListItemContent>
            </ListItemButton>
          </ListItem>
        </List>
      </ListItem>
      {/* <ListItem nested sx={{ mt: 2 }}>
        <ListSubheader sx={{ letterSpacing: '2px', fontWeight: '800' }}>
          Tags
        </ListSubheader>
        <List
          aria-labelledby="nav-list-tags"
          size="sm"
          sx={{
            '--ListItemDecorator-size': '32px',
            '& .JoyListItemButton-root': { p: '8px' },
          }}
        >
          <ListItem>
            <ListItemButton>
              <ListItemDecorator>
                <Box
                  sx={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '99px',
                    bgcolor: 'primary.500',
                  }}
                />
              </ListItemDecorator>
              <ListItemContent>Personal</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton>
              <ListItemDecorator>
                <Box
                  sx={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '99px',
                    bgcolor: 'danger.500',
                  }}
                />
              </ListItemDecorator>
              <ListItemContent>Work</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton>
              <ListItemDecorator>
                <Box
                  sx={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '99px',
                    bgcolor: 'warning.400',
                  }}
                />
              </ListItemDecorator>
              <ListItemContent>Travels</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton>
              <ListItemDecorator>
                <Box
                  sx={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '99px',
                    bgcolor: 'success.400',
                  }}
                />
              </ListItemDecorator>
              <ListItemContent>Concert tickets</ListItemContent>
            </ListItemButton>
          </ListItem>
        </List>
      </ListItem> */}
    </List>
  );
}

const mapStateToProps = (store) => ({
  user: store.user,
  loadingCases: store.app.loadingCases
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
  createNewCase
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DashNavigation);