import React, { useEffect, useState } from 'react';
import {
  Sheet,
  FormControl,
  FormLabel,
  Input,
  Button,
  Typography,
  Box,
  Stack,
} from '@mui/joy';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateSiteLandingHero } from '../redux/actions/index.js';
import GradientCover from './GradientCover.js';

const AdminLandingHeroEditor = ({ content, apiKey, siteId, updateSiteLandingHero }) => {
    const [hero, setHero] = useState({})

    useEffect(() => {
        setHero(content);
    }, [content])

    const handleImageChange = (e, key) => {
        const file = e.target.files[0];
        if (file) {
            setHero(prev => ({
                ...prev,
                [key]: {
                ...prev[key],
                file,
                src: URL.createObjectURL(file),
                },
            }));
        }
    }

    const handleSubmit = (event) => {
        const formData = new FormData();
        formData.append('hero', JSON.stringify(hero));

        ['mainBanner', 'secondaryTopBanner', 'secondaryRightBanner'].forEach((key) => {
            if (hero[key]?.file) {
                formData.append(key, hero[key].file);
            }
        });

        updateSiteLandingHero(apiKey, siteId, formData);
    }

    if(!hero){
        return (
            <></>
        )
    }
    return (
        <>
        <Typography level="h4" gutterBottom>
            Hero Section
        </Typography>
        <Sheet sx={{mb: 3, p: 2, borderRadius: '10px'}} variant={'outlined'}>
            <Box sx={{ display: 'flex', flexDirection: {xs: 'column', md: 'row'}, gap: 2, mb: 2}}>
                {hero?.title?.map((titlePart, index) => (
                    <FormControl sx={{ mb: 2,  flex: 1}} key={index}>
                        <FormLabel>Title Part {index + 1}</FormLabel>
                        <Input
                            value={titlePart}
                            onChange={e => setHero(prev => ({
                                ...prev,
                                title: prev.title.map((v, i) => index === i ? e.target.value : v)
                            }))}
                        />
                    </FormControl>
                ))}
            </Box>
            <FormControl fullwidth={'true'} sx={{ mb: 2 }}>
                <FormLabel>Description</FormLabel>
                <Input
                maxRows={5}
                value={hero?.description}
                onChange={(e) => setHero(prev => ({
                    ...prev,
                    description: e.target.value
                }))
                }
                />
            </FormControl>
            <Box sx={{ display: 'flex', flexDirection: {xs: 'column', md: 'row'}, justifyContent: 'center', gap: 2, mb: 2 }}>
            <Stack spacing={2}>
                <GradientCover title={'Main Banner'} image={hero?.mainBanner} />
                <input type="file" accept="image/*" onChange={(e) => handleImageChange(e, 'mainBanner')} />
            </Stack>
            <Stack spacing={2}>
                <GradientCover title={'Secondary Top Banner'} image={hero?.secondaryTopBanner} />
                <input type="file" accept="image/*" onChange={(e) => handleImageChange(e, 'secondaryTopBanner')} />
            </Stack>
            <Stack spacing={2}>
                <GradientCover title={'Secondary Right Banner'} image={hero?.secondaryRightBanner} />
                <input type="file" accept="image/*" onChange={(e) => handleImageChange(e, 'secondaryRightBanner')} />
            </Stack>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: {xs: 'column', md: 'row'}, gap: 2, mb: 2 }}>
                <FormControl fullwidth={'true'} sx={{ flex: 1 }}>
                    <FormLabel>Main Button Text</FormLabel>
                    <Input
                    value={hero?.mainButton?.text}
                    onChange={(e) => setHero(prev => ({
                        ...prev,
                        mainButton: {
                            ...prev.mainButton,
                            text: e.target.value
                        }
                    }))}
                    />
                </FormControl>
                <FormControl sx={{ flex: 1 }}>
                    <FormLabel>Button Link</FormLabel>
                    <Input
                    value={hero?.mainButton?.href}
                    onChange={(e) => setHero(prev => ({
                        ...prev,
                        mainButton: {
                            ...prev.mainButton,
                            href: e.target.value
                        }
                    }))}
                    />
                </FormControl>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: {xs: 'column', md: 'row'}, gap: 2, mb: 2 }}>
                <FormControl fullwidth={'true'} sx={{ flex: 1 }}>
                    <FormLabel>Main Button Text</FormLabel>
                    <Input
                    value={hero?.secondaryButton?.text}
                    onChange={(e) => setHero(prev => ({
                        ...prev,
                        secondaryButton: {
                            ...prev.secondaryButton,
                            text: e.target.value
                        }
                    }))}
                    />
                </FormControl>
                <FormControl sx={{ flex: 1 }}>
                    <FormLabel>Button Link</FormLabel>
                    <Input
                    value={hero?.secondaryButton?.href}
                    onChange={(e) => setHero(prev => ({
                        ...prev,
                        secondaryButton: {
                            ...prev.secondaryButton,
                            href: e.target.value
                        }
                    }))}
                    />
                </FormControl>
            </Box>
            <Button onClick={handleSubmit} color={'success'} fullWidth variant="soft">Save Hero Changes</Button>
        </Sheet>
        </>
    )
}

const mapStateToProps = (store) => ({
    apiKey: store.admin?.apiKey,
    siteId: store.admin.site._id,
    content: store.admin.site.landing.hero
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateSiteLandingHero
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AdminLandingHeroEditor);