import React, { useEffect, useState } from 'react';
import {
  Sheet,
  FormControl,
  FormLabel,
  Input,
  Typography,
  Box,
  Button,
} from '@mui/joy';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateSiteHeader } from '../redux/actions/index.js';

const AdminLandingHeaderEditor = ({ content, apiKey, siteId, updateSiteHeader }) => {
    const [header, setHeader] = useState({})
    
    useEffect(() => {
        setHeader(content);
    }, [content])

    const addMainPage = () => {
        if ((header.mainPages || []).length < 7) {
            setHeader(prev => ({
                ...prev,
                mainPages: [...(prev.mainPages || []), { text: '', href: '' }]
            }));
        }
    };

    const addOtherPage = () => {
        setHeader(prev => ({
            ...prev,
            otherPages: {
                ...prev.otherPages,
                listItems: [...(prev.otherPages?.listItems || []), { text: '', href: '' }]
            }
        }));
    };

    const removeMainPage = (indexToRemove) => {
        setHeader(prev => ({
            ...prev,
            mainPages: prev.mainPages.filter((_, index) => index !== indexToRemove)
        }));
    };

    const removeOtherPage = (indexToRemove) => {
        setHeader(prev => ({
            ...prev,
            otherPages: {
                ...prev.otherPages,
                listItems: prev.otherPages.listItems.filter((_, index) => index !== indexToRemove)
            }
        }));
    };

    const handleSubmit = (event) => {
        updateSiteHeader(apiKey, siteId, header);
    }

    if(!header){
        <></>
    }

    return (
        <>
        <Typography level="h4" gutterBottom>
            Header
        </Typography>
        <Sheet sx={{mb: 3, p: 2, borderRadius: '10px'}} variant={'outlined'}>
            <FormControl fullwidth={'true'} sx={{ mb: 2 }}>
                <FormLabel>Brand Name</FormLabel>
                <Input
                    value={header?.brandName}
                    onChange={(e) =>
                        setHeader(prev => ({
                            ...prev,
                            brandName: e.target.value
                        }))
                    }
                />
            </FormControl>
            <Sheet sx={{mb: 3, p: 2, borderRadius: '10px'}} variant={'soft'}>
                {header?.mainPages?.map((page, index) => (
                    <Box key={index} sx={{ display: 'flex', flexDirection: {xs: 'column', md: 'row'}, gap: 2, mb: 2 }}>
                        <FormControl sx={{ flex: 1 }}>
                            <FormLabel>{`${index + 1}. Main Page Text`}</FormLabel>
                            <Input
                            value={page.text}
                            onChange={(e) =>
                                setHeader(prev => ({
                                    ...prev,
                                    mainPages: prev.mainPages.map((p, i) => index === i ? ({...p, text: e.target.value }) : p)
                                }))
                            }
                            />
                        </FormControl>
                        <FormControl sx={{ flex: 1 }}>
                            <FormLabel>Main Page Link</FormLabel>
                            <Input
                            value={page.href}
                            onChange={(e) =>
                                setHeader(prev => ({
                                    ...prev,
                                    mainPages: prev.mainPages.map((p, i) => index === i ? ({...p, href: e.target.value }) : p )
                                }))
                            }
                            />
                        </FormControl>
                        <FormControl sx={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button variant={'outlined'} color={'danger'} onClick={() => removeMainPage(index)}>Remove</Button>
                        </FormControl>
                    </Box>
                ))}
                <Button variant="soft" onClick={addMainPage}>Add Main Page</Button>
            </Sheet>
            <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                <FormControl sx={{ flex: 1 }}>
                    <FormLabel>Dropdown Title</FormLabel>
                        <Input
                            value={header?.otherPages?.title}
                            onChange={(e) =>
                                setHeader(prev => ({
                                    ...prev,
                                    otherPages: {
                                        ...prev.otherPages,
                                        title: e.target.value
                                    }
                                }))
                            }
                        />
                </FormControl>
            </Box>
            <Sheet sx={{mb: 3, p: 2, borderRadius: '10px'}} variant={'soft'}>
                {header?.otherPages?.listItems?.map((page, index) => (
                    <Box key={index} sx={{ display: 'flex', flexDirection: {xs: 'column', md: 'row'}, gap: 2, mb: 2 }}>
                        <FormControl sx={{ flex: 1 }}>
                            <FormLabel>{`${index + 1}. Dropdown Page Text`}</FormLabel>
                            <Input
                                value={page.text}
                                onChange={e =>
                                    setHeader(prev => ({
                                        ...prev,
                                        otherPages: {
                                            ...prev.otherPages,
                                            listItems: prev.otherPages.listItems.map((p, i) => index === i ? ({...p, text: e.target.value }) : p )
                                        }
                                    }))
                                }
                            />
                        </FormControl>
                        <FormControl sx={{ flex: 1 }}>
                            <FormLabel>Dropdown Page Link</FormLabel>
                            <Input
                                value={page.href}
                                onChange={e =>
                                    setHeader(prev => ({
                                        ...prev,
                                        otherPages: {
                                            ...prev.otherPages,
                                            listItems: prev.otherPages.listItems.map((p, i) => index === i ? ({...p, href: e.target.value }) : p )
                                        }
                                    }))
                                }
                            />
                        </FormControl>
                        <FormControl sx={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button variant={'outlined'} color={'danger'} onClick={() => removeOtherPage(index)}>Remove</Button>
                        </FormControl>
                    </Box>
                ))}
                <Button variant="soft" onClick={addOtherPage}>Add Dropdown Page</Button>
            </Sheet>
            <Box sx={{ display: 'flex', flexDirection: {xs: 'column', md: 'row'}, gap: 2, mb: 2 }}>
                <FormControl sx={{ flex: 1 }}>
                    <FormLabel>Button Text</FormLabel>
                    <Input
                        value={header?.button?.text}
                        onChange={(e) =>
                            setHeader(prev => ({
                                ...prev,
                                button: {
                                    ...prev.button,
                                    text: e.target.value
                                }
                            }))
                        }
                    />
                </FormControl>
                <FormControl sx={{ flex: 1 }}>
                    <FormLabel>Button Link</FormLabel>
                    <Input
                        value={header?.button?.href}
                        onChange={(e) =>
                            setHeader(prev => ({
                                ...prev,
                                button: {
                                    ...prev.button,
                                    href: e.target.value
                                }
                            }))
                        }
                    />
                </FormControl>
            </Box>
            <Button onClick={handleSubmit} color={'success'} fullWidth variant="soft">Save Header Changes</Button>
        </Sheet>
        </>
    )
}

const mapStateToProps = (store) => ({
    apiKey: store.admin?.apiKey,
    siteId: store.admin.site._id,
    content: store.admin.site.landing.header
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateSiteHeader
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AdminLandingHeaderEditor);