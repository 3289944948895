import React, { useState, useEffect } from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Breadcrumbs from '@mui/joy/Breadcrumbs';
import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';

import Sidebar from '../components/AdminSidebar';
import Header from '../components/AdminHeader';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadAudits } from '../redux/actions';
import { Chip, IconButton, Stack } from '@mui/joy';

import RefreshIcon from '@mui/icons-material/Refresh';

function Audits({ auditsData, handleLoadMore }) {
  const [audits, setAudits] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setAudits(Object.values(auditsData.audits).flat().reverse());
  }, [auditsData])

  return (
    <Box sx={{ my: 2, overflowY: 'scroll' }}>
      {page < auditsData.totalPages && (<Stack>
        <IconButton onClick={(e) => { setPage(page + 1); handleLoadMore(page + 1) }}>
          <RefreshIcon />
        </IconButton>
      </Stack>)}
      {audits && audits.length > 0 ? (
        audits.map((log, index) => (
          <Stack direction={'row'} spacing={1} key={index} sx={{ my: 1, p: 1 }}>
            <Typography variant="body2">
              <Chip variant={'outlined'} color={'warning'}>{log.action}</Chip>
            </Typography>
            <Typography variant="body2">
              <Chip variant={'outlined'} color={'success'}>{log.user?.email || "N/A"}</Chip>
            </Typography>
            <Typography variant="body2">
              [ {new Date(log.timestamp).toLocaleString()} ]
            </Typography>
            <Typography variant='body2'>
              {log.details}
            </Typography>
          </Stack>
        ))
      ) : (
        <Typography variant="body2">No audits available</Typography>
      )}
    </Box>
  );
}

function AdminAudits(props) {
  const { apiKey, auditsData, loadAudits } = props;

  useEffect(() => {
    loadAudits(apiKey, 1, 20)
  }, [])

  const handleLoadMore = (page) => {
    loadAudits(apiKey, page, 20)
  }

  function downloadCSV() {
    const endpoint = '/api/admin/audits/download';

    fetch(endpoint, {
      method: 'GET',
      headers: {
        'x-api-key': apiKey,
        'Content-Type': 'text/csv',
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.blob();
        }
        throw new Error('Network response was not ok.');
      })
      .then((blob) => {
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = 'audits.csv';
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(downloadUrl);
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }

  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
        <Header />
        <Sidebar selectedTab={'audits'} />
        <Box
          component="main"
          className="MainContent"
          sx={{
            px: { xs: 2, md: 6 },
            pt: {
              xs: 'calc(12px + var(--Header-height))',
              sm: 'calc(12px + var(--Header-height))',
              md: 3,
            },
            pb: { xs: 2, sm: 2, md: 3 },
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            minWidth: 0,
            height: '100dvh',
            gap: 1,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Breadcrumbs
              size="sm"
              aria-label="breadcrumbs"
              separator={<ChevronRightRoundedIcon fontSize="sm" />}
              sx={{ pl: 0 }}
            >
                <Link
                    underline="none"
                    color="neutral"
                    href="/"
                    aria-label="Home"
                >
                    <HomeRoundedIcon />
                </Link>
                <Link
                    underline="hover"
                    color="neutral"
                    href="/dashboard"
                    fontSize={12}
                    fontWeight={500}
                >
                    Dashboard
                </Link>
                <Typography color="primary" fontWeight={500} fontSize={12}>
                    Audits
                </Typography>
            </Breadcrumbs>
          </Box>
          <Box
            sx={{
              display: 'flex',
              mb: 1,
              gap: 1,
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: { xs: 'start', sm: 'center' },
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            <Typography level="h2" component="h1">
                Audits
            </Typography>
            <Button
              color="primary"
              startDecorator={<DownloadRoundedIcon />}
              size="sm"
              onClick={downloadCSV}
            >
              Download CSV
            </Button>
          </Box>
          <Audits auditsData={auditsData} handleLoadMore={handleLoadMore} />
        </Box>
      </Box>
    </CssVarsProvider>
  );
}

const mapStateToProps = (store) => ({
  apiKey: store.admin?.apiKey,
  auditsData: store.admin.auditsData
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
  loadAudits
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AdminAudits);