import React from "react";

const PricingComponent = ({ content }) => {
    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="theme-title">
                            <h6>{content.title}</h6>
                            <h2>{content.subtitle}</h2>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    {content.plans.map((plan, index) => (
                        <div key={index} className={`col-lg-4 col-md-12 ${index > 0 ? 'mt-6 mt-lg-0' : ''}`}>
                            <div className="price-table">
                                <div className="price-header">
                                    <div>
                                        <h3 className="price-title">{plan.title}</h3>
                                        <div className="price-value">
                                            <h2>
                                                <sup>{plan.currencySymbol}</sup>{plan.price}
                                            </h2>
                                            <span>{plan.duration}</span>
                                        </div>
                                    </div>
                                    <div className="price-icon">
                                        <img className="img-fluid" src={plan.iconSrc} alt={plan.iconAlt} />
                                    </div>
                                </div>
                                <p>{plan.description}</p>
                                <div className="price-list">
                                    <ul className="list-unstyled">
                                        {plan.features.map((feature, i) => (
                                            <li key={i}>
                                                <i className="bi bi-check-lg"></i>{feature}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <a className="themeht-btn mt-5" href={plan.purchaseLink}>{plan.purchaseText}</a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default PricingComponent;