import React, {useState, useEffect, useCallback} from 'react';
import { useColorScheme } from '@mui/joy/styles';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Avatar from '@mui/joy/Avatar';
import Input from '@mui/joy/Input';
import Tooltip from '@mui/joy/Tooltip';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import ListDivider from '@mui/joy/ListDivider';
import Drawer from '@mui/joy/Drawer';
import ModalClose from '@mui/joy/ModalClose';
import DialogTitle from '@mui/joy/DialogTitle';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import ChatIcon from '@mui/icons-material/Chat';
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';

import { 
  FileUpload as FileUploadIcon,
  Print as PrintIcon,
  Schedule as ScheduleIcon,
  ZoomOutMap as ZoomOutMapIcon
} from '@mui/icons-material';

import Navigation from './EditorNavigation';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  renameCase,
  changeTheme,
  mergePDF,
  createNewCase,
  setPagination,
  generateToc,
  toggleCaseFavorite,
  logout,
  toggleTheme
} from '../redux/actions/index.js';
import { Link, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Divider } from '@mui/joy';
import OpenCasesModal from './OpenCasesModal.js';
import PaginationSettingsModal from './PaginationSettingsModal.js';
import { useRavenContext } from './RavenContext.js';
import Note from './Note.js';

function ColorSchemeToggle() {
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);
  React.useEffect(() => {
    setMounted(true);
  }, []);
  if (!mounted) {
    return <IconButton size="sm" variant="outlined" color="primary" />;
  }
  return (
    <Tooltip title="Change theme" variant="outlined">
      <IconButton
        id="toggle-mode"
        size="sm"
        variant="plain"
        color="neutral"
        sx={{ alignSelf: 'center' }}
        onClick={() => {
          if (mode === 'light') {
            setMode('dark');
          } else {
            setMode('light');
          }
        }}
      >
        {mode === 'light' ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
      </IconButton>
    </Tooltip>
  );
}

function EditorHeader(props) {
  const { user, logout, isRightDrawerOpen, setIsRightDrawerOpen, selectedOption, setSelectedOption, setBackDropOpen, selected, setSelected, handleDrawerClose } = props;

  const [open, setOpen] = React.useState(false);
  const company = "CaseGenie";

  const { print, Download, EnterFullScreen } = useRavenContext();

  const { toggleCaseFavorite, caseData, renameCase, generateToc, pagination, setPagination, toolBarBusy, bundleBusy, mergePDF, createNewCase } = props;
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [title, setTitle] = useState(caseData?.name || '');
  const [allowToggle, setAllowToggle] = useState(false);
  const [openCaseModal, setOpenCaseModal] = useState(false);
  const [isPagination, setIsPagination] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const [isPaginationModalOpen, setIsPaginationModalOpen] = useState(false);

  const [xopen, setXOpen] = useState(false);

  const handleClose = () => {
    setXOpen(false);
  };

  const { enqueueSnackbar } = useSnackbar();

  const handleNewCaseCreation = (event) => {
    
    setXOpen(true);
    createNewCase(user.apiKey, navigate, handleClose);
  }

  const handleOpenCase = (event) => {
    setOpenCaseModal(true);
  }

  const handleRecentCases = (event) => {
    setOpenCaseModal(true);
  }

  const handlePaginationSettings = (event) => {
    setIsPaginationModalOpen(true);
  }

  const handleRightDrawerOpen = (event) => {
    setIsRightDrawerOpen(true);
  }

  const handleOnOffPagination = (event) => {
    if(!pagination){
      setPagination(caseData._id, user.apiKey, true)
    } else {
      setPagination(caseData._id, user.apiKey, false)
    }
    setIsPagination(value => !value)
  }

  const handleBlur = useCallback((event) => {
    if(event.target.value === caseData.name || event.target.value.trim().length === 0){
      return;
    }
    renameCase(caseData._id, user.apiKey, event.target.value, enqueueSnackbar);
  }, [caseData]); 

  useEffect(() => {
    setTitle(caseData.name);
    setIsPagination(pagination);
  }, [caseData, bundleBusy, toolBarBusy, pagination])

  useEffect(() => {
    if (user?.profileImage?.data) {
      try{
        const blob = new Blob([new Uint8Array(user.profileImage.data)], { type: 'image/jpeg' });
        const reader = new FileReader();
        reader.onload = () => {
          setImageSrc(reader.result);
        };
        reader.readAsDataURL(blob);
      } catch(error){
        console.log(error);
      }
    }
  }, [user?.profileImage])

  const handlePrint = (event) => {
    print();
  }

  const handleRefresh = (event) => {
    mergePDF(caseData._id, user.apiKey);
  }

  const handleTocGeneration = (event) => {
    generateToc(caseData._id, user.apiKey);
  }

  const handleToggleFavorite = (event) => {
    toggleCaseFavorite(caseData._id, user.apiKey, enqueueSnackbar);
  }

  const handleLogout = (event) => {
    logout(navigate);
  }

  const handleLogut = () => {
    logout(navigate);
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
      }}
    >
    <PaginationSettingsModal open={isPaginationModalOpen} setOpen={setIsPaginationModalOpen} />
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{ display: { xs: 'none', sm: 'flex' } }}
      >
        <Link to={'/'} style={{textDecoration: 'none'}}>
          <IconButton
            size="md"
            variant="outlined"
            color="neutral"
            sx={{
              display: { xs: 'none', sm: 'inline-flex' },
              // borderRadius: '50%',
            }}
          >
            <HomeRoundedIcon />
          </IconButton>
        </Link>
          <Input
            variant="plain"
            value={title || ''}
            onChange={(e) => setTitle(e.target.value)}
            onBlur={handleBlur}
            sx={{
              input: {
                fontSize: 16,
                fontWeight: 500,
                padding: 0.25,
                pl: 0.75,
                border: 0,
                width: '150px',
              },
              mb: isMobile ? 0.5 : 0.5,
              mt: 1,
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none',
                },
              },
            }}
          />

          <IconButton onClick={handleToggleFavorite} edge="start" color="inherit" aria-label="star">
            {!caseData.favorite ? ( <FavoriteBorderRoundedIcon />) : (<FavoriteRoundedIcon />)}
          </IconButton>
        <Dropdown>
          <MenuButton
            variant="plain"
            color="neutral"
            component="button"
            size="sm"
            aria-pressed={selectedOption === 1}
            onClick={(e) =>setSelectedOption(1) }
            sx={{ alignSelf: 'center' }}
          >
            <Typography>
              File
            </Typography>
          </MenuButton>
          <Menu
            placement="bottom-end"
            size="sm"
            sx={{
              zIndex: '99999',
              p: 1,
              gap: 1,
              '--ListItem-radius': 'var(--joy-radius-sm)',
            }}
          >
            <MenuItem onClick={handleNewCaseCreation}>New Case</MenuItem>
            <MenuItem onClick={handleOpenCase}>Open Case</MenuItem>
            <MenuItem sx={{justifyContent: 'space-between'}} onClick={handleRecentCases}>Recent Cases <ScheduleIcon sx={{ml: 2}} /></MenuItem>
            <Download>
            {
              (props) => (
                <MenuItem disabled={(caseData.pdf1 && caseData.pdf1.length === 0) || caseData.bundle.items.length == 0 } sx={{justifyContent: 'space-between'}} onClick={props.onClick}>Export Case to PDF <FileUploadIcon sx={{ml: 2}} /></MenuItem>
              )
            }
            </Download>
            <Divider />
              <MenuItem disabled={(caseData.pdf1 && caseData.pdf1.length === 0) || caseData.bundle.items.length == 0} sx={{justifyContent: 'space-between'}} onClick={handlePrint}>Print <PrintIcon onClick={print} sx={{ml: 2}} /></MenuItem>
          </Menu>
        </Dropdown>
        <Dropdown>
          <MenuButton
            variant="plain"
            color="neutral"
            component="button"
            size="sm"
            aria-pressed={selectedOption === 2}
            onClick={(e) =>setSelectedOption(2) }
            sx={{ alignSelf: 'center' }}
          >
            <Typography>
              Edit
            </Typography>
          </MenuButton>
          <Menu
            placement="bottom-end"
            size="sm"
            sx={{
              zIndex: '99999',
              p: 1,
              gap: 1,
              '--ListItem-radius': 'var(--joy-radius-sm)',
            }}
          >
            <MenuItem onClick={handleRefresh}>Refresh</MenuItem>
            <Divider />
            <MenuItem onClick={handlePaginationSettings} >Pagination Settings</MenuItem>
            <MenuItem disabled={(caseData.pdf1 && caseData.pdf1.length === 0)  || caseData.bundle.items.length == 0} onClick={handleOnOffPagination}>Pagination On/Off</MenuItem>
            <Divider />
            <MenuItem disabled={(caseData.pdf1 && caseData.pdf1.length === 0)  || caseData.bundle.items.length == 0} onClick={handleTocGeneration}>{caseData.isTocCreated ? 'Re-Generate TOC' : 'Generate TOC'}</MenuItem>
          </Menu>
        </Dropdown>
        <Dropdown>
          <MenuButton
            variant="plain"
            color="neutral"
            component="button"
            size="sm"
            aria-pressed={selectedOption === 3}
            onClick={(e) =>setSelectedOption(3) }
            sx={{ alignSelf: 'center' }}
          >
            <Typography>
              View
            </Typography>
          </MenuButton>
          <Menu
            placement="bottom-end"
            size="sm"
            sx={{
              zIndex: '99999',
              p: 1,
              gap: 1,
              '--ListItem-radius': 'var(--joy-radius-sm)',
            }}
          >
            <EnterFullScreen>
            {
              (props) => (
                <MenuItem disabled={(caseData.pdf1 && caseData.pdf1.length === 0 )|| caseData.bundle.items.length == 0} sx={{justifyContent: 'space-between'}} onClick={props.onClick}>Full Screen <ZoomOutMapIcon sx={{ml: 2}} /></MenuItem>
              )
            }
            </EnterFullScreen>
          </Menu>
        </Dropdown>
        <Dropdown>
          <MenuButton
            variant="plain"
            color="neutral"
            component="button"
            size="sm"
            aria-pressed={selectedOption === 4}
            onClick={(e) =>setSelectedOption(4) }
            sx={{ alignSelf: 'center' }}
          >
            <Typography>
              Help
            </Typography>
          </MenuButton>
            <Menu
              placement="bottom-end"
              size="sm"
              sx={{
                zIndex: '99999',
                p: 1,
                gap: 1,
                '--ListItem-radius': 'var(--joy-radius-sm)',
              }}
            >
            <Link to={'https://www.fiverr.com/tfraven'} style={{textDecoration: 'none'}}>
              <MenuItem onClick={handleNewCaseCreation}>Contact Dev</MenuItem>
            </Link>
            </Menu>
          </Dropdown>
      </Stack>
      <Box sx={{ display: { xs: 'inline-flex', sm: 'inline-flex', md: 'none'} }}>
        <IconButton variant="plain" color="neutral" onClick={() => setOpen(true)}>
          <MenuRoundedIcon />
        </IconButton>
        <Drawer
          sx={{ display: { xs: 'inline-flex', sm: 'inline-flex', md: 'none' } }}
          open={open}
          onClose={() => setOpen(false)}
        >
          <ModalClose />
          <DialogTitle>{company}</DialogTitle>
          <Box sx={{ px: 1 }}>
            <Navigation open={open} handleDrawerClose={handleDrawerClose} setBackDropOpen={setBackDropOpen} setDrawerOpen={setOpen} selected={selected} setSelected={setSelected} />
          </Box>
        </Drawer>
      </Box>
      <Stack direction={'row'} spacing={1.5}>
        <IconButton sx={{ display: { xs: 'inline-flex', sm: 'inline-flex', lg: 'none' }}} variant="plain" color="neutral" onClick={handleRightDrawerOpen}>
          <ChatIcon />
        </IconButton>
        <Drawer
          anchor={'right'}
          sx={{ display: { xs: 'inline-flex', sm: 'inline-flex', lg: 'none' }}}
          open={isRightDrawerOpen}
          onClose={() => setIsRightDrawerOpen(false)}
        >
          <ModalClose />
          <DialogTitle>Notes</DialogTitle>
          <Stack spacing={2} sx={{p: 2}}>
            {caseData.notes.map(note => <Note key={`note${note._id}`} note={note} />)}
          </Stack>
        </Drawer>
        <ColorSchemeToggle />
        <Dropdown>
          <MenuButton
            variant="plain"
            size="sm"
            sx={{ maxWidth: '32px', maxHeight: '32px', borderRadius: '9999999px' }}
          >
            <Avatar alt={`${user?.firstname} ${user?.lastname}`} src={imageSrc} sx={{ maxWidth: '32px', maxHeight: '32px' }}>
              {`${user?.firstname[0]}${user?.lastname[0]}`}
            </Avatar>
          </MenuButton>
          <Menu
            placement="bottom-end"
            size="sm"
            sx={{
              zIndex: '99999',
              p: 1,
              gap: 1,
              '--ListItem-radius': 'var(--joy-radius-sm)',
            }}
          >
            <MenuItem>
            <Link to={'/myprofile'} style={{textDecoration: 'none' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Avatar alt={`${user.firstname} ${user.lastname}`} src={imageSrc} sx={{ borderRadius: '50%' }}>
                  {`${user?.firstname[0]}${user?.lastname[0]}`}
                </Avatar>
                <Box sx={{ ml: 1.5 }}>
                  <Typography level="title-sm" textColor="text.primary">
                    {`${user.firstname} ${user.lastname}`}
                  </Typography>
                  <Typography level="body-xs" textColor="text.tertiary">
                    {user.email}
                  </Typography>
                </Box>
              </Box>
            </Link>
            </MenuItem>
            <ListDivider />
            <Link to={'https://www.fiverr.com/tfraven'} style={{textDecoration: 'none'}}>
            <MenuItem>
              <HelpRoundedIcon />
              Help
            </MenuItem>
            </Link>
            <Link  to={'/myprofile'} style={{ textDecoration: 'none' }}>
              <MenuItem>
                <SettingsRoundedIcon />
                Settings
              </MenuItem>
            </Link>
            <ListDivider />
            <MenuItem onClick={handleLogut}>
              <LogoutRoundedIcon />
              Log out
            </MenuItem>
          </Menu>
        </Dropdown>
      </Stack>
      <OpenCasesModal open={openCaseModal} setOpen={setOpenCaseModal} />
    </Box>
  );
}

const mapStateToProps = (store) => ({
  caseData: store.caseData,
  user: store.user,
  pagination: store.app.pagination,
  bundleBusy: store.app.bundleUpdating,
  deskBusy: store.app.creatingFolder,
  toolBarBusy: store.app.toolBarBusy,
})
const mapDispatchProps = (dispatch) => bindActionCreators({
  renameCase,
  changeTheme,
  mergePDF,
  createNewCase,
  setPagination,
  generateToc,
  toggleCaseFavorite,
  logout,
  toggleTheme
}, dispatch);
export default connect(mapStateToProps, mapDispatchProps)(EditorHeader);