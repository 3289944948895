import React, { useEffect, useState } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  Sheet,
  Typography,
  Box,
  Grid,
} from '@mui/joy';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateSiteContactForm } from '../redux/actions/index.js';

const AdminContactUsFormEditor = ({ content, apiKey, siteId, updateSiteContactForm }) => {
  const [form, setForm] = useState(content);

  useEffect(() => {
    setForm(content)
  }, [content])

  const handleInputChange = (rowIndex, colIndex, field, value) => {
    setForm((prev) => {
      const updatedInputs = [...prev.inputs];
      updatedInputs[rowIndex][colIndex][field] = value;
      return { ...prev, inputs: updatedInputs };
    });
  };

  const handleAddInputRow = () => {
    setForm((prev) => ({
      ...prev,
      inputs: [...prev.inputs, [{ name: '', type: '', placeHolder: '', dataError: '' }]],
    }));
  };

  const handleRemoveInputRow = (rowIndex) => {
    setForm((prev) => ({
      ...prev,
      inputs: prev.inputs.filter((_, index) => index !== rowIndex),
    }));
  };

  const handleMessageChange = (field, value) => {
    setForm((prev) => ({
      ...prev,
      message: { ...prev.message, [field]: value },
    }));
  };

  const handleButtonChange = (value) => {
    setForm((prev) => ({
      ...prev,
      button: { text: value },
    }));
  };

  const handleSubmit = (event) => {
    updateSiteContactForm(apiKey, siteId, form)
  }

  return (
    <Box>
      <Typography level="h4" gutterBottom>
        Form Editor
      </Typography>

      <Sheet sx={{ mb: 3, p: 2, borderRadius: '10px' }} variant="outlined">
        <FormControl fullWidth sx={{ mb: 2 }}>
          <FormLabel>Form Title</FormLabel>
          <Input
            value={form?.title}
            onChange={(e) => setForm({ ...form, title: e.target.value })}
          />
        </FormControl>

        <FormControl fullWidth sx={{ mb: 2 }}>
          <FormLabel>Form Subtitle</FormLabel>
          <Input
            value={form?.subtitle}
            onChange={(e) => setForm({ ...form, subtitle: e.target.value })}
          />
        </FormControl>

        <FormControl fullWidth sx={{ mb: 2 }}>
          <FormLabel>Form Map</FormLabel>
          <Input
            value={form?.map}
            onChange={(e) => setForm({ ...form, map: e.target.value })}
          />
        </FormControl>

        {form?.inputs?.map((row, rowIndex) => (
          <Grid container spacing={2} key={rowIndex} sx={{ mb: 2 }}>
            {row?.map((input, colIndex) => (
              <Grid item xs={6} key={colIndex}>
                <Sheet sx={{ p: 2, borderRadius: '10px' }} variant="outlined">
                  <FormControl fullWidth sx={{ mb: 1 }}>
                    <FormLabel>Input Name</FormLabel>
                    <Input
                      value={input.name}
                      onChange={(e) =>
                        handleInputChange(rowIndex, colIndex, 'name', e.target.value)
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ mb: 1 }}>
                    <FormLabel>Input Type</FormLabel>
                    <Input
                      value={input.type}
                      onChange={(e) =>
                        handleInputChange(rowIndex, colIndex, 'type', e.target.value)
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ mb: 1 }}>
                    <FormLabel>Placeholder</FormLabel>
                    <Input
                      value={input.placeHolder}
                      onChange={(e) =>
                        handleInputChange(rowIndex, colIndex, 'placeHolder', e.target.value)
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <FormLabel>Data Error</FormLabel>
                    <Input
                      value={input.dataError}
                      onChange={(e) =>
                        handleInputChange(rowIndex, colIndex, 'dataError', e.target.value)
                      }
                    />
                  </FormControl>
                </Sheet>
              </Grid>
            ))}
            {/* <Grid item xs={12}>
              <Button color="danger" onClick={() => handleRemoveInputRow(rowIndex)}>
                Remove Row
              </Button>
            </Grid> */}
          </Grid>
        ))}

        {/* <Button onClick={handleAddInputRow} sx={{ mb: 3 }}>
          Add Input Row
        </Button> */}

        <Sheet sx={{ mb: 3, p: 2, borderRadius: '10px' }} variant="outlined">
          <FormControl fullWidth sx={{ mb: 2 }}>
            <FormLabel>Message Placeholder</FormLabel>
            <Input
              value={form?.message?.placeHolder}
              onChange={(e) => handleMessageChange('placeHolder', e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <FormLabel>Data Error</FormLabel>
            <Input
              value={form?.message?.dataError}
              onChange={(e) => handleMessageChange('dataError', e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth>
            <FormLabel>Rows</FormLabel>
            <Input
              type="number"
              value={form?.message?.rows}
              onChange={(e) => handleMessageChange('rows', e.target.value)}
            />
          </FormControl>
        </Sheet>

        <FormControl fullWidth sx={{ mb: 3 }}>
          <FormLabel>Button Text</FormLabel>
          <Input
            value={form?.button?.text}
            onChange={(e) => handleButtonChange(e.target.value)}
          />
        </FormControl>
        <Button variant='soft' color='success' onClick={handleSubmit} fullWidth>Save Changes</Button>
      </Sheet>
      
    </Box>
  );
};

const mapStateToProps = (store) => ({
  apiKey: store.admin?.apiKey,
  siteId: store.admin.site._id,
  content: store.admin.site.contactUs.form
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateSiteContactForm
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AdminContactUsFormEditor);
