import React, { useState, useEffect } from 'react';
import {
    Avatar,
    Box,
    Chip,
    Divider,
    Link,
    Table,
    Sheet,
    Checkbox,
    IconButton,
    Typography,
    Menu,
    MenuButton,
    MenuItem,
    Dropdown
} from '@mui/joy';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import BlockIcon from '@mui/icons-material/Block';
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';

import { formatDate } from '../utils'
import { truncateString } from '../utils';
import { deleteSubscriptionPlan } from '../redux/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

function RowMenu({apiKey, planId, page, status, deleteSubscriptionPlan}) {

  const handleDeleteSubscription = (event) => {
    deleteSubscriptionPlan(apiKey, planId, page);
  }

  return (
    <Dropdown>
      <MenuButton
        slots={{ root: IconButton }}
        slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}
      >
        <MoreHorizRoundedIcon />
      </MenuButton>
      <Menu size="sm" sx={{ minWidth: 140 }}>
        <MenuItem>Details</MenuItem>
        <Divider />
        <MenuItem onClick={handleDeleteSubscription} disabled={status === 'deactivated'} color="danger">Deactivate</MenuItem>
      </Menu>
    </Dropdown>
  );
}

const AdminSubscriptionRow = ({ row, selected, setSelected, page, apiKey, deleteSubscriptionPlan }) => {
    const [imageSrc, setImageSrc] = useState('');

    useEffect(() => {
    if (row?.profileImage?.data) {
      try{
        const blob = new Blob([new Uint8Array(row.profileImage.data)], { type: 'image/jpeg' });
        const reader = new FileReader();
        reader.onload = () => {
            setImageSrc(reader.result);
        };
        reader.readAsDataURL(blob);
      } catch(error){
        console.log(error);
      }
    } else {
      setImageSrc('');
    }
  }, [row?.profileImage])

    return (
        <tr key={row._id}>
            <td style={{ textAlign: 'center', minWidth: 120 }}>
                <Checkbox
                  size="sm"
                  checked={selected.includes(row._id)}
                  color={selected.includes(row._id) ? 'primary' : undefined}
                  onChange={(event) => {
                      setSelected((ids) =>
                      event.target.checked
                          ? ids.concat(row._id)
                          : ids.filter((itemId) => itemId !== row._id),
                      );
                  }}
                  slotProps={{ checkbox: { sx: { textAlign: 'left' } } }}
                  sx={{ verticalAlign: 'text-bottom' }}
                />
            </td>
            <td>
                <Typography level="body-xs">{row.name}</Typography>
            </td>
            <td>
                <Typography level="body-xs">{row.bundleLimit}</Typography>
            </td>
            <td>
                <Typography level="body-xs">{row.spaceLimit}</Typography>
            </td>
            <td>
                <Typography level="body-xs">{`$${row.price}`}</Typography>
            </td>
            <td>
                <Typography level="body-xs">{row.subscriptionType}</Typography>
            </td>
            <td>
                <Chip
                variant="soft"
                size="sm"
                startDecorator={
                    {
                    active: <CheckRoundedIcon />,
                    archived: <AutorenewRoundedIcon />,
                    deactivated: <BlockIcon />,
                    }[row.status]
                }
                color={
                    {
                    active: 'success',
                    archived: 'neutral',
                    deactivated: 'danger',
                    }[row.status]
                }
                >
                {row.status}
                </Chip>
            </td>
            <td>
              <Typography level="body-xs">{row.trialPeriod}</Typography>
            </td>
            <td>
                <Typography level="body-xs">{row.renewalInterval}</Typography>
            </td>
            <td>
                <Typography level="body-xs">{formatDate(row.createdOn)}</Typography>
            </td>
            <td>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <RowMenu planId={row._id} page={page} apiKey={apiKey} status={row.status} deleteSubscriptionPlan={deleteSubscriptionPlan}  />
                </Box>
            </td>
        </tr>
    )
}

const mapStateToProps = (store) => ({
  apiKey: store.admin.apiKey
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
  deleteSubscriptionPlan
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AdminSubscriptionRow);