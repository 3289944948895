import * as constants from '../constants';

const initialState = {
    userDetails: {},
    usersData: {
        totalPages: 1,
        totalUsers: 0,
        users: {}
    },
    searchedUsersData: {
        totalPages: 1,
        totalUsers: 0,
        users: {}
    },
    messagesData: {
        totalPages: 1,
        totalMessages: 0,
        messages: {}
    },
    subscriptionPlansData: {
        totalPages: 1,
        totalPlans: 0,
        plans: {}
    },
    searchedSubscriptionPlansData: {
        totalPages: 1,
        totalPlans: 0,
        plans: {}
    },
    searchedMessagesData: {
        totalPages: 1,
        totalMessages: 0,
        messages: {}
    },
    paymentsData: {
        totalTransactions: 0,
        totalPages: 1,
        payments: {}
    },
    logsData: {
        totalPages: 1,
        totalLogs: 0,
        logs: {}
    },
    auditsData: {
        totalPages: 1,
        totalAudits: 0,
        audits: {}
    },
    userRegistrationMonthlyTrend: [],
    pageNumberFormatUsage: [],
    dashboardMetrics: {},
    revenueByPlan: {},
    activeUserLog: [],
    site: {
        landing: {
            header: {},
            hero: {},
            services: {},
            aboutusContent: {},
            features: {},
            mission: {},
            marqueeItems: [],
            overview: {},
            testimonials: {},
            stats: [],
            footerContent: {}
        },
        services: {
            content: {},
            stats: []
        },
        contactUs: {
            form: {},
            info: {}
        },
        faq: {
            body: {}
        },
        team: {
            body: {}
        },
        aboutUs: {
            content: {},
            marqueeItems: [],
            features: {},
            timeline: []
        },
        termsAndConditions: {
            body: []
        },
        privacyPolicy: {
            body: []
        }
    },
    websiteSettings: {}
};

export const admin = (state = initialState, action) => {
    switch(action.type){
        case constants.ON_ADMIN_SIGNIN_SUCCESS:
            return{
                ...state,
                ...action.data,
                loading: false
            }
        case constants.ON_ADMIN_LOADED:
            return {
                ...state,
                ...action.data,
                loading: false
            }
        case constants.ADMIN_ON_USER_DETAILS_LOADED:
            return {
                ...state,
                userDetails: action.data
            }
        case constants.ADMIN_ON_USERS_LOADED:
            return {
                ...state,
                usersData: {
                    ...state.usersData,
                    totalPages: action.data.totalPages,
                    totalUsers: action.data.totalUsers,
                    users: {
                        ...state.usersData.users,
                        [action.page]: action.data.users
                    }
                }
            }
        case constants.ADMIN_ON_SEARCHED_USERS_LOADED:
            return {
                ...state,
                searchedUsersData: {
                    ...state.searchedUsersData,
                    totalPages: action.data.totalPages,
                    totalUsers: action.data.totalUsers,
                    users: {
                        ...state.searchedUsersData.users,
                        [action.page]: action.data.users
                    }
                }
            }
        case constants.ADMIN_ON_SUBSCRIPTION_PLANS_LOADED:
            return {
                ...state,
                subscriptionPlansData: {
                    ...state.subscriptionPlansData,
                    totalPages: action.data.totalPages,
                    totalPlans: action.data.totalPlans,
                    plans: {
                        ...state.subscriptionPlansData.plans,
                        [action.page]: action.data.plans
                    }
                }
            }
        case constants.ADMIN_ON_SEARCHED_SUBSCRIPTION_LOADED:
            return {
                ...state,
                searchedSubscriptionPlansData: {
                    ...state.searchedSubscriptionPlansData,
                    totalPages: action.data.totalPages,
                    totalPlans: action.data.totalPlans,
                    plans: {
                        ...state.searchedSubscriptionPlansData.plans,
                        [action.page]: action.data.plans
                    }
                }
            }
        case constants.ADMIN_ADD_SUBSCRIPTION_PLAN_SUCCESS:
            return {
                ...state,
                subscriptionPlansData: {
                    ...state.subscriptionPlansData,
                    totalPlans: action.data.totalPlans + 1,
                    plans: {
                        ...state.subscriptionPlansData.plans,
                        1: [action.data, ...(state.subscriptionPlansData.plans[1] || [])]
                    }
                }
            }
        case constants.ADMIN_DEACTIVATE_SUBSCRIPTION_PLAN_SUCCESS:
            return{
                ...state,
                subscriptionPlansData: {
                    ...state.subscriptionPlansData,
                    totalPlans: action.data.totalPlans - 1,
                    totalPages: (state.subscriptionPlansData.plans[action.page].length > 1 ? state.totalPages : state.totalPages - 1),
                    plans: {
                        ...state.subscriptionPlansData.plans,
                        [action.page]: state.subscriptionPlansData.plans[action.page].map(v => (v._id === action.data._id) ? action.data : v)
                    }
                }
            }
        case constants.ADMIN_ON_MESSAGES_LOADED:
            return {
                ...state,
                messagesData: {
                    ...state.messagesData,
                    totalPages: action.data.totalPages,
                    totalMessages: action.data.totalMessages,
                    messages: {
                        ...state.messagesData.messages,
                        [action.page]: action.data.messages
                    }
                }
            }
        case constants.ADMIN_ON_SEARCHED_MESSAGES_LOADED:
            return {
                ...state,
                searchedMessagesData: {
                    ...state.searchedMessagesData,
                    totalPages: action.data.totalPages,
                    totalMessages: action.data.totalMessages,
                    messages: {
                        ...state.searchedMessagesData.messages,
                        [action.page]: action.data.messages
                    }
                }
            }
        case constants.ADMIN_ON_PAYMENTS_LOADED:
            return {
                ...state,
                paymentsData: {
                    ...state.paymentsData,
                    totalPages: action.data.totalPages,
                    totalTransactions: action.data.totalTransactions,
                    payments: {
                        ...state.paymentsData.payments,
                        [action.page]: action.data.payments
                    }
                }
            }
        case constants.ADMIN_ON_LOGS_LOADED:
            return {
                ...state,
                logsData: {
                    ...state.logsData,
                    totalPages: action.data.totalPages,
                    totalLogs: action.data.totalLogs,
                    logs: {
                        ...state.logsData.logs,
                        [action.page]: action.data.logs
                    }
                }
            }
        case constants.ADMIN_ON_AUDIT_TRAILS_LOADED:
            return {
                ...state,
                auditsData: {
                    ...state.auditsData,
                    totalPages: action.data.totalPages,
                    totalAudits: action.data.totalAudits,
                    audits: {
                        ...state.auditsData.audits,
                        [action.page]: action.data.audits
                    }
                }
            }
        case constants.ADMIN_ON_USER_REGISTRATION_MONTHLY_TREND_DATA_LOADED:
            return{
                ...state,
                userRegistrationMonthlyTrend: action.data
            }
        case constants.ADMIN_ON_PAGE_NUMBER_FORMAT_USAGE_STATS_LOADED:
            return{
                ...state,
                pageNumberFormatUsage: action.data
            }
        case constants.ADMIN_ON_DASHBOARD_METRICS_LOADED:
            return {
                ...state,
                dashboardMetrics: action.data
            }
        case constants.ADMIN_ON_REVENUE_BY_PLAN_DATA_LOADED:
            return {
                ...state,
                revenueByPlan: action.data
            }
        case constants.ADMIN_ON_ACTIVE_USER_LOG_LOADED:
            return {
                ...state,
                activeUserLog: action.data
            }
        case constants.ADMIN_ON_SITE_LOADED:
            return {
                ...state,
                site: action.data
            }
        case constants.ADMIN_ON_SITE_HEADER_UPDATED:
            return {
                ...state,
                site: {
                    ...state.site,
                    landing: {
                        ...state.site.landing,
                        header: action.data
                    }
                }
            }
        case constants.ADMIN_ON_SITE_LANDING_HERO_UPDATED:
            return {
                ...state,
                site: {
                    ...state.site,
                    landing: {
                        ...state.site.landing,
                        hero: action.data
                    }
                }
            }
        case constants.ADMIN_ON_SITE_LANDING_SERVICES_UPDATED:
            return {
                ...state,
                site: {
                    ...state.site,
                    landing: {
                        ...state.site.landing,
                        services: action.data
                    }
                }
            }
        case constants.ADMIN_ON_SITE_LANDING_ABOUT_US_UPDATED:
            return {
                ...state,
                site: {
                    ...state.site,
                    landing: {
                        ...state.site.landing,
                        aboutusContent: action.data
                    }
                }
            }
        case constants.ADMIN_ON_SITE_LANDING_FEATURES_UPDATED:
            return {
                ...state,
                site: {
                    ...state.site,
                    landing: {
                        ...state.site.landing,
                        features: action.data
                    }
                }
            }
        case constants.ADMIN_ON_SITE_LANDING_MISSION_UPDATED:
            return {
                ...state,
                site: {
                    ...state.site,
                    landing: {
                        ...state.site.landing,
                        mission: action.data
                    }
                }
            }
        case constants.ADMIN_ON_SITE_LANDING_MARQEE_ITEMS_UPDATED:
            return {
                ...state,
                site: {
                    ...state.site,
                    landing: {
                        ...state.site.landing,
                        marqueeItems: action.data
                    }
                }
            }
        case constants.ADMIN_ON_SITE_LANDING_OVERVIEW_UPDATED:
            return {
                ...state,
                site: {
                    ...state.site,
                    landing: {
                        ...state.site.landing,
                        overview: action.data
                    }
                }
            }
        case constants.ADMIN_ON_SITE_LANDING_TESTIMONIALS_UPDATED:
            return {
                ...state,
                site: {
                    ...state.site,
                    landing: {
                        ...state.site.landing,
                        testimonials: action.data
                    }
                }
            }
        case constants.ADMIN_ON_SITE_LANDING_STATS_UPDATED:
            return {
                ...state,
                site: {
                    ...state.site,
                    landing: {
                        ...state.site.landing,
                        stats: action.data
                    }
                }
            }
        case constants.ADMIN_ON_SITE_LANDING_FOOTER_UPDATED:
            return {
                ...state,
                site: {
                    ...state.site,
                    landing: {
                        ...state.site.landing,
                        footerContent: action.data
                    }
                }
            }
        case constants.ADMIN_ON_SERVICES_CONTENT_UPDATED:
            return {
                ...state,
                site: {
                    ...state.site,
                    services: {
                        ...state.site.services,
                        content: action.data
                    }
                }
            }
        case constants.ADMIN_ON_SERVICES_STATS_UPDATED:
            return {
                ...state,
                site: {
                    ...state.site,
                    services: {
                        ...state.site.services,
                        stats: action.data
                    }
                }
            }
        case constants.ADMIN_ON_CONTACT_US_FORM_UPDATED:
            return {
                ...state,
                site: {
                    ...state.site,
                    contactUs: {
                        ...state.site.contactUs,
                        form: action.data
                    }
                }
            }
        case constants.ADMIN_ON_CONTACT_US_INFO_UPDATED:
            return {
                ...state,
                site: {
                    ...state.site,
                    contactUs: {
                        ...state.site.contactUs,
                        info: action.data
                    }
                }
            }
        case constants.ADMIN_ON_FAQ_BODY_UPDATED:
            return {
                ...state,
                site: {
                    ...state.site,
                    faq: {
                        ...state.site.faq,
                        body: action.data
                    }
                }
            }
        case constants.ADMIN_ON_TEAM_BODY_UPDATED:
            return {
                ...state,
                site: {
                    ...state.site,
                    team: {
                        ...state.site.team,
                        body: action.data
                    }
                }
            }
        case constants.ADMIN_ON_ABOUT_US_CONTENT_UPDATED:
            return {
                ...state,
                site: {
                    ...state.site,
                    aboutUs: {
                        ...state.site.aboutUs,
                        aboutusContent: action.data
                    }
                }
            }
        case constants.ADMIN_ON_ABOUT_US_MARQUEE_ITEMS_UPDATED:
            return {
                ...state,
                site: {
                    ...state.site,
                    aboutUs: {
                        ...state.site.aboutUs,
                        marqueeItems: action.data
                    }
                }
            }
        case constants.ADMIN_ON_ABOUT_US_FEATURES_UPDATED:
            return {
                ...state,
                site: {
                    ...state.site,
                    aboutUs: {
                        ...state.site.aboutUs,
                        features: action.data
                    }
                }
            }
        case constants.ADMIN_ON_ABOUT_US_TIMELINE_UPDATED:
            return {
                ...state,
                site: {
                    ...state.site,
                    aboutUs: {
                        ...state.site.aboutUs,
                        timeline: action.data
                    }
                }
            }
        case constants.ADMIN_ON_TERMS_AND_CONDITION_BODY_UPDATED:
            return {
                ...state,
                site: {
                    ...state.site,
                    termsAndConditions: {
                        ...state.site.termsAndConditions,
                        body: action.data
                    }
                }
            }
        case constants.ADMIN_ON_PRIVACY_POLICY_BODY_UPDATED:
            return {
                ...state,
                site: {
                    ...state.site,
                    privacyPolicy: {
                        ...state.site.privacyPolicy,
                        body: action.data
                    }
                }
            }
        case constants.ADMIN_ON_WEBSITE_SETTINGS_LOADED:
            return {
                ...state,
                websiteSettings: action.data
            }
        case constants.ADMIN_ON_WEBSITE_SETTINGS_UPDATED:
            return {
                ...state,
                websiteSettings: action.data
            }
        case constants.ON_ADMIN_SIGNOUT_SUCCESS:
            return initialState;
        default:
            return state;
    }
}