import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateUser } from '../redux/actions';
import { useNavigate } from 'react-router-dom';

const PayPalSubButton = ({ userId, planId, apiKey, updateUser }) => {
  const navigate = useNavigate();

  const createSubscription = async () => {
    try {
      const response = await fetch('/api/user/subscribe', {
        method: 'POST',
        headers: {
          'x-api-key': apiKey,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId, planId }),
      });

      if (!response.ok) {
        throw new Error('Failed to create subscription');
      }

      const data = await response.json();
      if (data.subscriptionId) {
        return data.subscriptionId;  // Return the subscription ID
      } else {
        throw new Error('Subscription ID not found');
      }
    } catch (error) {
      console.error('Error creating subscription:', error);
      return null;
    }
  };

  useEffect(() => {
    try {
        window.paypal.Buttons({
            fundingSource: window.paypal.FUNDING.PAYPAL,
            vault: true,
            createSubscription: async (data, actions) => {
                const subscriptionId = await createSubscription();
                if (subscriptionId) {
                    return subscriptionId;
                } else {
                    throw new Error('Failed to create subscription');
                }
            },
            onApprove: async (data, actions) => {
                try {
                    const response = await fetch('/api/user/subscription-success', {
                        method: 'POST',
                        headers: {
                        'x-api-key': apiKey,
                        'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ subscriptionId: data.subscriptionID }),
                    });

                    if (!response.ok) {
                        throw new Error('Failed to activate subscription');
                    }

                    const details = await response.json();
                    updateUser(details.user);
                    alert(details.message);
                    navigate('/');
                } catch (error) {
                    console.error('Error activating subscription:', error);
                }
            },
        }).render('#paypal-button-container');
    } catch (error) {
        console.error('PayPal SDK not loaded');
    }
    
  }, [apiKey, userId, planId, navigate, updateUser]);

  return <div id="paypal-button-container"></div>;
};

const mapStateToProps = (store) => ({
  userId: store.user._id,
  apiKey: store.user.apiKey,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateUser,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PayPalSubButton);
