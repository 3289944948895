import React from "react";

const PrivacyPolicyComponent = ({ content }) => {
    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        {content?.map((section, index) => (
                            <div key={index}>
                                {section.title && (
                                    <h4 className="mt-5 text-theme">{section.title}</h4>
                                )}
                                {section.paragraphs && section.paragraphs.map((paragraph, idx) => (
                                    <p key={idx}>{paragraph}</p>
                                ))}
                                {section.list && (
                                    <ul className="list-unstyled list-icon">
                                        {section.list?.map((item, idx) => (
                                            <li key={idx} className="mb-3">
                                                <i className="bi bi-check-lg"></i> {item}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PrivacyPolicyComponent;