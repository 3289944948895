import React from "react";

export default function AboutUsMarquee({ content }) {
  return (
    <section className="overflow-hidden p-0">
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col">
            <div className="marquee-wrap">
              <div className="marquee-text">
                {content.map((item, index) => (
                  <React.Fragment key={index}>
                    <span>{item}</span>
                    {index < content.length - 1 && <i className="bi bi-dot"></i>}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
