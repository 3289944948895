import React, { useState, useEffect, useRef } from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Typography from '@mui/joy/Typography';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab, { tabClasses } from '@mui/joy/Tab';
import Breadcrumbs from '@mui/joy/Breadcrumbs';
import Link from '@mui/joy/Link';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import CardOverflow from '@mui/joy/CardOverflow';

import Chip from '@mui/joy/Chip';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Check from '@mui/icons-material/Check';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import AddIcon from '@mui/icons-material/Add';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TextField } from '@mui/material';
import { Avatar, CircularProgress, TabPanel } from '@mui/joy';
import { addConnectedUser, editUserDetails } from '../redux/actions';
import { useSnackbar } from 'notistack';

import { formatDate } from '../utils'
import AddConnectedUserForm from './AddConnectedUserForm';
import ConnectedUsers from './ConnectedUsers';

function Profile({ user, editUserDetails, updatingUserDetails, creatingConnectedUser }) {
    const [profileImage, setProfileImage] = useState(null);
    const [firstName, setFirstName] = useState(user?.firstname);
    const [lastName, setLastName] = useState(user?.lastname);
    const [username, setUsername] = useState(user?.username);
    const [businessName, setBusinessName] = useState(user?.businessName);
    const [isDataUpdated, setIsDataUpdated] = useState(false);
    const [imageSrc, setImageSrc] = useState('');
    const fileInputRef = useRef();

    const { enqueueSnackbar } = useSnackbar();

    const handleCanceled = (event) => {
        setFirstName(user?.firstname);
        setLastName(user?.lastname);
        setUsername(user?.username);
        setBusinessName(user?.businessName);
        setProfileImage(user?.profileImage);

        if (fileInputRef.current.value?.length > 0 && user?.profileImage?.data) {
            const base64String = btoa(String.fromCharCode(new Uint8Array(user.profileImage.data)));
            setImageSrc(`data:image/jpeg;base64,${base64String}`);
            fileInputRef.current.value = null;
        }
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setProfileImage(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            if (user?.profileImage?.data) {
                const base64String = btoa(String.fromCharCode(new Uint8Array(user.profileImage.data)));
                setImageSrc(`data:image/jpeg;base64,${base64String}`);
            }
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('firstname', firstName);
        formData.append('lastname', lastName);
        formData.append('username', username);
        formData.append('businessName', businessName);

        if (profileImage && fileInputRef.current.value?.length > 0) {
            formData.append('profileImage', profileImage);
        }
        editUserDetails(user.apiKey, formData, enqueueSnackbar);
    }

    useEffect(() => {
        if (user?.profileImage?.data && fileInputRef.current?.value?.length === 0) {
            try{
                const blob = new Blob([new Uint8Array(user.profileImage.data)], { type: 'image/jpeg' });
                const reader = new FileReader();
                reader.onload = () => {
                    setImageSrc(reader.result);
                };
                reader.readAsDataURL(blob);
            } catch(error){
                console.log(error);
            }
        }
        setIsDataUpdated(firstName !== user?.firstname || lastName !== user?.lastname || username !== user?.username || businessName !== user?.businessName || fileInputRef.current?.value)
    }, [user?.profileImage, firstName, lastName, username, businessName, profileImage, fileInputRef])

    return (
        <Box sx={{ flex: 1, width: '100%' }}>
        <Box
            sx={{
            position: 'sticky',
            top: { sm: -100, md: -110 },
            bgcolor: 'background.body',
            zIndex: 9995,
            }}
        >
            <Box sx={{ px: { xs: 2, md: 6 } }}>
            <Breadcrumbs
                size="sm"
                aria-label="breadcrumbs"
                separator={<ChevronRightRoundedIcon fontSize="sm" />}
                sx={{ pl: 0 }}
            >
                <Link
                underline="none"
                color="neutral"
                href="#some-link"
                aria-label="Home"
                >
                <HomeRoundedIcon />
                </Link>
                <Link
                underline="hover"
                color="neutral"
                href="#"
                fontSize={12}
                fontWeight={500}
                >
                Users
                </Link>
                <Typography color="primary" fontWeight={500} fontSize={12}>
                My profile
                </Typography>
            </Breadcrumbs>
            <Typography level="h2" component="h1" sx={{ mt: 1, mb: 2 }}>
                My profile
            </Typography>
            </Box>
            <Tabs
                defaultValue={0}
                sx={{
                    bgcolor: 'transparent',
                }}
            >
            <TabList
                tabFlex={1}
                size="sm"
                sx={{
                pl: { xs: 0, md: 4 },
                justifyContent: 'left',
                [`&& .${tabClasses.root}`]: {
                    fontWeight: '600',
                    flex: 'initial',
                    color: 'text.tertiary',
                    [`&.${tabClasses.selected}`]: {
                    bgcolor: 'transparent',
                    color: 'text.primary',
                    '&::after': {
                        height: '2px',
                        bgcolor: 'primary.500',
                    },
                    },
                },
                }}
            >
                <Tab sx={{ borderRadius: '6px 6px 0 0' }} indicatorInset value={0}>
                    Settings
                </Tab>
                <Tab sx={{ borderRadius: '6px 6px 0 0' }} indicatorInset value={1}>
                    Plan
                </Tab>
                <Tab sx={{ borderRadius: '6px 6px 0 0' }} indicatorInset value={2}>
                    Billing
                </Tab>
                <Tab sx={{ borderRadius: '6px 6px 0 0' }} indicatorInset value={3}>
                    Connected Users
                </Tab>
            </TabList>
            <TabPanel value={0}>
                <form onSubmit={handleSubmit} onAbort={handleCanceled}>
                <Stack
                    spacing={4}
                    sx={{
                    display: 'flex',
                    maxWidth: '800px',
                    mx: 'auto',
                    px: { xs: 2, md: 6 },
                    py: { xs: 2, md: 3 },
                    }}
                >
                    <Card>
                    <Box sx={{ mb: 1 }}>
                        <Typography level="title-md">Personal info</Typography>
                        <Typography level="body-sm">
                            Customize how your profile information will apper to the networks.
                        </Typography>
                    </Box>
                    <Divider />
                    <Stack
                        direction="row"
                        spacing={3}
                        sx={{ display: { xs: 'none', md: 'flex' }, my: 1 }}
                    >
                        <Stack direction="column" spacing={1}>
                        <AspectRatio
                            ratio="1"
                            maxHeight={200}
                            sx={{ flex: 1, minWidth: 120, borderRadius: '100%' }}
                        >
                            <Avatar alt={`${user.firstname} ${user.lastname}`} src={imageSrc}>
                                {`${user?.firstname[0]}${user?.lastname[0]}`}
                            </Avatar>
                        </AspectRatio>
                        <IconButton
                            aria-label="upload new picture"
                            size="sm"
                            variant="outlined"
                            color="neutral"
                            sx={{
                            bgcolor: 'background.body',
                            position: 'absolute',
                            zIndex: 2,
                            borderRadius: '50%',
                            left: 100,
                            top: 170,
                            boxShadow: 'sm',
                            }}
                            onClick={() => fileInputRef.current.click()}
                        >
                            <EditRoundedIcon />
                        </IconButton>
                        <input
                            type="file"
                            accept="image/*"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                        </Stack>
                        <Stack spacing={2} sx={{ flexGrow: 1 }}>
                        <Stack spacing={1}>
                            <FormLabel>Name</FormLabel>
                            <FormControl sx={{ display: { sm: 'flex-column', md: 'flex-row' }, gap: 2 }} >
                                <Input size="sm" placeholder="First name" name='firstname' value={firstName} onChange={e => setFirstName(e.target.value)} />
                            </FormControl>

                            <FormControl>
                                <Input size="sm" placeholder="Last name" sx={{ flexGrow: 1 }} name='lastname' value={lastName} onChange={e => setLastName(e.target.value)} />
                            </FormControl>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <FormControl>
                                <FormLabel>Username</FormLabel>
                                <Input size="sm" name="username" value={username} onChange={e => setUsername(e.target.value)} required />
                            </FormControl>
                            <FormControl sx={{ flexGrow: 1 }}>
                                <FormLabel>Email</FormLabel>
                                <Input
                                    size="sm"
                                    type="email"
                                    startDecorator={<EmailRoundedIcon />}
                                    placeholder="email"
                                    readOnly
                                    value={user?.email}
                                    sx={{ flexGrow: 1 }}
                                />
                            </FormControl>
                        </Stack>
                        <div>
                            <FormControl sx={{ display: { sm: 'contents' } }}>
                            <FormLabel>Business Name</FormLabel>
                            <Input
                                name="businessName"
                                label="Business Name"
                                type="text"
                                variant="outlined"
                                fullWidth
                                value={businessName}
                                onChange={(e) => setBusinessName(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            </FormControl>
                        </div>
                        </Stack>
                    </Stack>
                    <Stack
                        direction="column"
                        spacing={2}
                        sx={{ display: { xs: 'flex', md: 'none' }, my: 1 }}
                    >
                        <Stack direction="row" spacing={2}>
                        <Stack direction="column" spacing={1}>
                            <AspectRatio
                            ratio="1"
                            maxHeight={108}
                            sx={{ flex: 1, minWidth: 108, borderRadius: '100%' }}
                            >
                            <Avatar alt={`${user.firstname} ${user.lastname}`} src={imageSrc}>
                                {`${user?.firstname[0]}${user?.lastname[0]}`}
                            </Avatar>
                            </AspectRatio>
                            <IconButton
                            aria-label="upload new picture"
                            size="sm"
                            variant="outlined"
                            color="neutral"
                            sx={{
                                bgcolor: 'background.body',
                                position: 'absolute',
                                zIndex: 2,
                                borderRadius: '50%',
                                left: 85,
                                top: 180,
                                boxShadow: 'sm',
                            }}
                            onClick={() => fileInputRef.current.click()}
                            >
                            <EditRoundedIcon />
                            </IconButton>
                        </Stack>
                        <Stack spacing={1} sx={{ flexGrow: 1 }}>
                            <FormLabel>Name</FormLabel>
                            <Box sx={{
                                display: {
                                sm: 'flex-column',
                                md: 'flex-row',
                                },
                                gap: 2,
                            }}>
                                <FormControl>
                                    <Input size="sm" placeholder="First name" name='firstname' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                </FormControl>
                                <FormControl>
                                    <Input size="sm" placeholder="Last name" name="lastname" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                </FormControl>
                            </Box>
                            
                        </Stack>
                        </Stack>
                        <FormControl>
                        <FormLabel>Username</FormLabel>
                        <Input size="sm" name="username" value={username} onChange={e => setUsername(e.target.value)} required />
                        </FormControl>
                        <FormControl sx={{ flexGrow: 1 }}>
                        <FormLabel>Email</FormLabel>
                        <Input
                            size="sm"
                            type="email"
                            startDecorator={<EmailRoundedIcon />}
                            placeholder="email"
                            readOnly
                            value={user?.email}
                            sx={{ flexGrow: 1 }}
                        />
                        </FormControl>
                        <div>
                        <FormControl sx={{ display: { sm: 'contents' } }}>
                            <FormLabel>Business Name</FormLabel>
                            <Input
                                name="businessName"
                                label="Business Name"
                                type="text"
                                variant="outlined"
                                fullWidth
                                value={businessName}
                                onChange={(e) => setBusinessName(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </FormControl>
                        </div>
                    </Stack>
                    <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
                        <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                        <Button size="sm" variant="outlined" color="neutral" onClick={handleCanceled} disabled={updatingUserDetails}>
                            Cancel
                        </Button>
                        <Button type='submit' size="sm" variant="solid" disabled={!isDataUpdated || updatingUserDetails}>
                            { updatingUserDetails? <CircularProgress /> : "Save" }
                        </Button>
                        </CardActions>
                    </CardOverflow>
                    </Card>
                </Stack>
                </form>
            </TabPanel>
            <TabPanel value={1}>
                <Stack
                    spacing={4}
                    sx={{
                    display: 'flex',
                    maxWidth: '800px',
                    mx: 'auto',
                    px: { xs: 2, md: 6 },
                    py: { xs: 2, md: 3 },
                    }}
                >
                    <Card>
                    <Box sx={{ mb: 1 }}>
                        <Typography level="title-md">Your Subscription Plan</Typography>
                        {(user?.role === 'Admin') ? (
                            <Typography level="body-sm" color={'success'}>
                                As an admin, you do not need to subscribe to any plan. You have full access to all features without requiring a subscription.
                            </Typography>
                        ): (
                            <>
                                <Typography level="body-sm">
                                    Here are the details of your current subscription plan. Your plan will automatically renew according to the selected interval (monthly or yearly). If you wish to discontinue your subscription, you can easily cancel it at any time using the option provided below.
                                </Typography>
                                <Typography level="body-sm" color={'warning'}>
                                    Please note that once canceled, your access will not remain active until the end of the current billing cycle
                                </Typography>
                            </>
                        )}
                    </Box>
                    {user?.subscription?.plan &&
                    <>
                    <Divider />
                    <Box
                        sx={{
                            width: '100%',
                            display: 'grid',
                            gap: 2,
                        }}
                        >
                        <Card size="lg" variant="outlined">
                            <Chip size="sm" variant="outlined" color={'success'}>
                            {user?.subscription?.plan?.name.toUpperCase()}
                            </Chip>
                            <Typography level="h2">{user?.subscription?.plan?.name}</Typography>
                            <Divider inset="none" />
                            <List size="sm" sx={{ mx: 'calc(-1 * var(--ListItem-paddingX))' }}>
                            {user?.subscription?.plan?.features.map((feature, index) => (
                                <ListItem key={`${index}`}>
                                <ListItemDecorator>
                                    <Check />
                                </ListItemDecorator>
                                {feature}
                                </ListItem>
                            ))}
                            </List>
                            <Divider inset="none" />
                            <CardActions>
                            <Typography level="title-lg" sx={{ mr: 'auto' }}>
                                {`${user?.subscription?.plan?.price}$ `}
                                <Typography fontSize="sm" textColor="text.tertiary">
                                {`/ ${user?.subscription?.plan?.renewalInterval.slice(0, -2)}`}
                                </Typography>
                            </Typography>
                            <Button
                                variant="soft"
                                color="danger"
                                endDecorator={<KeyboardArrowRight />}
                            >
                                Cancel Subscription
                            </Button>
                            </CardActions>
                        </Card>
                        </Box>
                        </>}
                    </Card>
                </Stack>
            </TabPanel>
            <TabPanel value={2}>
                <Stack
                    spacing={4}
                    sx={{
                    display: 'flex',
                    maxWidth: '800px',
                    mx: 'auto',
                    px: { xs: 2, md: 6 },
                    py: { xs: 2, md: 3 },
                    }}
                >
                    <Card>
                        <Box sx={{ mb: 1 }}>
                            <Typography level="title-md">Billing Cycle</Typography>
                            {user?.role === 'Admin' ? (
                                <Typography level="body-sm" color={'success'}>
                                    As an admin, you do not need to subscribe to any plan. You have full access to all features without requiring a subscription.
                                </Typography>
                            ) : (
                                <Typography level='body-sm'>Your next billing is on <strong>{ formatDate( getNextBillingDate({startDate: user?.subscription?.startDate, renewalInterval: user?.subscription?.plan?.renewalInterval}) )}</strong> </Typography>
                            )}
                        </Box>
                    </Card>
                </Stack>
            </TabPanel>
            <TabPanel value={3}>
                <ConnectedUsers />
            </TabPanel>
            </Tabs>
        </Box>
        </Box>
    );
}

function getNextBillingDate(subscription) {
  const { startDate, renewalInterval } = subscription;
  let nextBillingDate = new Date(startDate);

  if (renewalInterval === 'MONTHLY') {
    nextBillingDate.setMonth(nextBillingDate.getMonth() + 1);
  } else if (renewalInterval === 'YEARLY') {
    nextBillingDate.setFullYear(nextBillingDate.getFullYear() + 1);
  }

  return nextBillingDate.toISOString().split('T')[0]; // Returns date in 'YYYY-MM-DD' format
}

const mapStateToProps = (store) => ({
  user: store.user,
  updatingUserDetails: store.app.updatingUserDetails,
  creatingConnectedUser: store.app.creatingConnectedUser
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
    editUserDetails
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Profile);