import React, { useState, useEffect } from 'react';
import PayPalButton from '../components/PaypalButton';
import { useLocation } from 'react-router-dom';
import { CssVarsProvider, useColorScheme } from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Checkbox from '@mui/joy/Checkbox';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import IconButton from '@mui/joy/IconButton';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import BadgeRoundedIcon from '@mui/icons-material/BadgeRounded';
import GoogleIcon from '../assets/GoogleIcon';
import { useNavigate } from 'react-router-dom';
import { signin } from '../redux/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Card, CardActions, Chip, CircularProgress, List, ListItem, ListItemDecorator } from '@mui/joy';
import { getUserLocation } from '../utils';
import Check from '@mui/icons-material/Check';
import PaypalSubButton from '../components/PaypalSubButton';

function ColorSchemeToggle(props) {
  const { onClick, ...other } = props;
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = useState(false);

  useEffect(() => setMounted(true), []);

  return (
    <IconButton
      aria-label="toggle light/dark mode"
      size="sm"
      variant="outlined"
      disabled={!mounted}
      onClick={(event) => {
        setMode(mode === 'light' ? 'dark' : 'light');
        onClick?.(event);
      }}
      {...other}
    >
      {mode === 'light' ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
    </IconButton>
  );
}

function Checkout(props) {
  const { subscriptions } = props;
  const location = useLocation();
  const { planid } = location.state || {};

  const [subscription, setSubscription] = useState(null)

  useEffect(() => {
    setSubscription(subscriptions.filter(plan => plan._id === planid)[0] || null)
  }, [planid])

  const company = "CaseGenie";
  const navigate = useNavigate();

  const handleNavigationToSignUpPage = (event) => {
      navigate('/signup')
  }
  const handleNavigationToLandingPage = (event) => {
      navigate('/home')
  }
    return (
        <CssVarsProvider defaultMode="dark" disableTransitionOnChange>
        <CssBaseline />
        <GlobalStyles
            styles={{
            ':root': {
                '--Form-maxWidth': '800px',
                '--Transition-duration': '0.4s', // set to `none` to disable transition
            },
            }}
        />
        <Box
            sx={(theme) => ({
            width: { xs: '100%', md: '50vw' },
            transition: 'width var(--Transition-duration)',
            transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
            position: 'relative',
            zIndex: 1,
            display: 'flex',
            justifyContent: 'flex-end',
            backdropFilter: 'blur(12px)',
            backgroundColor: 'rgba(255 255 255 / 0.2)',
            [theme.getColorSchemeSelector('dark')]: {
                backgroundColor: 'rgba(19 19 24 / 0.4)',
            },
            })}
        >
            <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100dvh',
                width: '100%',
                px: 2,
            }}
            >
            <Box
                component="header"
                sx={{
                py: 3,
                display: 'flex',
                justifyContent: 'space-between',
                }}
            >
                <Box sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
                <IconButton variant="soft" color="primary" size="sm" onClick={handleNavigationToLandingPage}>
                    <BadgeRoundedIcon />
                </IconButton>
                <Typography level="title-lg">{company}</Typography>
                </Box>
                <ColorSchemeToggle />
            </Box>
            <Box
                component="main"
                sx={{
                my: 'auto',
                py: 2,
                pb: 5,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                width: 400,
                maxWidth: '100%',
                mx: 'auto',
                borderRadius: 'sm',
                '& form': {
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                },
                [`& .MuiFormLabel-asterisk`]: {
                    visibility: 'hidden',
                },
                }}
            >
                <Stack gap={4} sx={{ mb: 2 }}>
                <Stack gap={1}>
                    {subscription && (
                      <Box
                        sx={{
                          width: '100%',
                          display: 'grid',
                          gap: 2,
                        }}
                      >
                        <Card size="lg" variant={'plain'}>
                          <Chip size="sm" variant="outlined" color={'success'}>
                            {subscription?.name.toUpperCase()}
                          </Chip>
                          <Typography level="h2">{subscription?.name}</Typography>
                          <Divider inset="none" />
                          <List size="sm" sx={{ mx: 'calc(-1 * var(--ListItem-paddingX))' }}>
                            {subscription?.features.map((feature, index) => (
                              <ListItem key={`${index}`}>
                                <ListItemDecorator>
                                  <Check />
                                </ListItemDecorator>
                                {feature}
                              </ListItem>
                            ))}
                          </List>
                          <Divider inset="none" />
                          <CardActions>
                            <Typography level="title-lg" sx={{ mr: 'auto' }}>
                              {`${subscription?.price}$ `}
                              <Typography fontSize="sm" textColor="text.tertiary">
                                {`/ ${subscription?.renewalInterval.slice(0, -2)}`}
                              </Typography>
                            </Typography>
                          </CardActions>
                        </Card>
                      </Box>
                    )}
                </Stack>
                </Stack>
                <Stack spacing={2}>
                  <Typography component={'h1'} level='h2'>Pay with PayPal</Typography>
                  <PaypalSubButton planId={planid} />
                </Stack>
            </Box>
            <Box component="footer" sx={{ py: 3 }}>
                <Typography level="body-xs" textAlign="center">
                © {company} {new Date().getFullYear()}
                </Typography>
            </Box>
            </Box>
        </Box>
        <Box
            sx={(theme) => ({
            height: '100%',
            position: 'fixed',
            right: 0,
            top: 0,
            bottom: 0,
            left: { xs: 0, md: '50vw' },
            transition:
                'background-image var(--Transition-duration), left var(--Transition-duration) !important',
            transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
            backgroundColor: 'background.level1',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundImage:
                'url(https://images.unsplash.com/photo-1527181152855-fc03fc7949c8?auto=format&w=1000&dpr=2)',
            [theme.getColorSchemeSelector('dark')]: {
                backgroundImage:
                'url(https://images.unsplash.com/photo-1572072393749-3ca9c8ea0831?auto=format&w=1000&dpr=2)',
            },
            })}
        />
        </CssVarsProvider>
    );
}

const mapDispatchProps = (dispatch) => bindActionCreators({
}, dispatch);
const mapStateToProps = (store) => ({
  subscriptions: store.site.subscriptions
})
export default connect(mapStateToProps, mapDispatchProps)(Checkout);
