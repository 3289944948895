export const findAndRemoveItem = (items, itemId) => {
    for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item._id === itemId) {
            return items.splice(i, 1)[0];
        }
        if (item.children) {
            const foundItem = findAndRemoveItem(item.children, itemId);
            if (foundItem) {
                return foundItem;
            }
        }
    }
    return null;
};

export const findAndAddItem = (items, newItem, parentId, index) => {
    if (!parentId) {
        items.splice(index, 0, newItem);
        return true;
    }

    for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item._id === parentId) {
            item.children.splice(index, 0, newItem);
            return true;
        }
        if (item.children) {
            const added = findAndAddItem(item.children, newItem, parentId);
            if (added) {
                return true;
            }
        }
    }
    return false;
};

export const findAndAlterItem = (items, itemId, magic, props) => {
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (item._id === itemId) {
        return magic({item, ...props});
    }
    if (item.children) {
        const foundItem = findAndAlterItem(item.children, itemId, magic, props);
        if (foundItem) {
            return foundItem;
        }
    }
  }
  return null;
};

export const calculatePageNumbers = (items) => {
  let startingPage = 1;
  const resultObj = {};

  const traverseItems = (items) => {
    for (const item of items) {
      if (item.type === 'folder' && Array.isArray(item.children)) {
        traverseItems(item.children);
      } else {
        resultObj[item._id] = {
          startingPage,
          numberOfPages: item.pages,
        };
        startingPage += item.pages;
      }
    }
  };

  traverseItems(items);
  return resultObj;
};

export function timeAgo(timestamp) {
    const date = new Date(timestamp);
    const now = new Date();
    const diff = now - date;
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    
    if (seconds < 10) {
        return "just now";
    } else if (seconds < 60) {
        return seconds + " sec ago";
    } else if (minutes < 60) {
        return minutes + " min ago";
    } else if (hours < 24) {
        return hours + " hr ago";
    } else if (days < 7) {
        return days + " day ago";
    } else {
        // Display the date in "day month year" format
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options);
    }
}

export function formatDate(timestamp){
    // Create a Date object from the timestamp
    const date = new Date(timestamp);

    // Define an array of month names
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Extract day, month, and year
    const day = date.getUTCDate();
    const month = monthNames[date.getUTCMonth()];
    const year = date.getUTCFullYear();

    // Extract hours and determine AM/PM
    let hours = date.getUTCHours();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;

    // Format the result
    const formattedDate = `${day} ${month} ${year}, ${hours}${ampm}`;
    return formattedDate;

}

export function convertBytes(bytes) {
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];
  let index = 0;

  // Convert bytes to the appropriate unit
  while (bytes >= 1024 && index < units.length - 1) {
    bytes /= 1024;
    index++;
  }

  return bytes?.toFixed(2) + ' ' + units[index];
}

export function openSidebar() {
  if (window !== 'undefined') {
    document.body.style.overflow = 'hidden';
    document.documentElement.style.setProperty('--SideNavigation-slideIn', '1');
  }
}

export function closeSidebar() {
  if (window !== 'undefined') {
    document.documentElement.style.removeProperty('--SideNavigation-slideIn');
    document.body.style.removeProperty('overflow');
  }
}

export function toggleSidebar() {
  if (window !== 'undefined' && document !== 'undefined') {
    const slideIn = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue('--SideNavigation-slideIn');
    if (slideIn) {
      closeSidebar();
    } else {
      openSidebar();
    }
  }
}

export const hexToRgb = (hex) => {
  hex = hex.replace(/^#/, '');

  let bigint = parseInt(hex, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;

  return {
    r: r / 255,
    g: g / 255,
    b: b / 255,
    a: 1,
  };
};

export const rgbToHex = (rgb) => {
  const r = Math.round(rgb.r * 255);
  const g = Math.round(rgb.g * 255);
  const b = Math.round(rgb.b * 255);

  const hexR = r.toString(16).padStart(2, '0');
  const hexG = g.toString(16).padStart(2, '0');
  const hexB = b.toString(16).padStart(2, '0');

  const hexColor = `#${hexR}${hexG}${hexB}`;
  return hexColor.toUpperCase();
};

export function adminToggleSidebar() {
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    const slideIn = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue('--SideNavigation-slideIn');
    if (slideIn) {
      closeSidebar();
    } else {
      openSidebar();
    }
  }
}

function range(start, end, step = 1) {
  return Array.apply(null, Array(Math.ceil((end - start + 1) / step)))
    .map((_, i) => start + i * step);
}

export const createWebSocket = (token) => {
  const ws = new WebSocket(`ws://localhost:5000?token=${token}`);
  
  ws.onopen = () => {
    console.log('WebSocket connection established');
  };

  ws.onclose = () => {
    console.log('WebSocket connection closed');
  };

  ws.onerror = (error) => {
    console.error('WebSocket error:', error);
  };

  return ws;
};


export function getUserLocation(updateUserLocation) {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(async (position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      const response = await fetch(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`);
      const locationData = await response.json();

      const location = {
        latitude,
        longitude,
        city: locationData.city || '',
        country: locationData.countryName || ''
      };

      updateUserLocation(location);
      return true;
    }, (error) => {
      console.error("Geolocation error:", error);
    });
  } else {
    console.error("Geolocation is not supported by this browser.");
  }
  return false;
}

export function arrayBufferToBase64(buffer) {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export function base64ToUint8Array(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
}

export function truncateString (str, maxLength = 30) {
  if (str.length > maxLength) {
    return str.substring (0, maxLength - 3) + '...';
  } else {
    return str;
  }
}
