import React, { useState, useEffect} from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import CardOverflow from '@mui/joy/CardOverflow';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

import { convertBytes, timeAgo } from '../utils/index.js';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setToBeDeletedCaseId, setToBeRecoveredCaseId, setToBeRemovedCaseId, setToBeRenamedCaseId, setToBeSharedCaseId } from '../redux/actions/index.js';
import { Restore } from '@mui/icons-material';


function DashBundleCard(props){
    const { user, bundle, setOpenCaseRenameModal, setOpenCaseRecoverModal, setToBeRecoveredCaseId, setOpenCaseRemoveModal, setOpenCaseShareModal, setToBeRenamedCaseId, setToBeSharedCaseId, setToBeRemovedCaseId, setToBeDeletedCaseId, setOpenCaseDeleteModal, prefix } = props;

    const handleOpenCaseRenameModal = (event) => {
      setToBeRenamedCaseId(bundle._id);
      setOpenCaseRenameModal(true);
    }

    const handleOpenCaseShareModal = (event) => {
      setToBeSharedCaseId(bundle._id);
      setOpenCaseShareModal(true);
    }

    const handleOpenCaseRemoveModal = (event) => {
      setToBeRemovedCaseId(bundle._id);
      setOpenCaseRemoveModal(true);
    }

    const handleOpenCaseDeleteModal = (event) => {
      setToBeDeletedCaseId(bundle._id);
      setOpenCaseDeleteModal(true);
    }

    const handleOpenCaseRecoverModal = (event) => {
      setToBeRecoveredCaseId(bundle._id)
      setOpenCaseRecoverModal(true);
    }

    return (
        <Card sx={{maxWidth: {sm: 300}}} variant="outlined" size="sm" key={`${prefix}_${bundle._id}`}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ flex: 1 }}>
            <Typography level="title-md">{bundle.name}</Typography>
            <Typography level="body-sm">{convertBytes(bundle.size)}</Typography>
          </Box>
          <Dropdown>
            <MenuButton
              variant="plain"
              size="sm"
              sx={{
                maxWidth: '32px',
                maxHeight: '32px',
              }}
            >
              <IconButton
                component="span"
                variant="plain"
                color="neutral"
                size="sm"
              >
                <MoreVertRoundedIcon />
              </IconButton>
            </MenuButton>
            <Menu
              placement="bottom-end"
              size="sm"
              sx={{
                zIndex: '99999',
                p: 1,
                gap: 1,
                '--ListItem-radius': 'var(--joy-radius-sm)',
              }}
            >
              <MenuItem onClick={handleOpenCaseRenameModal}>
                <EditRoundedIcon />
                Rename Bundle
              </MenuItem>
              { bundle.owner === user._id && !bundle.flagged && <MenuItem onClick={handleOpenCaseShareModal}>
                <ShareRoundedIcon />
                Share Bundle
              </MenuItem> }
              { bundle.owner === user._id && !bundle.flagged && <MenuItem sx={{ textColor: 'danger.500' }} onClick={handleOpenCaseRemoveModal}>
                <DeleteRoundedIcon color="danger" />
                Remove Bundle
              </MenuItem> }
              { bundle.owner === user._id && bundle.flagged && <MenuItem sx={{}} onClick={handleOpenCaseRecoverModal}>
                <Restore color='success'/>
                Recover Bundle
              </MenuItem> }
              { bundle.owner === user._id && bundle.flagged && <MenuItem sx={{}} onClick={handleOpenCaseDeleteModal}>
                <DeleteRoundedIcon color="danger" />
                Delete Bundle
              </MenuItem> }
            </Menu>
          </Dropdown>
        </Box>
        <Link to={`/case/${bundle._id}`}>
          <CardOverflow
            sx={{
              borderBottom: '1px solid',
              borderTop: '1px solid',
              borderColor: 'neutral.outlinedBorder',
              cursor: 'pointer'
            }}
          >
            <AspectRatio
              ratio="16/9"
              color="primary"
              sx={{ borderRadius: 0, color: 'primary.plainColor' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <InsertDriveFileRoundedIcon />
              </Box>
            </AspectRatio>
          </CardOverflow>
        </Link>
        <Typography level="body-xs">Opened {timeAgo(bundle.lastOpenedOn || bundle.lastUpdatedOn)}</Typography>
      </Card>
    )
}

const mapStateToProps = (store) => ({
  user: store.user,
})
const mapDispatchProps = (dispatch) => bindActionCreators({
  setToBeRenamedCaseId,
  setToBeSharedCaseId,
  setToBeRemovedCaseId,
  setToBeRecoveredCaseId,
  setToBeDeletedCaseId
}, dispatch);
export default connect(mapStateToProps, mapDispatchProps)(DashBundleCard);