import * as React from 'react';
import { CssVarsProvider, useColorScheme } from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import IconButton from '@mui/joy/IconButton';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import BadgeRoundedIcon from '@mui/icons-material/BadgeRounded';
import { useNavigate } from 'react-router-dom';
import { signup } from '../redux/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CircularProgress, Grid, Sheet } from '@mui/joy';
import { getUserLocation } from '../utils';
import Stepper from '../components/Stepper';

import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import Chip from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Check from '@mui/icons-material/Check';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

function ColorSchemeToggle(props) {
  const { onClick, ...other } = props;
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => setMounted(true), []);

  return (
    <IconButton
      aria-label="toggle light/dark mode"
      size="sm"
      variant="outlined"
      disabled={!mounted}
      onClick={(event) => {
        setMode(mode === 'light' ? 'dark' : 'light');
        onClick?.(event);
      }}
      {...other}
    >
      {mode === 'light' ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
    </IconButton>
  );
}

function SubscriptionPlans(props) {
    const { subscriptions } = props;
    const company = "CaseGenie";
    const navigate = useNavigate();

    const handleNavigationPayment = (event) => {
      navigate('/payment')
    }
    const handleNavigationToLandingPage = (event) => {
      navigate('/home')
    }

    const handleSubmit = async (event) => {
      event.preventDefault();
    }

    const colors = ['neutral', 'success', 'warning', 'danger', 'primary'];
    
    return (
        <CssVarsProvider defaultMode="dark" disableTransitionOnChange>
        <CssBaseline />
        <GlobalStyles
            styles={{
            ':root': {
                '--Transition-duration': '0.4s',
            },
            }}
        />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100dvh',
                    width: '100%',
                    px: 2,
                }}
            >
            <Box
                component="header"
                sx={{
                    py: 3,
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <Box sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
                <IconButton variant="soft" color="primary" size="sm" onClick={handleNavigationToLandingPage}>
                    <BadgeRoundedIcon />
                </IconButton>
                <Typography level="title-lg">{company}</Typography>
                </Box>
                <ColorSchemeToggle />
            </Box>
                <Stack spacing={2} justifyContent={'center'} alignItems={'center'}>
                  <Typography component={'h6'} level='h6'>To continue using our services</Typography>
                  <Typography component="h1" level="h3"> Choose Your Subscription Plan </Typography>
                </Stack>           
                <Grid container spacing={2} alignItems={'center'} justifyContent="center" sx={{ flexGrow: 1 }}>
                  {subscriptions.map((plan, index) => (
                    <Grid key={`${plan._id}`} xs={12} md={3}>
                      <PricingCards key={`_${plan._id}`} plan={plan} color={colors[index % colors.length]} />
                    </Grid>
                  ))}
                </Grid>
                <Box component="footer" sx={{ py: 3 }}>
                    <Typography level="body-xs" textAlign="center">
                    © {company} {new Date().getFullYear()}
                    </Typography>
                </Box>
            </Box>
        </CssVarsProvider>
    );
}

const mapDispatchProps = (dispatch) => bindActionCreators({
  signup
}, dispatch);
const mapStateToProps = (store) => ({
  subscriptions: store.site.subscriptions
})
export default connect(mapStateToProps, mapDispatchProps)(SubscriptionPlans);

function PricingCards({plan, color}) {
  const navigate = useNavigate();

  const handleNavigationPayment = (event) => {
    navigate('/payment', { state: { planid: plan._id }})
  }
  return (
    <Box
      sx={{
        width: '100%',
        display: 'grid',
        gap: 2,
      }}
    >
      <Card size="lg" variant="outlined">
        <Chip size="sm" variant="outlined" color={color}>
          {plan?.name.toUpperCase()}
        </Chip>
        <Typography level="h2">{plan?.name}</Typography>
        <Divider inset="none" />
        <List size="sm" sx={{ mx: 'calc(-1 * var(--ListItem-paddingX))' }}>
          {plan?.features.map((feature, index) => (
            <ListItem key={`${index}`}>
              <ListItemDecorator>
                <Check />
              </ListItemDecorator>
              {feature}
            </ListItem>
          ))}
        </List>
        <Divider inset="none" />
        <CardActions>
          <Typography level="title-lg" sx={{ mr: 'auto' }}>
            {`${plan?.price}$ `}
            <Typography fontSize="sm" textColor="text.tertiary">
              {`/ ${plan?.renewalInterval.slice(0, -2)}`}
            </Typography>
          </Typography>
          <Button
            variant="soft"
            color="neutral"
            endDecorator={<KeyboardArrowRight />}
            onClick={handleNavigationPayment}
          >
            Start now
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}
