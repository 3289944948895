import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  TreeItem2Label,
} from '@mui/x-tree-view/TreeItem2';
import DotIcon from './DotIcon';
import { CssVarsProvider, Tooltip } from '@mui/joy';
import { truncateString } from '../utils';

const StyledTreeItemLabelText = styled(Typography)({
  color: 'inherit',
  fontFamily: 'General Sans',
  fontWeight: 500,
});

function CustomLabel({ icon: Icon, expandable, isRenaming, calculateLineHeights, children, ...other }) {
  const divRef = useRef(null);
  const [lock, isLock] = useState(false);

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if(typeof calculateLineHeights === 'function')
          setTimeout(calculateLineHeights, 1000);
      });
    });

    if(divRef.current) {
      const config = { attributes: true, childList: true, subtree: true };
      observer.observe(divRef.current, config);
    }

    return () => {
      if (divRef.current) {
        observer.disconnect();
      }
    };
  }, [isRenaming]);

  return (
    <CssVarsProvider>
      <Tooltip title={children.length > 20 ? children : ''} variant={'outlined'} color={'neutral'} size='small' sx={{px: 1, py: 0.5}}>
        <TreeItem2Label ref={divRef}
          {...other}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {Icon && (
            <Box
              component={Icon}
              className="labelIcon"
              color="inherit"
              sx={{ mr: 1, fontSize: '1.2rem' }}
            />
          )}

          <StyledTreeItemLabelText sx={{fontFamily: 'Inter'}} variant="body2">{truncateString(children, 20)}</StyledTreeItemLabelText>
          {expandable && <DotIcon />}
        </TreeItem2Label>
      </Tooltip>
    </CssVarsProvider>
    
  );
}

export default CustomLabel;