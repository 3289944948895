import React, { useEffect, useState } from 'react';
import {
  CssVarsProvider,
  Sheet,
  FormControl,
  FormLabel,
  Input,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  Container,
  AspectRatio,
  Grid,
  Stack,
  CssBaseline,
  Breadcrumbs,
  Link
} from '@mui/joy';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadAdminSite, updateSiteLandingFeatures } from '../redux/actions/index.js';

import GradientCover from '../components/GradientCover.js';


const AdminLandingFeaturesEditor = ({content, apiKey, siteId, updateSiteLandingFeatures}) => {
    const [features, setFeatures] = useState({})

    useEffect(() => {
        setFeatures(content);
    }, [content])

    const handleImageChange = (e, index) => {
        const file = e.target.files[0];
        if (file) {
            setFeatures(prev => ({
                ...prev,
                cards: prev.cards.map((v, i) => index === i ? ({...v, imageSrc: URL.createObjectURL(file), file }) : v )
            }))
        }
    }

    const handleSubmit = (event) => {
        const formData = new FormData();
        formData.append('features', JSON.stringify(features));

        for(let i=0;i<features.cards.length;i++){
            if (features.cards[i]?.file) {
                formData.append(`cardImage_${i}`, features.cards[i].file);
            }
        }

        updateSiteLandingFeatures(apiKey, siteId, formData)
    }

    return (
        <>
        <Typography level="h4" gutterBottom>
            Edit Features Section
        </Typography>
        <Sheet sx={{mb: 3, p: 2, borderRadius: '10px'}} variant={'outlined'}>
        <FormControl fullwidth={'true'} sx={{ mb: 2 }}>
            <FormLabel>Title</FormLabel>
            <Input
                value={features?.title}
                onChange={(e) => setFeatures(prev => ({
                    ...prev,
                    title: e.target.value
                }))}
            />
        </FormControl>
        <FormControl fullwidth={'true'} sx={{ mb: 2 }}>
            <FormLabel>Subtitle</FormLabel>
            <Input
                value={features?.subtitle}
                onChange={(e) => setFeatures(prev => ({
                    ...prev,
                    subtitle: e.target.value
                }))}
            />
        </FormControl>
        <Grid container spacing={2} columns={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
            {features?.cards?.map((card, index) => (
                <Grid item xs={12} sm={4} md={3} lg={3}>
                    <Card key={index} sx={{ mb: 2 }} variant={'soft'}>
                    <img src={card.imageSrc} alt={card.title} />
                    <input type="file" accept="image/*" onChange={(e) => handleImageChange(e, index)} />
                    <CardContent>
                        <FormControl fullwidth={'true'} sx={{ mb: 1 }}>
                        <FormLabel>Card Title</FormLabel>
                        <Input
                            value={card.title}
                            onChange={(e) => setFeatures(prev => ({
                                ...prev,
                                cards: prev.cards.map((v, i) => index === i ? ({ ...v, title: e.target.value }) : v)
                            }))}
                        />
                        </FormControl>
                        <FormControl fullwidth={'true'} sx={{ mb: 1 }}>
                        <FormLabel>Card Description</FormLabel>
                        <Input
                            value={card.description}
                            onChange={(e) => setFeatures(prev => ({
                                ...prev,
                                cards: prev.cards.map((v, i) => index === i ? ({ ...v, description: e.target.value }) : v)
                            }))}
                        />
                        </FormControl>
                    </CardContent>
                    </Card>
                </Grid>
            ))}
            </Grid>
            <Button variant={'soft'} fullWidth color={'success'} onClick={handleSubmit}>Save Features Changes</Button>
        </Sheet>
        </>
    )
}

const mapStateToProps = (store) => ({
    apiKey: store.admin?.apiKey,
    siteId: store.admin.site._id,
    content: store.admin.site.landing.features
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateSiteLandingFeatures
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AdminLandingFeaturesEditor);