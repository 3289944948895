import React, { useEffect, useState } from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Breadcrumbs from '@mui/joy/Breadcrumbs';
import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';
import { FocusTrap } from '@mui/base/FocusTrap';

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import CreateRoundedIcon from '@mui/icons-material/CreateRounded';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadConnectedUsers } from '../redux/actions';
import Add from '@mui/icons-material/Add';
import ConnectedUserTable from './ConnectedUserTable';
import AddConnectedUserForm from './AddConnectedUserForm';

function ConnectedUsers(props) {
  const { apiKey, loadConnectedUsers} = props;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    loadConnectedUsers(apiKey, 1, 20);
  }, [])

  function downloadCSV() {
    // const endpoint = '/api/admin/users/download';

    // fetch(endpoint, {
    //   method: 'GET',
    //   headers: {
    //     'x-api-key': apiKey,
    //     'Content-Type': 'text/csv',
    //   },
    // })
    //   .then((response) => {
    //     if (response.ok) {
    //       return response.blob();
    //     }
    //     throw new Error('Network response was not ok.');
    //   })
    //   .then((blob) => {
    //     const downloadUrl = window.URL.createObjectURL(blob);
    //     const a = document.createElement('a');
    //     a.href = downloadUrl;
    //     a.download = 'users.csv';
    //     document.body.appendChild(a);
    //     a.click();
    //     a.remove();
    //     window.URL.revokeObjectURL(downloadUrl);
    //   })
    //   .catch((error) => {
    //     console.error('There was a problem with the fetch operation:', error);
    //   });
  }
  
  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
        <Box
          component="main"
          className="MainContent"
          sx={{
            px: { xs: 2, md: 6 },
            pt: {
              xs: 'calc(12px + var(--Header-height))',
              sm: 'calc(12px + var(--Header-height))',
              md: 3,
            },
            pb: { xs: 2, sm: 2, md: 3 },
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            minWidth: 0,
            height: '100dvh',
            gap: 1,
            zIndex: 200
          }}
        >
          <Box
            sx={{
              display: 'flex',
              mb: 1,
              gap: 1,
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: { xs: 'start', sm: 'center' },
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              zIndex: 201
            }}
          >
            <Typography level="h2" component="h1">
              Connected Users
            </Typography>
            <Button
              size="sm"
              startDecorator={<CreateRoundedIcon />}
              onClick={() => setOpen(true)}
              sx={{ ml: 'auto' }}
            >
              Add Connected User
            </Button>
            <FocusTrap open={open} disableAutoFocus disableEnforceFocus>
              <AddConnectedUserForm open={open} onClose={() => setOpen(false)} />
            </FocusTrap>
          </Box>
          <ConnectedUserTable />
          {/* <AdminUserTable />
          <AdminUserList /> */}
        </Box>
    </CssVarsProvider>
  );
}

const mapStateToProps = (store) => ({
  apiKey: store.user?.apiKey
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
  loadConnectedUsers
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ConnectedUsers);