import React, { useEffect, useState } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  Sheet,
  Typography,
  Box,
  Stack,
} from '@mui/joy';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateSiteTermsAndConditionsBody } from '../redux/actions/index.js';
import { Close as CloseIcon } from '@mui/icons-material';

const AdminTermsAndConditionsBodyEditor = ({ content, apiKey, siteId, updateSiteTermsAndConditionsBody }) => {
  const [body, setBody] = useState(content || []);

  useEffect(() => {
    setBody(content || []);
  }, [content]);

  const handleTitleChange = (sectionIndex, value) => {
    setBody((prev) => {
      const updatedBody = [...prev];
      updatedBody[sectionIndex].title = value;
      return updatedBody;
    });
  };

  const handleParagraphChange = (sectionIndex, paragraphIndex, value) => {
    setBody((prev) => {
      const updatedBody = [...prev];
      updatedBody[sectionIndex].paragraphs[paragraphIndex] = value;
      return updatedBody;
    });
  };

  const handleListChange = (sectionIndex, listIndex, value) => {
    setBody((prev) => {
      const updatedBody = [...prev];
      updatedBody[sectionIndex].list[listIndex] = value;
      return updatedBody;
    });
  };

  const addSection = () => {
    setBody((prev) => [...prev, { title: '', paragraphs: [], list: [] }]);
  };

  const removeSection = (sectionIndex) => {
    setBody((prev) => prev.filter((_, index) => index !== sectionIndex));
  };

  const addTitle = (sectionIndex) => {
    setBody((prev) => {
      const updatedBody = [...prev];
      if(!updatedBody[sectionIndex].title){
        updatedBody[sectionIndex].title = '';
      }
      return updatedBody;
    });
  }

  const removeTitle = (sectionIndex) => {
    setBody((prev) => {
        const updatedBody = [...prev];
        updatedBody[sectionIndex].title = null;
        return updatedBody;
    });
  }

  const addParagraph = (sectionIndex) => {
    setBody((prev) => {
      const updatedBody = [...prev];
      if(updatedBody[sectionIndex].paragraphs){
        updatedBody[sectionIndex].paragraphs.push('');
      } else {
        updatedBody[sectionIndex].paragraphs = [''];
      }
      return updatedBody;
    });
  };

  const removeParagraph = (sectionIndex, paragraphIndex) => {
    setBody((prev) => {
      const updatedBody = [...prev];
      updatedBody[sectionIndex].paragraphs = updatedBody[sectionIndex].paragraphs.filter(
        (_, index) => index !== paragraphIndex
      );
      return updatedBody;
    });
  };

  const addListItem = (sectionIndex) => {
    setBody((prev) => {
      const updatedBody = [...prev];
      if(updatedBody[sectionIndex]?.list){
        updatedBody[sectionIndex].list.push('');
      } else {
        updatedBody[sectionIndex].list = [''];
      }
      return updatedBody;
    });
  };

  const removeListItem = (sectionIndex, listIndex) => {
    setBody((prev) => {
      const updatedBody = [...prev];
      updatedBody[sectionIndex].list = updatedBody[sectionIndex].list.filter(
        (_, index) => index !== listIndex
      );
      return updatedBody;
    });
  };

  const handleSubmit = (event) => {
    updateSiteTermsAndConditionsBody(apiKey, siteId, body);
  }

  return (
    <>
      <Typography level="h4" gutterBottom>
        Terms & Conditions Body
      </Typography>
      <Sheet sx={{ mb: 3, p: 2, borderRadius: '10px' }} variant={'outlined'}>
        {body?.map((section, sectionIndex) => (
            <Stack spacing={2} direction={'column'} sx={{mb: 2}}>
            <Typography level={'body-sm'}>
                {`Section #${sectionIndex + 1}`}
            </Typography>
            <Sheet key={sectionIndex} sx={{ mb: 3, p: 2, borderRadius: '10px' }} variant={'soft'}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {(section.title !== null && section.title !== undefined) ? (
                        <Stack direction={'row'} spacing={2}>
                            <FormControl fullWidth sx={{ flex: 1 }}>
                                <FormLabel>Section Title</FormLabel>
                                <Input
                                    value={section.title}
                                    onChange={(e) => handleTitleChange(sectionIndex, e.target.value)}
                                />
                            </FormControl>
                            <FormControl sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                                <Button variant={'outlined'} color={'danger'} onClick={e => removeTitle(sectionIndex)}><CloseIcon /></Button>
                            </FormControl>
                        </Stack>
                    ): (
                        <FormControl>
                            <Button variant={'plain'} onClick={e => addTitle(sectionIndex)}>Add Title</Button>
                        </FormControl>
                    )}
                    {section.paragraphs &&
                    section.paragraphs.map((paragraph, paragraphIndex) => (
                        <Stack direction={'row'} spacing={2}>
                            <FormControl fullWidth key={paragraphIndex} sx={{flex: 1}}>
                                <FormLabel>Paragraph {paragraphIndex + 1}</FormLabel>
                                <Input
                                    value={paragraph}
                                    onChange={(e) =>
                                    handleParagraphChange(sectionIndex, paragraphIndex, e.target.value)
                                    }
                                    minRows={3}
                                />
                            </FormControl>
                            <FormControl sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <Button variant={'outlined'} color={'danger'} onClick={() => removeParagraph(sectionIndex, paragraphIndex)}><CloseIcon /></Button>
                            </FormControl>
                        </Stack>
                    ))}
                    <Button variant={'plain'} onClick={() => addParagraph(sectionIndex)}>Add Paragraph</Button>
                    {section.list &&
                    section.list.map((listItem, listIndex) => (
                        <Stack direction={'row'} spacing={2}>
                            <FormControl fullWidth key={listIndex} sx={{flex: 1}}>
                                <FormLabel>List Item {listIndex + 1}</FormLabel>
                                <Input
                                    value={listItem}
                                    onChange={(e) => handleListChange(sectionIndex, listIndex, e.target.value)}
                                />
                            </FormControl>
                            <FormControl sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                                <Button variant={'outlined'} color={'danger'} onClick={() => removeListItem(sectionIndex, listIndex)}><CloseIcon /></Button>
                            </FormControl>
                        </Stack>
                    ))}
                    <Button variant={'plain'} onClick={() => addListItem(sectionIndex)}>Add List Item</Button>
                    <Button variant={'outlined'} color={'danger'} onClick={() => removeSection(sectionIndex)}>Remove Section</Button>
                </Box>
            </Sheet>
            </Stack>
        ))}
        <Button onClick={addSection}>Add Section</Button>
      </Sheet>
      <Button variant={'solid'} color={'success'} onClick={handleSubmit}>Save Changes</Button>
    </>
  );
};

const mapStateToProps = (store) => ({
  apiKey: store.admin?.apiKey,
  siteId: store.admin.site._id,
  content: store.admin.site.termsAndConditions.body
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateSiteTermsAndConditionsBody
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AdminTermsAndConditionsBodyEditor);
