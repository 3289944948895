import * as React from 'react';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardActions from '@mui/joy/CardActions';
import CircularProgress from '@mui/joy/CircularProgress';
import Typography from '@mui/joy/Typography';
import SvgIcon from '@mui/joy/SvgIcon';

export default function AdminHomeInfoCard({title, value, icon, color}) {
  return (
    <Card variant="solid" color={color} invertedColors>
      <CardContent orientation="horizontal">
        <CircularProgress size="lg" determinate value={30}>
          {icon}
        </CircularProgress>
        <CardContent>
          <Typography level="body-md">{title}</Typography>
          <Typography level="h2">{value}</Typography>
        </CardContent>
      </CardContent>
      {/* <CardActions>
        <Button variant="soft" size="sm">
          Add to Watchlist
        </Button>
        <Button variant="solid" size="sm">
          See breakdown
        </Button>
      </CardActions> */}
    </Card>
  );
}
