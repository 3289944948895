import * as constants from '../constants';

const initialState = {
    loaded: false,
    subscriptions: [],
    websiteSettings: {}
};

export const site = (state = initialState, action) => {
    switch(action.type){
        case constants.ON_SITE_CONTENT_LOADED:
            return{
                ...state,
                ...action.data,
                loaded: true
            }
        case constants.ON_SITE_CONTENT_LOADING:
            return {
                ...state,
                loaded: false
            }
        case constants.WEBSITE_SETTINGS_LOADED:
            return{
                ...state,
                websiteSettings: action.data,
            }
        case constants.ON_ACTIVE_SUBSCRIPTION_PLANS_LOADED:
            return {
                ...state,
                subscriptions: action.data
            }
        default:
            return state;
    }
}