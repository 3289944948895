import React, { useEffect, useState } from 'react';
import {
  CssVarsProvider,
  Sheet,
  FormControl,
  FormLabel,
  Input,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  Container,
  AspectRatio,
  Grid,
  Stack,
  CssBaseline,
  Breadcrumbs,
  Link
} from '@mui/joy';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateSiteLandingMarqueeItems } from '../redux/actions/index.js';

import GradientCover from '../components/GradientCover.js';

const AdminLandingMarqueeItemsEditor = ({ content, apiKey, siteId, updateSiteLandingMarqueeItems }) => {
    const [marqueeItems, setMarqueeItems] = useState([])

    useEffect(() => {
        setMarqueeItems(content);
    }, [content])

    const handleAddMarqueeItem = () => {
        setMarqueeItems(prev => [...prev, '']);
    };

    const handleRemoveMarqueeItem = (index) => {
        setMarqueeItems(prev => prev.filter((_, i) => i !== index));
    };

    const handleSubmit = (event) => {
        updateSiteLandingMarqueeItems(apiKey, siteId, marqueeItems)
    }

    return (
        <>
            <Typography level="h4" gutterBottom>
                Marquee Items
            </Typography>
            <Sheet sx={{ mb: 3, p: 2, borderRadius: '10px' }} variant={'outlined'}>
                {marqueeItems?.map((item, index) => (
                    <Box key={index} sx={{ display: 'flex', gap: 2, mb: 2 }}>
                        <FormControl sx={{ flex: 1 }}>
                            <FormLabel>{`Marquee Item ${index + 1}`}</FormLabel>
                            <Input
                                value={item}
                                onChange={(e) => setMarqueeItems(prev => prev.map((v, i) => index === i ? e.target.value : v))}
                            />
                        </FormControl>
                        <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                            <Button variant={'outlined'} color={'danger'} onClick={() => handleRemoveMarqueeItem(index)}>
                                Remove
                            </Button>
                        </Box>
                        
                    </Box>
                ))}
                <Button variant="soft" onClick={handleAddMarqueeItem}>
                    Add Marquee Item
                </Button>
                <Button onClick={handleSubmit} color={'success'} fullWidth variant="soft">Save Marquee Items Changes</Button>
            </Sheet>
        </>
    )
}

const mapStateToProps = (store) => ({
    apiKey: store.admin?.apiKey,
    siteId: store.admin.site._id,
    content: store.admin.site.landing.marqueeItems
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateSiteLandingMarqueeItems
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AdminLandingMarqueeItemsEditor);