import React, { useState, useEffect } from 'react';
import List from '@mui/joy/List';
import ListSubheader from '@mui/joy/ListSubheader';
import ListItem from '@mui/joy/ListItem';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';

import { createNewCase } from '../redux/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import EditorBundleSpace from './EditorBundleSpace';
import EditorDeskSpace from './EditorDeskSpace';
import BottomShortcutsBar from './BottomShortcutsBar';
import { Divider } from '@mui/joy';
import { LinearProgress, useMediaQuery, useTheme } from '@mui/material';

function EditorNavigation(props) {
  const {open, bundleBusy, deskBusy, toolBarBusy, deletingItem } = props;
  const [buffering, setBuffering] = useState(false);
  const [isDeskBusy, setIsDeskBusy] = useState(false);
  const [isToolBarBusy, setIsToolBarBusy] = useState(false);
  const [isDeletingItem, setIsDeletingItem] = useState(false);
  const theme = useTheme();
  var isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setBuffering(bundleBusy);
    setIsDeskBusy(deskBusy);
    setIsToolBarBusy(toolBarBusy);
    setIsDeletingItem(deletingItem)
  }, [open, bundleBusy, deskBusy, toolBarBusy, deletingItem,  theme.breakpoints.down('sm')])

  return (
    <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
      {(buffering || isDeskBusy || isToolBarBusy || isDeletingItem) ? <LinearProgress color='inherit' /> : <></>}
      <List size="sm" sx={{ '--ListItem-radius': '8px', '--List-gap': '4px', minWidth: '200px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '87vh', overflow: 'hidden' }}>
        <ListItem nested sx={{flex: 1}}>
          <ListSubheader sx={{ letterSpacing: '2px', fontWeight: '800' }}>
            Bundle Space
          </ListSubheader>
          <EditorBundleSpace />
        </ListItem>
        <ListItem nested sx={{ mt: 2, flex: 1 }}>
          <ListSubheader sx={{ letterSpacing: '2px', fontWeight: '800' }}>
            DeskSpace
          </ListSubheader>
          <EditorDeskSpace />
        </ListItem>
      </List>
      <Divider />
      <BottomShortcutsBar />
    </DndProvider>
  );
}

const mapStateToProps = (store) => ({
  user: store.user,
  bundleBusy: store.app.bundleUpdating,
  deskBusy: store.app.creatingFolder,
  toolBarBusy: store.app.toolBarBusy,
  loadingCases: store.app.loadingCases,
  deletingItem: store.app.deletingItem
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
  createNewCase
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(EditorNavigation);