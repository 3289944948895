import React from "react";

const TermsAndConditionsComponent = ({ content }) => {
    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        {content.map((section, index) => (
                            <div key={index}>
                                {section.title && (
                                    <h4 className={`text-theme ${index > 0 ? 'mt-5' : ''}`}>{section.title}</h4>
                                )}
                                {section.paragraphs && section.paragraphs.map((paragraph, idx) => (
                                    <p key={idx} className={idx === section.paragraphs.length - 1 ? 'mb-3' : 'mb-5'}>
                                        {paragraph}
                                    </p>
                                ))}
                                {section.list && (
                                    <ul className="list-unstyled list-icon mb-3">
                                        {section.list.map((item, idx) => (
                                            <li key={idx} className="mb-3">
                                                <i className="bi bi-check-lg"></i> {item}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        ))}
                        <a className="themeht-btn" href="#">Accept</a>
                        <a className="themeht-btn dark-btn ms-2" href="#">Close</a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TermsAndConditionsComponent;
