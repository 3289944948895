import React from "react";

const AboutUsFeatures = ({ content }) => {
    return (
        <section class="position-relative">
            <div class="container">
                <div class="row">
                    {content.cards.map((feature, index) => (
                        <div className={`col-lg-3 col-md-6 ${feature.classes}`} key={index}>
                            <div className="featured-item style-1">
                                <div className="featured-icon">
                                    <img className="img-fluid" src={feature.imageSrc} alt={feature.title} />
                                </div>
                                <div className="featured-title">
                                    <h5>{feature.title}</h5>
                                </div>
                                <div className="featured-desc">
                                    <p>{feature.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default AboutUsFeatures;