import React from "react";

const FAQComponent = ({ content }) => {
    return (
        <section>
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="white-bg rounded box-shadow">
                            <div class="theme-title p-5 mb-0">
                                <h6>{content?.title}.</h6>
                                <h2>{content?.subtitle}</h2>
                            </div>
                            <img class="img-fluid rounded" src="images/about/04.jpg" alt="" />
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 mt-6 mt-lg-0 ps-lg-10">
                        <div class="accordion" id="accordion">
                            {content?.questions.map((q, index) => (
                                <div class="accordion-item mb-4">
                                    <h2 class="accordion-header" id={`header${index + 1}`}>
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index + 1}`} aria-expanded={index === 0 ? "true" : "false"} aria-controls={`collapse${index + 1}`}>{q.question}</button>
                                    </h2>
                                    <div id={`collapse${index + 1}`} class={`accordion-collapse collapse border-0 ${index === 0 ? 'show' : ''}`} aria-labelledby={`header${index + 1}`} data-bs-parent="#accordion">
                                        <div class="accordion-body">{q.answer}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FAQComponent;