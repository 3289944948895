import { combineReducers } from 'redux';
import { caseData } from './case';
import { user } from './user';
import { app } from './app';
import { admin } from './admin';
import { site } from './site'

const Reducers = combineReducers({
    user, caseData, app, admin, site
});

export default Reducers;