import React, { useEffect, useState } from 'react';
import {
  Sheet,
  FormControl,
  FormLabel,
  Input,
  Button,
  Typography,
  Box,
  Grid,
  Stack,
} from '@mui/joy';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateSiteAboutUsContent } from '../redux/actions/index.js';
import GradientCover from './GradientCover.js';

const AdminAboutUsComponent = ({ content, apiKey, siteId, updateSiteAboutUsContent }) => {
    const [aboutusContent, setAboutusContent] = useState({})

    useEffect(() => {
        setAboutusContent(content);
    }, [content])

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setAboutusContent(prev => ({
                ...prev,
                image: {
                    ...prev.image,
                    file,
                    src: URL.createObjectURL(file),
                }
            }))
        }
    }

    const handleAddAccordionItem = () => {
        const newAccordionItem = {
            _id: { $oid: new Date().getTime() }, // Temporary ID, ideally should be generated by the backend
            title: '',
            body: '',
        };
        setAboutusContent(prev => ({
            ...prev,
            accordionItems: [...prev.accordionItems, newAccordionItem],
        }));
    };

    const handleRemoveAccordionItem = (indexToRemove) => {
        setAboutusContent(prev => ({
            ...prev,
            accordionItems: prev.accordionItems.filter((_, index) => index !== indexToRemove),
        }));
    };

    const handleSubmit = (event) => {
        const formData = new FormData();
        formData.append('aboutusContent', JSON.stringify(aboutusContent));
        if(aboutusContent.image?.file){
            formData.append(`image`, aboutusContent.image.file);
        }
        updateSiteAboutUsContent(apiKey, siteId, formData)
    }

    return (
        <>
        <Typography level="h4" gutterBottom>
            About Us
        </Typography>
        <Sheet sx={{ mb: 3, p: 2, borderRadius: '10px' }} variant={'outlined'}>
            <Stack spacing={2}>
            <FormControl fullWidth sx={{ mb: 2 }}>
                <FormLabel>Title</FormLabel>
                <Input
                    value={aboutusContent?.title}
                    onChange={(e) => setAboutusContent(prev => ({
                        ...prev,
                        title: e.target.value
                    }))}
                />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
                <FormLabel>Subtitle</FormLabel>
                <Input
                    value={aboutusContent?.subtitle}
                    onChange={(e) => setAboutusContent(prev => ({
                        ...prev,
                        subtitle: e.target.value
                    }))}
                />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
                <FormLabel>Description</FormLabel>
                <Input
                    maxRows={5}
                    value={aboutusContent?.description}
                    onChange={(e) => setAboutusContent(prev => ({
                        ...prev,
                        description: e.target.value
                    }))}
                />
            </FormControl>
            <Stack direction={'column'} spacing={2} alignItems={'center'}>
                <GradientCover title={'About Us Image Src'} image={aboutusContent?.image} />
                <input type="file" accept="image/*" onChange={(e) => handleImageChange(e)} />
            </Stack>
            <Box sx={{ display: 'flex', flexDirection: {xs: 'column', md: 'row'}, gap: 2, mb: 2 }}>
                <FormControl fullWidth sx={{ mb: 2, flex: 1 }}>
                    <FormLabel>Button Text</FormLabel>
                    <Input
                        value={aboutusContent?.button?.text}
                        onChange={(e) => setAboutusContent(prev => ({
                            ...prev,
                            button: {
                                ...prev.button,
                                text: e.target.value
                            }
                        }))}
                    />
                </FormControl>
                <FormControl fullWidth sx={{ mb: 2, flex: 1 }}>
                    <FormLabel>Button Link</FormLabel>
                    <Input
                        value={aboutusContent?.button?.href}
                        onChange={(e) => setAboutusContent(prev => ({
                            ...prev,
                            button: {
                                ...prev.button,
                                href: e.target.value
                            }
                        }))}
                    />
                </FormControl>
            </Box>
            <Typography level="h5" gutterBottom>
                Accordion Items
            </Typography>
            <Grid container spacing={2} columns={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                {aboutusContent?.accordionItems?.map((item, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Box key={item._id.$oid} sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2 }}>
                            <FormControl fullWidth>
                                <FormLabel>Title</FormLabel>
                                <Input
                                    value={item.title}
                                    onChange={(e) => setAboutusContent(prev => ({
                                        ...prev,
                                        accordionItems: prev.accordionItems.map((value, i) => index === i ? ({...value, title: e.target.value }) : value)
                                    }))}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <FormLabel>Body</FormLabel>
                                <Input
                                    maxRows={10}
                                    value={item.body}
                                    onChange={(e) => setAboutusContent(prev => ({
                                        ...prev,
                                        accordionItems: prev.accordionItems.map((value, i) => index === i ? ({...value, body: e.target.value }) : value)
                                    }))}
                                />
                            </FormControl>
                            <Button variant={'outlined'} color={'danger'} onClick={() => handleRemoveAccordionItem(index)}>
                                Remove
                            </Button>
                        </Box>
                    </Grid>
                ))}
                <FormControl sx={{display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                    <Button variant="soft" onClick={handleAddAccordionItem}>Add Accordion Item</Button>
                </FormControl>
            </Grid>
            
            <Button variant='soft' color={'success'} fullWidth onClick={handleSubmit}>Save About Us Changes</Button>
            </Stack>
        </Sheet>
        </>
    )
}

const mapStateToProps = (store) => ({
    apiKey: store.admin?.apiKey,
    siteId: store.admin.site._id,
    content: store.admin.site.aboutUs.aboutusContent
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateSiteAboutUsContent
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AdminAboutUsComponent);