
import React, { createContext, useContext, useState } from 'react';

const RavenContext = createContext();
export const useRavenContext = () => useContext(RavenContext);

const RavenProvider = ({ children }) => {
  const [jumpToHighlightArea, setJumpToHighlightArea] = useState(() => () => {});
  const [Download, setDownload] = useState(() => () => {});
  const [print, setPrint] = useState(() => () => {});
  const [EnterFullScreen, setEnterFullScreen] = useState(() => () => {});
  const [jumpToPage, setJumpToPage] = useState(() => () => {});

  const value = { jumpToHighlightArea, setJumpToHighlightArea, Download, setDownload, print, setPrint, EnterFullScreen, setEnterFullScreen, jumpToPage, setJumpToPage };

  return (
    <RavenContext.Provider value={value}>
      {children}
    </RavenContext.Provider>
  );
};

export default RavenProvider;