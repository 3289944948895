import React from 'react';
import { alpha } from '@mui/material/styles';
import { useDrop } from 'react-dnd';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  updateBundle,
  moveItemsFromDeskToBundle,
  moveItemsFromDeskToBundleLocally,
  moveItemsFromBundleToDesk,
  moveItemsFromBundleToDeskLocally,
  mergePDF
} from '../redux/actions/index.js';


const DropContainer = ({ caseid, user, updateBundle, loadPDF, space, mergePDF, moveItemsFromDeskToBundle, moveItemsFromDeskToBundleLocally, moveItemsFromBundleToDesk, moveItemsFromBundleToDeskLocally}) => {
  const [{ isOver }, drop] = useDrop({
    accept: 'TREE_ITEM',
    drop: (droppedItem) => {
      if (space === 'bundle') {
        moveItemsFromDeskToBundleLocally(droppedItem.itemId, null, 0);
        moveItemsFromDeskToBundle(caseid, user.apiKey, droppedItem.itemId, null, 0).then(() => mergePDF(caseid, user.apiKey));
      } else if (space === 'desk') {
        moveItemsFromBundleToDeskLocally(droppedItem.itemId, null, 0);
        moveItemsFromBundleToDesk(caseid, user.apiKey, droppedItem.itemId, null, 0).then(() => mergePDF(caseid, user.apiKey));
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <div ref={drop} style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px', backgroundColor: isOver ? alpha('#1976d2', 0.1) : 'transparent', border: '1px dashed #1976d2', marginTop: '10px' }}>
      Drop files here to move to {space}
    </div>
  );
}

const mapDispatchProps = (dispatch) => bindActionCreators({
  updateBundle,
  mergePDF,
  moveItemsFromDeskToBundle,
  moveItemsFromDeskToBundleLocally,
  moveItemsFromBundleToDesk,
  moveItemsFromBundleToDeskLocally,
}, dispatch);
const mapStateToProps = (store) => ({
  user: store.user,
  caseid: store.caseData._id
})
export default connect(mapStateToProps, mapDispatchProps)(DropContainer);