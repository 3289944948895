import React, { useState, useEffect} from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Avatar from '@mui/joy/Avatar';
import AvatarGroup from '@mui/joy/AvatarGroup';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListDivider from '@mui/joy/ListDivider';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';

import FolderRoundedIcon from '@mui/icons-material/FolderRounded';
import { convertBytes, timeAgo } from '../utils/index.js';
import { Link } from 'react-router-dom';;

export default function DashBundleMobileTable({cases, loadingCases }){
  if(loadingCases){
    return (
      <></>
    )
  }
  return (
    <Sheet
      variant="outlined"
      sx={{
        display: { xs: 'inherit', sm: 'none' },
        borderRadius: 'sm',
        overflow: 'auto',
        backgroundColor: 'background.surface',
        '& > *': {
          '&:nth-child(n):not(:nth-last-child(-n+4))': {
            borderBottom: '1px solid',
            borderColor: 'divider',
          },
        },
      }}
    >
    <List size="sm" aria-labelledby="table-in-list">
      {cases.map((bundle, index) => (
        <React.Fragment key={`frag_${bundle._id}`}>
          <Link to={`/case/${bundle._id}`} style={{textDecoration: 'none'}} key={`link_${bundle._id}`}>
            <ListItem key={`tab_${bundle._id}`}>
              <ListItemButton variant="soft" sx={{ bgcolor: 'transparent' }}>
                <ListItemContent sx={{ p: 1 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography
                      level="title-sm"
                      startDecorator={<FolderRoundedIcon color="primary" />}
                      sx={{ alignItems: 'flex-start' }}
                    >
                      {bundle.name}
                    </Typography>
                    <AvatarGroup
                      size="sm"
                      sx={{
                        '--AvatarGroup-gap': '-8px',
                        '--Avatar-size': '24px',
                      }}
                    >
                      <Avatar
                        src="https://i.pravatar.cc/24?img=4"
                        srcSet="https://i.pravatar.cc/48?img=4 2x"
                      />
                      <Avatar
                        src="https://i.pravatar.cc/24?img=8"
                        srcSet="https://i.pravatar.cc/48?img=8 2x"
                      />
                      <Avatar
                        src="https://i.pravatar.cc/24?img=5"
                        srcSet="https://i.pravatar.cc/48?img=5 2x"
                      />
                    </AvatarGroup>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      mt: 2,
                    }}
                  >
                    <Typography level="body-sm">{convertBytes(bundle.size)}</Typography>

                    <Typography level="body-sm">{timeAgo(bundle.lastOpenedOn || bundle.lastUpdatedOn)}</Typography>
                  </Box>
                </ListItemContent>
              </ListItemButton>
            </ListItem>
          </Link>
          {index !== cases.length - 1  ? (<ListDivider />) : (<></>)}
        </React.Fragment>
      ))}
    </List>
    </Sheet>
  )
}