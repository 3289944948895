import React, { useState } from 'react';
import Box from '@mui/joy/Box';
import ModalClose from '@mui/joy/ModalClose';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Sheet from '@mui/joy/Sheet';
import { Select, Option, Stack, Typography } from '@mui/joy';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addSubscriptionPlan } from '../redux/actions';

const AdminNewSubscriptionPlan = React.forwardRef(
  function AddPlanForm({ open, onClose, apiKey, addSubscriptionPlan }, ref) {
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [features, setFeatures] = useState('')
    const [bundleLimit, setBundleLimit] = useState(0)
    const [spaceLimit, setSpaceLimit] = useState(0)
    const [subscriptionType, setSubscriptionType] = useState('recurring')
    const [status, setStatus] = useState('active')
    const [trialPeriod, setTrialPeriod] = useState(0)
    const [price, setPrice] = useState(0)
    const [renewalInterval, setRenewalInterval] = useState('monthly')

    const handleFeaturesChange = (event) => {
      setFeatures(event.target.value.split(','))
    };

    const handleSubmit = (event) => {
      event.preventDefault();
      const planData = {
        name,
        description,
        features,
        bundleLimit,
        spaceLimit,
        subscriptionType,
        status,
        trialPeriod,
        price,
        renewalInterval
      }
      console.log(planData);
      addSubscriptionPlan(apiKey, planData);
      onClose();
    };

    return (
      <Sheet
        ref={ref}
        sx={{
          alignItems: 'center',
          px: 1.5,
          py: 1.5,
          ml: 'auto',
          width: { xs: '100dvw', md: 600 },
          flexGrow: 1,
          border: '1px solid',
          borderRadius: '8px 8px 0 0',
          backgroundColor: 'background.level1',
          borderColor: 'neutral.outlinedBorder',
          boxShadow: 'lg',
          zIndex: 1000,
          position: 'fixed',
          bottom: 0,
          right: 24,
          transform: open ? 'translateY(0)' : 'translateY(100%)',
          transition: 'transform 0.3s ease',
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Typography level="title-sm">Add New Subscription Plan</Typography>
          <ModalClose id="close-icon" onClick={onClose} />
        </Box>
        <form onSubmit={handleSubmit}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, flexShrink: 0 }}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                name="name"
                value={name}
                onChange={e => setName(e.target.value)}
                placeholder="Plan Name"
                required
              />
            </FormControl>

            <FormControl>
              <FormLabel>Description</FormLabel>
              <Input
                name="description"
                value={description}
                onChange={e => setDescription(e.target.value)}
                placeholder="Description"
                required
              />
            </FormControl>

            <FormControl>
              <FormLabel>Features</FormLabel>
              <Input
                name="features"
                value={features}
                onChange={handleFeaturesChange}
                placeholder="Features (comma-separated)"
                required
              />
            </FormControl>

            <Stack direction={'row'} spacing={2}>
              <FormControl sx={{flex: 1}}>
                <FormLabel>Bundle Limit</FormLabel>
                <Input
                  name="bundleLimit"
                  type="number"
                  value={bundleLimit}
                  onChange={e => setBundleLimit(e.target.value)}
                  required
                />
              </FormControl>

              <FormControl sx={{flex: 1}}>
                <FormLabel>Space Limit (GB)</FormLabel>
                <Input
                  name="spaceLimit"
                  type="number"
                  value={spaceLimit}
                  onChange={e => setSpaceLimit(e.target.value)}
                  required
                />
              </FormControl>
            </Stack>

            <Stack direction={'row'} spacing={2}>
              <FormControl sx={{flex: 1}}>
                <FormLabel>Subscription Type</FormLabel>
                <Select
                  name="subscriptionType"
                  value={subscriptionType}
                  onChange={(e, value) => setSubscriptionType(value)}
                  required
                  disabled
                >
                  <Option value="one-time">One Time</Option>
                  <Option value="recurring">Recurring</Option>
                </Select>
              </FormControl>

              <FormControl sx={{flex: 1}}>
                <FormLabel>Status</FormLabel>
                <Select
                  name="status"
                  value={status}
                  onChange={(e, value) => setStatus(value)}
                  required
                  disabled
                >
                  <Option value="active">Active</Option>
                  <Option value="archived">Archived</Option>
                  <Option value="deactivated">Deactivated</Option>
                </Select>
              </FormControl>
            </Stack>

            <Stack direction={'row'} spacing={2}>
              <FormControl sx={{flex: 1}}>
                <FormLabel>Trial Period (Days)</FormLabel>
                <Input
                  name="trialPeriod"
                  type="number"
                  value={trialPeriod}
                  onChange={(e) => setTrialPeriod(e.target.value)}
                  required
                />
              </FormControl>

              <FormControl sx={{flex: 1}}>
                <FormLabel>Price (USD)</FormLabel>
                <Input
                  name="price"
                  type="number"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  required
                />
              </FormControl>
            </Stack>

            <FormControl>
              <FormLabel>Renewal Interval</FormLabel>
              <Select
                name="renewalInterval"
                value={renewalInterval}
                onChange={(e, value) => setRenewalInterval(value)}
                required
              >
                <Option value="monthly">Monthly</Option>
                <Option value="yearly">Yearly</Option>
              </Select>
            </FormControl>

            <Button color="primary" sx={{ borderRadius: 'sm' }} type="submit">
              Add Plan
            </Button>
          </Box>
        </form>
      </Sheet>
    );
  }
);

const mapStateToProps = (store) => ({
  apiKey: store.admin.apiKey,
});

const mapDispatchProps = (dispatch) =>  bindActionCreators({
  addSubscriptionPlan
}, dispatch );

export default connect(mapStateToProps, mapDispatchProps)(AdminNewSubscriptionPlan);
