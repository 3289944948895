import React, { useState, useEffect } from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Breadcrumbs from '@mui/joy/Breadcrumbs';
import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';

import Sidebar from '../components/AdminSidebar';
import AdminPaymentTable from '../components/AdminPaymentTable';
import AdminPaymentList from '../components/AdminPaymentList';
import Header from '../components/AdminHeader';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadWebsiteSettings } from '../redux/actions';
import AdminSettingsComponent from '../components/AdminSettingsComponent';
import { Container, Sheet } from '@mui/joy';

const EditorComponent = (props) => {
    const { settings } = props;
    const [content, setContent] = useState(settings);

    useEffect(() => {
        setContent(settings)
    }, [settings])

    if(!content){
        return (
            <CssVarsProvider>
                <Container>
                    <Typography>Loading...</Typography>
                </Container>
            </CssVarsProvider>
        )
    }

    return (
        <CssVarsProvider>
            <Sheet sx={{py: 2, overflowY: 'scroll'}} variant={'plain'}>
                <AdminSettingsComponent />
            </Sheet>
        </CssVarsProvider>
    );
};

function AdminWebsiteSettings(props) {
  const { apiKey, loadWebsiteSettings, settings } = props;

  useEffect(() => {
    loadWebsiteSettings(apiKey);
  }, [])

  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
        <Header />
        <Sidebar selectedTab={'settings'} />
        <Box
          component="main"
          className="MainContent"
          sx={{
            px: { xs: 2, md: 6 },
            pt: {
              xs: 'calc(12px + var(--Header-height))',
              sm: 'calc(12px + var(--Header-height))',
              md: 3,
            },
            pb: { xs: 2, sm: 2, md: 3 },
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            minWidth: 0,
            height: '100dvh',
            gap: 1,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Breadcrumbs
              size="sm"
              aria-label="breadcrumbs"
              separator={<ChevronRightRoundedIcon fontSize="sm" />}
              sx={{ pl: 0 }}
            >
              <Link
                underline="none"
                color="neutral"
                href="/"
                aria-label="Home"
              >
                <HomeRoundedIcon />
              </Link>
              <Link
                underline="hover"
                color="neutral"
                href="/dashboard"
                fontSize={12}
                fontWeight={500}
              >
                Dashboard
              </Link>
              <Typography color="primary" fontWeight={500} fontSize={12}>
                Settings
              </Typography>
            </Breadcrumbs>
          </Box>
          <Box
            sx={{
              display: 'flex',
              mb: 1,
              gap: 1,
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: { xs: 'start', sm: 'center' },
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            <Typography level="h2" component="h1">
              Settings
            </Typography>
          </Box>
          <EditorComponent settings={settings} />
        </Box>
      </Box>
    </CssVarsProvider>
  );
}

const mapStateToProps = (store) => ({
  apiKey: store.admin?.apiKey,
  settings: store.admin?.websiteSettings,
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
  loadWebsiteSettings
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AdminWebsiteSettings);