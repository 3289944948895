import React from "react";

export default function ServicesStats({ content }) {
  return (
    <section className="p-0 text-center">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="p-8 white-bg rounded" data-bg-img="images/bg/02.png">
              <div className="row">
                {content.map((stat, index) => (
                  <div
                    className={`col-lg-3 col-sm-6 ${index > 0 ? "mt-6 mt-sm-0" : ""} ${index > 1 ? "mt-lg-0" : ""}`}
                    key={index}
                  >
                    <div className="counter">
                      <div className="counter-desc">
                        <span className="count-number" data-count={stat.value}>{stat.value}</span>
                        <span>{stat.unit}</span>
                        <h5>{stat.label}</h5>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
