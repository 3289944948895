import React, { useState, useEffect } from 'react';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import Stack from '@mui/joy/Stack';
import Select, { selectClasses } from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';

import { connect } from 'react-redux';
import { clearToBeSharedCaseId, shareCase } from '../redux/actions';
import { useSnackbar } from 'notistack';
import { bindActionCreators } from 'redux';

function ShareCaseModal(props) {
    const { open, setOpen, bundle, caseid, apiKey, currentEmail, shareCase, setBackDropOpen } = props;

    const { enqueueSnackbar } = useSnackbar();

    const handleClose = () => {
        setBackDropOpen(false);
        clearToBeSharedCaseId()
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setOpen(false);
        setBackDropOpen(true);
        const email = event.target.email.value;
        const permission = event.target.permission[1].value;
        if(email && email.length > 0 && email !== currentEmail && permission && permission.length > 0){
            shareCase(caseid, apiKey, email, permission, enqueueSnackbar, handleClose);
        }
    } 

    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <ModalDialog>
            <DialogTitle>Share {bundle?.name} bundle</DialogTitle>
            <DialogContent>To share this bundle/case, please enter the recipient's email address.</DialogContent>
            <form onSubmit={handleSubmit} >
                <Stack spacing={2}>
                <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Input name='email' type='email' autoFocus required />
                </FormControl>
                <FormControl>
                    <Select
                        name='permission'
                        placeholder="Select permission"
                        indicator={<KeyboardArrowDown />}
                        sx={{
                            width: 240,
                            [`& .${selectClasses.indicator}`]: {
                            transition: '0.2s',
                            [`&.${selectClasses.expanded}`]: {
                                transform: 'rotate(-180deg)',
                            },
                            },
                        }}
                        >
                        <Option value="read">Read</Option>
                        <Option value="write">Write</Option>
                        </Select>
                </FormControl>
                <Button type="submit">Share</Button>
                </Stack>
            </form>
            </ModalDialog>
        </Modal>
    );
}

const mapStateToProps = (store) => ({
    currentEmail: store.app.email,
    apiKey: store.user.apiKey,
    caseid: store.app.toBeSharedCaseId
})
const mapDispatchProps = (dispatch) => bindActionCreators({
    shareCase,
    clearToBeSharedCaseId
}, dispatch);
export default connect(mapStateToProps, mapDispatchProps)(ShareCaseModal);