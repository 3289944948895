import React, { useEffect, useState } from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import Chip from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import IconButton from '@mui/joy/IconButton';
import Input from '@mui/joy/Input';
import LinearProgress from '@mui/joy/LinearProgress';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Stack from '@mui/joy/Stack';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import SupportRoundedIcon from '@mui/icons-material/SupportRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import BrightnessAutoRoundedIcon from '@mui/icons-material/BrightnessAutoRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import WebIcon from '@mui/icons-material/Web';
import SecurityIcon from '@mui/icons-material/Security';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

import ColorSchemeToggle from './AdminColorSchemeToggle';
import { closeSidebar } from '../utils';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadUsers, logoutAdmin } from '../redux/actions';
import { useNavigate } from 'react-router-dom';

function Toggler({
  defaultExpanded = false,
  renderToggle,
  children,
}) {
  const [open, setOpen] = useState(defaultExpanded);
  return (
    <React.Fragment>
      {renderToggle({ open, setOpen })}
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: open ? '1fr' : '0fr',
          transition: '0.2s ease',
          '& > *': {
            overflow: 'hidden',
          },
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
}

function AdminSidebar(props) {
  const {admin, selectedTab, logoutAdmin} = props;
  const [imageSrc, setImageSrc] = useState('')
  const navigate = useNavigate();

  useEffect(() => {
    if (admin?.profileImage?.data) {
      try{
        const blob = new Blob([new Uint8Array(admin.profileImage.data)], { type: 'image/jpeg' });
        const reader = new FileReader();
        reader.onload = () => {
            setImageSrc(reader.result);
        };
        reader.readAsDataURL(blob);
      } catch(error){
        console.log(error);
      }
    } else {
      setImageSrc('');
    }
  }, [admin?.profileImage])

  const handleLogout = (event) => {
    logoutAdmin(navigate);
  }

  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: { xs: 'fixed', md: 'sticky' },
        transform: {
          xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
          md: 'none',
        },
        transition: 'transform 0.4s, width 0.4s',
        zIndex: 10000,
        height: '100dvh',
        width: 'var(--Sidebar-width)',
        top: 0,
        p: 2,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRight: '1px solid',
        borderColor: 'divider',
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Sidebar-width': '220px',
            [theme.breakpoints.up('lg')]: {
              '--Sidebar-width': '240px',
            },
          },
        })}
      />
      <Box
        className="Sidebar-overlay"
        sx={{
          position: 'fixed',
          zIndex: 9998,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          opacity: 'var(--SideNavigation-slideIn)',
          backgroundColor: 'var(--joy-palette-background-backdrop)',
          transition: 'opacity 0.4s',
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
            lg: 'translateX(-100%)',
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <IconButton variant="soft" color="primary" size="sm">
          <BrightnessAutoRoundedIcon />
        </IconButton>
        <Typography level="title-lg">Admin Portal</Typography>
        <ColorSchemeToggle sx={{ ml: 'auto' }} />
      </Box>
      <Input size="sm" startDecorator={<SearchRoundedIcon />} placeholder="Search" />
      <Box
        sx={{
          minHeight: 0,
          overflow: 'hidden auto',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      >
        <List
          size="sm"
          sx={{
            gap: 1,
            '--List-nestedInsetStart': '30px',
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
          }}
        >
          <ListItem>
            <ListItemButton selected={selectedTab === 'home'} component='a' href='/'>
              <HomeRoundedIcon />
              <ListItemContent>
                <Typography level="title-sm">Home</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton selected={selectedTab === 'dashboard'} component='a' href='/dashboard'>
              <DashboardRoundedIcon />
              <ListItemContent>
                <Typography level="title-sm">Dashboard</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton selected={selectedTab === 'subscriptions'} component='a' href='/dashboard/subscriptions'>
              <MonetizationOnIcon />
              <ListItemContent>
                <Typography level="title-sm">Manage Subscriptions</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton
              selected={selectedTab === 'payments'}
              component="a"
              href="/dashboard/payments"
            >
              <ShoppingCartRoundedIcon />
              <ListItemContent>
                <Typography level="title-sm">Payments</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem nested>
            <Toggler defaultExpanded={['landing-page', 'services-page', 'faq-page', 'contact-page', 'about-page', 'team-page', 'terms-page', 'policy-page'].includes(selectedTab)}
              renderToggle={({ open, setOpen }) => (
                <ListItemButton onClick={() => setOpen(!open)}>
                  <WebIcon />
                  <ListItemContent>
                    <Typography level="title-sm">Site</Typography>
                  </ListItemContent>
                  <KeyboardArrowDownIcon
                    sx={{ transform: open ? 'rotate(180deg)' : 'none' }}
                  />
                </ListItemButton>
              )}
            >
              <List sx={{ gap: 0.5 }}>
                <ListItem sx={{ mt: 0.5 }}>
                  <ListItemButton
                    selected={selectedTab === 'landing-page'}
                    component="a"
                    href="/dashboard/site/landing"
                  >
                    Landing Page
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton
                    selected={selectedTab === 'services-page'}
                    component="a"
                    href="/dashboard/site/services"
                  >
                    Services
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton
                    selected={selectedTab === 'contact-page'}
                    component="a"
                    href="/dashboard/site/contactus"
                  >
                    Contact Us
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton
                    selected={selectedTab === 'faq-page'}
                    component="a"
                    href="/dashboard/site/faq"
                  >
                    FAQ
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton
                    selected={selectedTab === 'team-page'}
                    component="a"
                    href="/dashboard/site/team"
                  >
                    Team
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton
                    selected={selectedTab === 'about-page'}
                    component="a"
                    href="/dashboard/site/aboutus"
                  >
                    About Us
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton
                    selected={selectedTab === 'terms-page'}
                    component="a"
                    href="/dashboard/site/termsandconditions"
                  >
                    Terms & Conditions
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton
                    selected={selectedTab === 'policy-page'}
                    component="a"
                    href="/dashboard/site/privacypolicy"
                    >
                    Privacy Policy
                  </ListItemButton>
                </ListItem>
              </List>
            </Toggler>
          </ListItem>

          <ListItem>
            <ListItemButton
              role="menuitem"
              component="a"
              href="/dashboard/messages"
              selected={selectedTab === 'messages'}
            >
              <QuestionAnswerRoundedIcon />
              <ListItemContent>
                <Typography level="title-sm">Messages</Typography>
              </ListItemContent>
              {/* <Chip size="sm" color="primary" variant="solid">
                4
              </Chip> */}
            </ListItemButton>
          </ListItem>

          <ListItem nested>
            <Toggler defaultExpanded={selectedTab === 'all-users'}
              renderToggle={({ open, setOpen }) => (
                <ListItemButton onClick={() => setOpen(!open)}>
                  <GroupRoundedIcon />
                  <ListItemContent>
                    <Typography level="title-sm">Users</Typography>
                  </ListItemContent>
                  <KeyboardArrowDownIcon
                    sx={{ transform: open ? 'rotate(180deg)' : 'none' }}
                  />
                </ListItemButton>
              )}
            >
              <List sx={{ gap: 0.5 }}>
                <ListItem sx={{ mt: 0.5 }}>
                  <ListItemButton
                    role="menuitem"
                    component="a"
                    href="/dashboard/users"
                    selected={selectedTab === 'all-users'}
                  >
                    All Users
                  </ListItemButton>
                </ListItem>
                {/* <ListItem>
                  <ListItemButton selected={selectedTab === 'create-user'}>Create a new user</ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton selected={selectedTab === 'roles-permission'}>Roles & permission</ListItemButton>
                </ListItem> */}
              </List>
            </Toggler>
          </ListItem>

          <ListItem>
            <ListItemButton
              selected={selectedTab === 'logs'}
              role="menuitem"
              component="a"
              href="/dashboard/logs"
            >
              <AssignmentRoundedIcon />
              <ListItemContent>
                <Typography level="title-sm">Logs</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton
              selected={selectedTab === 'audits'}
              role="menuitem"
              component="a"
              href="/dashboard/audits"
            >
              <SecurityIcon />
              <ListItemContent>
                <Typography level="title-sm">Audits</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

        </List>

        <List
          size="sm"
          sx={{
            mt: 'auto',
            flexGrow: 0,
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
            '--List-gap': '8px',
            mb: 0,
          }}
        >
          {/* <ListItem>
            <ListItemButton>
              <SupportRoundedIcon />
              Support
            </ListItemButton>
          </ListItem> */}
          <ListItem>
            <ListItemButton
              selected={selectedTab === 'settings'}
              role="menuitem"
              component="a"
              href="/dashboard/settings"
            >
              <SettingsRoundedIcon />
              Settings
            </ListItemButton>
          </ListItem>
        </List>
        {/* <Card
          invertedColors
          variant="soft"
          color="warning"
          size="sm"
          sx={{ boxShadow: 'none' }}
        >
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography level="title-sm">Used space</Typography>
            <IconButton size="sm">
              <CloseRoundedIcon />
            </IconButton>
          </Stack>
          <Typography level="body-xs">
            Your team has used 80% of your available space. Need more?
          </Typography>
          <LinearProgress variant="outlined" value={80} determinate sx={{ my: 1 }} />
          <Button size="sm" variant="solid">
            Upgrade plan
          </Button>
        </Card> */}
      </Box>
      <Divider />
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <Avatar
          variant="outlined"
          size="sm"
          src={imageSrc}
        />
        <Box sx={{ minWidth: 0, flex: 1 }}>
          <Typography level="title-sm">{`${admin?.firstname} ${admin?.lastname}`}</Typography>
          <Typography level="body-xs">{admin?.email}</Typography>
        </Box>
        <IconButton size="sm" variant="plain" color="neutral" onClick={handleLogout}>
          <LogoutRoundedIcon />
        </IconButton>
      </Box>
    </Sheet>
  );
}

const mapStateToProps = (store) => ({
  admin: store.admin
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
  loadUsers,
  logoutAdmin
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AdminSidebar);