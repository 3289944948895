import React, { useState, useEffect } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Sheet,
  Typography,
  Box,
  Grid,
  Stack,
} from '@mui/joy';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateSiteAboutUsTimeline } from '../redux/actions/index.js';


const AdminAboutUsTimelineEditor = ({ content, apiKey, siteId, updateSiteAboutUsTimeline }) => {
  const [timeline, setContent] = useState(content || []);

  useEffect(() => {
    setContent(content || []);
  }, [content]);

  const handleAddTimelineItem = () => {
    setContent([...timeline, { year: '', icon: '', title: '', description: '' }]);
  };

  const handleRemoveTimelineItem = (index) => {
    setContent(timeline.filter((_, i) => i !== index));
  };

  const handleChange = (index, field, value) => {
    setContent((prev) => {
      const updatedTimeline = [...prev];
      updatedTimeline[index][field] = value;
      return updatedTimeline;
    });
  };

  const handleSubmit = (event) => {
    updateSiteAboutUsTimeline(apiKey, siteId, timeline);
  }

  return (
    <>
      <Typography level="h4" gutterBottom>
        Timeline Editor
      </Typography>
      <Sheet sx={{ mb: 3, p: 2, borderRadius: '10px' }} variant={'outlined'}>
        {timeline?.map((item, index) => (
            <Sheet key={index} sx={{ mb: 3, p: 2, borderRadius: '10px' }} variant={'soft'}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <FormControl fullWidth>
                <FormLabel>Year</FormLabel>
                <Input
                    value={item.year}
                    onChange={(e) => handleChange(index, 'year', e.target.value)}
                />
                </FormControl>
                <FormControl fullWidth>
                <FormLabel>Icon</FormLabel>
                <Input
                    value={item.icon}
                    onChange={(e) => handleChange(index, 'icon', e.target.value)}
                />
                </FormControl>
                <FormControl fullWidth>
                <FormLabel>Title</FormLabel>
                <Input
                    value={item.title}
                    onChange={(e) => handleChange(index, 'title', e.target.value)}
                />
                </FormControl>
                <FormControl fullWidth>
                <FormLabel>Description</FormLabel>
                <Input
                    value={item.description}
                    onChange={(e) => handleChange(index, 'description', e.target.value)}
                    minRows={3}
                />
                </FormControl>
                <Button variant='outlined' color="danger" onClick={() => handleRemoveTimelineItem(index)}>
                Remove Timeline Item
                </Button>
            </Box>
            </Sheet>
        ))}
        <Stack spacing={2}>
            <Button variant={'plain'} onClick={handleAddTimelineItem} fullWidth>Add Timeline Item</Button>
            <Button variant='soft' color='success' fullWidth onClick={handleSubmit}>Save Changes</Button>
        </Stack>
        
      </Sheet>
      
    </>
  );
};

const mapStateToProps = (store) => ({
  apiKey: store.admin?.apiKey,
  siteId: store.admin.site._id,
  content: store.admin.site.aboutUs.timeline
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateSiteAboutUsTimeline
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AdminAboutUsTimelineEditor);
