import React, { useState, useEffect, useCallback, createContext } from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  renameCase,
  changeTheme,
  mergePDF,
  createNewCase,
  generateToc,
  toggleCaseFavorite,
  logout,
  toggleTheme,
  openCase,
  closeCase,
  loadPDF
} from '../redux/actions/index.js';

import Layout from '../components/EditorLayout';
import Navigation from '../components/EditorNavigation';
import Header from '../components/EditorHeader';
import { Backdrop, CircularProgress as MCircularProgress } from '@mui/material';

import { Worker } from '@react-pdf-viewer/core';
import { useTheme } from '@mui/material/styles';
import PdfViewer from '../components/PDFviewer.js';

import { CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { DialogContent, DialogTitle, FormControl, LinearProgress, Modal, ModalClose, ModalDialog, ModalOverflow, Stack, Switch, Typography } from '@mui/joy';
import Note from '../components/Note.js';
import RavenProvider from '../components/RavenContext.js';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function EditorPage(props) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(0);
  const [searchText, setSearchText] = useState('')
  const [backDropOpen, setBackDropOpen] = useState(false);
  const [isRightDrawerOpen, setIsRightDrawerOpen] = useState(false);

  const [selected, setSelected] = useState(1);

  const { caseid } = useParams();
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [layout, setLayout] = useState(undefined);
  const { user, caseData, openCase, closeCase, loadPDF } = props;

  useEffect(() => {
      openCase(caseid, user.apiKey).then(() => {
          loadPDF(caseid, user.apiKey)
      })
  }, [caseid, user.apiKey, openCase, loadPDF, closeCase]);

  const handleDrawerClose = () => {
      setOpen(false);
  };

  if(!caseData._id){
    return(
      <Box sx={{ display: 'flex', width: '100%', minHeight: '100vh', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress color='inherit' />
      </Box>
    )
  }

  return (
    <HelmetProvider>
    <Helmet>
      <title>{caseData?.name + " | Hybrid FB" || 'Hybrid FB'}</title>
    </Helmet>
    <CssVarsProvider disableTransitionOnChange>
      <RavenProvider>
      <Modal
        open={!!layout}
        onClose={() => {
          setLayout(undefined);
        }}
      >
        <ModalOverflow>
          <ModalDialog aria-labelledby="modal-dialog-overflow" layout={layout}>
            <ModalClose />
            <Typography id="modal-dialog-overflow" level="h2">
              Notes
            </Typography>
            { caseData.notes.filter(note => note.highlightAreas[0].pageIndex !== -1 ).map(note => <Note key={`note${note._id}`} note={note} />) }
          </ModalDialog>
        </ModalOverflow>
      </Modal>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => 9999 }}
        open={backDropOpen}
      >
        <MCircularProgress color="inherit" />
      </Backdrop>
      <CssBaseline />
      {/* {drawerOpen && (
        <Layout.SideDrawer open={drawerOpen} onClose={() => setDrawerOpen(false)}>
          <Navigation open={drawerOpen} handleDrawerClose={handleDrawerClose} selected={selected}  setSelected={setSelected} backDropOpen={backDropOpen} setBackDropOpen={setBackDropOpen} />
        </Layout.SideDrawer>
      )} */}

      <Layout.Root
        sx={{
          ...(drawerOpen && {
            height: '100vh',
            overflow: 'hidden',
          }),
        }}
      >
        <Layout.Header>
          <Header open={drawerOpen} isRightDrawerOpen={isRightDrawerOpen} setIsRightDrawerOpen={setIsRightDrawerOpen} handleDrawerClose={handleDrawerClose} selected={selected} setSelected={setSelected} setDrawerOpen={setDrawerOpen} setBackDropOpen={setBackDropOpen} selectedOption={selectedOption} setSelectedOption={setSelectedOption} searchText={searchText} setSearchText={setSearchText} />
        </Layout.Header>
        <Layout.SideNav>
          <Navigation open={drawerOpen} handleDrawerClose={handleDrawerClose} selected={selected} setDrawerOpen={setDrawerOpen} setSelected={setSelected} setBackDropOpen={setBackDropOpen} />
        </Layout.SideNav>
        <Layout.Main>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Box sx={{ display: 'flex', height: '100hv' }}>
                    <Box component="main" sx={{ flexGrow: 1, p: 0,}}>
                        <PdfViewer />
                    </Box>
                </Box>
            </Worker>
        </Layout.Main>
        <Layout.RightSection>
            <Stack spacing={2} sx={{display: 'flex', flexDirection: 'column'}}>
              <Typography>Notes</Typography>
              { caseData.notes.filter(note => note.highlightAreas[0].pageIndex !== -1 ).map(note => <Note key={`note${note._id}`} note={note} />) }
            </Stack>
        </Layout.RightSection>
      </Layout.Root>
      </RavenProvider>
    </CssVarsProvider>
    </HelmetProvider>
  );
}

const mapStateToProps = (store) => ({
  caseData: store.caseData,
  user: store.user,
  bundleBusy: store.app.bundleUpdating,
  deskBusy: store.app.creatingFolder,
  toolBarBusy: store.app.toolBarBusy,
  loadingCases: store.app.loadingCases,
  theme: store.app.theme
})
const mapDispatchProps = (dispatch) => bindActionCreators({
  renameCase,
  changeTheme,
  mergePDF,
  createNewCase,
  generateToc,
  toggleCaseFavorite,
  logout,
  toggleTheme,
  openCase,
  closeCase,
  loadPDF
}, dispatch);
export default connect(mapStateToProps, mapDispatchProps)(EditorPage);