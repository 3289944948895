import React from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Sidebar from '../components/NotificationSidebar';
import Header from '../components/NotificationHeader';
import MyMessages from '../components/NotificationList';

export default function Notifications () {
  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      <Box sx={{display: 'flex', minHeight: '100dvh'}}>
        <Sidebar />
        <Header />
        <Box component="main" className="MainContent" sx={{flex: 1}}>
          <MyMessages />
        </Box>
      </Box>
    </CssVarsProvider>
  );
}
