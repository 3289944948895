import React, { useEffect, useState } from 'react';
import {
  Sheet,
  FormControl,
  FormLabel,
  Input,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  Grid,
  Stack
} from '@mui/joy';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateSiteTeamBody } from '../redux/actions/index.js';

const AdminTeamComponent = ({ content, apiKey, siteId, updateSiteTeamBody }) => {
  const [body, setBody] = useState({});

  useEffect(() => {
    setBody(content);
  }, [content]);

  const handleImageChange = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      setBody((prev) => ({
        ...prev,
        teamMembers: prev.teamMembers.map((v, i) =>
          index === i ? { ...v, image: URL.createObjectURL(file), file } : v
        )
      }));
    }
  };

  const handleSocialLinkChange = (memberIndex, socialIndex, key, value) => {
    setBody((prev) => ({
      ...prev,
      teamMembers: prev.teamMembers.map((v, i) =>
        i === memberIndex
          ? {
              ...v,
              socialLinks: v.socialLinks.map((social, si) =>
                si === socialIndex ? { ...social, [key]: value } : social
              )
            }
          : v
      )
    }));
  };

  const addSocialLink = (memberIndex) => {
    setBody((prev) => ({
      ...prev,
      teamMembers: prev.teamMembers.map((v, i) =>
        i === memberIndex && v.socialLinks.length < 5
          ? { ...v, socialLinks: [...v.socialLinks, { url: '', iconClass: '' }] }
          : v
      )
    }));
  };

  const removeSocialLink = (memberIndex, socialIndex) => {
    setBody((prev) => ({
      ...prev,
      teamMembers: prev.teamMembers.map((v, i) =>
        i === memberIndex
          ? {
              ...v,
              socialLinks: v.socialLinks.filter((_, si) => si !== socialIndex)
            }
          : v
      )
    }));
  };

  const addTeamMember = () => {
    setBody((prev) => ({
      ...prev,
      teamMembers: [
        ...prev.teamMembers,
        { name: '', role: '', image: '', file: null, socialLinks: [] }
      ]
    }));
  };

  const removeTeamMember = (index) => {
    setBody((prev) => ({
      ...prev,
      teamMembers: prev.teamMembers.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append('body', JSON.stringify(body));

    for (let i = 0; i < body.teamMembers.length; i++) {
      if (body.teamMembers[i]?.file) {
        formData.append(`image_${i}`, body.teamMembers[i].file);
      }
    }

    updateSiteTeamBody(apiKey, siteId, formData)
  };

  return (
    <>
      <Typography level="h4" gutterBottom>
        Team
      </Typography>
      <Sheet sx={{ mb: 3, p: 2, borderRadius: '10px' }} variant={'outlined'}>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <FormLabel>Title</FormLabel>
          <Input
            value={body?.title}
            onChange={(e) =>
              setBody((prev) => ({
                ...prev,
                title: e.target.value
              }))
            }
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <FormLabel>Subtitle</FormLabel>
          <Input
            value={body?.subtitle}
            onChange={(e) =>
              setBody((prev) => ({
                ...prev,
                subtitle: e.target.value
              }))
            }
          />
        </FormControl>
        <Grid container spacing={2}>
          {body?.teamMembers?.map((member, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <Card sx={{ mb: 2 }} variant={'soft'}>
                <img src={member.image} alt={member.name} />
                <input type="file" accept="image/*" onChange={(e) => handleImageChange(e, index)} />
                <CardContent>
                  <FormControl fullWidth sx={{ mb: 1 }}>
                    <FormLabel>Member Title</FormLabel>
                    <Input
                      value={member.name}
                      onChange={(e) =>
                        setBody((prev) => ({
                          ...prev,
                          teamMembers: prev.teamMembers.map((v, i) =>
                            index === i ? { ...v, name: e.target.value } : v
                          )
                        }))
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ mb: 1 }}>
                    <FormLabel>Member Role</FormLabel>
                    <Input
                      value={member.role}
                      onChange={(e) =>
                        setBody((prev) => ({
                          ...prev,
                          teamMembers: prev.teamMembers.map((v, i) =>
                            index === i ? { ...v, role: e.target.value } : v
                          )
                        }))
                      }
                    />
                  </FormControl>
                  <Stack spacing={2}>
                    {member.socialLinks.map((social, socialIndex) => (
                      <Sheet variant="soft" key={`${socialIndex}`}>
                        <Stack spacing={2}>
                            <Typography level={'body-sm'} sx={{ textAlign: 'end' }}>{`Social #${
                                socialIndex + 1
                            }`}</Typography>
                            <FormControl>
                                <FormLabel>URL</FormLabel>
                                <Input
                                    value={social.url}
                                    onChange={(e) =>
                                        handleSocialLinkChange(index, socialIndex, 'url', e.target.value)
                                    }
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Icon Class</FormLabel>
                                <Input
                                    value={social.iconClass}
                                    onChange={(e) =>
                                    handleSocialLinkChange(index, socialIndex, 'iconClass', e.target.value)
                                    }
                                />
                            </FormControl>
                            <FormControl>
                                <Button
                                    variant="outlined"
                                    color="danger"
                                    onClick={() => removeSocialLink(index, socialIndex)}
                                >
                                    Remove Social Link
                                </Button>
                            </FormControl>
                        </Stack>
                      </Sheet>
                    ))}
                    {member.socialLinks.length < 5 && (
                      <Button variant="solid" onClick={() => addSocialLink(index)}>
                        Add Social Link
                      </Button>
                    )}
                  </Stack>
                </CardContent>
                <Button variant="solid" color="danger" onClick={() => removeTeamMember(index)}>
                  Remove Member
                </Button>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Stack spacing={2}>
            <Button variant="solid" fullWidth onClick={addTeamMember}>
            Add Team Member
            </Button>
            <Button variant={'soft'} fullWidth color={'success'} onClick={handleSubmit}>
            Save Features Changes
            </Button>
        </Stack>
      </Sheet>
    </>
  );
};

const mapStateToProps = (store) => ({
  apiKey: store.admin?.apiKey,
  siteId: store.admin.site._id,
  content: store.admin.site.team.body
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateSiteTeamBody
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AdminTeamComponent);