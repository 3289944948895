import React, { useCallback, useEffect, useRef, useState } from 'react';
import { animated, useSpring } from '@react-spring/web';
import { styled, alpha } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import {
  Article as ArticleIcon,
  Delete,
  Delete as DeleteIcon,
  FolderOpen as FolderOpenIcon,
  FolderRounded as FolderRoundedIcon,
  Image as ImageIcon,
  PictureAsPdf as PictureAsPdfIcon,
  VideoCameraBack as VideoCameraBackIcon,
} from '@mui/icons-material';
import { useDrag, useDrop } from 'react-dnd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  TreeItem2Checkbox,
  TreeItem2Content,
  TreeItem2IconContainer,
  TreeItem2Root,
  TreeItem2Icon,
  TreeItem2Provider,
  unstable_useTreeItem2 as useTreeItem2,
  treeItemClasses,
} from '@mui/x-tree-view';
import CustomLabel from './CustomLabel';
import {
  updateBundle,
  mergePDF,
  updateBundleLocally,
  updateDesk,
  updateDeskLocally,
  initializeOperation,
  getOperation,
  deleteItem,
  moveItemsFromDeskToBundle,
  moveItemsFromBundleToDesk,
  moveItemsFromDeskToBundleLocally,
  moveItemsFromBundleToDeskLocally
} from '../redux/actions';
import { Box } from '@mui/material';
import RenameLabelModal from './RenameLabelModal';
import { useSnackbar } from 'notistack';

import '../styles/CustomTreeItem.css'
import { CssVarsProvider, IconButton, Radio, Tooltip } from '@mui/joy';
import { useRavenContext } from './RavenContext';

const StyledTreeItemRoot = styled(TreeItem2Root)(({ theme }) => ({
  // color: theme.palette.mode === 'light' ? theme.palette.grey[800] : theme.palette.grey[400],
  position: 'relative',
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: theme.spacing(3.5),
  },
}));

const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
  flexDirection: 'row-reverse',
  borderRadius: theme.spacing(0.7),
  marginBottom: theme.spacing(0.5),
  marginTop: theme.spacing(0.5),
  padding: theme.spacing(0.5),
  paddingRight: theme.spacing(1),
  fontWeight: 500,
  [`&.Mui-expanded `]: {
    '&:not(.Mui-focused, .Mui-selected, .Mui-selected.Mui-focused) .labelIcon': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.primary.main
          : theme.palette.primary.dark,
    },
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: '16px',
      top: '44px',
      height: 'calc(100% - 48px)',
      width: '1.5px',
      backgroundColor:
        theme.palette.mode === 'light'
          ? theme.palette.grey[300]
          : theme.palette.grey[700],
    },
  },
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    color: theme.palette.mode === 'light' ? theme.palette.primary.main : 'white',
  },
  [`&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused`]: { 
    // Leaving this block empty
  },
}));

const AnimatedCollapse = animated(Collapse);

const TransitionComponent = (props) => {
  const style = useSpring({
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(0,${props.in ? 0 : 20}px,0)`,
    },
  });

  return <AnimatedCollapse style={style} {...props} />;
};

const isExpandable = (reactChildren) => Array.isArray(reactChildren) ? reactChildren.some(isExpandable) : Boolean(reactChildren);

const getIconFromFileType = (type) => {
  const icons = {
    image: ImageIcon,
    pdf: PictureAsPdfIcon,
    doc: ArticleIcon,
    video: VideoCameraBackIcon,
    folder: FolderRoundedIcon,
    pinned: FolderOpenIcon,
    trash: DeleteIcon,
  };
  return icons[type] || ArticleIcon;
};

const Placeholder = styled('div')(({ theme, position }) => ({
  height: position === 'inside' ? '100%' : '2px',
  margin: position === 'before' ? '4px 0 0 0' : '0 0 4px 0',
  backgroundColor: theme.palette.primary.main,
  opacity: 0.5,
}));

const EditorCustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
  const { id, itemId, label, disabled, radioRefs, calculateLineHeights, lineHeights, deskBusy, bundleBusy, deleteItem, deletingItem, children, caseid, moveItemsFromBundleToDesk, moveItemsFromDeskToBundleLocally, itemSpace, moveItemsFromBundleToDeskLocally, user, bundleItems, initializeOperation, moveItemsFromDeskToBundle, getOperation, deskSpaceItems, updateBundle, mergePDF, updateBundleLocally, updateDesk, updateDeskLocally, space, selectedItemId, onRadioChange, pageIndexes, ...other } = props;
  const { getRootProps, getContentProps, getIconContainerProps, getCheckboxProps, getLabelProps, getGroupTransitionProps, status, publicAPI } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

  const item = publicAPI.getItem(itemId);
  const expandable = isExpandable(children);
  const icon = getIconFromFileType(item.type);

  const [dropPosition, setDropPosition] = useState(null);
  const [newItemName, setNewItemName] = useState('');
  const rootRef = useRef(null);
  const [hovering, setHovering] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const { jumpToPage } = useRavenContext();

  const handleRadioChange = (event) => {
    onRadioChange(event.target.value);
    if (item.type !== 'folder' && item.pages && pageIndexes && pageIndexes[item._id]) {
      jumpToPage(pageIndexes[item._id].startingPage - 1);
    }
  };

  const handleDoubleClickOnItem = (event) => {
    event.preventDefault();
    setIsRenaming(true);
  }

  const handleBlur = (event) => {
    setIsRenaming(false);
  }

  const handleRenameItem = (event) => {
    setIsRenaming(false);
    setNewItemName('');
  }

  const handleItemDelete = (event) => {
    deleteItem(caseid, user.apiKey, itemId, enqueueSnackbar)
  }

  const handleItemNameChange = (event) => {
    const key = event.key;
    if ((key >= 'A' && key <= 'Z') || (key >= 'a' && key <= 'z') || (key >= '0' && key <= '9') || key === '_') {
      setNewItemName((value) => value + key);
    } else if (key === 'Backspace') {
      setNewItemName((value) => value.substring(0, value.length - 1));
    }
  }

  const [{ isDragging }, drag] = useDrag({
    type: 'TREE_ITEM',
    // canDrag: () => (space === 'bundle') ? !bundleBusy : !deskBusy,
    item: { itemId, label },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver }, drop] = useDrop({
    accept: 'TREE_ITEM',
    // canDrop: () => (space === 'bundle') ? !bundleBusy : !deskBusy,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    hover: useCallback((droppedItem, monitor) => {
      if (!rootRef.current || droppedItem.itemId === itemId){
        setDropPosition(null);
        return;
      }

      const hoverBoundingRect = rootRef.current.getBoundingClientRect();
      const hoverClientY = monitor.getClientOffset().y - hoverBoundingRect.top;

      if(hoverClientY <= 0 || hoverClientY >= 20) {
        setDropPosition(null);
      } 
      else if(item.type === 'folder') {
        if (hoverClientY < 5) {
          setDropPosition('before');
        } else if (hoverClientY > 15) {
          setDropPosition('after');
        } else {
          setDropPosition('inside');
        }
      }
      else {
        if (hoverClientY < 10) {
          setDropPosition('before');
        } else if (hoverClientY > 10) {
          setDropPosition('after');
        } else {
          setDropPosition(null);
        }
      }
    }, [itemId, rootRef, item.type]),
    drop: (droppedItem) => {
      if (droppedItem && itemSpace === space) {
        let index = 0;
        if (item.parent) {
          const catcher = publicAPI.getItem(item.parent);
          index = catcher.children && catcher.children.length > 0 ? catcher.children.findIndex(value => value._id === itemId) : 0;
        } else if (space === 'bundle') {
          index = bundleItems.findIndex(value => value._id === itemId);
        } else if (space === 'desk') {
          index = deskSpaceItems.findIndex(value => value._id === itemId);
        }

        index = index <= -1 ? 0 : index;

        if (dropPosition === 'before') {
          if (space === 'bundle') {
            updateBundleLocally(droppedItem.itemId, item.parent, index).then(() =>
            updateBundle(caseid, user.apiKey, droppedItem.itemId, item.parent, index).then(() => mergePDF(caseid, user.apiKey)))
          } else if (space === 'desk') {
            updateDeskLocally(droppedItem.itemId, item.parent, index);
            updateDesk(caseid, user.apiKey, droppedItem.itemId, item.parent, index)
          }
        } else if (dropPosition === 'after') {
          if (space === 'bundle') {
            updateBundleLocally(droppedItem.itemId, item.parent, index + 1).then(() => 
            updateBundle(caseid, user.apiKey, droppedItem.itemId, item.parent, index + 1).then(() => mergePDF(caseid, user.apiKey)))
          } else if (space === 'desk') {
            updateDeskLocally(droppedItem.itemId, item.parent, index + 1);
            updateDesk(caseid, user.apiKey, droppedItem.itemId, item.parent, index + 1);
          }
        } else if (dropPosition === 'inside') {
          if (space === 'bundle') {
            updateBundleLocally(droppedItem.itemId, itemId, 0).then(() => 
            updateBundle(caseid, user.apiKey, droppedItem.itemId, itemId, 0).then(() => mergePDF(caseid, user.apiKey)));
          } else if (space === 'desk') {
            updateDeskLocally(droppedItem.itemId, itemId, 0);
            updateDesk(caseid, user.apiKey, droppedItem.itemId, itemId, 0);
          }
        }
      } else if (droppedItem && itemSpace !== space) {
        let index = 0;
        if (item.parent) {
          const catcher = publicAPI.getItem(item.parent);
          index = catcher.children && catcher.children.length > 0 ? catcher.children.findIndex(value => value._id === itemId) : 0;
        } else if (space === 'bundle') {
          index = bundleItems.findIndex(value => value._id === itemId);
        } else if (space === 'desk') {
          index = deskSpaceItems.findIndex(value => value._id === itemId);
        }

        if (dropPosition === 'before') {
          if (space === 'bundle') {
            moveItemsFromDeskToBundleLocally(droppedItem.itemId, item.parent, index);
            moveItemsFromDeskToBundle(caseid, user.apiKey, droppedItem.itemId, item.parent, index).then(() => mergePDF(caseid, user.apiKey));
          } else if (space === 'desk') {
            moveItemsFromBundleToDeskLocally(droppedItem.itemId, item.parent, index);
            moveItemsFromBundleToDesk(caseid, user.apiKey, droppedItem.itemId, item.parent, index).then(() => mergePDF(caseid, user.apiKey));
          }
        } else if (dropPosition === 'after') {
          if (space === 'bundle') {
            moveItemsFromDeskToBundleLocally(droppedItem.itemId, item.parent, index + 1);
            moveItemsFromDeskToBundle(caseid, user.apiKey, droppedItem.itemId, item.parent, index + 1).then(() => mergePDF(caseid, user.apiKey));
          } else if (space === 'desk') {
            moveItemsFromBundleToDeskLocally(droppedItem.itemId, item.parent, index + 1);
            moveItemsFromBundleToDesk(caseid, user.apiKey, droppedItem.itemId, item.parent, index + 1).then(() => mergePDF(caseid, user.apiKey));
          }
        } else if (dropPosition === 'inside') {
          if (space === 'bundle') {
            moveItemsFromDeskToBundleLocally(droppedItem.itemId, itemId, 0);
            moveItemsFromDeskToBundle(caseid, user.apiKey, droppedItem.itemId, itemId, 0).then(() => mergePDF(caseid, user.apiKey));
          } else if (space === 'desk') {
            moveItemsFromBundleToDeskLocally(droppedItem.itemId, itemId, 0);
            moveItemsFromBundleToDesk(caseid, user.apiKey, droppedItem.itemId, itemId, 0).then(() => mergePDF(caseid, user.apiKey));
          }
        }
      }
      setDropPosition(null);
    },
  });

  useEffect(() => {
    if (isDragging) {
      initializeOperation(space);
    }
    setDropPosition(null);
  }, [isDragging]);

  const handleVerticalLine = () => {
    if (typeof calculateLineHeights === "function") {
      calculateLineHeights();
    }
  }

  drag(drop(rootRef));
  
  return (
    <>
    <RenameLabelModal enqueueSnackbar={enqueueSnackbar} open={isRenaming} setOpen={setIsRenaming} item={item} space={space} />
    <TreeItem2Provider itemId={itemId} itemType={item.type}>
      <StyledTreeItemRoot
        {...getRootProps(other)}
        ref={rootRef}
        style={{ opacity: isDragging ? 0.5 : 1, paddingLeft: 0 }}
      >
        {dropPosition === 'before' && isOver && <Placeholder position="before" />}
        <CustomTreeItemContent
          {...getContentProps()}
          style={{
            backgroundColor: dropPosition === 'inside' && isOver ? alpha('#1976d2', 0.1) : 'transparent',
          }}
        >
          <TreeItem2IconContainer {...getIconContainerProps()}>
            <TreeItem2Icon status={status} />
          </TreeItem2IconContainer>
          <TreeItem2Checkbox {...getCheckboxProps()} />
          {(space === 'desk' || item.type == 'folder') ? (
            <Box
              onMouseOver={(e) => setHovering(true)}
              onMouseOut={(e) => setHovering(false)}
              sx={{display: 'flex', height: '20.02px', flexDirection: 'row', justifyContent: 'space-between', width: '100%', margin: 0, padding: 0}}
            >
              <CustomLabel
                isRenaming={isRenaming}
                calculateLineHeights={calculateLineHeights}
                onDoubleClick={handleDoubleClickOnItem}
                {...getLabelProps({ icon, expandable: expandable && status.expanded })}
              />
            {(space === 'desk' && hovering && !deletingItem)?
              <CssVarsProvider>
                <IconButton onClick={handleItemDelete} edge='start' size={'sm'}>
                  <Delete />
                </IconButton>
              </CssVarsProvider>
              : <></>}
            </Box>
          )
          :(
            <Box
              className={`${space}-radio-line`}
              span ref={el => radioRefs.current[itemId] = el}
              sx={{display: 'flex', height: '20.02px', flexDirection: 'row', justifyContent: 'space-between', width: '100%', margin: 0, padding: 0}}
            >
              <CustomLabel isRenaming={isRenaming} calculateLineHeights={calculateLineHeights} onDoubleClick={handleDoubleClickOnItem} {...getLabelProps({ icon, expandable: expandable && status.expanded })} />
              <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative'
              }}>
                {(lineHeights && lineHeights[itemId] && lineHeights[itemId] !== 0) ?(<span className={'spanii'} style={{ height: `${lineHeights[itemId]}px`}} ></span>) : (<></>)}
                <CssVarsProvider>
                  <Radio
                    checked={selectedItemId === itemId}
                    onChange={handleRadioChange}
                    disabled={!(pageIndexes && pageIndexes[itemId])}
                    value={itemId}
                    color={'success'}
                    name="radio-button-item"
                    sx={{zIndex: 7}}
                  />
                </CssVarsProvider>
              </Box>
              
            </Box>
          )}
          
        </CustomTreeItemContent>
        {dropPosition === 'after' && isOver && <Placeholder position="after" />}
        {children && <TransitionComponent {...getGroupTransitionProps()} />}
      </StyledTreeItemRoot>
    </TreeItem2Provider>
    </>
  )
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateBundle,
  mergePDF,
  updateBundleLocally,
  updateDesk,
  updateDeskLocally,
  initializeOperation,
  getOperation,
  deleteItem,
  moveItemsFromDeskToBundle,
  moveItemsFromBundleToDesk,
  moveItemsFromDeskToBundleLocally,
  moveItemsFromBundleToDeskLocally  
}, dispatch);

const mapStateToProps = (store) => ({
  user: store.user,
  caseid: store.caseData._id,
  bundleItems: store.caseData.bundle.items,
  deskSpaceItems: store.caseData.deskSpace.items,
  bundleBusy: store.app.bundleUpdating,
  deskBusy: store.app.creatingFolder,
  pageIndexes: store.app.pageIndexes,
  itemSpace: store.app.space,
  deletingItem: store.app.deletingItem
});

export default connect(mapStateToProps, mapDispatchToProps)(EditorCustomTreeItem);