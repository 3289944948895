import React from "react";

export default function LandingFeatures({ content }) {
  return (
    <section className="service-sec position-relative">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="theme-title z-index-1">
              <h6>{content.title}</h6>
              <h2>{content.subtitle}</h2>
            </div>
          </div>
        </div>
        <div className="row">
          {content.cards.map((feature, index) => (
            <div className={`col-lg-3 col-md-6 ${feature.classes}`} key={index}>
              <div className="featured-item style-1">
                <div className="featured-icon">
                  <img className="img-fluid" src={feature.imageSrc} alt={feature.title} />
                </div>
                <div className="featured-title">
                  <h5>{feature.title}</h5>
                </div>
                <div className="featured-desc">
                  <p>{feature.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
