import React, { useEffect, useState } from 'react';
import {
  CssVarsProvider,
  Sheet,
  FormControl,
  FormLabel,
  Input,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  Container,
  AspectRatio,
  Grid,
  Stack,
  CssBaseline,
  Breadcrumbs,
  Link
} from '@mui/joy';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadAdminSite, updateSiteLandingOveriew } from '../redux/actions/index.js';

import GradientCover from '../components/GradientCover.js';

const AdminLandingOverviewEditor = ({content, apiKey, siteId, updateSiteLandingOveriew}) => {
    const [overview, setOverview] = useState({})

    useEffect(() => {
        setOverview(content);
    }, [content])

    const handleAddListItem = () => {
        setOverview(prev => ({
            ...prev,
            listItems: [...(prev.listItems || []), '']
        }));
    };

    const handleRemoveListItem = (index) => {
        setOverview(prev => ({
            ...prev,
            listItems: prev.listItems.filter((_, i) => i !== index)
        }));
    };

    const handleImageChange = (e, index) => {
        const file = e.target.files[0];
        if (file) {
            setOverview(prev => ({
                ...prev,
                images: prev.images.map((v, i) => index === i ? ({...v, src: URL.createObjectURL(file), file }) : v )
            }))
        }
    }

    const handleSubmit = (event) => {
        const formData = new FormData();
        formData.append('overview', JSON.stringify(overview));

        for(let i=0;i<overview.images.length;i++){
            if (overview.images[i]?.file) {
                formData.append(`image_${i}`, overview.images[i].file);
            }
        }
        updateSiteLandingOveriew(apiKey, siteId, formData)
    }

    return (
        <>
            <Typography level="h4" gutterBottom>
                Overview Section
            </Typography>
            <Sheet sx={{ mb: 3, p: 2, borderRadius: '10px' }} variant={'outlined'}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <FormLabel>Title</FormLabel>
                    <Input
                        value={overview?.title}
                        onChange={(e) => setOverview(prev => ({
                            ...prev,
                            title: e.target.value
                        }))}
                    />
                </FormControl>
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <FormLabel>Subtitle</FormLabel>
                    <Input
                        value={overview?.subtitle}
                        onChange={(e) => setOverview(prev => ({
                            ...prev,
                            subtitle: e.target.value
                        }))}
                    />
                </FormControl>
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <FormLabel>Description</FormLabel>
                    <Input
                        maxRows={5}
                        value={overview?.description}
                        onChange={(e) => setOverview(prev => ({
                            ...prev,
                            description: e.target.value
                        }))}
                    />
                </FormControl>
                <Typography level="h5" gutterBottom>
                    List Items
                </Typography>
                {overview?.listItems?.map((item, index) => (
                    <Box key={index} sx={{ display: 'flex', gap: 2, mb: 2 }}>
                        <FormControl sx={{ flex: 1 }}>
                            <FormLabel>{`Item ${index + 1}`}</FormLabel>
                            <Input
                                value={item}
                                onChange={(e) => setOverview(prev => ({
                                    ...prev,
                                    listItems: prev.listItems.map((v, i) => index === i ? e.target.value : v)
                                }))}
                            />
                        </FormControl>
                        <FormControl sx={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button variant={'outlined'} color={'danger'} onClick={(e) => handleRemoveListItem(index)}>
                                Remove
                            </Button>
                        </FormControl>
                        
                    </Box>
                ))}
                <Button variant="soft" onClick={handleAddListItem}>
                    Add List Item
                </Button>
                <Typography level="h5" gutterBottom sx={{ mt: 3 }}>
                    Images
                </Typography>
                <Grid container spacing={2} columns={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    {overview?.images?.map((image, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <Stack spacing={2}>
                                <AspectRatio ratio={'7/8'}>
                                    <img src={image.src} alt={image.alt} />
                                </AspectRatio>
                                <input type="file" accept="image/*" onChange={(e) => handleImageChange(e, index)} />
                                <FormControl fullWidth>
                                    <FormLabel>Image Alt Text</FormLabel>
                                    <Input
                                        value={image.alt}
                                        onChange={(e) => setOverview(prev => ({
                                            ...prev,
                                            images: prev.images.map((v, i) => index === i ? ({ ...v, alt: e.target.value }) : v)
                                        }))}
                                    />
                                </FormControl>
                            </Stack>
                        </Grid>
                    ))}
                </Grid>
                <Button onClick={handleSubmit} color={'success'} fullWidth variant="soft">Save Overview Changes</Button>
            </Sheet>
        </>
    )
}

const mapStateToProps = (store) => ({
    apiKey: store.admin?.apiKey,
    siteId: store.admin.site._id,
    content: store.admin.site.landing.overview
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateSiteLandingOveriew
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AdminLandingOverviewEditor);