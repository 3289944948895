import React from "react";

export default function LandingFooter({ content }) {
    return (
        <footer className="footer" data-bg-img="images/bg/02.png">
            <div className="container">
                <div className="primary-footer">
                    <div className="row">
                        <div className="col-lg-5 col-md-12">
                            <h5>{content.infoTitle}</h5>
                            <ul className="media-icon list-unstyled mb-8">
                                <li><p className="mb-0">{content.address}</p></li>
                                <li><a href={`mailto:${content.email}`}>{content.email}</a></li>
                                <li><a href={`tel:${content.phone}`}>{content.phone}</a></li>
                            </ul>
                            <h5>{content.socialsTitle}</h5>
                            <ul className="list-inline ps-0 ms-0 footer-social">
                                {content.socials.map((item, index) => (
                                    <li key={index} className="list-inline-item">
                                        <a href={item.link}>
                                            <i className={`bi ${item.icon}`}></i>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="col-lg-7 col-md-12 mt-6 mt-lg-0">
                            <h5>{content.infromationTitle}</h5>
                            <div className="row">
                                <div className="col-lg-4 col-md-4 mt-5 mt-md-0 footer-menu">
                                    <ul className="list-unstyled w-100">
                                        {content.informationLinks.map((link, index) => (
                                            <li key={index}>
                                                <a href={link.link}>{link.text}</a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="col-lg-4 col-md-4 mt-5 mt-md-0 footer-menu">
                                    <ul className="list-unstyled w-100">
                                        {content.blogLinks.map((link, index) => (
                                            <li key={index}>
                                                <a href={link.link}>{link.text}</a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="col-lg-4 col-md-4 mt-5 mt-md-0 footer-menu">
                                    <ul className="list-unstyled">
                                        {content.otherLinks.map((link, index) => (
                                            <li key={index}>
                                                <a href={link.link}>{link.text}</a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="row mt-8">
                                <div className="col-md-10">
                                    <h5>{content.subscribeTitle}</h5>
                                    <div className="subscribe-form">
                                        <form id="mc-form" className="mc-form">
                                            <input type="email" name="EMAIL" className="email" id="mc-email" placeholder="Email Address" required />
                                            <input className="subscribe-btn" type="submit" name="subscribe" value="Subscribe Now" />
                                        </form>
                                        <small className="d-block mt-3">{content.subscribeText}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="secondary-footer">
                <div className="container">
                    <div className="copyright">
                        <div className="row text-center">
                            <div className="col">{content.copyright}</div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
