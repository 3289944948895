import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

const LinearBuffer = ({percentages}) => {
  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(10);

  const progressRef = useRef(() => {});

  useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
        setBuffer(10);
      } else {
        setProgress(percentages);
        const diff = Math.random() * 10;
        setBuffer(progress + diff);
      }
    };
  });

  useEffect(() => {
    setProgress(percentages);
    progressRef.current();
  }, [percentages]);

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress color='inherit' variant="buffer" value={progress} valueBuffer={buffer} />
    </Box>
  );
}

export default LinearBuffer;