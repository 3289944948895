import React, { useCallback, useEffect, useRef, useState } from 'react';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { Toc as TocIcon, CloudUpload as CloudUploadIcon, CreateNewFolder as CreateNewFolderIcon } from '@mui/icons-material';
import { connect } from 'react-redux';
import DropContainer from './DropContainer';
import { bindActionCreators } from 'redux';
import { createNewFolder, importFiles, generateToc, mergePDF } from '../redux/actions';
import { Backdrop, Box, CircularProgress, TextField, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FolderRounded } from '@mui/icons-material';
import NewFolder from './NewFolder';
import { createTheme } from '@mui/material/styles';
import { CssVarsProvider, Divider, IconButton, List, ListItem, ListItemButton, ListSubheader, Typography } from '@mui/joy';
import cssVars from '@mui/system/cssVars';
import EditorCustomTreeItem from './EditorCustomTreeItem';

const xtheme = createTheme({
  cssVars
});

function EditorDeskSpace(props) {
  const { xitems, createNewFolder, caseData, user, importFiles, generateToc } = props;
  const theme = useTheme();
  const [newFolder, setNewFolder] = useState(false);
  const [folderName, setFolderName] = useState('');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const fileInputRef = useRef(null);

  const [xopen, setXOpen] = useState(false);

  const operations = [
    { text: 'Generate Table of Contents',
      disabled: caseData.bundle.items.length === 0,
      icon: <TocIcon />, handleClick: (e) => {
      setXOpen(true);
      generateToc(caseData._id, user.apiKey).then(async () => {
        await mergePDF(caseData._id, user.apiKey, false, true);
        setXOpen(false);
      });
    }},
    { text: 'Import Files', disabled: false, icon: <CloudUploadIcon />, handleClick: () => fileInputRef.current.click() },
    { text: 'Create New Folder', disabled: false, icon: <CreateNewFolderIcon />, handleClick: () => setNewFolder(true) }
  ];

  useEffect(() => { }, [xitems, caseData, user]);

  const handleFileChange = async (event) => {
    const files = event.target.files;
    if (files.length > 10) {
      alert('You can only select up to 10 files.');
      return;
    }

    const selectedFiles = await Array.from(files);
    importFiles(caseData._id, user.apiKey, selectedFiles);

    // Reset file input
    event.target.value = null;
  };

  const handleBlur = useCallback((e) => {
    setNewFolder(false);
    if (e.target.value.length > 0)
      createNewFolder(caseData._id, user.apiKey, e.target.value);

    setFolderName('');
  }, [caseData._id, user.apiKey]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    setNewFolder(false);
    if (e.target.name.value.length > 0)
      createNewFolder(caseData._id, user.apiKey, e.target.name.value);

    setFolderName('');
  }, [caseData._id, user.apiKey])

  const handleChangeText = (e) => setFolderName(e.target.value);
  const handleCanceled = (e) => {
    setFolderName('');
    setNewFolder(false);
  };

  return (
    <CssVarsProvider theme={xtheme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={xopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {newFolder && (
        <NewFolder
          folderName={folderName}
          handleBlur={handleBlur}
          handleChangeText={handleChangeText}
          handleCanceled={handleCanceled}
          handleSubmit={handleSubmit}
        />
      )}
      {xitems && xitems.length > 0 ? (
        <RichTreeView
          items={xitems}
          getItemId={(item) => item._id}
          getItemLabel={(item) => item.name}
          defaultExpandedItems={xitems.filter((value) => value.type==='folder').map(value => value._id)}
          defaultSelectedItems="1.1"
          sx={{ height: 'fit-content', flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
          slots={{ item: (props) => <EditorCustomTreeItem {...props} space={'desk'} />}}
        />
      ) : (
        <>
          {<DropContainer space={'desk'} />}
        </>
      )}
      <CssVarsProvider>
      <Divider />
      <List sx={{flex: 1}}>
        {operations.map((operation, index) => (
          <ListItem key={operation.text} disablePadding sx={{ display: 'block', px: 1 }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: 'initial',
                color: 'inherit',
                p: 0,
              }}
              onClick={operation.handleClick}
              disabled={operation.disabled}
            >
              <IconButton
                sx={{
                  minWidth: 0,
                  mr: 1,
                  justifyContent: 'center',
                  color: 'inherit',
                }}
              >
                {operation.icon}
              </IconButton>
              <Typography sx={{ opacity: 1, color: 'inherit', fontSize: '14px' }} >{operation.text}</Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      </CssVarsProvider>
      <input
        ref={fileInputRef}
        type="file"
        accept=".pdf, .doc, .docx, .html, .htm, .eml, image/*"
        multiple
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
    </CssVarsProvider>
  );
}

const mapStateToProps = (store) => ({
  xitems: store.caseData.deskSpace.items,
  caseData: store.caseData,
  user: store.user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  createNewFolder,
  importFiles,
  generateToc,
  mergePDF
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditorDeskSpace);
