import React, { useEffect, useState } from 'react';
import {
  CssVarsProvider,
  Sheet,
  FormControl,
  FormLabel,
  Input,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  Container,
  AspectRatio,
  Grid,
  Stack,
  CssBaseline,
  Breadcrumbs,
  Link
} from '@mui/joy';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateSiteServicesStats } from '../redux/actions/index.js';

import GradientCover from '../components/GradientCover.js';

const AdminServicesStatsEditor = ({content, apiKey, siteId, updateSiteServicesStats}) => {
    const [stats, setStats] = useState([])

    useEffect(() => {
        setStats(content);
    }, [content])

    const handleAddStat = () => {
        setStats(prevStats => [
            ...prevStats,
            { value: '', unit: '', label: '', _id: { $oid: `${Date.now()}` } }
        ]);
    };

    const handleRemoveStat = (index) => {
        setStats(prevStats => prevStats.filter((_, i) => i !== index));
    };

    const handleSubmit = (event) => {
        updateSiteServicesStats(apiKey, siteId, stats)
    }

    return (
        <>
            <Typography level="h4" gutterBottom>
                Stats
            </Typography>
            <Sheet sx={{ mb: 3, p: 2, borderRadius: '10px' }} variant={'outlined'}>
                <Grid container spacing={2} columns={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    {stats?.map((stat, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Sheet variant={'outlined'} key={stat._id.$oid} sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2, p: 2, borderRadius: '10px' }}>
                                <FormControl fullWidth>
                                    <FormLabel>Value</FormLabel>
                                    <Input
                                        value={stat.value}
                                        onChange={(e) => setStats(prev => prev.map((v, i) => index === i ? ({ ...v, value: e.target.value }) : v))}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <FormLabel>Unit</FormLabel>
                                    <Input
                                        value={stat.unit}
                                        onChange={(e) => setStats(prev => prev.map((v, i) => index === i ? ({ ...v, unit: e.target.value }) : v))}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <FormLabel>Label</FormLabel>
                                    <Input
                                        value={stat.label}
                                        onChange={(e) => setStats(prev => prev.map((v, i) => index === i ? ({ ...v, label: e.target.value }) : v))}
                                    />
                                </FormControl>
                                <Button variant={'outlined'} color={'danger'} onClick={(e) => handleRemoveStat(index)}>
                                    Remove
                                </Button>
                            </Sheet>
                        </Grid>
                    ))}
                </Grid>
                <Stack spacing={2}>
                    <Button variant={'plain'} onClick={handleAddStat} fullWidth>Add Stat</Button>
                    <Button onClick={handleSubmit} color={'success'} fullWidth variant="soft">Save Stats Changes</Button>
                </Stack>
            </Sheet>
        </>
    )
}

const mapStateToProps = (store) => ({
    apiKey: store.admin?.apiKey,
    siteId: store.admin.site._id,
    content: store.admin.site.services.stats
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateSiteServicesStats
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AdminServicesStatsEditor);