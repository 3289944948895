import React, { useEffect, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import CssBaseline from '@mui/material/CssBaseline';

import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import NotFound from './pages/NotFound';
import './App.css';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUserCases, loadUser, loadTheme, getFlaggedCases, getSharedCases, loadAdmin, updateUserStatus, initializeWebSocket, loadActiveSubscriptionPlans } from './redux/actions';
import SignIn from './pages/SignIn';
import Landing from './pages/Landing';
import SignUp from './pages/SignUp';
import Dashboard from './pages/Dashboard';
import EditorPage from './pages/EditorPage';
import MyProfilePage from './pages/MyProfilePage';
import AdminSignIn from './pages/AdminSignIn';
import AdminDashboard from './pages/AdminDashboard';
import AdminAllUsers from './pages/AdminAllUsers';
import AdminHome from './pages/AdminHome';
import InactiveUser from './pages/InactiveUser';
import AdminMessages from './pages/AdminMessages';
import HomePage from './pages/HomePage';
import ScriptLoader from './components/ScriptLoader';
import Notifications from './pages/Notifications';
import Payment from './pages/Payment';
import SubscriptionPlans from './pages/SubscriptionPlans';
import AdminLandingContentEditor from './pages/AdminLandingContentEditor';
import AdminLogs from './pages/AdminLogs';
import AdminAudits from './pages/AdminAudits';
import AdminPayments from './pages/AdminPayments';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import FAQ from './pages/FAQ';
import AboutUs from './pages/AboutUs';
import Services from './pages/Services';
import Pricing from './pages/Pricing';
import ContactUs from './pages/ContactUs';
import Team from './pages/Team';
import AdminEditUser from './pages/AdminEditUser';
import AdminSubscriptions from './pages/AdminSubscriptions';
import Developer from './pages/Developer';
import AdminPrivacyPolicyContentEditor from './pages/AdminPrivacyPolicyContentEditor';
import AdminTermsAndConditionsEditor from './pages/AdminTermsAndConditionsEditor';
import AdminServicesEditor from './pages/AdminServicesEditor';
import AdminContactUsEditor from './pages/AdminContactUsEditor';
import AdminFAQEditor from './pages/AdminFAQEditor';
import AdminTeamEditor from './pages/AdminTeamEditor';
import AdminAboutUsEditor from './pages/AdminAboutUsEditor';
import AdminWebsiteSettings from './pages/AdminWebsiteSettings';


function App(props) {
  const { app, user, getUserCases, loadActiveSubscriptionPlans, admin, loadUser, loadAdmin, loadTheme, getFlaggedCases, getSharedCases, updateUserStatus, initializeWebSocket } = props;
  const [theme, setTheme] = useState(createTheme({palette: {mode: app.theme}}))

  useEffect(()=>{
    const handleTabClose = (event) => {
      event.preventDefault();
      event.returnValue = 'Are you sure?';
      // window.open('/inactivate')
    };

    loadTheme();
    if(user && user._id && user.apiKey && user.cases.length === 0){
      initializeWebSocket(user.apiKey);
      updateUserStatus(user.apiKey, 'active');
      getUserCases(user.apiKey)
      getFlaggedCases(user.apiKey)
      getSharedCases(user.apiKey)
    } else {
      loadUser();
      loadAdmin();
    }
    loadActiveSubscriptionPlans();
    setTheme(value => ({...value, palette: {...value.palette, mode: app.theme}}))

    window.addEventListener('unload', handleTabClose);
    return () => {
      window.removeEventListener('unload', handleTabClose);
    };

  }, [localStorage, user.apiKey, app.theme,])

  if((!user._id || !user.apiKey) && (!admin || !admin._id || !admin.apiKey)){
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <div>
            <Routes>
              <Route exact path="/" element={<HomePage />} />
              <Route path='/home' element={<Landing />}/>
              <Route path='/services' element={<Services />}/>
              <Route path='/pricing' element={<Pricing />}/>
              <Route path='/contactus' element={<ContactUs />}/>
              <Route path='/team' element={<Team />}/>
              <Route path='/privacypolicy' element={<PrivacyPolicy /> } />
              <Route path='/termsandconditions' element={<TermsAndConditions /> } />
              <Route path='/aboutus' element={<AboutUs /> } />
              <Route path='/faq' element={<FAQ /> } />
              <Route path='/signin' element={<SignIn />} />
              <Route path='/signup' element={<SignUp />} />
              <Route path='/admin' element={<AdminSignIn />} />
              <Route path='/dev' element={<Developer />} />
              <Route path='*' element={<NotFound />} />
            </Routes>
          </div>
        </Router>
      </ThemeProvider>
    )
  }

  if(admin && admin._id && admin.apiKey){
    return(
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <div>
            <Routes>
              <Route exact path="/" element={<AdminHome />} />
              <Route path='/dashboard' element={<AdminDashboard />} />
              <Route path='/dashboard/users' element={<AdminAllUsers />} />
              <Route path='/dashboard/users/:id' element={<AdminEditUser />} />
              <Route path='/dashboard/payments' element={<AdminPayments />} />
              <Route path='/dashboard/subscriptions' element={<AdminSubscriptions />} />
              <Route path='/dashboard/messages' element={<AdminMessages />} />
              <Route path='/dashboard/logs' element={<AdminLogs />} />
              <Route path='/dashboard/audits' element={<AdminAudits />} />
              <Route path='/dashboard/site/landing' element={<AdminLandingContentEditor />} />
              <Route path='/dashboard/site/services' element={<AdminServicesEditor />} />
              <Route path='/dashboard/site/contactus' element={<AdminContactUsEditor />} />
              <Route path='/dashboard/site/faq' element={<AdminFAQEditor />} />
              <Route path='/dashboard/site/team' element={<AdminTeamEditor />} />
              <Route path='/dashboard/site/aboutus' element={<AdminAboutUsEditor />} />
              <Route path='/dashboard/site/privacypolicy' element={<AdminPrivacyPolicyContentEditor /> } />
              <Route path='/dashboard/site/termsandconditions' element={<AdminTermsAndConditionsEditor /> } />
              <Route path='/dashboard/settings' element={<AdminWebsiteSettings /> } />
              <Route path='/inactivate' element={<InactiveUser />} />
              <Route path='*' element={<NotFound />} />
            </Routes>
          </div>
        </Router>
      </ThemeProvider>
    )
  }

  if(user?.role != 'Admin' && (!user?.subscription || user.subscription.status === 'inactive')){
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <div>
            <Routes>
              <Route path='*' element={<Navigate to={'/subscriptions'} />} />
              <Route path='/subscriptions' element={<SubscriptionPlans />} />
              <Route path='/payment' element={<Payment />} />
            </Routes>
          </div>
        </Router>
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider theme={theme}>
    <CssBaseline />
      <Router>
        <div>
          <Routes>
            <Route exact path="/" element={<Dashboard />} />
            <Route path="/case/:caseid" element={<EditorPage />} />
            <Route path='/payment' element={<Payment />} />
            <Route path='/myprofile' element={<MyProfilePage />} />
            <Route path='/inactivate' element={<InactiveUser />} />
            <Route path='/notifications' element={<Notifications />} />
            <Route path='*' element={<NotFound />} />
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
}

const mapStateToProps = (store) => ({
  app: store.app,
  admin: store.admin,
  user: store.user
})

const mapDispatchProps = (dispatch) => bindActionCreators({
  getUserCases,
  loadUser,
  loadAdmin,
  loadTheme,
  getFlaggedCases,
  getSharedCases,
  updateUserStatus,
  initializeWebSocket,
  loadActiveSubscriptionPlans
}, dispatch)
export default connect(mapStateToProps, mapDispatchProps)(App);
