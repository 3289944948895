import React, { useState, useEffect, useRef } from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Typography from '@mui/joy/Typography';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab, { tabClasses } from '@mui/joy/Tab';
import Breadcrumbs from '@mui/joy/Breadcrumbs';
import Link from '@mui/joy/Link';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import CardOverflow from '@mui/joy/CardOverflow';

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TextField } from '@mui/material';
import { Avatar, CircularProgress } from '@mui/joy';
import { editUserDetails } from '../redux/actions';
import { useSnackbar } from 'notistack';

function AdminUserEditor({ user, editUserDetails, updatingUserDetails }) {
    const [profileImage, setProfileImage] = useState(null);
    const [firstName, setFirstName] = useState(user?.firstname);
    const [lastName, setLastName] = useState(user?.lastname);
    const [username, setUsername] = useState(user?.username);
    const [businessName, setBusinessName] = useState(user?.businessName);
    const [isDataUpdated, setIsDataUpdated] = useState(false);
    const [imageSrc, setImageSrc] = useState('');
    const fileInputRef = useRef();

    const { enqueueSnackbar } = useSnackbar();

    const handleCanceled = (event) => {
        setFirstName(user?.firstname);
        setLastName(user?.lastname);
        setUsername(user?.username);
        setBusinessName(user?.businessName);
        setProfileImage(user?.profileImage);

        if (fileInputRef.current.value?.length > 0 && user?.profileImage?.data) {
            const base64String = btoa(String.fromCharCode(new Uint8Array(user.profileImage.data)));
            setImageSrc(`data:image/jpeg;base64,${base64String}`);
            fileInputRef.current.value = null;
        }
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setProfileImage(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            if (user?.profileImage?.data) {
                const base64String = btoa(String.fromCharCode(new Uint8Array(user.profileImage.data)));
                setImageSrc(`data:image/jpeg;base64,${base64String}`);
            }
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('firstname', firstName);
        formData.append('lastname', lastName);
        formData.append('username', username);
        formData.append('businessName', businessName);

        if (profileImage && fileInputRef.current.value?.length > 0) {
            formData.append('profileImage', profileImage);
        }
        // editUserDetails(user.apiKey, formData, enqueueSnackbar);
    }

    useEffect(() => {
        if (user?.profileImage?.data && fileInputRef.current.value?.length === 0) {
            try{
                const blob = new Blob([new Uint8Array(user.profileImage.data)], { type: 'image/jpeg' });
                const reader = new FileReader();
                reader.onload = () => {
                    setImageSrc(reader.result);
                };
                reader.readAsDataURL(blob);
            } catch(error){
                console.log(error);
            }
        }
        setFirstName(user?.firstname);
        setLastName(user?.lastname);
        setUsername(user?.username);
        setBusinessName(user?.businessName);
        setProfileImage(user?.profileImage);
        setIsDataUpdated(firstName !== user?.firstname || lastName !== user?.lastname || username !== user?.username || businessName !== user?.businessName || fileInputRef.current.value)
    }, [user, user?.profileImage, firstName, lastName, username, businessName, profileImage, fileInputRef])

    if(!user){
        return (
            <></>
        )
    }

    return (
        <Box sx={{ flex: 1, width: '100%' }}>
        <Box
            sx={{
            position: 'sticky',
            top: { sm: -100, md: -110 },
            bgcolor: 'background.body',
            zIndex: 9995,
            }}
        >
            <Tabs
            defaultValue={0}
            sx={{
                bgcolor: 'transparent',
            }}
            >
            <TabList
                tabFlex={1}
                size="sm"
                sx={{
                pl: { xs: 0, md: 4 },
                justifyContent: 'left',
                [`&& .${tabClasses.root}`]: {
                    fontWeight: '600',
                    flex: 'initial',
                    color: 'text.tertiary',
                    [`&.${tabClasses.selected}`]: {
                    bgcolor: 'transparent',
                    color: 'text.primary',
                    '&::after': {
                        height: '2px',
                        bgcolor: 'primary.500',
                    },
                    },
                },
                }}
            >
                <Tab sx={{ borderRadius: '6px 6px 0 0' }} indicatorInset value={0}>
                    General
                </Tab>
                <Tab sx={{ borderRadius: '6px 6px 0 0' }} indicatorInset value={2}>
                    Plan
                </Tab>
                { user.role === 'Main' &&
                    <Tab sx={{ borderRadius: '6px 6px 0 0' }} indicatorInset value={3}>
                        Additional Users
                    </Tab>
                }
            </TabList>
            </Tabs>
        </Box>
        <form onSubmit={handleSubmit} onAbort={handleCanceled}>
        <Stack
            spacing={4}
            sx={{
            display: 'flex',
            maxWidth: '800px',
            mx: 'auto',
            px: { xs: 2, md: 6 },
            py: { xs: 2, md: 3 },
            }}
        >
            <Card>
            <Box sx={{ mb: 1 }}>
                <Typography level="title-md">User's Personal info</Typography>
                <Typography level="body-sm">
                    Customize how user profile information will apper to the networks.
                </Typography>
            </Box>
            <Divider />
            <Stack
                direction="row"
                spacing={3}
                sx={{ display: { xs: 'none', md: 'flex' }, my: 1 }}
            >
                <Stack direction="column" spacing={1}>
                <AspectRatio
                    ratio="1"
                    maxHeight={200}
                    sx={{ flex: 1, minWidth: 120, borderRadius: '100%' }}
                >
                    <Avatar alt={`${user?.firstname} ${user?.lastname}`} src={imageSrc}>
                        {`${user?.firstname && user?.firstname.length > 0 && user?.firstname[0]}${user?.lastname && user?.lastname.length > 0 && user?.lastname[0]}`}
                    </Avatar>
                </AspectRatio>
                <IconButton
                    aria-label="upload new picture"
                    size="sm"
                    variant="outlined"
                    color="neutral"
                    sx={{
                    bgcolor: 'background.body',
                    position: 'absolute',
                    zIndex: 2,
                    borderRadius: '50%',
                    left: 100,
                    top: 170,
                    boxShadow: 'sm',
                    }}
                    onClick={() => fileInputRef.current.click()}
                >
                    <EditRoundedIcon />
                </IconButton>
                <input
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                />
                </Stack>
                <Stack spacing={2} sx={{ flexGrow: 1 }}>
                <Stack spacing={1}>
                    <FormLabel>Name</FormLabel>
                    <FormControl sx={{ display: { sm: 'flex-column', md: 'flex-row' }, gap: 2 }} >
                        <Input size="sm" placeholder="First name" name='firstname' value={firstName} onChange={e => setFirstName(e.target.value)} />
                    </FormControl>

                    <FormControl>
                        <Input size="sm" placeholder="Last name" sx={{ flexGrow: 1 }} name='lastname' value={lastName} onChange={e => setLastName(e.target.value)} />
                    </FormControl>
                </Stack>
                <Stack direction="row" spacing={2}>
                    <FormControl>
                        <FormLabel>Username</FormLabel>
                        <Input size="sm" name="username" value={username} onChange={e => setUsername(e.target.value)} required />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                        <FormLabel>Email</FormLabel>
                        <Input
                            size="sm"
                            type="email"
                            startDecorator={<EmailRoundedIcon />}
                            placeholder="email"
                            readOnly
                            value={user?.email}
                            sx={{ flexGrow: 1 }}
                        />
                    </FormControl>
                </Stack>
                <div>
                    <FormControl sx={{ display: { sm: 'contents' } }}>
                    <FormLabel>Business Name</FormLabel>
                    <Input
                        name="businessName"
                        label="Business Name"
                        type="text"
                        variant="outlined"
                        fullWidth
                        value={businessName}
                        onChange={(e) => setBusinessName(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    </FormControl>
                </div>
                </Stack>
            </Stack>
            <Stack
                direction="column"
                spacing={2}
                sx={{ display: { xs: 'flex', md: 'none' }, my: 1 }}
            >
                <Stack direction="row" spacing={2}>
                <Stack direction="column" spacing={1}>
                    <AspectRatio
                    ratio="1"
                    maxHeight={108}
                    sx={{ flex: 1, minWidth: 108, borderRadius: '100%' }}
                    >
                    <Avatar alt={`${user?.firstname} ${user?.lastname}`} src={imageSrc}>
                        {`${user?.firstname && user?.firstname.length > 0 && user?.firstname[0]}${user?.lastname && user?.lastname.length > 0 && user?.lastname[0]}`}
                    </Avatar>
                    </AspectRatio>
                    <IconButton
                    aria-label="upload new picture"
                    size="sm"
                    variant="outlined"
                    color="neutral"
                    sx={{
                        bgcolor: 'background.body',
                        position: 'absolute',
                        zIndex: 2,
                        borderRadius: '50%',
                        left: 85,
                        top: 180,
                        boxShadow: 'sm',
                    }}
                    onClick={() => fileInputRef.current.click()}
                    >
                    <EditRoundedIcon />
                    </IconButton>
                </Stack>
                <Stack spacing={1} sx={{ flexGrow: 1 }}>
                    <FormLabel>Name</FormLabel>
                    <Box sx={{
                        display: {
                        sm: 'flex-column',
                        md: 'flex-row',
                        },
                        gap: 2,
                    }}>
                        <FormControl>
                            <Input size="sm" placeholder="First name" name='firstname' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                        </FormControl>
                        <FormControl>
                            <Input size="sm" placeholder="Last name" name="lastname" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                        </FormControl>
                    </Box>
                    
                </Stack>
                </Stack>
                <FormControl>
                <FormLabel>Username</FormLabel>
                <Input size="sm" name="username" value={username} onChange={e => setUsername(e.target.value)} required />
                </FormControl>
                <FormControl sx={{ flexGrow: 1 }}>
                <FormLabel>Email</FormLabel>
                <Input
                    size="sm"
                    type="email"
                    startDecorator={<EmailRoundedIcon />}
                    placeholder="email"
                    readOnly
                    value={user?.email}
                    sx={{ flexGrow: 1 }}
                />
                </FormControl>
                <div>
                <FormControl sx={{ display: { sm: 'contents' } }}>
                    <FormLabel>Business Name</FormLabel>
                    <Input
                        name="businessName"
                        label="Business Name"
                        type="text"
                        variant="outlined"
                        fullWidth
                        value={businessName}
                        onChange={(e) => setBusinessName(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </FormControl>
                </div>
            </Stack>
            <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
                <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                <Button size="sm" variant="outlined" color="neutral" onClick={handleCanceled} disabled={updatingUserDetails}>
                    Cancel
                </Button>
                <Button type='submit' size="sm" variant="solid" disabled={!isDataUpdated || updatingUserDetails}>
                    { updatingUserDetails? <CircularProgress /> : "Save" }
                </Button>
                </CardActions>
            </CardOverflow>
            </Card>
        </Stack>
        </form>
        </Box>
    );
}

const mapStateToProps = (store) => ({
    user: store.admin?.userDetails,
    updatingUserDetails: store.app.updatingUserDetails
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
    editUserDetails
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AdminUserEditor);