import * as constants from '../constants';
const initialState = {
    theme: 'light',
    bundleUpdating: false,
    creatingFolder: false,
    loadingCases: false,
    userLoggingIn: false,
    userSigningUp: false,
    loadingSharedCases: false,
    loadingTrashCases: false,
    updatingUserDetails: false,
    updatingPaginationSettings: false,
    loadingMergedPDF: false,
    deletingCase: false,
    removingCase: false,
    recoveringCase: false,
    sharingCase: false,
    deletingItem: false,
    toolBarBusy: false,
    creatingConnectedUser: false,
    operation: null,
    pagination: false,
    toBeRenamedCaseId: null,
    toBeSharedCaseId: null,
    toBeRecoveredCaseId: null,
    toBeRemovedCaseId: null,
    toBeDeletedCaseId: null,
    pageIndexes: {},
    messages: [],
}

export const app = (state = initialState, action) => {
    switch(action.type){
        case constants.ON_SYSTEM_THEME_CHANGED:
            return{
                ...state,
                theme: action.data,
            }
        case constants.ON_SYSTEM_UPDATING_BUNDLE:
            return{
                ...state,
                bundleUpdating: true,
            }
        case constants.ON_SYSTEM_UPDATED_BUNDLE:
            return{
                ...state,
                bundleUpdating: false,
            }
        case constants.ON_SYSTEM_UPDATING_DESKSPACE:
            return {
                ...state,
                creatingFolder: true,
            }
        case constants.ON_SYSTEM_UPDATED_DESKSPACE:
            return {
                ...state,
                creatingFolder: false,
            }
        case constants.ON_SYSTEM_OPERATION_DRAG:
            return {
                ...state,
                operation: 'DRAG',
            }
        case constants.ON_SYSTEM_OPERATION_MOVE:
            return {
                ...state,
                operation: 'MOVE',
            }
        case constants.ON_SYSTEM_OPERATION_NULL:
            return {
                ...state,
                operation: null,
                space: action.data
            }
        case constants.ON_PAGINATION_ON:
            return {
                ...state,
                pagination: true
            }
        case constants.ON_PAGINATION_OFF:
            return {
                ...state,
                pagination: false
            }
        case constants.ON_SYSTEM_PAGINATION_START:
            return {
                ...state,
                toolBarBusy: true
            }
        case constants.ON_SYSTEM_PAGINATION_DONE:
            return {
                ...state,
                toolBarBusy: false
            }
        case constants.ON_SYSTEM_LOADING_CASES:
            return {
                ...state,
                loadingCases: true
            }
        case constants.ON_SYSTEM_LOADED_CASES:
            return {
                ...state,
                loadingCases: false
            }
        case constants.ON_SYSTEM_LOADING_MERGED_PDF:
            return {
                ...state,
                loadingMergedPDF: true
            }
        case constants.ON_SYSTEM_LOADED_MERGED_PDF:
            return {
                ...state,
                loadingMergedPDF: false
            }
        case constants.ON_SYSTEM_DOCUMENTS_INDEXES_CALCULATED:
            return {
                ...state,
                pageIndexes: action.data
            }
        case constants.ON_SYSTEM_DELETING_CASE:
            return {
                ...state,
                deletingCase: true
            }
        case constants.ON_SYSTEM_DELETED_CASE:
            return {
                ...state,
                deletingCase: false
            }
        case constants.ON_SYSTEM_REMOVING_CASE:
            return {
                ...state,
                removingCase: true
            }
        case constants.ON_SYSTEM_REMOVED_CASE:
            return {
                ...state,
                removingCase: false
            }
        case constants.ON_SYSTEM_RECOVERING_CASE:
            return {
                ...state,
                recoveringCase: true
            }
        case constants.ON_SYSTEM_RECOVERED_CASE:
            return {
                ...state,
                recoveringCase: false
            }
        case constants.ON_SYSTEM_SHARING_CASE:
            return {
                ...state,
                sharingCase: true
            }
        case constants.ON_SYSTEM_SHARED_CASE:
            return {
                ...state,
                sharingCase: false
            }
        case constants.ON_SYSTEM_DELETING_ITEM:
            return {
                ...state,
                deletingItem: true
            }
        case constants.ON_SYSTEM_DELETED_ITEM:
            return {
                ...state,
                deletingItem: false
            }
        case constants.ON_TOGGLE_THEME:
            return {
                ...state,
                theme: state.theme === 'dark'? 'light' : 'dark'
            }
        case constants.ON_THEME_LOADED:
            return {
                ...state,
                theme: action.data
            }
        case constants.SET_TO_BE_RENAMED_CASE_ID:
            return {
                ...state,
                toBeRenamedCaseId: action.data
            }
        case constants.CLEAR_TO_BE_RENAMED_CASE_ID:
            return {
                ...state,
                toBeRenamedCaseId: null,
            }
        case constants.SET_TO_BE_SHARED_CASE_ID:
            return {
                ...state,
                toBeSharedCaseId: action.data
            }
        case constants.CLEAR_TO_BE_SHARED_CASE_ID:
            return {
                ...state,
                toBeSharedCaseId: null,
            }
        case constants.SET_TO_BE_DELETED_CASE_ID:
            return {
                ...state,
                toBeDeletedCaseId: action.data
            }
        case constants.CLEAR_TO_BE_DELETED_CASE_ID:
            return {
                ...state,
                toBeDeletedCaseId: null,
            }
        case constants.SET_TO_BE_REMOVED_CASE_ID:
            return {
                ...state,
                toBeRemovedCaseId: action.data
            }
        case constants.CLEAR_TO_BE_REMOVED_CASE_ID:
            return {
                ...state,
                toBeRemovedCaseId: null
            }
        case constants.SET_TO_BE_RECOVERED_CASE_ID:
            return {
                ...state,
                toBeRecoveredCaseId: action.data
            }
        case constants.CLEAR_TO_BE_RECOVERED_CASE_ID:
            return {
                ...state,
                toBeRecoveredCaseId: null
            }
        case constants.ON_SYSTEM_LOADING_TRASH_CASES:
            return {
                ...state,
                loadingTrashCases: true
            }
        case constants.ON_SYSTEM_LOADED_TRASH_CASES:
            return {
                ...state,
                loadingTrashCases: false
            }
        case constants.ON_SYSTEM_LOADING_SHARED_CASES:
            return {
                ...state,
                loadingSharedCases: true
            }
         case constants.ON_SYSTEM_LOADED_SHARED_CASES:
            return {
                ...state,
                loadingSharedCases: false
            }
        case constants.ON_SYSTEM_UPDATING_USER_DETAILS:
            return {
                ...state,
                updatingUserDetails: true
            }
        case constants.ON_SYSTEM_UPDATED_USER_DETAILS:
            return {
                ...state,
                updatingUserDetails: false
            }
        case constants.ON_SYSTEM_USER_LOGGING_IN:
            return {
                ...state,
                userLoggingIn: true
            }
        case constants.ON_SYSTEM_USER_LOGGED_IN:
            return {
                ...state,
                userLoggingIn: false
            }
        case constants.ON_SYSTEM_USER_SIGNING_UP:
            return {
                ...state,
                userSigningUp: true
            }
        case constants.ON_SYSTEM_USER_SIGNED_UP:
            return {
                ...state,
                userSigningUp: false
            }
        case constants.ON_SYSTEM_PAGINATION_SETTINGS_UPDATING:
            return {
                ...state,
                updatingPaginationSettings: true
            }
        case constants.ON_SYSTEM_PAGINATION_SETTINGS_UPDATED:
            return {
                ...state,
                updatingPaginationSettings: false
            }
        case constants.ON_MESSAGES_LOADED:
            return {
                ...state,
                messages: action.data
            }
        case constants.ON_SYSTEM_CREATING_CONNECTED_USER:
            return {
                ...state,
                creatingConnectedUser: true
            }
        case constants.ON_SYSTEM_CONNECTED_USER_CREATED:
            return {
                ...state,
                creatingConnectedUser: false
            }
        case constants.ON_SYSTEM_CLEAN:
            return initialState;
        default:
            return state;
    }
}