import React, {useState, useEffect} from 'react';
import { useColorScheme } from '@mui/joy/styles';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Avatar from '@mui/joy/Avatar';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import ListDivider from '@mui/joy/ListDivider';
import Drawer from '@mui/joy/Drawer';
import ModalClose from '@mui/joy/ModalClose';
import DialogTitle from '@mui/joy/DialogTitle';

import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import LocalFireDepartmentRoundedIcon from '@mui/icons-material/LocalFireDepartmentRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';


import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  renameCase,
  changeTheme,
  mergePDF,
  createNewCase,
  generateToc,
  toggleCaseFavorite,
  logout,
  toggleTheme
} from '../redux/actions/index.js';
import { Link, useNavigate } from 'react-router-dom';

function ColorSchemeToggle() {
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);
  React.useEffect(() => {
    setMounted(true);
  }, []);
  if (!mounted) {
    return <IconButton size="sm" variant="outlined" color="primary" />;
  }
  return (
    <Tooltip title="Change theme" variant="outlined">
      <IconButton
        id="toggle-mode"
        size="sm"
        variant="plain"
        color="neutral"
        sx={{ alignSelf: 'center' }}
        onClick={() => {
          if (mode === 'light') {
            setMode('dark');
          } else {
            setMode('light');
          }
        }}
      >
        {mode === 'light' ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
      </IconButton>
    </Tooltip>
  );
}

function LandingHeader({ content }) {
  return (
    <header id="site-header" className="header">
        <div id="header-wrap">
            <div className="container">
            <div className="row">
                <div className="col">
                {/* <!-- Navbar --> */}
                <nav className="navbar navbar-expand-lg">
                    <a className="navbar-brand logo" href="/">
                    <span>{content.brandName.split(' ')[0]}</span>{content.brandName.split(' ')[1]}.
                    </a>
                    <button className="navbar-toggler ht-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <svg width="100" height="100" viewBox="0 0 100 100">
                        <path className="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"></path>
                        <path className="line line2" d="M 20,50 H 80"></path>
                        <path className="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"></path>
                    </svg>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                    {/* <!-- Left nav --> */}
                    <ul className="nav navbar-nav mx-auto">
                        {/* <!-- Home --> */}
                        {content.mainPages.map((page, index) => (
                          <li className="nav-item" key={index}>
                            <a className={`nav-link ${index === 0? 'active' : ''}`} href={page.href}>{page.text}</a>
                          </li>
                        ))}
                        <li className="nav-item dropdown">
                          <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">{content.otherPages.title}</a>
                          <ul className="dropdown-menu">
                            {content.otherPages.listItems.map((item, index) => (
                              <li key={index}>
                                <a href={item.href}>{item.text}</a>
                              </li>
                            ))}
                          </ul>
                        </li>
                    </ul>
                    </div>
                    <a className="themeht-btn dark-btn" href={content.button.href}>{content.button.text}</a>
                </nav>
                </div>
            </div>
            </div>
        </div>
        </header>
  );
}

const mapStateToProps = (store) => ({
  caseData: store.caseData,
  user: store.user,
  bundleBusy: store.app.bundleUpdating,
  deskBusy: store.app.creatingFolder,
  toolBarBusy: store.app.toolBarBusy,
})
const mapDispatchProps = (dispatch) => bindActionCreators({
  renameCase,
  changeTheme,
  mergePDF,
  createNewCase,
  generateToc,
  toggleCaseFavorite,
  logout,
  toggleTheme
}, dispatch);
export default connect(mapStateToProps, mapDispatchProps)(LandingHeader);