import React, { useEffect } from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Breadcrumbs from '@mui/joy/Breadcrumbs';
import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import LogoutIcon from '@mui/icons-material/Logout';

import Sidebar from '../components/AdminSidebar';
import UserTable from '../components/AdminUserTable';
import UserList from '../components/AdminUserList';
import Header from '../components/AdminHeader';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadDashboardMetrics, loadUsers } from '../redux/actions';
import AdminHomeComponent from '../components/AdminHomeComponent';

function AdminAllUsers(props) {
  const { admin, loadDashboardMetrics } = props;

  useEffect(() => {
    loadDashboardMetrics(admin.apiKey);
  }, [])

  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
        <Header />
        <Sidebar selectedTab={'home'} />
        <Box
          component="main"
          className="MainContent"
          sx={{
            px: { xs: 2, md: 6 },
            pt: {
              xs: 'calc(12px + var(--Header-height))',
              sm: 'calc(12px + var(--Header-height))',
              md: 3,
            },
            pb: { xs: 2, sm: 2, md: 3 },
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            minWidth: 0,
            height: '100dvh',
            gap: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              mb: 1,
              gap: 1,
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: { xs: 'start', sm: 'center' },
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            <Typography level="h2" component="h1">
              {`Welcome, ${admin?.firstname}`}
            </Typography>
            <Button
              color="primary"
              startDecorator={<LogoutIcon />}
              size="sm"
            >
              Log out
            </Button>
          </Box>
          <AdminHomeComponent />
        </Box>
      </Box>
    </CssVarsProvider>
  );
}

const mapStateToProps = (store) => ({
  admin: store.admin
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
  loadUsers,
  loadDashboardMetrics
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AdminAllUsers);