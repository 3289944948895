import React, { useEffect } from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Breadcrumbs from '@mui/joy/Breadcrumbs';
import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

import Sidebar from '../components/AdminSidebar';
import Header from '../components/AdminHeader';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { adminLoadSubscriptionPlans } from '../redux/actions';
import { FocusTrap } from '@mui/base/FocusTrap';
import AddIcon from '@mui/icons-material/Add';
import AdminSubscriptionList from '../components/AdminSubscriptionList';
import AdminSubscriptionTable from '../components/AdminSubscriptionTable';
import AdminNewSubscriptionPlan from '../components/AdminNewSubscriptionPlan';

function AdminSubscriptions(props) {
  const { apiKey, adminLoadSubscriptionPlans } = props;
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    adminLoadSubscriptionPlans(apiKey, 1, 20);
  }, [])

  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
        <Header />
        <Sidebar selectedTab={'subscriptions'} />
        <Box
          component="main"
          className="MainContent"
          sx={{
            px: { xs: 2, md: 6 },
            pt: {
              xs: 'calc(12px + var(--Header-height))',
              sm: 'calc(12px + var(--Header-height))',
              md: 3,
            },
            pb: { xs: 2, sm: 2, md: 3 },
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            minWidth: 0,
            height: '100dvh',
            gap: 1,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Breadcrumbs
              size="sm"
              aria-label="breadcrumbs"
              separator={<ChevronRightRoundedIcon fontSize="sm" />}
              sx={{ pl: 0 }}
            >
              <Link
                underline="none"
                color="neutral"
                href="/"
                aria-label="Home"
              >
                <HomeRoundedIcon />
              </Link>
              <Link
                underline="hover"
                color="neutral"
                href="/dashboard"
                fontSize={12}
                fontWeight={500}
              >
                Dashboard
              </Link>
              <Typography color="primary" fontWeight={500} fontSize={12}>
                Subscriptions
              </Typography>
            </Breadcrumbs>
          </Box>
          <Box
            sx={{
              display: 'flex',
              mb: 1,
              gap: 1,
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: { xs: 'start', sm: 'center' },
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            <Typography level="h2" component="h1">
              Manage Subscription Plans
            </Typography>
            <Button
              size="sm"
              startDecorator={<AddIcon />}
              onClick={() => setOpen(true)}
              sx={{ ml: 'auto' }}
            >
              Add Plan
            </Button>
            <FocusTrap open={open} disableAutoFocus disableEnforceFocus>
              <AdminNewSubscriptionPlan open={open} onClose={() => setOpen(false)} />
            </FocusTrap>
          </Box>
          <AdminSubscriptionList />
          <AdminSubscriptionTable />
        </Box>
      </Box>
    </CssVarsProvider>
  );
}

const mapStateToProps = (store) => ({
  apiKey: store.admin?.apiKey
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
  adminLoadSubscriptionPlans
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AdminSubscriptions);