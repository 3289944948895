import React, { useEffect, useState } from 'react';
import {
  CssVarsProvider,
  Sheet,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  Container,
  AspectRatio,
  Grid,
  Stack,
  CssBaseline,
  Breadcrumbs,
  Link
} from '@mui/joy';

import Sidebar from '../components/AdminSidebar';
import Header from '../components/AdminHeader';

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadAdminSite } from '../redux/actions/index.js';
import AdminTeamComponent from '../components/AdminTeamComponent.js';


const EditorComponent = (props) => {
    const { site } = props;
    const [content, setContent] = useState(site);

    useEffect(() => {
        setContent(site)
    }, [site])

    if(!content || !content.team){
        return (
            <CssVarsProvider>
                <Container>
                    <Typography>Loading...</Typography>
                </Container>
            </CssVarsProvider>
            
        )
    }

    return (
        <CssVarsProvider>
            <Sheet sx={{py: 2, overflowY: 'scroll'}} variant={'plain'}>
              <AdminTeamComponent />
            </Sheet>
        </CssVarsProvider>
    );
};

function AdminTeamEditor(props) {
    const { site, loadAdminSite } = props;
    const name = 'Raven3';

    useEffect(() => {
        loadAdminSite(name);
    }, [loadAdminSite, name])

  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
        <Header />
        <Sidebar selectedTab={'team-page'} />
        <Box
          component="main"
          className="MainContent"
          sx={{
            px: { xs: 2, md: 6 },
            pt: {
              xs: 'calc(12px + var(--Header-height))',
              sm: 'calc(12px + var(--Header-height))',
              md: 3,
            },
            pb: { xs: 2, sm: 2, md: 3 },
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            minWidth: 0,
            height: '100dvh',
            gap: 1,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Breadcrumbs
              size="sm"
              aria-label="breadcrumbs"
              separator={<ChevronRightRoundedIcon fontSize="sm" />}
              sx={{ pl: 0 }}
            >
              <Link
                underline="none"
                color="neutral"
                href="#some-link"
                aria-label="Home"
              >
                <HomeRoundedIcon />
              </Link>
              <Link
                underline="hover"
                color="neutral"
                href="/dashboard"
                fontSize={12}
                fontWeight={500}
              >
                Dashboard
              </Link>
              <Link
                underline="hover"
                color="neutral"
                href="#"
                fontSize={12}
                fontWeight={500}
              >
                Site
              </Link>
              <Typography color="primary" fontWeight={500} fontSize={12}>
                Team
              </Typography>
            </Breadcrumbs>
          </Box>
          <Box
            sx={{
              display: 'flex',
              mb: 1,
              gap: 1,
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: { xs: 'start', sm: 'center' },
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            <Typography level="h2" component="h1">
              Team Page Editor
            </Typography>
          </Box>
          <EditorComponent site={site} />
        </Box>
      </Box>
    </CssVarsProvider>
  );
}

const mapStateToProps = (store) => ({
  site: store.admin.site
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
  loadAdminSite
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AdminTeamEditor);
