import React from "react";

export default function LandingServices({ content }) {

  return (
    <section>
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-lg-8 col-md-12">
            <div className="theme-title">
              <h6>{content.title}</h6>
              <h2>{content.subtitle}</h2>
            </div>
          </div>
        </div>
        <div className="row gx-lg-5 text-center">
          {content.cards.map((service, index) => (
            <div className={`col-lg-4 col-md-6 ${service.classes}`} key={index}>
              <div className="service-item style-1">
                <div className="service-images">
                  <img className="img-fluid" src={service.imageSrc} alt={service.title} />
                </div>
                <div className="service-desc">
                  <div className="service-title">
                    <h4>{service.title}</h4>
                  </div>
                  <p>{service.description}</p>
                  <a className="service-btn" href={service.link}>
                    <i className="bi bi-arrow-right-short"></i>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
