import React from 'react';
import { Box, Button, CssVarsProvider, Typography } from '@mui/joy'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

function NotFound({ user, admin }) {

  if(!user?._id && !admin?._id){
    return (
      <CssVarsProvider>
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'initial'}}>
          <Typography sx={{color: 'initial'}}>SESSION TIME OUT!</Typography>
          <Link to={'/'}>
            <Button>HOME</Button>
          </Link>
        </Box>
      </CssVarsProvider>
    )
  }
  
  return (
    <CssVarsProvider>
      <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'initial'}}>
        <Typography sx={{color: 'initial'}}>404 - NOT FOUND!</Typography>
        {/* <Link to={'/'}>
          <Button>HOME</Button>
        </Link> */}
      </Box>
    </CssVarsProvider>
  )
}

const mapStateToProps = (store) => ({
  user: store.user,
  admin: store.admin
})

export default connect(mapStateToProps, null)(NotFound);
