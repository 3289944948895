import React, { useState, useEffect } from 'react';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import Stack from '@mui/joy/Stack';
import { connect } from 'react-redux';
import { clearToBeRenamedCaseId, renameCase } from '../redux/actions';
import { useSnackbar } from 'notistack';
import { bindActionCreators } from 'redux';
import { CssVarsProvider } from '@mui/joy';

function RenameCaseModal(props) {
    const { open, setOpen, bundle, caseid, apiKey, currentCaseName, renameCase, setBackDropOpen, clearToBeRenamedCaseId } = props;

    const { enqueueSnackbar } = useSnackbar();

    const handleClose = () => {
        setBackDropOpen(false);
        clearToBeRenamedCaseId();
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setOpen(false);
        setBackDropOpen(true);
        const { value } = event.target.name;
        if(value && value.length > 0 && value !== currentCaseName){
            renameCase(caseid, apiKey, event.target.name.value, enqueueSnackbar, handleClose);
        }
    } 

    return (
        <CssVarsProvider>
        <Modal open={open} onClose={() => setOpen(false)}>
            <ModalDialog>
            <DialogTitle>Rename {bundle?.name} bundle</DialogTitle>
            <DialogContent>To rename this Bundle/Case, please enter your new name here.</DialogContent>
            <form onSubmit={handleSubmit} >
                <Stack spacing={2}>
                <FormControl>
                    <FormLabel>Name</FormLabel>
                    <Input name='name' autoFocus required />
                </FormControl>
                <Button type="submit">Rename</Button>
                </Stack>
            </form>
            </ModalDialog>
        </Modal>
        </CssVarsProvider>
    );
}

const mapStateToProps = (store) => ({
  apiKey: store.user.apiKey,
  caseid: store.app.toBeRenamedCaseId
})
const mapDispatchProps = (dispatch) => bindActionCreators({
  renameCase,
  clearToBeRenamedCaseId
}, dispatch);
export default connect(mapStateToProps, mapDispatchProps)(RenameCaseModal);