import React, {useState, useEffect} from 'react';
import { useColorScheme } from '@mui/joy/styles';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Avatar from '@mui/joy/Avatar';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import ListDivider from '@mui/joy/ListDivider';
import Drawer from '@mui/joy/Drawer';
import ModalClose from '@mui/joy/ModalClose';
import DialogTitle from '@mui/joy/DialogTitle';

import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import LocalFireDepartmentRoundedIcon from '@mui/icons-material/LocalFireDepartmentRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import NotificationsIcon from '@mui/icons-material/Notifications';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import CampaignIcon from '@mui/icons-material/Campaign';
import DangerousIcon from '@mui/icons-material/Dangerous';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';


import Navigation from './DashNavigation';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  renameCase,
  changeTheme,
  mergePDF,
  createNewCase,
  generateToc,
  toggleCaseFavorite,
  logout,
  toggleTheme
} from '../redux/actions/index.js';
import { Link, useNavigate } from 'react-router-dom';
import { Badge, Divider } from '@mui/joy';
import { timeAgo, truncateString } from '../utils/index.js';

function ColorSchemeToggle() {
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);
  React.useEffect(() => {
    setMounted(true);
  }, []);
  if (!mounted) {
    return <IconButton size="sm" variant="outlined" color="primary" />;
  }
  return (
    <Tooltip title="Change theme" variant="outlined">
      <IconButton
        id="toggle-mode"
        size = 'sm'
        variant="plain"
        color="neutral"
        sx={{ alignSelf: 'center' }}
        onClick={() => {
          if (mode === 'light') {
            setMode('dark');
          } else {
            setMode('light');
          }
        }}
      >
        {mode === 'light' ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
      </IconButton>
    </Tooltip>
  );
}

function DashHeader(props) {
  const { user, logout, messages, selectedOption, setSelectedOption, setOpenCreateNewBundleModal, searchText, setSearchText, setBackDropOpen, selected, setSelected } = props;

  const [open, setOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const navigate = useNavigate();
  const company = "CaseGenie";

  useEffect(() => {
    if (user?.profileImage?.data) {
      try{
        const blob = new Blob([new Uint8Array(user?.profileImage.data)], { type: 'image/jpeg' });
        const reader = new FileReader();
        reader.onload = () => {
            setImageSrc(reader.result);
        };
        reader.readAsDataURL(blob);
      } catch(error){
        console.log(error);
      }
    }
  }, [user?.profileImage])

  const handleLogut = () => {
    logout(navigate);
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
      }}
    >
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{ display: { xs: 'none', sm: 'flex' } }}
      >
        <IconButton
          size="md"
          variant="outlined"
          color="neutral"
          sx={{
            display: { xs: 'none', sm: 'inline-flex' },
            borderRadius: '50%',
          }}
        >
          <LocalFireDepartmentRoundedIcon />
        </IconButton>
        <Button
          variant="plain"
          color="neutral"
          component="a"
          href="#"
          size="sm"
          aria-pressed={selectedOption === 1}
          onClick={(e) =>setSelectedOption(1) }
          sx={{ alignSelf: 'center' }}
        >
          All
        </Button>
        <Button
          variant="plain"
          color="neutral"
          component="a"
          href="#"
          size="sm"
          onClick={(e) =>setSelectedOption(2) }
          aria-pressed={selectedOption === 2}
          sx={{ alignSelf: 'center' }}
        >
          Favorites
        </Button>
        <Button
          variant="plain"
          color="neutral"
          onClick={(e) =>setSelectedOption(3) }
          aria-pressed={selectedOption === 3}
          component="a"
          href="#"
          size="sm"
          sx={{ alignSelf: 'center' }}
        >
          Table
        </Button>
      </Stack>
      <Box sx={{ display: { xs: 'inline-flex', sm: 'none' } }}>
        <IconButton variant="plain" color="neutral" onClick={() => setOpen(true)}>
          <MenuRoundedIcon />
        </IconButton>
        <Drawer
          sx={{ display: { xs: 'inline-flex', sm: 'none' } }}
          open={open}
          onClose={() => setOpen(false)}
        >
          <ModalClose />
          <DialogTitle>{company}</DialogTitle>
          <Box sx={{ px: 1 }}>
            <Navigation setBackDropOpen={setBackDropOpen} setDrawerOpen={setOpen} setOpenCreateNewBundleModal={setOpenCreateNewBundleModal} selected={selected} setSelected={setSelected} />
          </Box>
        </Drawer>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1.5,
          alignItems: 'center',
        }}
      >
        <Input
          size="sm"
          variant="outlined"
          placeholder="Search Bundles"
          value={searchText || ''}
          onChange={(e) => setSearchText(e.target.value)}
          startDecorator={<SearchRoundedIcon color="primary" />}
          // endDecorator={
          //   <IconButton
          //     variant="outlined"
          //     color="neutral"
          //     sx={{ bgcolor: 'background.level1' }}
          //   >
          //     <Typography level="title-sm" textColor="text.icon">
          //       ⌘ K
          //     </Typography>
          //   </IconButton>
          // }
          sx={{
            alignSelf: 'center',
            display: {
              xs: 'none',
              sm: 'flex',
            },
          }}
        />
        <IconButton
          size="sm"
          variant="outlined"
          color="neutral"
          sx={{ display: { xs: 'none', sm: 'none' }, alignSelf: 'center' }}
        >
          <SearchRoundedIcon />
        </IconButton>
        <Dropdown>
          <Tooltip title="Notifications" variant="outlined">
            <MenuButton variant="plain">
              <Badge color={'warning'} size="sm">
                <NotificationsIcon />
              </Badge>
            </MenuButton>
          </Tooltip>
          <Menu
            placement="bottom-end"
            size="sm"
            sx={{
              zIndex: '99999',
              p: 1,
              gap: 1,
              '--ListItem-radius': 'var(--joy-radius-sm)',
            }}
          >
            { messages.map((message, index) =>
              (
                <>
                  <MenuItem sx={{maxWidth: '400px'}}>
                    <Stack spacing={1} direction={'row'}>
                      {{
                        info: <InfoIcon color={'primary'} />,
                        warning: <WarningIcon color={'warning'} />,
                        alert: <CampaignIcon color={'success'} />,
                        error: <DangerousIcon sx={{color: 'maroon'}}/>,
                        critical: <PriorityHighIcon sx={{color: 'goldenrod'}} />
                      }[message.type]}
                      <Stack spacing={1}>
                        <Typography fontSize={12} fontWeight={'bold'}>{message.title}</Typography>
                        <Typography fontSize={10}>{truncateString (message.content, 165)}</Typography>
                        <Typography sx={{alignSelf: 'end'}} fontSize={9} fontWeight={'bold'}>{timeAgo (message.scheduleTime)}</Typography>
                      </Stack>
                    </Stack>                    
                  </MenuItem>
                  {index !== messages.length - 1 && <Divider />}
                </>
              )
            )}
          </Menu>
        </Dropdown>
        <ColorSchemeToggle />
        <Dropdown>
          <MenuButton
            variant="plain"
            size="sm"
            sx={{ maxWidth: '32px', maxHeight: '32px', borderRadius: '9999999px' }}
          >
            <Avatar alt={`${user?.firstname} ${user?.lastname}`} src={imageSrc} sx={{ maxWidth: '32px', maxHeight: '32px' }}>
              {`${user?.firstname[0]}${user?.lastname[0]}`}
            </Avatar>
          </MenuButton>
          <Menu
            placement="bottom-end"
            size="sm"
            sx={{
              zIndex: '99999',
              p: 1,
              gap: 1,
              '--ListItem-radius': 'var(--joy-radius-sm)',
            }}
          >
            <MenuItem>
            <Link to={'/myprofile'} style={{textDecoration: 'none' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Avatar alt={`${user?.firstname} ${user?.lastname}`} src={imageSrc} sx={{ borderRadius: '50%' }}>
                  {`${user?.firstname[0]}${user?.lastname[0]}`}
                </Avatar>
                <Box sx={{ ml: 1.5 }}>
                  <Typography level="title-sm" textColor="text.primary">
                    {`${user?.firstname} ${user?.lastname}`}
                  </Typography>
                  <Typography level="body-xs" textColor="text.tertiary">
                    {user?.email}
                  </Typography>
                </Box>
              </Box>
            </Link>
            </MenuItem>
            <ListDivider />
            <Link to={'https://www.fiverr.com/tfraven'} style={{textDecoration: 'none'}}>
            <MenuItem>
              <HelpRoundedIcon />
              Help
            </MenuItem>
            </Link>
            <Link  to={'/myprofile'} style={{ textDecoration: 'none' }}>
              <MenuItem>
                <SettingsRoundedIcon />
                Settings
              </MenuItem>
            </Link>
            <ListDivider />
            <MenuItem onClick={handleLogut}>
              <LogoutRoundedIcon />
              Log out
            </MenuItem>
          </Menu>
        </Dropdown>
      </Box>
    </Box>
  );
}

const mapStateToProps = (store) => ({
  caseData: store.caseData,
  user: store.user,
  bundleBusy: store.app.bundleUpdating,
  deskBusy: store.app.creatingFolder,
  toolBarBusy: store.app.toolBarBusy,
  messages: store.app.messages
})
const mapDispatchProps = (dispatch) => bindActionCreators({
  renameCase,
  changeTheme,
  mergePDF,
  createNewCase,
  generateToc,
  toggleCaseFavorite,
  logout,
  toggleTheme
}, dispatch);
export default connect(mapStateToProps, mapDispatchProps)(DashHeader);