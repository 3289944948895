import React, { useEffect, useState } from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Sheet from '@mui/joy/Sheet';
import IconButton, { iconButtonClasses } from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import CloseIcon from '@mui/icons-material/Close';
import CloseRounded from '@mui/icons-material/CloseRounded';

import AdminSubscriptionRows from './AdminSubscriptionRows';

import { range } from '../utils';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { adminLoadSubscriptionPlans, loadSearchedSubscriptions } from '../redux/actions';

function AdminSubscriptionTable(props) {
  const { subscriptionPlansData: { plans, currentPage, totalPages, totalPlans }, searchedSubscriptionPlansData: { plans: splans, currentPage: scurrentPage, totalPages: stotalPages, totalPlans: stotalPlans }, adminLoadSubscriptionPlans, apiKey, loadSearchedSubscriptions } = props;
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);

  const [search, setSearch] = useState('');
  const [searchSubmit, setSearchSubmit] = useState(false);
  const [wplans, setWPlans] = useState([]);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [status, setStatus] = useState('*');
  const [subscriptionType , setSubscriptionType] = useState('*');
  const [renewalInterval , setRenewalInterval] = useState('*');

  const renderFilters = () => (
    <React.Fragment>
      <FormControl size="sm">
        <FormLabel>Status</FormLabel>
        <Select
          size="sm"
          placeholder="Filter by status"
          slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
          onChange={(e, newValue) => setStatus(newValue)}
        >
          <Option value="*">All</Option>
          <Option value="active">Active</Option>
          <Option value="archived">Archived</Option>
          <Option value="deactivated">Deactivated</Option>
        </Select>
      </FormControl>
      <FormControl size="sm">
        <FormLabel>Time Period</FormLabel>
        <Select size="sm" placeholder="All" onChange={(e, newValue) => setRenewalInterval(newValue)}>
          <Option value="*">All</Option>
          <Option value="month">Month</Option>
          <Option value="year">Year</Option>
        </Select>
      </FormControl>
      <FormControl size="sm">
        <FormLabel>Type</FormLabel>
        <Select size="sm" placeholder="All" onChange={(e, newValue) => setSubscriptionType(newValue)}>
          <Option value="*">All</Option>
          <Option value="one-time">One Time</Option>
          <Option value="recurring">Recurring</Option>
        </Select>
      </FormControl>
    </React.Fragment>
  );

  const getPages = () => {
    let pages = [];
    if (numberOfPages <= 7) {
      for (let i = 1; i <= numberOfPages; i++) {
        pages.push(i);
      }
    } else {
      pages = [1, 2, 3, '...', numberOfPages - 2, numberOfPages - 1, numberOfPages];
      if (page > 3 && page < numberOfPages - 2) {
        pages = [1, '...', page - 1, page, page + 1, '...', numberOfPages];
      } else if (page <= 3) {
        pages = [1, 2, 3, 4, '...', numberOfPages];
      } else {
        pages = [1, '...', numberOfPages - 3, numberOfPages - 2, numberOfPages - 1, numberOfPages];
      }
    }
    return pages;
  };

  const handlePageChange = (newPage) => {
    if (newPage === '...' || newPage < 1 || newPage > numberOfPages) return;
    setPage(newPage);
    if(!plans[newPage] && !searchSubmit){
      adminLoadSubscriptionPlans(apiKey, newPage, 20);
    } else if(searchSubmit){
      loadSearchedSubscriptions(apiKey, search.trim(), { status, subscriptionType, renewalInterval }, newPage, 20)
    }
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    if(search.trim().length > 0){
      setSearchSubmit(true);
      loadSearchedSubscriptions(apiKey, search.trim(), { status, subscriptionType, renewalInterval }, 1, 20);
      setPage(1);
    } else {
      setSearchSubmit(false);
    }
  }

  const handleClearSearch = (event) => {
    setSearchSubmit(false);
    setSearch('');
    setStatus('*');
    setSubscriptionType('*');
    setRenewalInterval('*');
  }

  useEffect(() => {
    if(searchSubmit && search.trim().length > 0){
      setWPlans(splans);
      setNumberOfPages(stotalPages);
    } else {
      setWPlans(plans);
      setNumberOfPages(totalPages);
    }
  }, [plans, splans, searchSubmit, totalPages, stotalPages, status, subscriptionType, renewalInterval])

  return (
    <React.Fragment>
      <Sheet
        className="SearchAndFilters-mobile"
        sx={{
          display: { xs: 'flex', sm: 'none' },
          my: 1,
          gap: 1,
        }}
      >
        <Input
          size="sm"
          placeholder="Search"
          startDecorator={<SearchIcon />}
          sx={{ flexGrow: 1 }}
        />
        <IconButton
          size="sm"
          variant="outlined"
          color="neutral"
          onClick={() => setOpen(true)}
        >
          <FilterAltIcon />
        </IconButton>
        <Modal open={open} onClose={() => setOpen(false)}>
          <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
            <ModalClose />
            <Typography _id="filter-modal" level="h2">
              Filters
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Sheet sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {renderFilters()}
              <Button color="primary" onClick={() => setOpen(false)}>
                Submit
              </Button>
            </Sheet>
          </ModalDialog>
        </Modal>
      </Sheet>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: 'sm',
          py: 2,
          display: { xs: 'none', sm: 'flex' },
          flexWrap: 'wrap',
          gap: 1.5,
          '& > *': {
            minWidth: { xs: '120px', md: '160px' },
          },
        }}
      >
      <form style={{flex: 1}} onSubmit={handleSearchSubmit}>
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Search for Subscriptions</FormLabel>
          <Input size="sm" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search" startDecorator={<SearchIcon />} endDecorator={<IconButton onClick={handleClearSearch}><CloseIcon /></IconButton>} />
        </FormControl>
      </form>
        {renderFilters()}
      </Box>
      {wplans && <AdminSubscriptionRows rows={wplans} page={page} />}
      <Box
        className="Pagination-laptopUp"
        sx={{
          pt: 2,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: { borderRadius: '50%' },
          display: {
            xs: 'none',
            md: 'flex',
          },
        }}
      >
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          startDecorator={<KeyboardArrowLeftIcon />}
          disabled={page === 1}
          onClick={() => handlePageChange(page - 1)}
        >
          Previous
        </Button>

        <Box sx={{ flex: 1 }} />
        {getPages().map((ipage, index) => (
          <IconButton
            key={index}
            size="sm"
            variant={Number(ipage) ? (ipage === page ? 'solid' : 'soft') : 'plain'}
            color="neutral"
            onClick={() => handlePageChange(ipage)}
          >
            {ipage}
          </IconButton>
        ))}
        <Box sx={{ flex: 1 }} />

        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          endDecorator={<KeyboardArrowRightIcon />}
          disabled={page === numberOfPages}
          onClick={() => handlePageChange(page + 1)}
        >
          Next
        </Button>
      </Box>
    </React.Fragment>
  );
}

const mapStateToProps = (store) => ({
  subscriptionPlansData: store.admin.subscriptionPlansData,
  searchedSubscriptionPlansData: store.admin.searchedSubscriptionPlansData,
  apiKey: store.admin.apiKey
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
  adminLoadSubscriptionPlans,
  loadSearchedSubscriptions
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AdminSubscriptionTable);