import React, { useEffect, useState } from 'react';
import {
  CssVarsProvider,
  Sheet,
  FormControl,
  FormLabel,
  Input,
  Button,
  Typography,
  Box,
  Stack,
  Grid,
} from '@mui/joy';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadAdminSite } from '../redux/actions/index.js';

const AdminSettingsComponent = ({ content, apiKey }) => {
  const [settings, setSettings] = useState({
    brandName: '',
    logo: '',
    contactEmail: '',
    phoneNumber: '',
    address: '',
    socialLinks: {
      facebook: '',
      twitter: '',
      linkedin: ''
    }
  });

  useEffect(() => {
    setSettings(content);
  }, [content]);

  const handleInputChange = (field, value) => {
    setSettings(prev => ({ ...prev, [field]: value }));
  };

  const handleSocialLinkChange = (platform, value) => {
    setSettings(prev => ({
      ...prev,
      socialLinks: { ...prev.socialLinks, [platform]: value }
    }));
  };

  const handleSubmit = () => {
    // const formData = new FormData();
    // formData.append('settings', JSON.stringify(settings));
    // updateSiteSettings(apiKey, siteId, formData);
  };

  return (
    <Sheet sx={{ p: 2, borderRadius: '10px' }} variant={'outlined'}>
      <Typography level="h4" gutterBottom>
        Website Settings
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullwidth={'true'} sx={{ mb: 2 }}>
            <FormLabel>Brand Name</FormLabel>
            <Input
              value={settings?.brandName}
              onChange={(e) => handleInputChange('brandName', e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullwidth={'true'} sx={{ mb: 2 }}>
            <FormLabel>Logo URL</FormLabel>
            <Input
              value={settings?.logo}
              onChange={(e) => handleInputChange('logo', e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullwidth={'true'} sx={{ mb: 2 }}>
            <FormLabel>Contact Email</FormLabel>
            <Input
              value={settings?.contactEmail}
              onChange={(e) => handleInputChange('contactEmail', e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullwidth={'true'} sx={{ mb: 2 }}>
            <FormLabel>Phone Number</FormLabel>
            <Input
              value={settings?.phoneNumber}
              onChange={(e) => handleInputChange('phoneNumber', e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullwidth={'true'} sx={{ mb: 2 }}>
            <FormLabel>Address</FormLabel>
            <Input
              value={settings?.address}
              onChange={(e) => handleInputChange('address', e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography level="h5" gutterBottom>
            Social Links
          </Typography>
          <Stack spacing={2}>
            {settings?.socialLinks && Object.keys(settings?.socialLinks).map((platform) => (
              <FormControl fullwidth={'true'} key={platform} sx={{ mb: 2 }}>
                <FormLabel>{platform.charAt(0).toUpperCase() + platform.slice(1)}</FormLabel>
                <Input
                  value={settings?.socialLinks[platform]}
                  onChange={(e) => handleSocialLinkChange(platform, e.target.value)}
                />
              </FormControl>
            ))}
          </Stack>
        </Grid>
      </Grid>
      <Stack spacing={2} mt={2}>
        <Button variant={'soft'} fullWidth color={'success'} onClick={handleSubmit}>
            Save Settings
        </Button>
      </Stack>
    </Sheet>
  );
};

const mapStateToProps = (store) => ({
  apiKey: store.admin?.apiKey,
  content: store.admin.websiteSettings
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch );

export default connect(mapStateToProps, mapDispatchToProps)(AdminSettingsComponent);
