import React, {useCallback, useEffect, useState} from 'react';
import { styled } from '@mui/material/styles';
import {
  Print as PrintIcon,
  Download as DownloadIcon,
  Toc as TocIcon,
  CloudUpload as CloudUploadIcon,
  CreateNewFolder as CreateNewFolderIcon
} from '@mui/icons-material';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  setPagination,
  generateToc,
  mergePDF
} from '../redux/actions/index.js';
import { Backdrop, CircularProgress, FormGroup, createTheme, Stack, Switch } from '@mui/material';
import { CssVarsProvider, IconButton } from '@mui/joy';
import cssVars from '@mui/system/cssVars';
import { useRavenContext } from './RavenContext.js';

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&::before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&::after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));

const theme = createTheme({
  cssVars
})

function BottomShortcutsBar(props) {
    const { caseData: { _id: caseid, bundle: { items: bundleItems}, paginationSettings }, pdf, user, pagination, setPagination, toolBarBusy, mergePDF, generateToc } = props;
    const [isToolBarBusy, setIsToolBarBusy] = useState(false);
    const [isPagination, setIsPagination] = useState(false);
    const [xopen, setXOpen] = useState(false);

    const { print, Download } = useRavenContext();

    const handleChange = ((e) => {
      setIsPagination(e.target.checked)

      if(e.target.checked){
          setPagination(caseid, user.apiKey, true)
      } else {
          setPagination(caseid, user.apiKey, false)
      }
    })

    const handlePrint = (event) => {
      print()
    }

    const handleTocGeneration = (event) => {
      setXOpen(true);
      generateToc(caseid, user.apiKey).then(async () => {
        await mergePDF(caseid, user.apiKey, false, true);
        setXOpen(false);
      })
    }

    useEffect(() => {
        setIsToolBarBusy(toolBarBusy);
        setIsPagination(pagination);
    }, [toolBarBusy, pagination])

    // if(!open){
    //     return(<></>);
    // }

    return (
      <CssVarsProvider theme={theme}>
        <FormGroup>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={xopen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
              <Stack direction="row" spacing={1} alignItems="center" justifyContent={"space-around"}>
                <Android12Switch onChange={handleChange} checked={isPagination} disabled={bundleItems.length === 0 || isToolBarBusy || (pdf && pdf.length === 0) } inputProps={{ 'aria-label': 'ant design' }} />
                <CssVarsProvider>
                  <IconButton disabled={bundleItems.length === 0 || (pdf && pdf.length === 0)} onClick={handlePrint} edge="start" color="inherit" aria-label="star" sx={{ mb: 0.5 }}>
                    <PrintIcon />
                  </IconButton>
                </CssVarsProvider>
                <Download>
                {
                  (props) => (
                    <CssVarsProvider>
                      <IconButton disabled={bundleItems.length === 0 || (pdf && pdf.length === 0) } onClick={props.onClick} edge="start" color="inherit" aria-label="star" sx={{ mb: 0.5 }}>
                        <DownloadIcon  />
                      </IconButton>
                    </CssVarsProvider>
                  )
                }
                </Download>
                <CssVarsProvider>
                  <IconButton disabled={bundleItems.length === 0 || (pdf && pdf.length === 0)} onClick={handleTocGeneration} edge="start" color="inherit" aria-label="star" sx={{ mb: 0.5 }}>
                    <TocIcon />
                  </IconButton>
                </CssVarsProvider>
              </Stack>
        </FormGroup>
      </CssVarsProvider>
    );
}

const mapDispatchProps = (dispatch) => bindActionCreators({
  setPagination,
  generateToc,
  mergePDF
}, dispatch);
const mapStateToProps = (store) => ({
  caseData: store.caseData,
  pdf: store.caseData.pdf1,
  user: store.user,
  pagination: store.app.pagination,
  toolBarBusy: store.app.toolBarBusy
})
export default connect(mapStateToProps, mapDispatchProps)(BottomShortcutsBar);