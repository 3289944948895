import React, { useCallback, useEffect, useMemo, useState } from 'react';
import '../styles/PDFviewer.css'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { connect } from 'react-redux';

import CssBaseline from '@mui/joy/CssBaseline';
import LinearBuffer from './PDFProgressBar';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { printPlugin } from '@react-pdf-viewer/print';
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { fullScreenPlugin } from '@react-pdf-viewer/full-screen';

import EditNoteIcon from '@mui/icons-material/EditNote';
import BorderColorIcon from '@mui/icons-material/BorderColor';

import {
    highlightPlugin,
    HighlightArea,
    MessageIcon,
} from '@react-pdf-viewer/highlight';
import { Button, Position, Spinner, PrimaryButton, Tooltip, Viewer, ThemeContext } from '@react-pdf-viewer/core';

// import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import '@react-pdf-viewer/highlight/lib/styles/index.css';

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/full-screen/lib/styles/index.css';
import { bindActionCreators } from 'redux';
import { addNote } from '../redux/actions';
import { useRavenContext } from './RavenContext';
import { base64ToUint8Array } from '../utils';

// interface HighlightArea {
//     height: number;
//     left: number;
//     pageIndex: number;
//     top: number;
//     width: number;
// }

// interface SelectionData {
//     startPageIndex: number;
//     startOffset: number;
//     startDivIndex: number;
//     endPageIndex: number;
//     endOffset: number;
//     endDivIndex: number;
// }

// interface Note {
//     // The generated unique identifier
//     id: number;
//     // The note content
//     content: string;
//     // The list of highlight areas
//     highlightAreas: HighlightArea[];
//     // The selected text
//     quote: string;
// }

const renderPage = (props) => (
    <>
        {props.canvasLayer.children}
        <div
            style={{
                alignItems: 'center',
                display: 'flex',
                height: '100%',
                justifyContent: 'center',
                left: 0,
                position: 'absolute',
                top: 0,
                width: '100%',
            }}
        >
            <div
                style={{
                    color: 'rgba(0, 0, 0, 0.2)',
                    fontSize: `${8 * props.scale}rem`,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    transform: 'rotate(-45deg)',
                    userSelect: 'none',
                }}
            >
                Raven
            </div>
        </div>
        {props.annotationLayer.children}
        {props.textLayer.children}
    </>
);


const PdfViewer = (props) => {
  const { caseData: { _id: caseid, notes: wnotes, metadata: { filesData } }, apiKey, addComment } = props;
  const [currentTheme, setCurrentTheme] = React.useState(localStorage.getItem('joy-mode'));
  const themeContext = { currentTheme, setCurrentTheme };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [pdfUrl, setPdfUrl] = useState(null);

  const RenderPrintProgressBar = useCallback(
      (numLoadedPages, numPages, onCancel) => (
          <>
          <CssBaseline />
          <Box
            sx={{
              alignItems: 'center',
              background: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, .5)' : 'rgba(255, 255, 255, 0.5)',
              display: 'flex',
              height: '100%',
              justifyContent: 'center',
              left: 0,
              position: 'fixed',
              top: 0,
              width: '100%',
              zIndex: 9999,
            }}
          >
              <Box
                  sx={{
                  alignItems: 'center',
                  backgroundColor: theme.palette.mode === 'light'? '#fff' : '#111',
                  border: '1px solid rgba(0, 0, 0, 0.3)',
                  borderRadius: '0.25rem',
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '1.5rem',
                  width: '20rem',
                  }}
              >
                  <Box sx={{ marginBottom: '1rem' }}>
                      Preparing {numLoadedPages}/{numPages} pages ...
                  </Box>
                  <Box sx={{ marginBottom: '1rem' }}>
                      <Spinner />
                  </Box>
                  <Button onClick={onCancel}>Cancel</Button>
              </Box>
          </Box>
          </>
      ),
      []
  );

  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;

  const printPluginInstance = printPlugin({
      renderProgressBar: RenderPrintProgressBar,
  });
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const getFilePluginInstance = getFilePlugin();
  const fullScreenPluginInstance = fullScreenPlugin({enableShortcuts: true});

  const { Download } = getFilePluginInstance;
  const { print } = printPluginInstance;
  const { EnterFullScreen  } = fullScreenPluginInstance;
  const { jumpToPage } = pageNavigationPluginInstance;

  const transform = (slot) => ({
    ...slot,
    Open: () => <></>,
    DownloadMenuItem: () => <></>,
    EnterFullScreenMenuItem: () => <></>,
    SwitchTheme: () => <></>,
    SwitchThemeMenuItem: () => <></>,
  });

  const pdfData = useMemo(() => {
    return props.pdf1;
  }, [props.pdf1])

  useEffect(() => {
    setCurrentTheme(localStorage.getItem('joy-mode'));
    if (pdfData) {
      const pdfDataAsUint8Array = base64ToUint8Array(pdfData);
      const blob = new Blob([pdfDataAsUint8Array], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      setPdfUrl(url);

      return () => URL.revokeObjectURL(url);
    }
  }, [props, pdfData, localStorage.getItem('joy-mode')]);

  const [message, setMessage] = useState('');
  const [notes, setNotes] = useState(wnotes);

  useEffect(() => {
    setNotes(wnotes)
  }, [wnotes])

  const noteEles = new Map();

  const handleRedaction = async (props) => {
    const { item, filePageNo } = findItemIdOfPageIndex(filesData, props.highlightAreas[0].pageIndex)
    // Calculate the bounding box for all highlight areas
    const boundingBox = await props.highlightAreas.filter(area => area.left !== 0).reduce((bbox, area) => {
      return {
        left: Math.min(bbox.left, area.left),
        top: Math.min(bbox.top, area.top),
        width: Math.max(bbox.left + bbox.width, area.left + area.width) - Math.min(bbox.left, area.left),
        height: Math.max(bbox.top + bbox.height, area.top + area.height) - Math.min(bbox.top, area.top),
      };
    }, props.highlightAreas[0]);

    // Create a single large redaction area
    const redactionArea = {
      ...boundingBox,
      item,
      pageIndex: props.highlightAreas[0].pageIndex,
      type: 'redaction',
      filePageNo,
    };

    console.log(props.highlightAreas)
    setNotes(prevNotes => [
      ...prevNotes,
      {
        _id: `redaction-${Date.now()}`,
        highlightAreas: [redactionArea],
        type: 'redaction',
      }
    ]);
  };

  const renderHighlightTarget = (props) => (
    <div
      style={{
        background: '#eee',
        display: 'flex',
        position: 'absolute',
        left: `${props.selectionRegion.left}%`,
        top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
        transform: 'translate(0, 8px)',
        zIndex: 1,
      }}
    >
      <Tooltip
        position={Position.TopCenter}
        target={
          <Button onClick={props.toggle}>
            <EditNoteIcon />
          </Button>
        }
        content={() => <div style={{ width: '100px' }}>Add a note</div>}
        offset={{ left: 0, top: -8 }}
      />

      <Tooltip
        position={Position.TopCenter}
        target={
          <Button onClick={() => handleRedaction(props)}>
            <BorderColorIcon />
          </Button>
        }
        content={() => <div style={{ width: '100px' }}>Redaction</div>}
        offset={{ left: 0, top: -8 }}
      />
    </div>
  );

  const findItemIdOfPageIndex = (filesData, pageIndex) => {
    for (const [key, value] of Object.entries(filesData)) {
      if (pageIndex >= value.firstPageNumber && pageIndex <= value.lastPageNumber) {
        return { item: key, filePageNo: pageIndex - value.firstPageNumber };
      }
    }
    return null
  };

  const renderHighlightContent = (props) => {
    const addNote = () => {
      const { item, filePageNo } = findItemIdOfPageIndex(filesData, props.highlightAreas[0].pageIndex)
      props.highlightAreas = props.highlightAreas.filter(area => area.left !== 0).map((highlightArea) => ({ ...highlightArea, item, filePageNo }))
      if (message !== '') {
        const note = {
          content: message,
          highlightAreas: props.highlightAreas,
          quote: props.selectedText,
        };
        addComment(caseid, apiKey, note)
        setNotes(notes.concat([note]));
        props.cancel();
      }
    };

    return (
      <div
        style={{
          background: '#fff',
          border: '1px solid rgba(0, 0, 0, .3)',
          borderRadius: '2px',
          padding: '8px',
          position: 'absolute',
          left: `${props.selectionRegion.left}%`,
          top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
          zIndex: 1,
        }}
      >
        <div>
          <textarea
            rows={3}
            style={{
                border: '1px solid rgba(0, 0, 0, .3)',
            }}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        </div>
        <div
          style={{
            display: 'flex',
            marginTop: '8px',
          }}
        >
          <div style={{ marginRight: '8px' }}>
            <PrimaryButton onClick={addNote}>Add</PrimaryButton>
          </div>
          <Button onClick={props.cancel}>Cancel</Button>
        </div>
      </div>
    );
  };

    const jumpToNote = (note) => {
      if (noteEles.has(note._id)) {
        noteEles.get(note._id).scrollIntoView();
      }
    };

    const renderHighlights = (props) => (
      <div>
        {notes.map((note) => (
          <React.Fragment key={note._id}>
            {note.highlightAreas
              .filter((area) => area.pageIndex === props.pageIndex)
              .map((area, idx) => {
                let style;
                if (note.type === 'redaction') {
                  style = {
                    background: 'black',
                    opacity: 1,
                  };
                } else {
                  style = {
                    background: 'yellow',
                    opacity: 0.4,
                  };
                }
                
                return (
                  <div
                    key={idx}
                    style={Object.assign(
                      {},
                      style,
                      props.getCssProperties(area, props.rotation)
                    )}
                    onClick={() => jumpToNote(note)}
                    ref={(ref) => {
                      noteEles.set(note._id, ref);
                    }}
                  />
                );
              })}
          </React.Fragment>
        ))}
      </div>
    );


    const highlightPluginInstance = highlightPlugin({
        renderHighlightTarget,
        renderHighlightContent,
        renderHighlights,
    });

    const { jumpToHighlightArea } = highlightPluginInstance;
    const { setJumpToHighlightArea, setDownload, setPrint, setEnterFullScreen, setJumpToPage } = useRavenContext();

    useEffect(() => {
      setJumpToHighlightArea(() => jumpToHighlightArea);
      setDownload(() => Download);
      setPrint(() => print);
      setEnterFullScreen(() => EnterFullScreen);
      setJumpToPage(() => jumpToPage);
    }, [setJumpToHighlightArea, setDownload, setPrint, setEnterFullScreen, setJumpToPage]);

  const plugins = [toolbarPluginInstance, highlightPluginInstance, getFilePluginInstance, fullScreenPluginInstance, printPluginInstance, pageNavigationPluginInstance];

  return (
    <div className={isMobile ? 'mpdf-container' : 'pdf-container'}>
      {!props.buffering ? (
        <div
          className={`rpv-core__viewer rpv-core__viewer--${currentTheme}`}
          style={{
              borderColor: currentTheme === 'dark' ? '#454647' : 'rgba(0, 0, 0, 0.3)',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
          }}
        >
          <div
            style={{
              alignItems: 'center',
              borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
              display: 'flex',
              justifyContent: 'center',
              padding: '4px',
            }}
          >
            <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
          </div>
          <div
            style={{
              flex: 1,
              overflow: 'hidden',
            }}
          >
          {(pdfUrl) ? (
            <ThemeContext.Provider value={themeContext}>
              <Viewer
                theme={currentTheme}
                fileUrl={pdfUrl}
                plugins={plugins} 
                renderLoader={(percentages) => (
                  <LinearBuffer percentages={percentages} />
                )}
                //renderPage={renderPage}
              />
            </ThemeContext.Provider>
          ): (<></>)}
            
          </div>
        </div>
      ) : (
        <Box sx={{ display: 'flex', width: '100%', minHeight: '80vh', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress color='inherit' />
        </Box>
      )}
    </div>
  );
};

const mapStateToProps = (store) => ({
  pdf1: store.caseData.pdf1,
  pdf2: store.caseData.pdf2,
  buffering: store.app.loadingMergedPDF,
  theme: store.app.theme,
  caseData: store.caseData,
  apiKey: store.user.apiKey
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
  addComment: addNote
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PdfViewer);