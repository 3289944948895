import React, { useEffect, useState, useCallback } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadSite } from '../redux/actions/index.js';
import LandingHeader from '../components/LandingHeader.js';
import LandingFooter from '../components/LandingFooter.js';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { CircularProgress, CssVarsProvider, Sheet, Box } from '@mui/joy';
import PageTitleSection from '../components/PageTitleSection.js';
import PrivacyPolicyComponent from '../components/PrivacyPolicyComponent.js';

function PrivacyPolicy(props) {
  const { site, loadSite } = props;
  const name = 'Raven3';

  useEffect(() => {
    loadSite(name);
  }, [])

  return (
    <HelmetProvider>
      <Helmet>
        <link rel="stylesheet" href="css/bootstrap.min.css" type="text/css" />
        <link rel="stylesheet" href="css/bootstrap-icons.css" type="text/css" />
        <link rel="stylesheet" href="css/animate.css" type="text/css" />
        <link rel="stylesheet" href="css/magnific-popup.css" type="text/css" />
        <link rel="stylesheet" href="css/owl.carousel.css" type="text/css" />
        <link rel="stylesheet" href="css/odometer.css" type="text/css" />
        <link rel="stylesheet" href="css/spacing.css" type="text/css" />
        <link rel="stylesheet" href="css/base.css" type="text/css" />
        <link rel="stylesheet" href="css/shortcodes.css" type="text/css" />
        <link rel="stylesheet" href="css/style.css" type="text/css" />
        <link rel="stylesheet" href="css/responsive.css" type="text/css" />
        <link rel="stylesheet" href="css/theme-color/color-8.css" type="text/css" />
      </Helmet>
        {(site.loaded)? (
          <div className="page-wrapper">
            <LandingHeader content={site?.landing?.header} />
            <PageTitleSection content={site?.privacyPolicy?.pageTitle} />
            <div className="page-content">
              <PrivacyPolicyComponent content={site?.privacyPolicy?.body} />
            </div>
            <LandingFooter content={site?.landing?.footerContent} />
          </div>
        ): (
          <CssVarsProvider>
            <Sheet sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
              <Box>
                <CircularProgress />
              </Box>
            </Sheet>
          </CssVarsProvider>
        )}

        <div className="scroll-top" style={{display: (site.loaded)? 'initial' : 'none'}}>
          <svg className="scroll-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
          </svg>
        </div>

        <Helmet>
          <script src='js/wscript.js'></script>
        </Helmet>
    </HelmetProvider>
  );
}

const mapStateToProps = (store) => ({
  user: store.user,
  site: store.site
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
  loadSite
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
