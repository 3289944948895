import React from "react";

const AboutUsTimeline = ({ content }) => {
    return (
        <section className="overflow-hidden">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="main-timeline position-relative">
                            {content.map((item, index) => (
                                <div key={index} className="timeline_item">
                                    <div className={`date-content ${index % 2 === 0 ? 'timeline-order-1b' : 'timeline-order-1'}`}>
                                        <div className="date">
                                            {item.year}
                                        </div>
                                    </div>
                                    <div className={`timeline-icon ${index % 2 === 0 ? 'timeline-order-2b' : 'timeline-order-2'}`}>
                                        <i className={item.icon}></i>
                                    </div>
                                    <div className={`timeline-content ${index % 2 === 0 ? 'timeline-order-3b' : 'timeline-order-3'} col-md`}>
                                        <h4>{item.title}</h4>
                                        <p>{item.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutUsTimeline;
