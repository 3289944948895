import React, { useState, useEffect } from 'react';
import {
    Avatar,
    Box,
    Chip,
    Divider,
    Link,
    Table,
    Sheet,
    Checkbox,
    IconButton,
    Typography,
    Menu,
    MenuButton,
    MenuItem,
    Dropdown
} from '@mui/joy';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import BlockIcon from '@mui/icons-material/Block';
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';

import { formatDate } from '../utils'
import { truncateString } from '../utils';

function RowMenu() {
  return (
    <Dropdown>
      <MenuButton
        slots={{ root: IconButton }}
        slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}
      >
        <MoreHorizRoundedIcon />
      </MenuButton>
      <Menu size="sm" sx={{ minWidth: 140 }}>
        <MenuItem>Edit</MenuItem>
        <Divider />
        <MenuItem color="danger">Delete</MenuItem>
      </Menu>
    </Dropdown>
  );
}

const AdminMessageRow = ({row, selected, setSelected}) => {
    return (
        <tr key={row._id}>
            <td style={{ textAlign: 'center', minWidth: 120 }}>
                <Checkbox
                size="sm"
                checked={selected.includes(row._id)}
                color={selected.includes(row._id) ? 'primary' : undefined}
                onChange={(event) => {
                    setSelected((ids) =>
                    event.target.checked
                        ? ids.concat(row._id)
                        : ids.filter((itemId) => itemId !== row._id),
                    );
                }}
                slotProps={{ checkbox: { sx: { textAlign: 'left' } } }}
                sx={{ verticalAlign: 'text-bottom' }}
                />
            </td>
            <td>
                <Typography level="body-xs">{truncateString(row._id, 10)}</Typography>
            </td>
            <td>
                <Typography level='body-xs'>{truncateString(row.title, 45)}</Typography>
            </td>
            <td>
                <Typography level="body-xs">{formatDate(row.createdOn)}</Typography>
            </td>
            <td>
                <Chip
                variant="soft"
                size="sm"
                startDecorator={
                    {
                    sent: <CheckRoundedIcon />,
                    scheduled: <AutorenewRoundedIcon />,
                    draft: <BlockIcon />,
                    }[row.status]
                }
                color={
                    {
                    sent: 'success',
                    scheduled: 'primary',
                    draft: 'warning',
                    }[row.status]
                }
                >
                {row.status}
                </Chip>
            </td>
            <td>
              <Typography level="body-xs">{row.type}</Typography>
            </td>
            <td>
              <Typography level="body-xs">{row.impression}</Typography>
            </td>
            <td>
              <Typography level="body-xs">{row.clicks}</Typography>
            </td>
            <td>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <RowMenu />
                </Box>
            </td>
        </tr>
    )
}

export default AdminMessageRow;