import * as React from 'react';
import Button from '@mui/joy/Button';
import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';
import ArrowForward from '@mui/icons-material/ArrowForward';
import TwoSidedLayout from '../components/TwoSidedLayout';
import { useNavigate } from 'react-router-dom';

export default function BusinessInfo() {
    const company = "CaseGenie";
    const navigate = useNavigate()

    const handleNavigationToSignInPage = (event) => {
        navigate('/signin');
    }
    const handleNavigationToSignUpPage = (event) => {
        navigate('/signup')
    }
    return (
        <TwoSidedLayout>
        <Typography color="primary" fontSize="lg" fontWeight="lg">
            Get on top of your papers
        </Typography>
        <Typography
            level="h1"
            fontWeight="xl"
            fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
        >
            Unlock Efficiency with {company} & Discover the Power
        </Typography>
        <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
            {company} assists you in organizing, comprehending, and e-bundling your documents effortlessly, enhancing your efficiency, accuracy, and reducing frustration.
        </Typography>
        <Button size="lg" onClick={handleNavigationToSignUpPage} endDecorator={<ArrowForward fontSize="xl" />}>
            Get Started
        </Button>
        <Typography>
            Already a member? <Link onClick={handleNavigationToSignInPage} fontWeight="lg">Sign in</Link>
        </Typography>
        <Typography
            level="body-xs"
            sx={{
            position: 'absolute',
            top: '2rem',
            left: '50%',
            transform: 'translateX(-50%)',
            }}
        >
            {company}
        </Typography>
        </TwoSidedLayout>
    );
}