import React from "react";

const styles = {
  section: {
    padding: "20px",
    backgroundColor: "#f9f9f9",
  },
  container: {
    maxWidth: "1000px",
    margin: "0 auto",
  },
  row: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  },
  col: {
    flex: "1",
    maxWidth: "200px",
    padding: "10px",
  },
  image: {
    width: "100%",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
  },
};

export default function LandingOverview({content}) {
    return (
        <section>
        <div className="container">
            <div className="row">
                <div className="col-lg-8 col-md-12">
                    <h2>{content.title}</h2>
                    <p className="mb-5">
                    <strong>
                        {content.subtitle}
                    </strong>
                    </p>
                    <p>{content.description}</p>
                    <ul className="list-unstyled list-icon">
                    {content.listItems.map((item, index) => (
                        <li className="p-2" key={index}>
                        <i className="bi bi-check-lg"></i>
                        {item}
                        </li>
                    ))}
                    </ul>
                </div>
                <div className="col-lg-4 col-md-12 mt-7 mt-lg-0 p-5">
                    <div style={styles.row}>
                        <div style={styles.col}>
                            <img
                                src="images/portfolio/33.jpg"
                                alt="Manager Dashboard"
                                style={styles.image}
                            />
                        </div>
                        <div style={styles.col}>
                            <img
                                src="images/portfolio/22.jpg"
                                alt="Phone App"
                                style={styles.image}
                            />
                        </div>
                    </div>
                    <div style={styles.row}>
                        <div style={styles.col}>
                            <img
                                src="images/portfolio/11.jpg"
                                alt="Woman Working"
                                style={styles.image}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </section>
    );
}
