import * as React from 'react';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';

import { connect } from 'react-redux';
import { clearToBeRecoveredCaseId, recoverCase, updatePaginationSettings } from '../redux/actions';
import { useSnackbar } from 'notistack';
import { bindActionCreators } from 'redux';
import { MuiColorInput } from 'mui-color-input';
import { Box, CssVarsProvider, FormControl, FormLabel, Option, Select, Slider, Stack } from '@mui/joy';
import cssVars from '@mui/system/cssVars';
import { createTheme } from '@mui/material/styles';
import ColourPicker from './ColourPicker';
import { rgbToHex, hexToRgb } from '../utils';

const theme = createTheme({
  cssVars
});

function PaginationSettingsModal(props) {
    const { open, setOpen, caseData: { _id: caseid, paginationSettings}, apiKey, setBackDropOpen, updatePaginationSettings } = props;

    const { enqueueSnackbar } = useSnackbar();

    const handleClose = () => {
        // setBackDropOpen(false);
        setOpen(false);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setOpen(false);
        const position = event.target.position[1].value;
        const flow = event.target.flow[1].value;
        const pageNumberFormat = event.target.pageNumberFormat[1].value;
        const pageNumberColour = hexToRgb(event.target.pageNumberColour.value);
        const font = event.target.font[1].value;
        const fontSize = parseFloat(event.target.fontSize.value);
        const containerRadius = parseFloat(event.target.containerRadius.value);
        const containerColour = hexToRgb(event.target.containerColour.value);

        const paginationSettings = {
          position,
          flow,
          pageNumberFormat,
          pageNumberColour,
          font,
          fontSize,
          containerRadius,
          containerColour,
          tabNumberFormat: {sectionKey: 'A', documentNumber: '1'}
        }

        updatePaginationSettings(caseid, apiKey, paginationSettings, enqueueSnackbar);
        // setBackDropOpen(true);
    } 
  return (
      <Modal open={open} onClose={handleClose}>
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            Pagination Settings
          </DialogTitle>
          <Divider />
          <form onSubmit={handleSubmit}>
          <DialogContent>
            <Stack spacing={2} sx={{flexGrow: 1, padding: 2}}>
                <Stack spacing={1} direction={'row'}>
                    <FormControl sx={{flex: 1}}>
                    <FormLabel>Format</FormLabel>
                        <Select
                            color="primary"
                            placeholder="Select Format"
                            size="sm"
                            variant="soft"
                            defaultValue={paginationSettings.pageNumberFormat}
                            name='pageNumberFormat'
                            >
                            <Option value='none'>None</Option>
                            <Option value='numeric'>Numeric</Option>
                            <Option value='roman'>Roman</Option>
                            <Option value='alphabetic'>Alphabetic</Option>
                            <Option value='alphanumeric'>Alphanumeric</Option>
                        </Select>
                    </FormControl>
                    <FormControl sx={{flex: 1}}>
                        <FormLabel>Position</FormLabel>
                        <Select
                            color="primary"
                            placeholder="Select Position"
                            size="sm"
                            variant="soft"
                            defaultValue={paginationSettings.position}
                            name='position'
                            >
                            <Option value='Top Left'>Top Left</Option>
                            <Option value='Top Center'>Top Center</Option>
                            <Option value='Top Right'>Top Right</Option>
                            <Option value='Bottom Left'>Bottom Left</Option>
                            <Option value='Bottom Center'>Bottom Center</Option>
                            <Option value='Bottom Right'>Bottom Right</Option>
                        </Select>
                    </FormControl>
                </Stack>
                <Stack spacing={1}>
                    <FormControl>
                        <FormLabel>Flow</FormLabel>
                        <Select
                            color="primary"
                            placeholder="Select Flow"
                            size="sm"
                            variant="soft"
                            defaultValue={paginationSettings.flow}
                            name='flow'
                        >
                            <Option value='single'>Number Bundle As One Document</Option>
                            <Option value='individual'>Number Bundle As Individual Documents</Option>
                        </Select>
                    </FormControl>
                </Stack>
                <Stack spacing={1}>
                    <FormControl>
                        <FormLabel>Font</FormLabel>
                        <Select
                            color="primary"
                            placeholder="Select Font"
                            size="sm"
                            variant="soft"
                            defaultValue={paginationSettings.font}
                            name='font'
                        >
                            <Option value='Helvetica'>Helvetica</Option>
                            <Option value='Helvetica-Bold'>Helvetica Bold</Option>
                            <Option value='Courier'>Courier</Option>
                            <Option value='Courier-Bold'>Courier Bold</Option>
                            <Option value='Times-Roman'>Times Roman</Option>
                            <Option value='Times-Bold'>Times Bold</Option>
                            <Option value='Symbol'>Symbol</Option>
                            <Option value='ZapfDingbats'>ZapfDingbats</Option>
                        </Select>
                    </FormControl>
                </Stack>
                <Stack spacing={1}>
                    <FormControl>
                        <FormLabel>Font Size</FormLabel>
                        <Slider
                            name='fontSize'
                            color={'primary'}
                            size="md"
                            valueLabelDisplay="auto"
                            variant="solid"
                            min={4}
                            max={24}
                            defaultValue={paginationSettings.fontSize}
                            />
                    </FormControl>
                </Stack>
                <Stack spacing={1}>
                  <FormLabel>Font Color</FormLabel>
                  <ColourPicker name={'pageNumberColour'} value={rgbToHex(paginationSettings.pageNumberColour)}/>
                </Stack>
                <Stack spacing={1}>
                    <FormControl>
                      <FormLabel>Background Size</FormLabel>
                        <Slider
                            name='containerRadius'
                            color={'primary'}
                            size="md"
                            valueLabelDisplay="auto"
                            variant="solid"
                            min={3}
                            max={15}
                            defaultValue={paginationSettings.containerRadius || 5}
                            />
                    </FormControl>
                </Stack>
                <Stack spacing={1}>
                  <FormLabel>Background Color</FormLabel>
                  <ColourPicker name={'containerColour'} value={paginationSettings.containerColour ? rgbToHex(paginationSettings.containerColour) : '#000000'}/>
                </Stack>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button variant="solid" color="success" type='submit'>
              Save Settings
            </Button>
            <Button variant="plain" color="neutral" onClick={() => setOpen(false)}>
              Cancel
            </Button>
          </DialogActions>
          </form>
        </ModalDialog>
      </Modal>
  );
}

const mapStateToProps = (store) => ({
  apiKey: store.user.apiKey,
  caseData: store.caseData
})
const mapDispatchProps = (dispatch) => bindActionCreators({
  updatePaginationSettings
}, dispatch);
export default connect(mapStateToProps, mapDispatchProps)(PaginationSettingsModal);
