import React from 'react';
import {
  Typography,
  Card,
  CardContent,
  CardCover,
} from '@mui/joy';

export default function GradientCover({ title, image }) {
  return (
    <Card sx={{ minHeight: '280px', width: 320, cursor: 'pointer' }}>
      <CardCover>
        <img
          src={image?.src}
          loading="lazy"
          alt={image?.alt}
        />
      </CardCover>
      <CardCover
        sx={{
          background:
            'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
        }}
      />
      <CardContent sx={{ justifyContent: 'flex-end' }}>
        <Typography level="title-lg" textColor="#fff">
          {title}
        </Typography>
      </CardContent>
    </Card>
  );
}