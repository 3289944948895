import React, { useState } from 'react';
import { Box, Input, Button, Typography, Card, Sheet } from '@mui/joy';
import '../styles/ColourPicker.css'

const ColourPicker = ({ name, value, onChange }) => {
  const [color, setColor] = useState( value || '#ffffff');

  const handleColorChange = (event) => {
    setColor(event.target.value);
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 1,
        p: 1,
        boxShadow: 'sm',
      }}
      variant={'soft'}
    >
        <Input
            type="color"
            value={color}
            onChange={handleColorChange}
            sx={{ width: '36px', height: '36px', backgroundColor: color, cursor: 'pointer', padding: 0, margin: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            variant={'soft'}
        />
      <Input
        type="text"
        name={name}
        value={color}
        onChange={handleColorChange}
        sx={{textAlign: 'center'}}
        color={'primary'}
        variant={'soft'}
      />
    </Card>
  );
};

export default ColourPicker;
