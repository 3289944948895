import React from "react";

export default function LandingTestimonial({ content }) {
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="theme-title">
              <h6>{content.title}</h6>
              <h2>{content.subtitle}</h2>
            </div>
            <div className="row">
              {content.items.slice(0, 2).map((testimonial, index) => (
                <div
                  className={`col-md-6 ${index === 0 ? "mt-md-10" : "mt-10 mt-md-0"}`}
                  key={index}
                >
                  <div className="testimonial style-1">
                    <div className="testimonial-media">
                      <div className="testimonial-img">
                        <img
                          className="img-fluid"
                          src={testimonial.imageSrc}
                          alt={testimonial.name}
                        />
                      </div>
                      <div className="testimonial-quote">
                        <i className="flaticon flaticon-quote"></i>
                      </div>
                    </div>
                    <p>{testimonial.quote}</p>
                    <div className="testimonial-caption">
                      <h5>{testimonial.name}</h5>
                      <label>{testimonial.position}</label>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row">
              {content.items.slice(2).map((testimonial, index) => (
                <div
                  className={`col-md-6 ${index === 0 ? "mt-10" : "mt-10 mt-md-0"}`}
                  key={index + 2}
                >
                  <div className="testimonial style-1">
                    <div className="testimonial-media">
                      <div className="testimonial-img">
                        <img
                          className="img-fluid"
                          src={testimonial.imageSrc}
                          alt={testimonial.name}
                        />
                      </div>
                      <div className="testimonial-quote">
                        <i className="flaticon flaticon-quote"></i>
                      </div>
                    </div>
                    <p>{testimonial.quote}</p>
                    <div className="testimonial-caption">
                      <h5>{testimonial.name}</h5>
                      <label>{testimonial.position}</label>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
