export const CLEAR_DATA = 'CLEAR_DATA';
export const ON_USER_LOGIN = 'ON_USER_LOGIN';
export const ON_ADMIN_SIGNIN_SUCCESS = 'ON_ADMIN_SIGNIN_SUCCESS';
export const ON_ADMIN_SIGNIN_FAIL = 'ON_ADMIN_SIGNIN_FAIL';
export const ON_ADMIN_SIGNOUT_SUCCESS = 'ON_ADMIN_SIGNOUT_SUCCESS';
export const ON_USER_SIGNUP = 'ON_USER_SIGNUP';
export const ON_USER_LOADED = 'ON_USER_LOADED';
export const ON_BUTTON_PRESSED = 'ON_BUTTON_PRESSED';
export const ON_ACTION_FAILED = 'ON_ACTION_FAILED';

export const ON_ADMIN_LOADED = 'ON_ADMIN_LOADED';

export const ON_SHARED_CASES_LOADED = 'ON_SHARED_CASES_LOADED';
export const ON_NEW_CASE_CREATED = 'ON_NEW_CASE_CREATED';
export const ON_CASE_LOADED = 'ON_CASE_LOADED';
export const ON_CASE_REMOVED = 'ON_CASE_REMOVED';
export const ON_CASE_DELETED = 'ON_CASE_DELETED';
export const ON_CASE_RECOVERED = 'ON_CASE_RECOVERED';
export const ON_CASE_SHARED = 'ON_CASE_SHARED';
export const ON_PDF_LOADED = 'ON_PDF_LOADED';
export const ON_UPDATE_BUNDLE = 'ON_UPDATE_BUNDLE';
export const ON_RENAME_BUNDLE = 'ON_RENAME_BUNDLE';
export const ON_UPDATE_DESKSPACE = 'ON_UPDATE_DESKSPACE';
export const ON_NEW_FOLDER_CREATED = 'ON_NEW_FOLDER_CREATED';
export const ON_REFRESH_DESKSPACE = 'ON_REFRESH_DESKSPACE';
export const ON_ITEM_RENAMED = 'ON_ITEM_RENAMED';
export const ON_ALL_CASES_LOADED = 'ON_ALL_CASES_LOADED';
export const ON_ITEM_DELETED = 'ON_ITEM_DELETED';
export const ON_TRASH_CASES_LOADED = 'ON_TRASH_CASES_LOADED';
export const ON_MESSAGES_LOADED = 'ON_MESSAGES_LOADED';

export const ON_SYSTEM_USER_LOGGING_IN = 'ON_SYSTEM_USER_LOGGING_IN';
export const ON_SYSTEM_USER_LOGGED_IN = 'ON_SYSTEM_USER_LOGGED_IN';
export const ON_SYSTEM_USER_SIGNING_UP = 'ON_SYSTEM_USER_SIGNING_UP';
export const ON_SYSTEM_USER_SIGNED_UP = 'ON_SYSTEM_USER_SIGNED_UP';
export const ON_SYSTEM_LOADED_SHARED_CASES = 'ON_SYSTEM_LOADED_SHARED_CASES';
export const ON_SYSTEM_LOADING_SHARED_CASES = 'ON_SYSTEM_LOADING_SHARED_CASES';
export const ON_SYSTEM_THEME_CHANGED = 'ON_SYSTEM_THEME_CHANGED';
export const ON_SYSTEM_UPDATING_BUNDLE = 'ON_SYSTEM_UPDATING_BUNDLE';
export const ON_SYSTEM_UPDATED_BUNDLE = 'ON_SYSTEM_UPDATED_BUNDLE';
export const ON_SYSTEM_UPDATING_DESKSPACE = 'ON_SYSTEM_UPDATING_DESKSPACE';
export const ON_SYSTEM_UPDATED_DESKSPACE = 'ON_SYSTEM_UPDATED_DESKSPACE';
export const ON_SYSTEM_OPERATION_DRAG = 'ON_SYSTEM_OPERATION_DRAG';
export const ON_SYSTEM_OPERATION_MOVE = 'ON_SYSTEM_OPERATION_MOVE';
export const ON_SYSTEM_OPERATION_NULL = 'ON_SYSTEM_OPERATION_NULL';
export const ON_SYSTEM_LOADING_CASES = 'ON_SYSTEM_LOADING_CASES';
export const ON_SYSTEM_LOADED_CASES = 'ON_SYSTEM_LOADED_CASES';
export const ON_SYSTEM_LOADING_MERGED_PDF = 'ON_SYSTEM_LOADING_MERGED_PDF';
export const ON_SYSTEM_LOADED_MERGED_PDF = 'ON_SYSTEM_LOADED_MERGED_PDF';
export const ON_SYSTEM_DELETING_CASE = 'ON_SYSTEM_DELETING_CASE';
export const ON_SYSTEM_DELETED_CASE = 'ON_SYSTEM_DELETED_CASE';
export const ON_SYSTEM_REMOVING_CASE = 'ON_SYSTEM_REMOVING_CASE';
export const ON_SYSTEM_REMOVED_CASE = 'ON_SYSTEM_REMOVED_CASE';
export const ON_SYSTEM_RECOVERING_CASE = 'ON_SYSTEM_RECOVERING_CASE';
export const ON_SYSTEM_RECOVERED_CASE = 'ON_SYSTEM_RECOVERED_CASE';
export const ON_SYSTEM_SHARING_CASE = 'ON_SYSTEM_SHARING_CASE';
export const ON_SYSTEM_SHARED_CASE = 'ON_SYSTEM_SHARED_CASE';
export const ON_SYSTEM_DELETING_ITEM = 'ON_SYSTEM_DELETING_ITEM';
export const ON_SYSTEM_DELETED_ITEM = 'ON_SYSTEM_DELETED_ITEM';
export const ON_USER_RENAME_BUNDLE = 'ON_USER_RENAME_BUNDLE';
export const ON_SYSTEM_LOADING_TRASH_CASES = 'ON_SYSTEM_LOADING_TRASH_CASES';
export const ON_SYSTEM_LOADED_TRASH_CASES = 'ON_SYSTEM_LOADED_TRASH_CASES';
export const ON_SYSTEM_UPDATING_USER_DETAILS = 'ON_SYSTEM_UPDATING_USER_DETAILS';
export const ON_SYSTEM_UPDATED_USER_DETAILS = 'ON_SYSTEM_UPDATED_USER_DETAILS';

export const ON_SYSTEM_UNLOADED = 'ON_SYSTEM_UNLOADED';

export const ON_THEME_LOADED = 'ON_THEME_LOADED';
export const ON_TOGGLE_THEME = 'ON_TOGGLE_THEME';
export const ON_CASE_CLOSED = 'ON_CASE_CLOSED';
export const ON_PAGINATION_TOGGLE = 'ON_PAGINATION_TOGGLE';
export const ON_PAGINATION_ON = 'ON_PAGINATION_ON';
export const ON_PAGINATION_OFF = 'ON_PAGINATION_OFF';
export const ON_SYSTEM_PAGINATION_START = 'ON_SYSTEM_PAGINATION_START';
export const ON_SYSTEM_PAGINATION_DONE = 'ON_SYSTEM_PAGINATION_DONE';
export const ON_SYSTEM_CLEAN = 'ON_SYSTEM_CLEAN';
export const ON_SYSTEM_DOCUMENTS_INDEXES_CALCULATED = 'ON_SYSTEM_DOCUMENTS_INDEXES_CALCULATED';
export const ON_ADDED_OR_REMOVED_CASE_TO_OR_FROM_FAVORITE_CASES = 'ON_ADDED_OR_REMOVED_CASE_TO_OR_FROM_FAVORITE_CASES';

export const NUMERIC_PAGINATION = 'numeric';
export const ALPHA_NUMERIC_PAGINATION = 'alphanumeric';

export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_FAIL = 'SIGNIN_FAIL';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';
export const SIGNOUT_FAIL = 'SIGNOUT_FAIL';

export const SET_TO_BE_RENAMED_CASE_ID = 'SET_TO_BE_RENAMED_CASE_ID';
export const CLEAR_TO_BE_RENAMED_CASE_ID = 'CLEAR_TO_BE_RENAMED_CASE_ID';

export const SET_TO_BE_SHARED_CASE_ID = 'SET_TO_BE_SHARED_CASE_ID';
export const CLEAR_TO_BE_SHARED_CASE_ID = 'CLEAR_TO_BE_SHARED_CASE_ID';

export const SET_TO_BE_REMOVED_CASE_ID = 'SET_TO_BE_REMOVED_CASE_ID';
export const CLEAR_TO_BE_REMOVED_CASE_ID = 'CLEAR_TO_BE_REMOVED_CASE_ID';

export const SET_TO_BE_DELETED_CASE_ID = 'SET_TO_BE_DELETED_CASE_ID';
export const CLEAR_TO_BE_DELETED_CASE_ID = 'CLEAR_TO_BE_DELETED_CASE_ID';

export const SET_TO_BE_RECOVERED_CASE_ID = 'SET_TO_BE_RECOVERED_CASE_ID';
export const CLEAR_TO_BE_RECOVERED_CASE_ID = 'CLEAR_TO_BE_RECOVERED_CASE_ID';

export const ON_USER_DETAILS_UPDATED = 'ON_USER_DETAILS_UPDATED';

export const ON_PAGINATION_SETTINGS_UPDATED = 'ON_PAGINATION_SETTINGS_UPDATED';
export const ON_SYSTEM_PAGINATION_SETTINGS_UPDATING = 'ON_SYSTEM_PAGINATION_SETTINGS_UPDATING';
export const ON_SYSTEM_PAGINATION_SETTINGS_UPDATED = 'ON_SYSTEM_PAGINATION_SETTINGS_UPDATED';

export const ON_NOTES_N_FILES_METADATA_LOADED = 'ON_NOTES_N_FILES_METADATA_LOADED';

export const ON_NOTE_ADDED = 'ON_NOTE_ADDED';
export const ON_NOTE_REMOVED = 'ON_NOTE_REMOVED';
export const ON_NOTE_UPDATED  = 'ON_NOTE_UPDATED';

export const ADMIN_ON_USER_DETAILS_LOADED = 'ADMIN_ON_USER_DETAILS_LOADED';
export const ADMIN_ON_USERS_LOADED = 'ADMIN_ON_USERS_LOADED';
export const ADMIN_ON_SEARCHED_USERS_LOADED = 'ADMIN_ON_SEARCHED_USERS_LOADED';
export const ADMIN_ON_SEARCHED_SUBSCRIPTION_LOADED = 'ADMIN_ON_SEARCHED_SUBSCRIPTION_LOADED';
export const ADMIN_ON_SEARCHED_MESSAGES_LOADED = 'ADMIN_ON_SEARCHED_MESSAGES_LOADED';
export const ADMIN_ON_ADD_MESSAGE = 'ADMIN_ON_ADD_MESSAGE';
export const ADMIN_ON_USER_REGISTRATION_MONTHLY_TREND_DATA_LOADED = 'ADMIN_ON_USER_REGISTRATION_MONTHLY_TREND_DATA_LOADED';
export const ADMIN_ON_DASHBOARD_METRICS_LOADED = 'ADMIN_ON_DASHBOARD_METRICS_LOADED';
export const ADMIN_ON_PAGE_NUMBER_FORMAT_USAGE_STATS_LOADED = 'ADMIN_ON_PAGE_NUMBER_FORMAT_USAGE_STATS_LOADED';
export const ADMIN_ON_ACTIVE_USER_LOG_LOADED = 'ADMIN_ON_ACTIVE_USER_LOG_LOADED';
export const ADMIN_ON_REVENUE_BY_PLAN_DATA_LOADED = 'ADMIN_ON_REVENUE_BY_PLAN_DATA_LOADED';
export const ADMIN_ON_LOGS_LOADED = 'ADMIN_ON_LOGS_LOADED';
export const ADMIN_ON_AUDIT_TRAILS_LOADED = 'ADMIN_ON_AUDIT_TRAILS_LOADED';
export const ADMIN_ON_MESSAGES_LOADED = 'ADMIN_ON_MESSAGES_LOADED';
export const ADMIN_ON_PAYMENTS_LOADED = 'ADMIN_ON_PAYMENTS_LOADED';

export const ADMIN_ON_SITE_LOADED = 'ADMIN_ON_SITE_LOADED';
export const ADMIN_ON_SITE_HEADER_UPDATED = 'ADMIN_ON_SITE_HEADER_UPDATED';
export const ADMIN_ON_SITE_LANDING_HERO_UPDATED = 'ADMIN_ON_SITE_LANDING_HERO_UPDATED';
export const ADMIN_ON_SITE_LANDING_SERVICES_UPDATED = 'ADMIN_ON_SITE_LANDING_SERVICES_UPDATED';
export const ADMIN_ON_SITE_LANDING_ABOUT_US_UPDATED = 'ADMIN_ON_SITE_LANDING_ABOUT_US_UPDATED';
export const ADMIN_ON_SITE_LANDING_FEATURES_UPDATED = 'ADMIN_ON_SITE_LANDING_FEATURES_UPDATED';
export const ADMIN_ON_SITE_LANDING_MISSION_UPDATED = 'ADMIN_ON_SITE_LANDING_MISSION_UPDATED';
export const ADMIN_ON_SITE_LANDING_MARQEE_ITEMS_UPDATED = 'ADMIN_ON_SITE_LANDING_MARQEE_ITEMS_UPDATED';
export const ADMIN_ON_SITE_LANDING_OVERVIEW_UPDATED = 'ADMIN_ON_SITE_LANDING_OVERVIEW_UPDATED';
export const ADMIN_ON_SITE_LANDING_TESTIMONIALS_UPDATED = 'ADMIN_ON_SITE_LANDING_TESTIMONIALS_UPDATED';
export const ADMIN_ON_SITE_LANDING_STATS_UPDATED = 'ADMIN_ON_SITE_LANDING_STATS_UPDATED';
export const ADMIN_ON_SITE_LANDING_FOOTER_UPDATED = 'ADMIN_ON_SITE_LANDING_FOOTER_UPDATED';

export const ON_SITE_CONTENT_LOADING = 'ON_SITE_CONTENT_LOADING';
export const ON_SITE_CONTENT_LOADED = 'ON_SITE_CONTENT_LOADED';

export const ON_ACTIVE_SUBSCRIPTION_PLANS_LOADED = 'ON_ACTIVE_SUBSCRIPTION_PLANS_LOADED';

export const ADMIN_ON_SUBSCRIPTION_PLANS_LOADED = 'ADMIN_ON_SUBSCRIPTION_PLANS_LOADED';

export const ADMIN_ADD_SUBSCRIPTION_PLAN_REQUEST = 'ADMIN_ADD_SUBSCRIPTION_PLAN_REQUEST';
export const ADMIN_ADD_SUBSCRIPTION_PLAN_SUCCESS = 'ADMIN_ADD_SUBSCRIPTION_PLAN_SUCCESS';
export const ADMIN_ADD_SUBSCRIPTION_PLAN_FAILURE = 'ADMIN_ADD_SUBSCRIPTION_PLAN_FAILURE';


export const ADMIN_DEACTIVATE_SUBSCRIPTION_PLAN_SUCCESS = 'ADMIN_DEACTIVATE_SUBSCRIPTION_PLAN_SUCCESS';


export const ADMIN_ON_SERVICES_CONTENT_UPDATED = 'ADMIN_ON_SERVICES_CONTENT_UPDATED';
export const ADMIN_ON_SERVICES_STATS_UPDATED = 'ADMIN_ON_SERVICES_STATS_UPDATED';

export const ADMIN_ON_CONTACT_US_FORM_UPDATED = 'ADMIN_ON_CONTACT_US_FORM_UPDATED';
export const ADMIN_ON_CONTACT_US_INFO_UPDATED = 'ADMIN_ON_CONTACT_US_INFO_UPDATED';

export const ADMIN_ON_FAQ_BODY_UPDATED = 'ADMIN_ON_FAQ_BODY_UPDATED';

export const ADMIN_ON_TEAM_BODY_UPDATED = 'ADMIN_ON_TEAM_BODY_UPDATED';

export const ADMIN_ON_ABOUT_US_CONTENT_UPDATED = 'ADMIN_ON_ABOUT_US_CONTENT_UPDATED';
export const ADMIN_ON_ABOUT_US_MARQUEE_ITEMS_UPDATED = 'ADMIN_ON_ABOUT_US_MARQUEE_ITEMS_UPDATED';
export const ADMIN_ON_ABOUT_US_FEATURES_UPDATED = 'ADMIN_ON_ABOUT_US_FEATURES_UPDATED';
export const ADMIN_ON_ABOUT_US_TIMELINE_UPDATED = 'ADMIN_ON_ABOUT_US_TIMELINE_UPDATED';

export const ADMIN_ON_TERMS_AND_CONDITION_BODY_UPDATED = 'ADMIN_ON_TERMS_AND_CONDITION_BODY_UPDATED';

export const ADMIN_ON_PRIVACY_POLICY_BODY_UPDATED = 'ADMIN_ON_PRIVACY_POLICY_BODY_UPDATED';

export const WEBSITE_SETTINGS_LOADED = 'WEBSITE_SETTINGS_LOADED';

export const ADMIN_ON_WEBSITE_SETTINGS_LOADED = 'ADMIN_ON_WEBSITE_SETTINGS_LOADED';
export const ADMIN_ON_WEBSITE_SETTINGS_UPDATED = 'ADMIN_ON_WEBSITE_SETTINGS_UPDATED';

export const ON_SYSTEM_CREATING_CONNECTED_USER = 'ON_SYSTEM_CREATING_CONNECTED_USER';
export const ON_SYSTEM_CONNECTED_USER_CREATED = 'ON_SYSTEM_CONNECTED_USER_CREATED';

export const ON_CONNECTED_USERS_LOADED = 'ON_CONNECTED_USERS_LOADED';
export const ON_CONNECTED_USER_CREATED = 'ON_CONNECTED_USER_CREATED';