

import React, { useEffect, useState } from 'react';

import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import Stack from '@mui/joy/Stack';


import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { renameItem } from '../redux/actions';
import { CssVarsProvider } from '@mui/joy';

const RenameLabelModal = React.memo((props) => {
    const { open, setOpen, item, renameItem, caseData, user, enqueueSnackbar } = props;

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const { value } = event.target.name;
        if (value.length > 0 && value !== item.name) {
            renameItem(caseData._id, user.apiKey, item, value, enqueueSnackbar);
        }
        handleClose();
    };

    return (
        <React.Fragment>
        <CssVarsProvider>
        <Modal open={open} onClose={handleClose}>
            <ModalDialog>
            <DialogTitle>Rename "{item.name}" {item.type}</DialogTitle>
            <DialogContent>To rename this {item.type}, please enter your new name here.</DialogContent>
            <form
                onSubmit={handleSubmit}
            >
                <Stack spacing={2}>
                <FormControl>
                    <FormLabel>{item.type} name</FormLabel>
                    <Input autoFocus required name='name' id='name' type='text' />
                </FormControl>
                <Button type="submit">Submit</Button>
                </Stack>
            </form>
            </ModalDialog>
        </Modal>
        </CssVarsProvider>
        </React.Fragment>
    );
});


const mapStateToProps = (store) => ({
    caseData: store.caseData,
    user: store.user,
    bundleBusy: store.app.bundleUpdating,
    deskBusy: store.app.creatingFolder,
    toolBarBusy: store.app.toolBarBusy,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({
        renameItem,
    }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RenameLabelModal);
