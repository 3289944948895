import React, { useEffect, useState } from 'react';
import {
  Sheet,
  FormControl,
  FormLabel,
  Input,
  Button,
  Typography,
  Card,
  CardContent,
  Grid,
  Stack,
} from '@mui/joy';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateSiteLandingServices } from '../redux/actions/index.js';


const AdminLandingServicesEditor = ({ content, apiKey, siteId, updateSiteLandingServices }) => {
    const [services, setServices] = useState({})

    useEffect(() => {
        setServices(content);
    }, [content])

    const handleImageChange = (e, index) => {
        const file = e.target.files[0];
        if (file) {
            setServices(prev => ({
                ...prev,
                cards: prev.cards.map((card, i) => index === i ? ({...card, file, imageSrc: URL.createObjectURL(file)}) : card )
            }))
        };
    }

    const handleSubmit = (event) => {
        const formData = new FormData();
        formData.append('services', JSON.stringify(services));

        for(let i=0;i<services.cards.length;i++){
            if (services.cards[i]?.file) {
                formData.append(`cardImage_${i}`, services.cards[i].file);
            }
        }

        updateSiteLandingServices(apiKey, siteId, formData);
    }
    return (
        <>
        <Typography level="h4" gutterBottom>
            Edit Services Section
        </Typography>
        <Sheet sx={{mb: 3, p: 2, borderRadius: '10px'}} variant={'outlined'}>
        <FormControl fullwidth={'true'} sx={{ mb: 2 }}>
            <FormLabel>Title</FormLabel>
            <Input
            value={services?.title}
            onChange={e => setServices(prev => ({
                ...prev,
                title: e.target.value
            }))}
            />
        </FormControl>
        <FormControl fullwidth={'true'} sx={{ mb: 2 }}>
            <FormLabel>Subtitle</FormLabel>
            <Input
            value={services?.subtitle}
            onChange={e => setServices(prev => ({
                ...prev,
                subtitle: e.target.value
            }))}
            />
        </FormControl>
        <Grid container spacing={2} columns={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
            {services?.cards?.map((card, index) => (
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Card key={index} sx={{ mb: 2 }} variant={'soft'}>
                    <Stack spacing={2}>
                        <img src={card.imageSrc} alt={card.title} />
                        <input type="file" accept="image/*" onChange={(e) => handleImageChange(e, index)} />
                    </Stack>
                    <CardContent>
                        <FormControl fullwidth={'true'} sx={{ mb: 1 }}>
                        <FormLabel>Card Title</FormLabel>
                        <Input
                            value={card.title}
                            onChange={e => setServices(prev => ({
                                ...prev,
                                cards: prev.cards.map((c, i) => index === i ? ({...c, title: e.target.value}) : c)
                            }))}
                        />
                        </FormControl>
                        <FormControl fullwidth={'true'} sx={{ mb: 1 }}>
                        <FormLabel>Card Description</FormLabel>
                        <Input
                            value={card.description}
                            onChange={e => setServices(prev => ({
                                ...prev,
                                cards: prev.cards.map((c, i) => index === i ? ({...c, description: e.target.value}) : c)
                            }))}
                        />
                        </FormControl>
                        <FormControl fullwidth={'true'} sx={{ mb: 1 }}>
                        <FormLabel>Card Link</FormLabel>
                        <Input
                            value={card.link}
                            onChange={e => setServices(prev => ({
                                ...prev,
                                cards: prev.cards.map((c, i) => index === i ? ({...c, link: e.target.value}) : c)
                            }))}
                        />
                        </FormControl>
                    </CardContent>
                    </Card>
                </Grid>
            ))}
            </Grid>
            <Button variant='soft' fullWidth color={'success'} onClick={handleSubmit}>Save Services Changes</Button>
        </Sheet>
        </>
    )
}

const mapStateToProps = (store) => ({
    apiKey: store.admin?.apiKey,
    siteId: store.admin.site._id,
    content: store.admin.site.landing.services
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateSiteLandingServices
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AdminLandingServicesEditor);