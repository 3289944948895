import React, { useEffect, useState } from 'react';
import {
  CssVarsProvider,
  Sheet,
  FormControl,
  FormLabel,
  Input,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  Container,
  AspectRatio,
  Grid,
  Stack,
  CssBaseline,
  Breadcrumbs,
  Link
} from '@mui/joy';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadAdminSite, updateSiteLandingTestimonials } from '../redux/actions/index.js';

import GradientCover from '../components/GradientCover.js';


const AdminLandingTestimonialsEditor = ({content, apiKey, siteId, updateSiteLandingTestimonials}) => {
    const [testimonials, setTestimonials] = useState({})

    useEffect(() => {
        setTestimonials(content);
    }, [content])

    const handleImageChange = (e, index) => {
        const file = e.target.files[0];
        if (file) {
            setTestimonials(prev => ({
                ...prev,
                items: prev.items.map((v, i) => index === i ? ({...v, imageSrc: URL.createObjectURL(file), file }) : v )
            }))
        }
    }

    const handleSubmit = (event) => {
        const formData = new FormData();
        formData.append('testimonials', JSON.stringify(testimonials));

        for(let i=0;i<testimonials.items.length;i++){
            if (testimonials.items[i]?.file) {
                formData.append(`image_${i}`, testimonials.items[i].file);
            }
        }

        updateSiteLandingTestimonials(apiKey, siteId, formData)
    }

    return (
        <>
            <Typography level="h4" gutterBottom>
                Testimonials Section
            </Typography>
            <Sheet sx={{ mb: 3, p: 2, borderRadius: '10px' }} variant={'outlined'}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <FormLabel>Title</FormLabel>
                    <Input
                        value={testimonials?.title}
                        onChange={(e) => setTestimonials(prev => ({
                            ...prev,
                            title: e.target.value
                        }))}
                    />
                </FormControl>
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <FormLabel>Subtitle</FormLabel>
                    <Input
                        value={testimonials?.subtitle}
                        onChange={(e) => setTestimonials(prev => ({
                            ...prev,
                            subtitle: e.target.value
                        }))}
                    />
                </FormControl>
                <Typography level="h5" gutterBottom>
                    Testimonial Items
                </Typography>
                <Grid container spacing={2} columns={{ xs: 12, md: 12, lg: 12, xl: 12 }}>
                    {testimonials?.items?.map((item, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Sheet variant={'outlined'} key={item._id.$oid} sx={{ display: 'flex', flexDirection: 'column', p: 2, gap: 2, mb: 2, borderRadius: '10px' }}>
                                <FormControl fullWidth>
                                    <FormLabel>Name</FormLabel>
                                    <Input
                                        value={item.name}
                                        onChange={(e) => setTestimonials(prev => ({
                                            ...prev,
                                            items: prev.items.map((value, i) => index === i ? ({ ...value, name: e.target.value }) : value)
                                        }))}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <FormLabel>Position</FormLabel>
                                    <Input
                                        value={item.position}
                                        onChange={(e) => setTestimonials(prev => ({
                                            ...prev,
                                            items: prev.items.map((value, i) => index === i ? ({ ...value, position: e.target.value }) : value)
                                        }))}
                                    />
                                </FormControl>
                                <AspectRatio ratio={'1/1'}>
                                    <img src={item.imageSrc} alt={item.name} />
                                </AspectRatio>
                                <input type="file" accept="image/*" onChange={(e) => handleImageChange(e, index)} />
                                <FormControl fullWidth>
                                    <FormLabel>Quote</FormLabel>
                                    <Input
                                        maxRows={5}
                                        value={item.quote}
                                        onChange={(e) => setTestimonials(prev => ({
                                            ...prev,
                                            items: prev.items.map((value, i) => index === i ? ({ ...value, quote: e.target.value }) : value)
                                        }))}
                                    />
                                </FormControl>
                            </Sheet>
                        </Grid>
                    ))}
                </Grid>
                <Button onClick={handleSubmit} color={'success'} fullWidth variant="soft">Save Testimonials Changes</Button>
            </Sheet>
        </>
    )
}

const mapStateToProps = (store) => ({
    apiKey: store.admin?.apiKey,
    siteId: store.admin.site._id,
    content: store.admin.site.landing.testimonials
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateSiteLandingTestimonials
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AdminLandingTestimonialsEditor);