import React, { useEffect, useState } from 'react';
import {
    Avatar,
    Box,
    Chip,
    Divider,
    Link,
    Table,
    Sheet,
    Checkbox,
    IconButton,
    Typography,
    Menu,
    MenuButton,
    MenuItem,
    Dropdown
} from '@mui/joy';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import BlockIcon from '@mui/icons-material/Block';
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import AdminSubscriptionRow from './AdminSubscriptionRow';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order,  orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function AdminSubscriptionRows({ rows: plans, page }){
    const [order, setOrder] = useState('desc');
    const [selected, setSelected] = useState([]);
    const [rows, setRows] = useState(plans[page] || []);

    useEffect(() => {
        setRows(plans[page] || [])
    }, [plans, page])

    return (
        <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: 'none', sm: 'initial' },
          width: '100%',
          borderRadius: 'sm',
          flexShrink: 1,
          overflow: 'auto',
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
            '--TableCell-paddingY': '4px',
            '--TableCell-paddingX': '8px',
          }}
        >
          <thead>
            <tr>
                <th style={{ width: 48, textAlign: 'center', padding: '12px 6px' }}>
                    <Checkbox
                    size="sm"
                    indeterminate={
                        selected.length > 0 && selected.length !== rows?.length
                    }
                    checked={selected.length === rows?.length}
                    onChange={(event) => {
                        setSelected(
                        event.target.checked ? rows.map((row) => row._id) : [],
                        );
                    }}
                    color={
                        selected.length > 0 || selected.length === rows.length
                        ? 'primary'
                        : undefined
                    }
                    sx={{ verticalAlign: 'text-bottom' }}
                    />
                </th>
                <th style={{ width: 120, padding: '12px 6px' }}>
                    <Link
                    underline="none"
                    color="primary"
                    component="button"
                    onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')}
                    fontWeight="lg"
                    endDecorator={<ArrowDropDownIcon />}
                    sx={{
                        '& svg': {
                        transition: '0.2s',
                        transform:
                            order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
                        },
                    }}
                    >
                    Name
                    </Link>
                </th>
                <th>Bundle Limit</th>
                <th>Space Limit (GB)</th>
                <th>Price ($)</th>
                <th>Subscription Type</th>
                <th>Status</th>
                <th>Trial Period (days)</th>
                <th>Renewal Interval</th>
                <th align="center">Created On</th>
                <th style={{ width: 140, padding: '12px 6px' }}> </th>
            </tr>
          </thead>
          <tbody>
            {stableSort(rows, getComparator(order, '_id')).map((row) => (
              <AdminSubscriptionRow row={row} selected={selected} setSelected={setSelected} page={page}/>
            ))}
          </tbody>
        </Table>
      </Sheet>
    )
}

export default AdminSubscriptionRows;