import React, { useState } from 'react';
import Box from '@mui/joy/Box';
import ModalClose from '@mui/joy/ModalClose';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Textarea from '@mui/joy/Textarea';
import Sheet from '@mui/joy/Sheet';
import { IconButton, Input, Option, Select, Stack, Typography } from '@mui/joy';

import FormatColorTextRoundedIcon from '@mui/icons-material/FormatColorTextRounded';
import AttachFileRoundedIcon from '@mui/icons-material/AttachFileRounded';
import InsertPhotoRoundedIcon from '@mui/icons-material/InsertPhotoRounded';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { postMessage } from '../redux/actions';

const AdminWriteMessage = React.forwardRef(
  function WriteEmail({ open, onClose, apiKey, postMessage }, ref) {
    const now = new Date ();
    const currentDate = now.toISOString ().split ('T')[0];
    const currentTime = now.toTimeString ().slice (0, 5);

    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [scheduleDate, setScheduleDate] = useState(currentDate);
    const [scheduleTime, setScheduleTime] = useState (currentTime);

    const handleChangeTitle = (event) => {
      if (event.target.value.length <= 60) {
        setTitle (event.target.value);
      }
    };

    const handleChangeContent = (event) => {
      if(event.target.value.length <= 1200){
        setContent (event.target.value);
      }
    }

    const handleDateChange = (event) => {
      setScheduleDate(event.target.value);
    }

    const handleSubmit = (event) => {
      event.preventDefault();
      const message = {
        type: event.target.type[1].value,
        scheduleTime: new Date(scheduleDate + 'T' + scheduleTime),
        title,
        content
      }
      postMessage(apiKey, message);
      onClose();
    }

    return (
      <Sheet
        ref={ref}
        sx={{
          alignItems: 'center',
          px: 1.5,
          py: 1.5,
          ml: 'auto',
          width: { xs: '100dvw', md: 600 },
          flexGrow: 1,
          border: '1px solid',
          borderRadius: '8px 8px 0 0',
          backgroundColor: 'background.level1',
          borderColor: 'neutral.outlinedBorder',
          boxShadow: 'lg',
          zIndex: 1000,
          position: 'fixed',
          bottom: 0,
          right: 24,
          transform: open ? 'translateY(0)' : 'translateY(100%)',
          transition: 'transform 0.3s ease',
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Typography level="title-sm">New message</Typography>
          <ModalClose id="close-icon" onClick={onClose} />
        </Box>
        <form onSubmit={handleSubmit}>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', gap: 2, flexShrink: 0 }}
        >
          <FormControl>
            <FormLabel>Type</FormLabel>
            <Select name='type' required placeholder='Select Message Type...'>
              <Option value="info">Info</Option>
              <Option value="warning">Warning</Option>
              <Option value="alert">Alert</Option>
              <Option value="error">Error</Option>
              <Option value="critical">Critical</Option>
            </Select>
          </FormControl>
          <Stack spacing={1}>
            <FormLabel>Schedule</FormLabel>
            <Stack spacing={2} direction={'row'}>
              <FormControl sx={{flex: 1}} >
                <Input onKeyDown={(event) => event.preventDefault()} onChange={handleDateChange} required slotProps={{ input: { min: currentDate } }} type="date" />
              </FormControl>
              <FormControl sx={{flex: 1}} >
                <Input required slotProps={{ input: (scheduleDate === currentDate) ? { min: currentTime } : {} }} type='time' />
              </FormControl>
            </Stack>
          </Stack>
          <Input required value={title} onChange={handleChangeTitle} endDecorator={<Typography>{`${title.length}/${60}`}</Typography>} placeholder="Title" aria-label="Message" />
          <FormControl sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Textarea
              placeholder="Type your message here…"
              aria-label="Message"
              minRows={8}
              value={content}
              onChange={handleChangeContent}
              required
              endDecorator={
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  flexGrow={1}
                  sx={{
                    py: 1,
                    pr: 1,
                    borderTop: '1px solid',
                    borderColor: 'divider',
                  }}
                >
                  <div style={{p: 2}}>
                    <Typography sx={{ml: 1}}>{`${content.length}/${1200}`}</Typography>
                  </div>
                  <Button
                    color="primary"
                    sx={{ borderRadius: 'sm' }}
                    type='submit'
                  >
                    Send
                  </Button>
                </Stack>
              }
              sx={{
                '& textarea:first-of-type': {
                  minHeight: 72,
                },
              }}
            />
          </FormControl>
        </Box>
        </form>
      </Sheet>
    );
  }
);

const mapStateToProps = (store) => ({
  apiKey: store.admin.apiKey
})
const mapDispatchProps = (dispatch) => bindActionCreators({
  postMessage
}, dispatch);
export default connect(mapStateToProps, mapDispatchProps)(AdminWriteMessage);