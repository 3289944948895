import React from "react";

export default function AboutUsComponent({ content }) {
  return (
    <section>
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-lg-8 col-md-12">
            <div className="theme-title mb-4">
              <h6>{content.title}</h6>
              <h2>{content.subtitle}</h2>
            </div>
            <p className="mb-5">
              {content.description}
            </p>
            {/* <a className="themeht-btn" href={content.button.href}>
              {content.button.text}
            </a> */}
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 mt-6 mt-lg-0 ps-lg-10">
            <div className="accordion" id="accordion">
              {content.accordionItems.map((item, index) => (
                <div className={`accordion-item bg-transparent ${index < content.accordionItems.length - 1 ? "mb-4" : ""}`} key={item.id}>
                  <h3 className="accordion-header" id={item.id}>
                    <button
                      className={`accordion-button shadow-none mb-0 bg-transparent ${!item.expanded ? "collapsed" : ""}`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={item.target}
                      aria-expanded={item.expanded}
                      aria-controls={item.target.slice(1)}
                    >
                      {item.title}
                    </button>
                  </h3>
                  <div
                    id={item.target.slice(1)}
                    className={`accordion-collapse border-0 collapse ${item.expanded ? "show" : ""}`}
                    aria-labelledby={item.id}
                    data-bs-parent="#accordion"
                  >
                    <div className="accordion-body">{item.body}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <img className="img-fluid" src="images/about/03.png" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}
