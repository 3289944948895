import React, { useState, useEffect } from 'react';
import { FormControl, FormLabel, Input, Typography, Box, Sheet, Button, Stack } from '@mui/joy';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateSiteContactInfo } from '../redux/actions/index.js';

const AdminContactUsInfoEditor = ({ content, apiKey, siteId, updateSiteContactInfo }) => {
  const [contactInfo, setContactInfo] = useState(content || {});

  useEffect(() => {
    setContactInfo(content || {});
  }, [content]);

  const handleChange = (field, value) => {
    const updatedInfo = { ...contactInfo, [field]: value };
    setContactInfo(updatedInfo);
  };

  const handleSubmit = (event) => {
    updateSiteContactInfo(apiKey, siteId, contactInfo)
  }

  return (
    <>
      <Typography level="h4" gutterBottom>
        Contact Information
      </Typography>
      <Sheet sx={{ mb: 3, p: 2, borderRadius: '10px' }} variant={'outlined'}>
        <Stack spacing={2}>
          <FormControl fullWidth>
            <FormLabel>Email</FormLabel>
            <Input
              value={contactInfo.email || ''}
              onChange={(e) => handleChange('email', e.target.value)}
              type="email"
            />
          </FormControl>
          <FormControl fullWidth>
            <FormLabel>Phone</FormLabel>
            <Input
              value={contactInfo.phone || ''}
              onChange={(e) => handleChange('phone', e.target.value)}
              type="tel"
            />
          </FormControl>
          <FormControl fullWidth>
            <FormLabel>Address</FormLabel>
            <Input
              value={contactInfo.address || ''}
              onChange={(e) => handleChange('address', e.target.value)}
              type="text"
            />
          </FormControl>
          <Button variant='soft' color='success' fullWidth onClick={handleSubmit}>Save Changes</Button>
        </Stack>
      </Sheet>
    </>
  );
};

const mapStateToProps = (store) => ({
  apiKey: store.admin?.apiKey,
  siteId: store.admin.site._id,
  content: store.admin.site.contactUs.info
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateSiteContactInfo
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AdminContactUsInfoEditor);
