import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateUser } from '../redux/actions';
import { useNavigate } from 'react-router-dom';

const PayPalButton = ({ userId, planId, apiKey, updateUser }) => {
  const navigate = useNavigate();
  const createOrder = async () => {
    try {
      const response = await fetch('/api/user/create-order', {
        method: 'POST',
        headers: {
          'x-api-key': apiKey,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ planId, userId }),
      });

      if (!response.ok) {
        throw new Error('Failed to create order');
      }

      const data = await response.json();
      return data.id;
    } catch (error) {
      console.error('Error creating order:', error);
      return null;
    }
  };

  const onApprove = async (data) => {
    try {
      const response = await fetch('/api/user/capture-order', {
        method: 'POST',
        headers: {
          'x-api-key': apiKey,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ orderId: data.orderID, userId, planId }),
      });

      if (!response.ok) {
        throw new Error('Failed to capture order');
      }

      const details = await response.json();
      updateUser(details.user);
      alert(details.status);
      navigate('/');
    } catch (error) {
      console.error('Error capturing order:', error);
    }
  };

  useEffect(() => {
    window.paypal.Buttons({
      fundingSource: window.paypal.FUNDING.PAYPAL,
      createOrder: createOrder,
      onApprove: onApprove,
    }).render('#paypal-button-container');
  }, []);

  return <div id="paypal-button-container"></div>;
};

const mapStateToProps = (store) => ({
  userId: store.user._id,
  apiKey: store.user.apiKey
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateUser
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PayPalButton);
