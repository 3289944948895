import React, { useState, useEffect } from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Stack from '@mui/joy/Stack';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Typography from '@mui/joy/Typography';
import Card from '@mui/joy/Card';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton } from '@mui/joy';

import ModalClose from '@mui/joy/ModalClose';
import Sheet from '@mui/joy/Sheet';
import AddIcon from '@mui/icons-material/Add';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addConnectedUser } from '../redux/actions';
import { useSnackbar } from 'notistack';


const AddConnectedUserForm = React.forwardRef(
  function WriteEmail({ open, onClose, user, creatingConnectedUser, addConnectedUser }, ref) {
    const [firstname, setFirstName] = useState('');
    const [lastname, setLastName] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const [confirmPasswordVisibility, setConfirmPasswordVisibility] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const handleClose = () => {
        onClose();
    }

    const handleConnectedUserCreation = (event) => {
        event.preventDefault();
        if(firstname.trim().length > 0 && lastname.trim().length > 0 && username.trim().length > 0 && email.trim().length > 0 && businessName.trim().length > 0 && password.trim().length > 0 && confirmPassword.trim().length > 0){
            if(password.trim() === confirmPassword.trim()){
                addConnectedUser(user.apiKey, { firstname, lastname, username, email, businessName, password }, enqueueSnackbar, handleClose)
            } else {
                enqueueSnackbar('Password does not match!', { variant: 'warning' })
            }
        } else {
            enqueueSnackbar('All fields are required!', { variant: 'warning' })
        }        
    }

    return (
      <Sheet
        ref={ref}
        sx={{
          alignItems: 'center',
          px: 1.5,
          py: 1.5,
          ml: 'auto',
          width: { xs: '100dvw', md: 600 },
          flexGrow: 1,
          border: '1px solid',
          borderRadius: '8px 8px 0 0',
          backgroundColor: 'background.level1',
          borderColor: 'neutral.outlinedBorder',
          boxShadow: 'lg',
          zIndex: 1000,
          position: 'fixed',
          bottom: 0,
          right: 24,
          transform: open ? 'translateY(0)' : 'translateY(100%)',
          transition: 'transform 0.3s ease',
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Typography level="title-sm">New Connected User</Typography>
          <ModalClose id="close-icon" onClick={onClose} />
        </Box>
        <Box sx={{ mb: 1 }}>
            <Typography level="body-sm">
                Use this form to add new connected user to your account or project. You can assign roles, set permissions, and manage access levels for each user. This allows you to collaborate effectively by ensuring that each user has the appropriate level of access and responsibility. Enter the details for each new connected user and submit the form to grant them access.
            </Typography>
        </Box>
        <Divider />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, flexShrink: 0, mt: 2 }}>
            <Stack direction={'row'} spacing={2}>
                <FormControl sx={{flex: 1}}>
                    <FormLabel>First Name</FormLabel>
                    <Input placeholder='Enter First Name' value={firstname} onChange={(e) => setFirstName(e.target.value)}/>
                </FormControl>
                <FormControl sx={{flex: 1}}>
                    <FormLabel>Last Name</FormLabel>
                    <Input placeholder='Enter Last Name' value={lastname} onChange={(e) => setLastName(e.target.value)}/>
                </FormControl>
            </Stack>
            <Stack direction={'row'} spacing={2}>
                <FormControl sx={{flex: 1}}>
                    <FormLabel>Business Name</FormLabel>
                    <Input placeholder='Enter Business Name' value={businessName} onChange={(e) => setBusinessName(e.target.value)}/>
                </FormControl>
                <FormControl sx={{flex: 1}}>
                    <FormLabel>Role</FormLabel>
                    <Select placeholder="Select Role" disabled={true} defaultValue={'Additional'}>
                        <Option value='Additional'>Connected User</Option>
                        <Option value='Viewer'>Viewer</Option>
                    </Select>
                </FormControl>
            </Stack>
            <Stack direction={'row'} spacing={2}>
                <FormControl sx={{flex: 1}}>
                    <FormLabel>Username</FormLabel>
                    <Input placeholder='Enter Username' value={username} onChange={(e) => setUsername(e.target.value)}/>
                </FormControl>
                <FormControl sx={{flex: 1}}>
                    <FormLabel>Email</FormLabel>
                    <Input type='email' placeholder='Enter Email' value={email} onChange={(e) => setEmail(e.target.value)}/>
                </FormControl>
            </Stack>
            <Stack direction={'row'} spacing={2}>
                <FormControl sx={{flex: 1}}>
                    <FormLabel>Passowrd</FormLabel>
                    <Input endDecorator={<IconButton onClick={() => setPasswordVisibility(value => !value)}> { !passwordVisibility ? <VisibilityIcon /> : <VisibilityOffIcon /> } </IconButton> } type={!passwordVisibility ? 'password' : 'text'} placeholder='Enter Password' value={password} onChange={(e) => setPassword(e.target.value)}/>
                </FormControl>
                <FormControl sx={{flex: 1}}>
                    <FormLabel>Confirm Passowrd</FormLabel>
                    <Input endDecorator={<IconButton onClick={() => setConfirmPasswordVisibility(value => !value)}> { !confirmPasswordVisibility ? <VisibilityIcon /> : <VisibilityOffIcon />} </IconButton> } type={!confirmPasswordVisibility ? 'password' : 'text'} placeholder='Re-enter Password' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}/>
                </FormControl>
            </Stack>
            <Stack>
                <Button onClick={handleConnectedUserCreation} disabled={creatingConnectedUser} startDecorator={<AddIcon />}>Create New User</Button>
            </Stack>
        </Box>
      </Sheet>
    );
  }
);

const mapStateToProps = (store) => ({
  user: store.user,
  creatingConnectedUser: store.app.creatingConnectedUser
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
    addConnectedUser
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AddConnectedUserForm);