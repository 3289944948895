import React, { useEffect, useState } from 'react';
import {
  CssVarsProvider,
  Sheet,
  FormControl,
  FormLabel,
  Input,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  Container,
  AspectRatio,
  Grid,
  Stack,
  CssBaseline,
  Breadcrumbs,
  Link
} from '@mui/joy';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadAdminSite, updateSiteLandingMission } from '../redux/actions/index.js';

import GradientCover from '../components/GradientCover.js';

const AdminLandingMissionEditor = ({content, apiKey, siteId, updateSiteLandingMission}) => {
    const [mission, setMission] = useState({})

    useEffect(() => {
        setMission(content);
    }, [content])

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setMission(prev => ({
                ...prev,
                image: {
                    ...prev.image,
                    file,
                    src: URL.createObjectURL(file),
                }
            }))
        }
    }

    const handleAddHighlight = () => {
        setMission(prev => ({
            ...prev,
            highlights: [...(prev.highlights || []), '']
        }));
    };

    const handleRemoveHighlight = (index) => {
        setMission(prev => ({
            ...prev,
            highlights: prev.highlights.filter((_, i) => i !== index)
        }));
    };

    const handleSubmit = (event) => {
        const formData = new FormData();
        formData.append('mission', JSON.stringify(mission));
        if(mission.image?.file){
            formData.append(`image`, mission.image.file);
        }
        updateSiteLandingMission(apiKey, siteId, formData)
    }
    
    return (
        <>
            <Typography level="h4" gutterBottom>
                Mission Section
            </Typography>
            <Sheet sx={{ mb: 3, p: 2, borderRadius: '10px' }} variant={'outlined'}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <FormLabel>Mission Title</FormLabel>
                    <Input
                        value={mission?.title}
                        onChange={(e) => setMission(prev => ({
                            ...prev,
                            title: e.target.value
                        }))}
                    />
                </FormControl>
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <FormLabel>Mission Subtitle</FormLabel>
                    <Input
                        value={mission?.subtitle}
                        onChange={(e) => setMission(prev => ({
                            ...prev,
                            subtitle: e.target.value
                        }))}
                    />
                </FormControl>
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <FormLabel>Mission Description</FormLabel>
                    <Input
                        maxRows={5}
                        value={mission?.description}
                        onChange={(e) => setMission(prev => ({
                            ...prev,
                            description: e.target.value
                        }))}
                    />
                </FormControl>
                <Stack direction={'column'} spacing={2} alignItems={'center'}>
                    <GradientCover title={'Mission Image Src'} image={mission?.image} />
                    <input type="file" accept="image/*" onChange={(e) => handleImageChange(e)} />
                </Stack>
                
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <FormLabel>Mission Image Alt</FormLabel>
                    <Input
                        value={mission?.image?.alt}
                        onChange={(e) => setMission(prev => ({
                            ...prev,
                            image: {
                                ...prev.image,
                                alt: e.target.value
                            }
                        }))}
                    />
                </FormControl>
                <Typography level="h6" gutterBottom>
                    Highlights
                </Typography>
                {mission?.highlights?.map((highlight, index) => (
                    <Stack direction={'row'} spacing={2} sx={{mb: 2}}>
                        <FormControl fullWidth sx={{ mb: 2, flex: 1 }} key={index}>
                            <FormLabel>{`Highlight ${index + 1}`}</FormLabel>
                            <Input
                                value={highlight}
                                onChange={(e) => setMission(prev => ({
                                    ...prev,
                                    highlights: prev.highlights.map( (v, i) => index === i ? e.target.value : v )
                                }))}
                            />
                        </FormControl>
                        <FormControl sx={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button variant={'outlined'} color={'danger'} onClick={(e) => handleRemoveHighlight(index)}>Remove</Button>
                        </FormControl>
                    </Stack>
                    
                ))}
                <Button variant="soft" onClick={handleAddHighlight}>Add Highlight</Button>
                <Button onClick={handleSubmit} color={'success'} fullWidth variant="soft">Save Mission Changes</Button>
            </Sheet>
        </>
    )
}

const mapStateToProps = (store) => ({
    apiKey: store.admin?.apiKey,
    siteId: store.admin.site._id,
    content: store.admin.site.landing.mission
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateSiteLandingMission
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AdminLandingMissionEditor);