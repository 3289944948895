import * as React from 'react';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

import { connect } from 'react-redux';
import { clearToBeRecoveredCaseId, recoverCase } from '../redux/actions';
import { useSnackbar } from 'notistack';
import { bindActionCreators } from 'redux';

function ConfirmCaseRecoverModal(props) {
  const { open, setOpen, bundle, caseid, apiKey, recoverCase, setBackDropOpen, clearToBeRecoveredCaseId } = props;

  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setBackDropOpen(false);
    setOpen(false);
    clearToBeRecoveredCaseId();
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setOpen(false);
    setBackDropOpen(true);
    recoverCase(caseid, apiKey, enqueueSnackbar, handleClose);
  } 
  return (
      <Modal open={open} onClose={handleClose}>
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            {/* <WarningRoundedIcon /> */}
            Confirmation
          </DialogTitle>
          <Divider />
          <DialogContent>
            Are you sure you want to recover {caseid} bundle?
          </DialogContent>
          <DialogActions>
            <Button variant="solid" color="primary" onClick={handleSubmit}>
              Recover Bundle
            </Button>
            <Button variant="plain" color="neutral" onClick={() => setOpen(false)}>
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
  );
}

const mapStateToProps = (store) => ({
  currentEmail: store.app.email,
  apiKey: store.user.apiKey,
  caseid: store.app.toBeRecoveredCaseId
})
const mapDispatchProps = (dispatch) => bindActionCreators({
  recoverCase,
  clearToBeRecoveredCaseId
}, dispatch);
export default connect(mapStateToProps, mapDispatchProps)(ConfirmCaseRecoverModal);
