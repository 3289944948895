import React, {useEffect, useRef, useState} from 'react';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { connect } from 'react-redux';
import DropContainer from './DropContainer';
import { Box } from '@mui/material';
import EditorCustomTreeItem from './EditorCustomTreeItem';

import { createTheme } from '@mui/material/styles';
import { CssVarsProvider } from '@mui/joy';
import cssVars from '@mui/system/cssVars';

const theme = createTheme({
  cssVars
});

const runAsync = (fn, args) => {
  const worker = new Worker(
    URL.createObjectURL(
      new Blob([`postMessage((${fn})(${JSON.stringify(args)}));`]),
      { type: 'application/javascript; charset=utf-8' }
    )
  );
  return new Promise((res, rej) => {
    worker.onmessage = ({ data }) => {
      res(data);
      worker.terminate();
    };
    worker.onerror = (err) => {
      rej(err);
      worker.terminate();
    };
  });
};

function EditorBundleSpace({ xitems }) {
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [items, setItems] = useState([]);

  const handleRadioChange = (itemId) => {
    setSelectedItemId(itemId);
  };

  // State to store the heights of the lines between radio buttons
  const [lineHeights, setLineHeights] = useState({});
  const radioRefs = useRef({});

  // const calculateLineHeights = () => {
  //   const heights = {};
  //   const radioIds = Object.keys(radioRefs.current);
  //   for (let i = 0; i < radioIds.length - 1; i++) {
  //     const id = radioIds[i];
  //     const nextId = radioIds[i + 1];
  //     const currentRadio = radioRefs.current[id];
  //     const nextRadio = radioRefs.current[nextId];
  //     if (currentRadio && nextRadio) {
  //       const currentRadioRect = currentRadio.getBoundingClientRect();
  //       const nextRadioRect = nextRadio.getBoundingClientRect();
  //       const height = nextRadioRect.top - currentRadioRect.bottom;
  //       heights[id] = height >= 0 ? height : 0;
  //     }
  //   }
  //   setLineHeights(heights);
  // }

  const calculateLineHeights = () => {
    const radioRects = {};
    const radioIds = Object.keys(radioRefs.current);
    for (let id of radioIds) {
      if (radioRefs.current[id]) {
        radioRects[id] = radioRefs.current[id].getBoundingClientRect();
      }
    }

    const fn = (radioRects) => {
      const heights = {};
      const radioIds = Object.keys(radioRects);
      for (let i = 0; i < radioIds.length - 1; i++) {
        const id = radioIds[i];
        const nextId = radioIds[i + 1];
        const currentRadioRect = radioRects[id];
        const nextRadioRect = radioRects[nextId];
        if (currentRadioRect && nextRadioRect) {
          const height = nextRadioRect.top - currentRadioRect.bottom;
          heights[id] = height >= 0 ? height : 0;
        }
      }
      return heights;
    };

    runAsync(fn, radioRects).then((heights) => {
      setLineHeights(heights);
    });
  };

  useEffect(() => {
    console.log('xitems:', xitems);
    setItems(xitems);
  }, [xitems]);


  return (
    <CssVarsProvider theme={theme}>
      {(items && items.length > 0)?(
        <RichTreeView
          items={items}
          getItemId={(item) => item._id}
          getItemLabel={(item) => item.name}
          defaultExpandedItems={items.filter((value) => value.type==='folder').map(value => value._id)}
          defaultSelectedItems="1.1"
          sx={{ height: 'fit-content', flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
          slots={{ item: (props) => <EditorCustomTreeItem
            {...props}
            space={'bundle'}
            selectedItemId={selectedItemId}
            onRadioChange={handleRadioChange}
            radioRefs={radioRefs}
            lineHeights={lineHeights}
            calculateLineHeights={calculateLineHeights}
            />
          }}
        />
      ):(
        <DropContainer space={'bundle'} />
      )}
    </CssVarsProvider>
  )
}

const mapStateToProps = (store) => ({
  xitems: store.caseData.bundle.items,
  loading: store.app.bundleUpdating
})
export default connect(mapStateToProps, null)(EditorBundleSpace);

