import React, { useState, useEffect} from 'react';
import Box from '@mui/joy/Box';
import { CircularProgress } from '@mui/joy';
import DashBundleCard from './DashBundleCard.js';
import DashSharedBundleCard from './DashSharedBundleCard.js';

export default function DashFavoriteBundleList(props){
  const { cases, loadingCases, setBackDropOpen, isShared, setOpenCaseRenameModal, setOpenCaseShareModal, setOpenCaseDeleteModal, setOpenCaseRemoveModal, setOpenCaseRecoverModal } = props;
  const [favoriteCases, setFavoriteCases] = useState([])
  useEffect(() => {
    if(cases && cases.length > 0){
      setFavoriteCases(cases.filter(value => value.favorite))
    } else {
      setFavoriteCases([])
    }
  }, [cases, loadingCases])

  if(loadingCases){
    return (
      <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh'}}>
        <CircularProgress />
      </Box>
    )
  }
  return (
    <>
    {favoriteCases.map(bundle => isShared ?
      <DashSharedBundleCard key={`fav_shared${bundle._id}`} prefix={'sfav'} bundle={bundle} setOpenCaseDeleteModal={setOpenCaseDeleteModal} setOpenCaseShareModal={setOpenCaseShareModal} setBackDropOpen={setBackDropOpen} setOpenCaseRenameModal={setOpenCaseRenameModal} setOpenCaseRemoveModal={setOpenCaseRemoveModal} setOpenCaseRecoverModal={setOpenCaseRecoverModal} /> :
      <DashBundleCard key={`fav_own${bundle._id}`} prefix={'fav'} bundle={bundle} setOpenCaseDeleteModal={setOpenCaseDeleteModal} setOpenCaseShareModal={setOpenCaseShareModal} setBackDropOpen={setBackDropOpen} setOpenCaseRenameModal={setOpenCaseRenameModal} setOpenCaseRemoveModal={setOpenCaseRemoveModal} setOpenCaseRecoverModal={setOpenCaseRecoverModal} />
    )}
    </>
  )
}