import React, { useState, useEffect } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Sheet,
  Typography,
  Box,
  Stack,
} from '@mui/joy';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateSiteFAQBody } from '../redux/actions/index.js';
import GradientCover from './GradientCover.js';

const AdminFAQContentEditor = ({ content, apiKey, siteId, updateSiteFAQBody}) => {
  const [body, setBody] = useState(content || { title: '', subtitle: '', image: {src: '', alt: ''}, questions: [] });

  useEffect(() => {
    setBody(content || { title: '', subtitle: '', image: {src: '', alt: ''}, questions: [] });
  }, [content]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBody(prev => ({
        ...prev,
        image: {
          ...prev.image,
          file,
          src: URL.createObjectURL(file),
        }
      }))
    }
  }

  const handleAddQuestion = () => {
    setBody((prev) => ({
      ...prev,
      questions: [...prev.questions, { question: '', answer: '' }],
    }));
  };

  const handleRemoveQuestion = (index) => {
    setBody((prev) => ({
      ...prev,
      questions: prev.questions.filter((_, i) => i !== index),
    }));
  };

  const handleChange = (field, value) => {
    setBody((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleQuestionChange = (index, field, value) => {
    setBody((prev) => {
      const updatedQuestions = [...prev.questions];
      updatedQuestions[index][field] = value;
      return { ...prev, questions: updatedQuestions };
    });
  };

  const handleSubmit = (event) => {
    const formData = new FormData();
    formData.append('body', JSON.stringify(body));
    if(body.image?.file){
      formData.append(`image`, body.image.file);
    }
    updateSiteFAQBody(apiKey, siteId, formData)
  }

  return (
    <>
      <Typography level="h4" gutterBottom>
        FAQ Body Editor
      </Typography>
      <Sheet sx={{ mb: 3, p: 2, borderRadius: '10px' }} variant={'outlined'}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <FormControl fullWidth>
            <FormLabel>Title</FormLabel>
            <Input
              value={body?.title}
              onChange={(e) => handleChange('title', e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth>
            <FormLabel>Subtitle</FormLabel>
            <Input
              value={body?.subtitle}
              onChange={(e) => handleChange('subtitle', e.target.value)}
            />
          </FormControl>
          <Stack direction={'column'} spacing={2} alignItems={'center'}>
            <GradientCover title={'Faq Body Image Src'} image={body?.image} />
            <input type="file" accept="image/*" onChange={(e) => handleImageChange(e)} />
          </Stack>
          {body?.questions?.map((questionItem, index) => (
            <Sheet key={index} sx={{ mb: 2, p: 2, borderRadius: '10px' }} variant={'soft'}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <FormControl fullWidth>
                  <FormLabel>Question</FormLabel>
                  <Input
                    value={questionItem.question}
                    onChange={(e) => handleQuestionChange(index, 'question', e.target.value)}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <FormLabel>Answer</FormLabel>
                  <Input
                    value={questionItem.answer}
                    onChange={(e) => handleQuestionChange(index, 'answer', e.target.value)}
                    minRows={3}
                  />
                </FormControl>
                <Button variant='outlined' color="danger" onClick={() => handleRemoveQuestion(index)}>
                  Remove Question
                </Button>
              </Box>
            </Sheet>
          ))}
        </Box>
        <Stack spacing={2}>
            <Button variant={'plain'} onClick={handleAddQuestion}>Add Question</Button>
            <Button variant='soft' color='success' onClick={handleSubmit}>Save Changes</Button>
        </Stack>
      </Sheet>
    </>
  );
};

const mapStateToProps = (store) => ({
  apiKey: store.admin?.apiKey,
  siteId: store.admin.site._id,
  content: store.admin.site.faq.body
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateSiteFAQBody
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AdminFAQContentEditor);
