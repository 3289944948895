import React, { useState, useEffect } from 'react';
import {
    Avatar,
    Box,
    Chip,
    Divider,
    Link,
    Table,
    Sheet,
    Checkbox,
    IconButton,
    Typography,
    Menu,
    MenuButton,
    MenuItem,
    Dropdown
} from '@mui/joy';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import BlockIcon from '@mui/icons-material/Block';
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';

import { formatDate } from '../utils'
import { truncateString } from '../utils';

function RowMenu() {
  return (
    <Dropdown>
      <MenuButton
        slots={{ root: IconButton }}
        slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}
      >
        <MoreHorizRoundedIcon />
      </MenuButton>
      <Menu size="sm" sx={{ minWidth: 140 }}>
        <MenuItem>Details</MenuItem>
      </Menu>
    </Dropdown>
  );
}

const AdminPaymentRow = ({row, selected, setSelected}) => {
    const [imageSrc, setImageSrc] = useState('');

    useEffect(() => {
    if (row?.profileImage?.data) {
      try{
        const blob = new Blob([new Uint8Array(row.profileImage.data)], { type: 'image/jpeg' });
        const reader = new FileReader();
        reader.onload = () => {
            setImageSrc(reader.result);
        };
        reader.readAsDataURL(blob);
      } catch(error){
        console.log(error);
      }
    } else {
      setImageSrc('');
    }
  }, [row?.profileImage])

    return (
        <tr key={row._id}>
            <td style={{ textAlign: 'center', minWidth: 120 }}>
                <Checkbox
                  size="sm"
                  checked={selected.includes(row._id)}
                  color={selected.includes(row._id) ? 'primary' : undefined}
                  onChange={(event) => {
                      setSelected((ids) =>
                      event.target.checked
                          ? ids.concat(row._id)
                          : ids.filter((itemId) => itemId !== row._id),
                      );
                  }}
                  slotProps={{ checkbox: { sx: { textAlign: 'left' } } }}
                  sx={{ verticalAlign: 'text-bottom' }}
                />
            </td>
            <td>
                <Typography level="body-xs">{row.transactionId}</Typography>
            </td>
            <td>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <Avatar size="sm" src={imageSrc}>{row.firstname[0]}</Avatar>
                <div>
                    <Typography level="body-xs">{`${row.firstname} ${row.lastname}`}</Typography>
                    <Typography level="body-xs">{row.email}</Typography>
                </div>
                </Box>
            </td>
            <td>
                <Typography level="body-xs">{formatDate(row.date)}</Typography>
            </td>
            <td>
              <Typography level="body-xs">{`$${row.amount}`}</Typography>
            </td>
            <td>
                <Chip
                variant="soft"
                size="sm"
                startDecorator={
                    {
                    active: <CheckRoundedIcon />,
                    inactive: <AutorenewRoundedIcon />,
                    deleted: <BlockIcon />,
                    }[row.status]
                }
                color={
                    {
                    active: 'success',
                    inactive: 'neutral',
                    deleted: 'danger',
                    }[row.status]
                }
                >
                {row.status}
                </Chip>
            </td>
            <td>
              <Typography level="body-xs">{row.role}</Typography>
            </td>
            <td>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <RowMenu />
                </Box>
            </td>
        </tr>
    )
}

export default AdminPaymentRow;