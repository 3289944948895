import React, { useEffect} from 'react';
import Box from '@mui/joy/Box';

import { CircularProgress } from '@mui/joy';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DashBundleCard from './DashBundleCard.js';
import DashSharedBundleCard from './DashSharedBundleCard.js';

function DashBundleList(props){
    const {cases, loadingCases, isShared, setOpenCaseRecoverModal, setOpenCaseRemoveModal, setOpenCaseRenameModal, setBackDropOpen, setOpenCaseShareModal, setOpenCaseDeleteModal } = props;

    useEffect(() => {
    }, [cases, loadingCases])

    if(loadingCases){
      return (
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh'}}>
          <CircularProgress />
        </Box>
      )
    }
    return (
    <>
    {cases.map(bundle => isShared ?
      <DashSharedBundleCard key={`shared${bundle._id}`} bundle={bundle} prefix={'shared'} setOpenCaseDeleteModal={setOpenCaseDeleteModal} setOpenCaseShareModal={setOpenCaseShareModal} setBackDropOpen={setBackDropOpen} setOpenCaseRenameModal={setOpenCaseRenameModal} setOpenCaseRemoveModal={setOpenCaseRemoveModal} setOpenCaseRecoverModal={setOpenCaseRecoverModal} /> :
      <DashBundleCard key={`own${bundle._id}`} bundle={bundle} prefix={'own'} setOpenCaseDeleteModal={setOpenCaseDeleteModal} setOpenCaseShareModal={setOpenCaseShareModal} setBackDropOpen={setBackDropOpen} setOpenCaseRenameModal={setOpenCaseRenameModal} setOpenCaseRemoveModal={setOpenCaseRemoveModal} setOpenCaseRecoverModal={setOpenCaseRecoverModal} />
    )}
    </>
  )
}

const mapStateToProps = (store) => ({
})
const mapDispatchProps = (dispatch) => bindActionCreators({
}, dispatch);
export default connect(mapStateToProps, mapDispatchProps)(DashBundleList);