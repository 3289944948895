import React from "react";

const ContactUsInfo = ({ content }) => {
    return (
        <section class="contact-info pt-0">
            <div class="container">
                <div class="row align-items-center">
                <div class="col-lg-4 col-md-12">
                    <div class="contact-media">
                    <i class="flaticon flaticon-office"></i>
                    <span>Address:</span>
                    <p>{content?.address}</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mt-5 mt-lg-0">
                    <div class="contact-media">
                    <i class="flaticon flaticon-email"></i>
                    <span>Email Address</span>
                    <a href={`mailto:${content?.email}`}> {content?.email}</a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mt-5 mt-lg-0">
                    <div class="contact-media">
                    <i class="flaticon flaticon-support"></i>
                    <span>Phone Number</span>
                    <a href={`tel:${content?.phone}`}>{content?.phone}</a>
                    </div>
                </div>
                </div>
            </div>
            </section>
    )
}

export default ContactUsInfo;