import React, { useState, useEffect} from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Stack from '@mui/joy/Stack';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  renameCase,
  changeTheme,
  mergePDF,
  createNewCase,
  generateToc,
  toggleCaseFavorite,
  logout,
  toggleTheme,
  loadMessages
} from '../redux/actions/index.js';

import Layout from '../components/DashLayout';
import Navigation from '../components/DashNavigation';
import Header from '../components/DashHeader';
import TableFiles from '../components/DashTableFiles';
import RenameCaseModal from '../components/RenameCaseModal.js';
import DashFavoriteBundleList from '../components/DashFavoriteBundleList.js';
import DashBundleList from '../components/DashBundleList.js';
import DashBundleMobileTable from '../components/DashBundleMobileTable.js';
import { DashRightBundleDetailsSection } from '../components/DashRightBundleDetailsSection.js';
import { Backdrop, CircularProgress as MCircularProgress } from '@mui/material';
import { Bookmarks, TableRows, ViewModule } from '@mui/icons-material';
import ShareCaseModal from '../components/ShareCaseModal.js';
import ConfirmCaseRemoveModal from '../components/ConfirmCaseRemoveModal.js';
import ConfirmCaseDeleteModal from '../components/ConfirmCaseDeleteModal.js';
import ConfirmCaseRecoverModal from '../components/ConfirmCaseRecoverModal.js';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Joyride from 'react-joyride';
import CreateNewBundleModal from '../components/CreateNewBundleModal.js';

const steps = [
  {
    target: '.ravenride-my-first-step',
    content: 'This is my awesome feature!',
  },
  {
    target: '.ravenride-my-other-step',
    content: 'Here is another great feature!',
  },
  // Add more steps as needed
];


function Dashboard(props) {
  const { user: { cases, trash, shared }, loadingCases, loadMessages } = props;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openCaseRenameModal, setOpenCaseRenameModal] = useState(false);
  const [openCaseShareModal, setOpenCaseShareModal] = useState(false);
  const [openCaseDeleteModal, setOpenCaseDeleteModal] = useState(false);
  const [openCaseRemoveModal, setOpenCaseRemoveModal] = useState(false);
  const [openCaseRecoverModal, setOpenCaseRecoverModal] = useState(false);
  const [openCreateNewBundleModal, setOpenCreateNewBundleModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(1);
  const [searchText, setSearchText] = useState('')
  const [filteredCases, setFilteredCases] = useState(cases);
  const [backDropOpen, setBackDropOpen] = useState(false);

  const [selected, setSelected] = useState(1);
  const pageTitle = ['Dashboard', 'My Bundles', 'Shared Files', 'Trash']

  const [run, setRun] = useState(true);

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    const finishedStatuses = ['finished', 'skipped'];

    if (finishedStatuses.includes(status)) {
      setRun(false);
    }
  };

  useEffect(() => {
    if(selected === 1){
      if(searchText.length > 0){
        setFilteredCases(cases.filter(acase => acase.name.toLowerCase().includes(searchText.toLowerCase())))
      } else {
        setFilteredCases(cases);
      }
    } else if(selected === 2) {
      if(searchText.length > 0){
        setFilteredCases(shared.filter(acase => acase.name.toLowerCase().includes(searchText.toLowerCase())))
      } else {
        setFilteredCases(shared);
      }
    }
    else if(selected === 3) {
      if(searchText.length > 0){
        setFilteredCases(trash.filter(acase => acase.name.toLowerCase().includes(searchText.toLowerCase())))
      } else {
        setFilteredCases(trash);
      }
    }    
  }, [loadingCases, cases, searchText, selected, shared, trash])

  useEffect(() => {
    loadMessages()
  }, [])

  return (
    <CssVarsProvider disableTransitionOnChange>
      <Joyride
        steps={steps}
        run={run}
        callback={handleJoyrideCallback}
        continuous
        scrollToFirstStep
        showSkipButton
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
      <HelmetProvider>
        <Helmet>
        <title>{selected >=0 && selected < pageTitle.length ? (pageTitle[selected] || 'Dashboard') : 'Dashboard'}</title>
      </Helmet>
      </HelmetProvider>
      
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => 9999 }}
        open={backDropOpen}
      >
        <MCircularProgress color="inherit" />
      </Backdrop>
      <RenameCaseModal open={openCaseRenameModal} setOpen={setOpenCaseRenameModal} setBackDropOpen={setBackDropOpen} />
      <ShareCaseModal open={openCaseShareModal} setOpen={setOpenCaseShareModal} setBackDropOpen={setBackDropOpen} />
      <CreateNewBundleModal open={openCreateNewBundleModal} setOpen={setOpenCreateNewBundleModal} setBackDropOpen={setBackDropOpen} />
      <ConfirmCaseRemoveModal open={openCaseRemoveModal} setOpen={setOpenCaseRemoveModal} setBackDropOpen={setBackDropOpen} />
      <ConfirmCaseDeleteModal open={openCaseDeleteModal} setOpen={setOpenCaseDeleteModal} setBackDropOpen={setBackDropOpen}  />
      <ConfirmCaseRecoverModal open={openCaseRecoverModal} setOpen={setOpenCaseRecoverModal} setBackDropOpen={setBackDropOpen} />
      <CssBaseline />
      {drawerOpen && (
        <Layout.SideDrawer open={drawerOpen} onClose={() => setDrawerOpen(false)}>
          <Navigation selected={selected}  setSelected={setSelected} setOpenCreateNewBundleModal={setOpenCreateNewBundleModal} backDropOpen={backDropOpen} setBackDropOpen={setBackDropOpen} />
        </Layout.SideDrawer>
      )}
      <Stack
        id="tab-bar"
        direction="row"
        justifyContent="space-around"
        spacing={1}
        sx={{
          display: { xs: 'flex', sm: 'none' },
          zIndex: '999',
          bottom: 0,
          position: 'fixed',
          width: '100dvw',
          py: 2,
          backgroundColor: 'background.body',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Button
          variant="plain"
          color="neutral"
          component="a"
          aria-pressed={selectedOption === 1}
          href="#"
          size="sm"
          onClick={() => setSelectedOption(1)}
          startDecorator={<ViewModule />}
          sx={{ flexDirection: 'column', '--Button-gap': 0 }}
        >
          Bundles
        </Button>
        <Button
          variant="plain"
          color="neutral"
          aria-pressed={selectedOption === 2}
          component="a"
          href="#"
          size="sm"
          onClick={() => setSelectedOption(2)}
          startDecorator={<Bookmarks />}
          sx={{ flexDirection: 'column', '--Button-gap': 0 }}
        >
          Favorites
        </Button>
        <Button
          variant="plain"
          color="neutral"
          aria-pressed={selectedOption === 3}
          component="a"
          href="#"
          size="sm"
          onClick={() => setSelectedOption(3)}
          startDecorator={<TableRows />}
          sx={{ flexDirection: 'column', '--Button-gap': 0 }}
        >
          Table
        </Button>
      </Stack>
      <Layout.Root
        sx={{
          gridTemplateColumns: {
            xs: '1fr',
            sm: 'minmax(64px, 200px) minmax(450px, 1fr)',
            md: 'minmax(160px, 300px) minmax(600px, 1fr) minmax(300px, 420px)',
          },
          ...(drawerOpen && {
            height: '100vh',
            overflow: 'hidden',
          }),
        }}
      >
        <Layout.Header>
          <Header selected={selected} setSelected={setSelected} setDrawerOpen={setDrawerOpen} setBackDropOpen={setBackDropOpen} setOpenCreateNewBundleModal={setOpenCreateNewBundleModal} selectedOption={selectedOption} setSelectedOption={setSelectedOption} searchText={searchText} setSearchText={setSearchText} />
        </Layout.Header>
        <Layout.SideNav>
          <Navigation selected={selected} setDrawerOpen={setDrawerOpen} setSelected={setSelected} setOpenCreateNewBundleModal={setOpenCreateNewBundleModal} setBackDropOpen={setBackDropOpen} />
        </Layout.SideNav>
        <Layout.Main>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
              gap: 2,
              mb: 10,
              // pr: {sm: 2},              
            }}
          >
            {' '}            
            {selectedOption === 1 && 
              <DashBundleList isShared={selected === 2} setOpenCaseRecoverModal={setOpenCaseRecoverModal} setOpenCaseRemoveModal={setOpenCaseRemoveModal} setOpenCaseDeleteModal={setOpenCaseDeleteModal} setOpenCaseShareModal={setOpenCaseShareModal} setBackDropOpen={setBackDropOpen} cases={filteredCases} loadingCases={loadingCases} setOpenCaseRenameModal={setOpenCaseRenameModal} />
            }
            {selectedOption === 2 && 
              <DashFavoriteBundleList isShared={selected === 2} setOpenCaseRecoverModal={setOpenCaseRecoverModal} setOpenCaseRemoveModal={setOpenCaseRemoveModal}  setOpenCaseDeleteModal={setOpenCaseDeleteModal} setOpenCaseShareModal={setOpenCaseShareModal} setBackDropOpen={setBackDropOpen} cases={filteredCases} loadingCases={loadingCases} setOpenCaseRenameModal={setOpenCaseRenameModal} />
            }
            {selectedOption === 3  &&
              <>
                <TableFiles cases={filteredCases} loadingCases={loadingCases} />
                <DashBundleMobileTable cases={filteredCases} loadingCases={loadingCases} />
              </>
            }
          </Box>
        </Layout.Main>
        <DashRightBundleDetailsSection />
      </Layout.Root>
    </CssVarsProvider>
  );
}

const mapStateToProps = (store) => ({
  caseData: store.caseData,
  user: store.user,
  bundleBusy: store.app.bundleUpdating,
  deskBusy: store.app.creatingFolder,
  toolBarBusy: store.app.toolBarBusy,
  loadingCases: store.app.loadingCases
})
const mapDispatchProps = (dispatch) => bindActionCreators({
  renameCase,
  changeTheme,
  mergePDF,
  createNewCase,
  generateToc,
  toggleCaseFavorite,
  logout,
  toggleTheme,
  loadMessages
}, dispatch);
export default connect(mapStateToProps, mapDispatchProps)(Dashboard);